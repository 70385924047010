import React, { useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { userCustomerId } from '../../selectors/auth';
import { selectCustomer } from '../../selectors/customer';
import { countMonetizationOrdersByCustomer } from '../../selectors/monetizationOrder';
import * as dailyInsightsActions from '../../store/customers/dailyInsights/actions';

// Pages Components
import CustomerMetrics from '../../components/Customers/CustomerMetrics';
import RevenuesGraph from '../../components/Dashboard/Customer/RevenuesGraph';
import MonetizationItemsInsights from '../../components/Dashboard/Customer/MonetizationItemsInsights';
import UserCard from '../../components/Common/UserCard';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const CustomerDashboard = (props) => {
  const customerId = useSelector(userCustomerId);
  const customer = useSelector(selectCustomer(customerId));
  const showRebate = customer ? customer.showRebate : true;
  const isAgency = customer ? customer.isAgency : false;
  const countMonetizationOrders = useSelector(
    countMonetizationOrdersByCustomer(customerId),
  );
  const countActiveMonetizationOrders = useSelector(
    countMonetizationOrdersByCustomer(customerId, true),
  );

  const loading = useSelector((state) => state.DailyCustomerInsights.loading);

  const startDate = useSelector(
    (state) => state.DailyCustomerInsights.startDate,
  );
  const endDate = useSelector((state) => state.DailyCustomerInsights.endDate);
  const filters = {
    customerId,
    monetizationOrderIds: null,
  };

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    customerId: filters?.customerId || customerId || '',
    monetizationOrderIds: filters?.monetizationOrderIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, customerId, monetizationOrderIds }) => {
      const selectedStartDate = startDate.utc().startOf('day').toDate();
      const selectedEndDate = endDate.utc().endOf('day').toDate();
      dispatch(
        dailyInsightsActions.fetchDailyInsights(
          selectedStartDate,
          selectedEndDate,
          {
            monetizationOrderIds,
            customerId,
          },
        ),
      );
      setSelectedFilters({
        startDate,
        endDate,
        monetizationOrderIds,
        customerId,
      });
    },
  );

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        title={t('Dashboard')}
        onRefresh={() => refresh(selectedFilters)}
        loading={loading}
      />
      <Row>
        <Col md="12">
          <UserCard
            firstBoxLabel="Total Monetization Orders"
            firstBoxValue={countMonetizationOrders}
            secondBoxLabel="Active Monetization Orders"
            secondBoxValue={countActiveMonetizationOrders}
            pathLink="/customers/dailyInsights"
            iconLink="bx bx-bar-chart-square"
            textLink="Daily Insights"
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showCustomerFilters
            initialFilters={selectedFilters}
          />
        </Col>
      </Row>

      <Row>
        <CustomerMetrics showRebate={showRebate} isAgency={isAgency} />
      </Row>
      <Row>
        <Col md="12">
          <RevenuesGraph
            dateRange={selectedFilters}
            showRebate={showRebate}
            isAgency={isAgency}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <MonetizationItemsInsights
            customerId={customerId}
            showRebate={showRebate}
            isAgency={isAgency}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomerDashboard;
