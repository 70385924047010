import React from 'react';
import { Col, InputGroupAddon } from 'reactstrap';
import { Input, Date, Select, Checkbox } from '../../lib/form-field';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-hook-clipboard';

import moment from 'moment';

const InputLead = ({
  listValues,
  typeField,
  onChangeUpdateField,
  asyncUrlValues,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();
  switch (typeField) {
    case 'select':
      return (
        <Select
          isMulti={false}
          isSearchable={true}
          options={
            Array.isArray(listValues)
              ? listValues.map(({ title, value }) => ({
                  label: t(title),
                  value,
                }))
              : []
          }
          onChange={(event) => onChangeUpdateField(event)}
          {...restProps}>
          {children}
        </Select>
      );
    case 'async-select':
      const loadOptions = async (search, loadedOptions) => {
        if (search < 3) return { options: [] };

        //?search=&offset=0 || ?search=mu&offset=0
        const response = await fetch(
          `${asyncUrlValues}/?search=${encodeURIComponent(
            search.toLowerCase(),
          )}&offset=${loadedOptions.length}`,
        );
        const responseJSON = await response.json();

        return {
          options: responseJSON,
        };
      };

      return (
        <Select
          isMulti={false}
          isSearchable={true}
          loadOptions={loadOptions}
          debounceTimeout={500}
          loadOptionsOnMenuOpen={false}
          onChange={(event) => onChangeUpdateField(event)}
          {...restProps}>
          {children}
        </Select>
      );
    case 'date':
      return (
        <Date format="DD/MM/YYYY" {...restProps}>
          {children}
        </Date>
      );
    case 'boolean':
      return <Checkbox {...restProps} />;
    case 'textarea':
      return (
        <Input type="textarea" {...restProps}>
          {children}
        </Input>
      );
    default:
      return <Input {...restProps}>{children}</Input>;
  }
};

const InputLeadGroup = (props) => (
  <Col md={props.size}>
    {props.onCopyToClipboard ? (
      <InputLead addonType="prepend" {...props}>
        <InputGroupAddon addonType="prepend">
          <button
            className="btn btn-outline-dark p-0"
            style={{ width: '31px', zIndex: 0 }}
            type="button"
            onClick={props.onCopyToClipboard}>
            <i className="bx bx-copy-alt d-block font-size-15" />
          </button>
        </InputGroupAddon>
      </InputLead>
    ) : (
      <InputLead {...props} />
    )}
  </Col>
);

const onChangeUpdateField = (field, setFieldValue) => {
  if (!field.onChangeUpdateKey) return () => {};

  //if place is city not update zipCode field
  if (field.onChangeUpdateField === 'zipCode')
    return (value) =>
      value.cityName !== value.place &&
      value[field.onChangeUpdateKey] &&
      setFieldValue(field.onChangeUpdateField, value[field.onChangeUpdateKey]);

  return (value) =>
    value[field.onChangeUpdateKey] &&
    setFieldValue(field.onChangeUpdateField, value[field.onChangeUpdateKey]);
};

const InputLeadField = ({ field, setFieldValue, values, ...restProps }) => {
  const [clipboard, copyToClipboard] = useClipboard();
  const { t } = useTranslation();

  const handleCopyToClipboard = (field, values) => {
    switch (field.typeField) {
      case 'select':
        return copyToClipboard(t(values[field.name]));
      case 'async-select':
        return copyToClipboard(values[field.name].label);
      case 'date':
        return copyToClipboard(moment(values[field.name]).format('DD/MM/YYYY'));
      default:
        return copyToClipboard(values[field.name]);
    }
  };

  return (
    <InputLeadGroup
      key={field.id}
      name={field.name}
      typeField={field.typeField}
      listValues={field.listValues}
      asyncUrlValues={field.asyncUrlValues}
      size={field.size || '3'}
      required={field.required ?? false}
      readOnly={field.readOnly ?? false}
      onCopyToClipboard={() => handleCopyToClipboard(field, values)}
      onChangeUpdateField={onChangeUpdateField(field, setFieldValue)}
      {...restProps}
    />
  );
};

export default InputLeadField;
