const statsTypes = {
  RESET_DAILY_SALE_INSIGHT_STATE: 'RESET_DAILY_SALE_INSIGHT_STATE',
  DAILY_SALE_INSIGHTS: {
    REQUEST: 'DAILY_SALE_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_SALE_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_SALE_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
