import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CAMPAIGN_STATE,
});

export const createCampaign = (campaign) => ({
  type: types.CREATE_CAMPAIGN.REQUEST,
  campaign,
});

export const createCampaignSuccess = (campaign) => ({
  type: types.CREATE_CAMPAIGN.SUCCESS,
  campaign,
});

export const createCampaignFailure = (error) => ({
  type: types.CREATE_CAMPAIGN.FAILURE,
  error,
});

export const updateCampaign = (campaign) => ({
  type: types.UPDATE_CAMPAIGN.REQUEST,
  campaign,
});

export const updateCampaignSuccess = () => ({
  type: types.UPDATE_CAMPAIGN.SUCCESS,
});

export const updateCampaignFailure = (error) => ({
  type: types.UPDATE_CAMPAIGN.FAILURE,
  error,
});

export const updateOrderAcquisitionIdCampaign = (
  campaignIds,
  prevCampaignIds,
  acquisitionOrderId,
) => ({
  type: types.UPDATE_ACQUISITION_ORDER_ID.REQUEST,
  campaignIds,
  prevCampaignIds,
  acquisitionOrderId,
});

export const updateOrderAcquisitionIdSuccess = () => ({
  type: types.UPDATE_ACQUISITION_ORDER_ID.SUCCESS,
});

export const updateOrderAcquisitionIdFailure = (error) => ({
  type: types.UPDATE_ACQUISITION_ORDER_ID.FAILURE,
  error,
});

export const syncCampaigns = () => ({
  type: types.SYNC_CAMPAIGNS.REQUEST,
});

export const syncCampaignsSuccess = (campaigns) => ({
  type: types.SYNC_CAMPAIGNS.SUCCESS,
  campaigns,
});

export const syncCampaignsFailure = (error) => ({
  type: types.SYNC_CAMPAIGNS.FAILURE,
  error,
});

export const syncFBCampaigns = (facebookAdAccountIds) => ({
  type: types.SYNC_FB_CAMPAIGNS.REQUEST,
  facebookAdAccountIds,
});

export const syncFBCampaignsSuccess = () => ({
  type: types.SYNC_FB_CAMPAIGNS.SUCCESS,
});

export const syncFBCampaignsFailure = (error) => ({
  type: types.SYNC_FB_CAMPAIGNS.FAILURE,
  error,
});
