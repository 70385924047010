export default {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  TEAM_LEADER: 'TEAM_LEADER',
  OPERATOR: 'OPERATOR',
  CUSTOMER: 'CUSTOMER',
  PUBLISHER: 'PUBLISHER',
  SALE: 'SALE',
};
