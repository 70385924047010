import React, { useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import * as campaignsActions from '../../../store/campaigns/actions';
import { selectCampaignsByPublisher } from '../../../selectors/campaign';
import {
  totalLeadsByCampaign,
  totalWorkedByCampaign,
  totalPositivesByCampaign,
  totalNegativesByCampaign,
  totalSupplierCostsByCampaign,
} from '../../../selectors/publisher';

import { useTranslation } from 'react-i18next';

const hideSensitiveDataString = '***';

const CampaignInsights = ({ campaign, showSensitiveData }) => {
  const leads = useSelector(totalLeadsByCampaign(campaign.id));
  const worked = useSelector(totalWorkedByCampaign(campaign.id));
  const positives = useSelector(totalPositivesByCampaign(campaign.id));
  const negatives = useSelector(totalNegativesByCampaign(campaign.id));
  const supplierCosts = useSelector(totalSupplierCostsByCampaign(campaign.id));

  const { t } = useTranslation();

  return (
    <tr>
      <td className="align-middle">
        <h5 className="font-size-14 mb-1 text-wrap">
          {showSensitiveData ? campaign.name : hideSensitiveDataString}
        </h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Incomings')}</p>
        <h5 className="mb-0">{leads}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Worked')}</p>
        <h5 className="mb-0">{worked}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Positive')}</p>
        <h5 className="mb-0">{positives}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Negative')}</p>
        <h5 className="mb-0">{negatives}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Revenues')}</p>
        <h5 className="mb-0 text-nowrap">
          {showSensitiveData
            ? isNaN(supplierCosts)
              ? 0
              : supplierCosts.toFixed(2)
            : hideSensitiveDataString}{' '}
          €
        </h5>
      </td>
    </tr>
  );
};

const CampaignsInsights = (props) => {
  const campaigns = useSelector(selectCampaignsByPublisher(props.supplierId));
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="clearfix">
            <h4 className="card-title mb-4">{t('Campaign Details')}</h4>
          </div>
          <div className="table-responsive mt-4">
            <Table className="mb-0">
              <tbody>
                {campaigns.map((campaign, index) => (
                  <CampaignInsights
                    key={'campaign_' + index}
                    campaign={campaign}
                    showSensitiveData={showSensitiveData}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CampaignsInsights;
