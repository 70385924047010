import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  templateType: yup.string().required(),
  subject: yup
    .string()
    .required()
    .when('templateType', {
      is: 'sms',
      then: () => yup.string().max(11).required(),
    }),
  messageText: yup.string().required(),
  linkType: yup.string().when('messageText', {
    is: (value) => value && value.includes('{{link}}'),
    then: () => yup.string().required(),
  }),
  monetizationOrderId: yup.string(),
  pushIcon: yup.string().url(),
  clickAction: yup.string(),
  /*
  monetizationOrderId: yup.string().when('linkType', {
    is: 'trackingLink',
    then: () => yup.string().required(),
  }),
  */
});

export default formValidation;
