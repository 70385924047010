import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as countriesActions from '../../store/countries/actions';
import { useDispatch, useSelector } from 'react-redux';
import FormCountry from '../../components/Countries/FormCountry';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Countries/tableColumns';
import {
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.Country.loading);
  const countries = useSelector((state) => state.Country.countries);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  const openEditModal = (country = null) => {
    setSelectedCountry(country);
    setEditModal(true);
  };

  const openViewModal = (country) => {
    setSelectedCountry(country);
    setViewModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Countries" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Country"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={countries}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Country"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedCountry} />
          </Modal>
          <Modal
            size="xl"
            title="Country"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCountry
              closeModal={() => setEditModal(false)}
              create={!selectedCountry}
              loading={loading}
              country={selectedCountry}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
