const statsTypes = {
    RESET_SURVEY_FORM_STATE: 'RESET_SURVEY_FORM_STATE',
    CREATE_SURVEY_FORM: {
      REQUEST: 'CREATE_SURVEY_FORM.REQUEST',
      SUCCESS: 'CREATE_SURVEY_FORM.SUCCESS',
      FAILURE: 'CREATE_SURVEY_FORM.FAILURE',
    },
    UPDATE_SURVEY_FORM: {
      REQUEST: 'UPDATE_SURVEY_FORM.REQUEST',
      SUCCESS: 'UPDATE_SURVEY_FORM.SUCCESS',
      FAILURE: 'UPDATE_SURVEY_FORM.FAILURE',
    },
    SYNC_SURVEY_FORMS: {
      REQUEST: 'SYNC_SURVEY_FORMS.REQUEST',
      SUCCESS: 'SYNC_SURVEY_FORMS.SUCCESS',
      FAILURE: 'SYNC_SURVEY_FORMS.FAILURE',
    },
  };
  
  export default statsTypes;
  
