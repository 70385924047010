import '@availity/yup';
import * as yup from 'yup';

const baseValidation = {
  create: yup.boolean().required(),
  campaignId: yup.string().required(),
  sendInitialSurvey: yup.string().when('create', {
    is: true,
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  closure: yup.string().when('create', {
    is: true,
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  monetizationOrders: yup.mixed().when('closure', {
    is: 'positive',
    then: () => yup.mixed().required(),
    otherwise: () => yup.mixed().notRequired(),
  }),
  negativeOutcome: yup.string().when('closure', {
    is: 'negative',
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  appointmentUserId: yup.string().when('closure', {
    is: 'no',
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  timeSlot: yup.string().when('appointmentUserId', {
    is: (value) => value && value !== 'none',
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
};

const createValidation = (field) => {
  switch (field.typeField) {
    case 'select':
      return field.required
        ? yup.string().required()
        : yup.string().notRequired();
    case 'date':
      return field.required ? yup.date().required() : yup.date().notRequired();
    case 'boolean':
      return yup
        .mixed()
        .transform((value, originalValue) =>
          originalValue[0] === 'yes' ? 'yes' : 'no',
        );
    default:
      return field.required
        ? yup.mixed().required()
        : yup.mixed().notRequired();
  }
};

const formValidation = (fields) => {
  let leadValidations = { ...baseValidation };
  for (let field of fields) {
    if (!field.showInFormLead) continue;
    leadValidations[field.name] = createValidation(field);
  }
  return yup.object().shape(leadValidations);
};

export default formValidation;
