export default {
    appointment: {
        title: 'Schedule appointment',
        icon: 'bx bx-calendar-event',
        color: 'info',
    },
    recall: {
        title: 'Recall',
        icon: 'bx bx-message-rounded-error',
        color: 'warning',
    },
    negative: {
        title: 'Negative Closure',
        icon: 'bx bx-message-rounded-x',
        color: 'danger',
    },
    positive: {
        title: 'Positive Closure',
        icon: 'bx bx-message-rounded-check',
        color: 'success',
    },
  };
  