import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import formValidation from './formValidation';

import * as countriesActions from '../../store/countries/actions';
import { useDispatch } from 'react-redux';

import companyPhoneExchanges from '../../config/companyPhoneExchanges';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormCountry = (props) => {
  const editingCountry = props.country || {};

  const facebookAdAccountIds = editingCountry.facebookAdAccountIds
    ? editingCountry.facebookAdAccountIds.join(',')
    : '';

  const [active, setActive] = useState(editingCountry.active || false);
  const [facebookModule, setFacebookModule] = useState(
    editingCountry.facebookModule || false,
  );
  const [googleModule, setGoogleModule] = useState(
    editingCountry.googleModule || false,
  );
  const [supplierModule, setSupplierModule] = useState(
    editingCountry.supplierModule || false,
  );
  const [audienceModule, setAudienceModule] = useState(
    editingCountry.audienceModule || false,
  );

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const facebookAdAccountArray =
      values.facebookAdAccountIds === ''
        ? []
        : values.facebookAdAccountIds.split(',');

    const countryData = {
      ...values,
      active,
      facebookModule,
      googleModule,
      supplierModule,
      facebookAdAccountIds: facebookAdAccountArray,
    };

    dispatch(
      props.create
        ? countriesActions.createCountry(countryData)
        : countriesActions.updateCountry({
            id: editingCountry.id,
            ...countryData,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingCountry,
            facebookAdAccountIds,
            facebookModule,
            googleModule,
            supplierModule,
            audienceModule,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="3">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="3">
                  <Input
                    id="waitingTimeRecall"
                    name="waitingTimeRecall"
                    label="Waiting Time Recall"
                    min={0.5}
                    type="number"
                    step="0.1"
                  />
                  <p className="font-size-12 text-muted">
                    In hours (ex. 0,5 or 2)
                  </p>
                </Col>
                <Col md="2">
                  <Switch
                    name="active"
                    label="Active?"
                    defaultChecked={active}
                    onClick={() => setActive(!active)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Select
                    name="phoneExchange"
                    id="phoneExchange"
                    label="Phone Exchange"
                    isMulti={false}
                    options={companyPhoneExchanges}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Switch
                    name="facebookModule"
                    label="Facebook Module"
                    defaultChecked={facebookModule}
                    onClick={() => setFacebookModule(!facebookModule)}
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="googleModule"
                    label="Google Module"
                    defaultChecked={googleModule}
                    onClick={() => setGoogleModule(!googleModule)}
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="supplierModule"
                    label="Supplier Module"
                    defaultChecked={googleModule}
                    onClick={() => setSupplierModule(!supplierModule)}
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="audienceModule"
                    label="Audience Module"
                    defaultChecked={audienceModule}
                    onClick={() => setAudienceModule(!audienceModule)}
                  />
                </Col>
              </Row>
              <Collapse isOpen={facebookModule}>
                <Row>
                  <Col md="6">
                    <Input
                      type="textarea"
                      name="facebookAdAccountIds"
                      id="facebookAdAccountIds"
                      label="Facebook Ad Account Ids"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex. value1,value2,value3,
                      ...)
                    </p>
                  </Col>
                  <Col md="6">
                    <Input
                      type="textarea"
                      name="facebookAccessToken"
                      id="facebookAccessToken"
                      label="Facebook Access Token"
                    />
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCountry;
