import React from 'react';
import {
  ViewButton,
  EditButton,
  ActiveButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';

const createTableColumns = (
  messageTemplates,
  audiences,
  openViewModal,
  openEditModal,
  openActiveAlert,
) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Audience',
    name: 'audienceId',
    formatterComponent: ({ value }) => getLabel(value, audiences),
    sorting: (a, b) => getSort(getLabel(a, audiences), getLabel(b, audiences)),
    filter: (value, filter) =>
      getFilter(getLabel(value, audiences), filter.value),
  },
  {
    title: 'People',
    name: 'size',
    width: 100,
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Setup',
    name: 'setup',
    width: 100,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : value === 'error' ? (
        <span className="badge badge-danger font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value.toUpperCase()}
        </span>
      ),
  },
  {
    title: 'Message Template',
    name: 'messageTemplateId',
    formatterComponent: ({ value }) => getLabel(value, messageTemplates),
    sorting: (a, b) =>
      getSort(getLabel(a, messageTemplates), getLabel(b, messageTemplates)),
    filter: (value, filter) =>
      getFilter(getLabel(value, messageTemplates), filter.value),
  },
  {
    title: 'Time Message',
    name: 'sendingTimeMessage',
    width: 150,
  },
  {
    title: 'Action',
    name: 'action',
    width: 150,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {row.setup !== 'building' && (
          <>
            <EditButton row={row} onPress={openEditModal} />
            <ActiveButton row={row} onPress={openActiveAlert} />
          </>
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
