import { createSelector } from 'reselect';
import { selectCampaignInsights as selectFBInsights } from './facebookAd/dailyCampaignInsight';
import { selectCampaignInsights as selectGoogleInsights } from './googleAd/dailyCampaignInsight';
import colors from '../components/CommonStyle/colors';
import { getDates, dateIsInRange } from '../helpers/sharedFunction';
import moment from 'moment';

export const selectMonetizationItemsByUser = (
  userId = null,
  fromCall = false,
) =>
  createSelector(
    (state) => state.MonetizationItem.monetizationItems,
    (monetizationItems) =>
      monetizationItems.filter((monetizationItem) =>
        userId
          ? monetizationItem.userId === userId
          : fromCall
          ? !!monetizationItem.callId
          : true,
      ),
  );

export const countMonetizationItemsByUser = (userId = null, fromCall = false) =>
  createSelector(
    selectMonetizationItemsByUser(userId, fromCall),
    (monetizationItems) => monetizationItems.length,
  );

export const selectMonetizationItemsByOrder = (
  monetizationOrderId,
  userId = null,
  fromCall = false,
) =>
  createSelector(
    selectMonetizationItemsByUser(userId, fromCall),
    (monetizationItems) => {
      if (!monetizationOrderId) return null;
      return monetizationItems.filter(
        (monetizationItem) =>
          monetizationItem.monetizationOrderId === monetizationOrderId,
      );
    },
  );

export const countMonetizationItemsByOrder = (
  monetizationOrderId,
  userId = null,
  fromCall = false,
) =>
  createSelector(
    selectMonetizationItemsByOrder(monetizationOrderId, userId, fromCall),
    (monetizationItems) => monetizationItems.length,
  );

export const countPositiveMonetizationItemsByOrder = (
  monetizationOrderId,
  userId = null,
  fromCall = false,
) =>
  createSelector(
    selectMonetizationItemsByOrder(monetizationOrderId, userId, fromCall),
    (monetizationItems) =>
      monetizationItems.filter(
        (monetizationItem) => monetizationItem.revenue > 0,
      ).length,
  );

export const revenuesMonetizationItems = (userId = null) =>
  createSelector(selectMonetizationItemsByUser(userId), (monetizationItems) =>
    monetizationItems
      .map((monetizationItem) => monetizationItem.revenue)
      .reduce((a, b) => a + b, 0),
  );

export const revenuesMonetizationItemsByOrder = (
  monetizationOrderId,
  userId = null,
) =>
  createSelector(
    selectMonetizationItemsByOrder(monetizationOrderId, userId),
    (monetizationItems) =>
      monetizationItems
        .map((monetizationItem) => monetizationItem.revenue)
        .reduce((a, b) => a + b, 0),
  );

export const revenuePerLead = (userId = null) =>
  createSelector(
    revenuesMonetizationItems(userId),
    countPositiveMonetizationItems(userId),
    (revenues, positives) => revenues / positives,
  );

export const costPerQualify = (userId = null) =>
  createSelector(
    costsMonetizationItems(userId),
    countPositiveMonetizationItems(userId, true),
    countNegativeMonetizationItems(userId, true),
    (qualifyCosts, positiveCallsCount, negativeCallsCount) =>
      qualifyCosts / (positiveCallsCount + negativeCallsCount),
  );

export const costsMonetizationItems = (userId = null) =>
  createSelector(selectMonetizationItemsByUser(userId), (monetizationItems) =>
    monetizationItems
      .map((monetizationItem) => monetizationItem.qualifyCost)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
  );

export const costsMonetizationItemsByOrder = (
  monetizationOrderId,
  userId = null,
) =>
  createSelector(
    selectMonetizationItemsByOrder(monetizationOrderId, userId),
    (monetizationItems) =>
      monetizationItems
        .map((monetizationItem) => monetizationItem.qualifyCost)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
  );

export const positiveMonetizationItems = (userId = null, fromCall = false) =>
  createSelector(
    selectMonetizationItemsByUser(userId, fromCall),
    (monetizationItems) =>
      monetizationItems.filter(
        (monetizationItem) => monetizationItem.revenue > 0,
      ),
  );

export const countPositiveMonetizationItems = (
  userId = null,
  fromCall = false,
) =>
  createSelector(
    positiveMonetizationItems(userId, fromCall),
    (monetizationItems) => monetizationItems.length,
  );

export const negativeMonetizationItems = (userId = null, fromCall = false) =>
  createSelector(
    selectMonetizationItemsByUser(userId, fromCall),
    (monetizationItems) =>
      monetizationItems.filter(
        ({ negativeOutcome }) =>
          negativeOutcome && !['fake', 'expired'].includes(negativeOutcome),
      ),
  );

export const countNegativeMonetizationItems = (
  userId = null,
  fromCall = false,
) =>
  createSelector(
    negativeMonetizationItems(userId, fromCall),
    (monetizationItems) => monetizationItems.length,
  );

export const fakeMonetizationItems = (userId = null, fromCall = false) =>
  createSelector(
    selectMonetizationItemsByUser(userId, fromCall),
    (monetizationItems) =>
      monetizationItems.filter(
        ({ negativeOutcome }) => negativeOutcome && negativeOutcome === 'fake',
      ),
  );

export const countFakeMonetizationItems = (userId = null, fromCall = false) =>
  createSelector(
    fakeMonetizationItems(userId, fromCall),
    (monetizationItems) => monetizationItems.length,
  );

export const countTotalMonetizationItems = (userId = null, fromCall = false) =>
  createSelector(
    countPositiveMonetizationItems(userId, fromCall),
    countNegativeMonetizationItems(userId, fromCall),
    countFakeMonetizationItems(userId, fromCall),
    (positives, negatives, fakes) => positives + negatives + fakes,
  );

export const percentagePositiveItems = (userId = null, fromCall = false) =>
  createSelector(
    countPositiveMonetizationItems(userId, fromCall),
    countTotalMonetizationItems(userId, fromCall),
    (positives, total) => (positives / total) * 100,
  );

export const percentageNegativeItems = (userId = null, fromCall = false) =>
  createSelector(
    countNegativeMonetizationItems(userId, fromCall),
    countTotalMonetizationItems(userId, fromCall),
    (negatives, total) => (negatives / total) * 100,
  );

export const percentageFakeItems = (userId = null, fromCall = false) =>
  createSelector(
    countFakeMonetizationItems(userId, fromCall),
    countTotalMonetizationItems(userId, fromCall),
    (fakes, total) => (fakes / total) * 100,
  );

export const sendingMonetizationItems = (sendingType, withError = false) =>
  createSelector(
    (state) => state.MonetizationItem.monetizationItems,
    (monetizationItems) =>
      monetizationItems.filter(({ status, sendLeadType, revenue, error }) =>
        withError
          ? status === 'error' &&
            sendLeadType === sendingType &&
            revenue === 0 &&
            error
          : sendLeadType === sendingType && revenue > 0 && !error,
      ),
  );

export const monetizationItemsWithoutWebServiceProblem = createSelector(
  (state) => state.MonetizationItem.monetizationItems,
  sendingMonetizationItems('webService', true),
  sendingMonetizationItems('googleSpreadsheets', true),
  (
    webServiceItems,
    googleSpreadsheetsItems,
    monetizationItemsWithWebServiceProblem,
  ) => {
    const ids = monetizationItemsWithWebServiceProblem.map(({ id }) => id);
    return [...webServiceItems, ...googleSpreadsheetsItems].filter(
      ({ id }) => !ids.includes(id),
    );
  },
);

export const negativeMonetizationItemForAcquisitionOrders = (userId) =>
  createSelector(
    negativeMonetizationItems(userId),
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (monetizationItems, acquisitionOrders) => {
      const data = [];

      acquisitionOrders.forEach((acquisitionOrder) => {
        const mo = monetizationItems.filter(
          ({ acquisitionOrderId }) =>
            acquisitionOrder.id === acquisitionOrderId,
        );
        if (!mo.length) return;

        const costs = mo
          .map(({ qualifyCost }) => qualifyCost || 0)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        data.push({
          acquisitionOrderId: acquisitionOrder.id,
          sector: acquisitionOrder.sector,
          count: mo.length,
          costs,
        });
      });
      return data;
    },
  );

export const drawSeriesDailyGraph = (dateRange, t, userId = null) =>
  createSelector(
    selectMonetizationItemsByUser(userId),
    selectFBInsights(true),
    selectGoogleInsights(true),
    (state) => state.SmsMessage.smsMessages,
    (state) => state.Leads.leads,
    (monetizationItems, fbCostsItems, googleCostsItems, smsItems, leads) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t('Revenues'),
            data: [],
            type: 'line',
            color: colors.dark,
          },
          {
            name: t('Costs'),
            data: [],
            type: 'line',
            color: colors.danger,
          },
          {
            name: t('Margin'),
            data: [],
            type: 'line',
            color: colors.success,
          },
          {
            name: t('eCPA'),
            data: [],
            type: 'line',
            color: colors.warning,
          },
        ],
        max: -9999999999,
        min: 99999999999,
      };

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const revenues = monetizationItems
          .filter((monetizationItem) =>
            dateIsInRange(
              new Date(monetizationItem.createdAt),
              startDay,
              endDay,
            ),
          )
          .map((monetizationItem) => monetizationItem.revenue)
          .reduce((a, b) => a + b, 0);

        const qualifyCosts = monetizationItems
          .filter((monetizationItem) =>
            dateIsInRange(
              new Date(monetizationItem.createdAt),
              startDay,
              endDay,
            ),
          )
          .map((monetizationItem) => monetizationItem.qualifyCost)
          .reduce((a, b) => a + b, 0);

        const fbCosts = fbCostsItems
          .filter((insight) =>
            dateIsInRange(new Date(insight.date), startDay, endDay),
          )
          .map((insight) => insight.totalSpend)
          .reduce((a, b) => a + b, 0);

        const googleCosts = googleCostsItems
          .filter((insight) =>
            dateIsInRange(new Date(insight.date), startDay, endDay),
          )
          .map((insight) => insight.totalSpend)
          .reduce((a, b) => a + b, 0);

        const smsCosts = smsItems
          .filter((sms) =>
            dateIsInRange(new Date(sms.createdAt), startDay, endDay),
          )
          .map((sms) => sms.cost)
          .reduce((a, b) => a + b, 0);

        const selectedLeads = leads.filter((lead) =>
          dateIsInRange(new Date(lead.createdAt), startDay, endDay),
        );

        const facebookLeads = selectedLeads.filter(
          (lead) => lead.source === 'facebook',
        );
        const googleLeads = selectedLeads.filter(
          (lead) => lead.source === 'googleAds',
        );
        const leadWithCosts = selectedLeads.filter((lead) => !!lead.cost);

        const countLeads =
          facebookLeads.length + googleLeads.length + leadWithCosts.length;

        dataGraph.series[0].data.push([day.getTime(), revenues]);

        const leadCosts = leadWithCosts
          .map((lead) => lead.cost)
          .reduce((a, b) => a + b, 0);

        const costs =
          qualifyCosts + fbCosts + googleCosts + leadCosts + smsCosts;
        dataGraph.series[1].data.push([day.getTime(), costs]);

        const margin = revenues - costs;
        dataGraph.series[2].data.push([day.getTime(), margin]);

        const eCPA = (fbCosts + googleCosts) / countLeads;
        dataGraph.series[3].data.push([
          day.getTime(),
          isFinite(eCPA) ? eCPA.toFixed(2) : 0,
        ]);

        dataGraph.max = Math.max(dataGraph.max, revenues, costs, margin);
        dataGraph.min = Math.min(dataGraph.min, revenues, costs, margin);
      });
      return dataGraph;
    },
  );

export const drawSeriesHourlyBar = (t, userId = null) =>
  createSelector(selectMonetizationItemsByUser(userId), (monetizationItems) => {
    //[Array(0), Array(0), Array(0), Array(0), Array(0), Array(0)]
    let monetizationItemByHours = Array.from({ length: 7 }, (v, k) => []);
    monetizationItems.forEach((mo) => {
      const index = chooseTimeSplit(new Date(mo.createdAt).getHours());
      monetizationItemByHours[index].push(mo);
    });

    let percentagePositiveByHours = [];
    monetizationItemByHours.forEach((monetizationItems) => {
      const countPositives = monetizationItems.filter(
        (mo) => mo.revenue > 0,
      ).length;
      const percentagePositives = countPositives / monetizationItems.length;
      percentagePositiveByHours.push(
        isFinite(percentagePositives) ? percentagePositives : 0,
      );
    });

    return {
      series: percentagePositiveByHours,
      labels: ['8-9', '10-11', '12-13', '14-15', '16-17', '18-19', '20-21'],
    };
  });

const chooseTimeSplit = (hour) => {
  switch (true) {
    case 8 <= hour && hour <= 9:
      return 0;
    case 10 <= hour && hour <= 11:
      return 1;
    case 12 <= hour && hour <= 13:
      return 2;
    case 14 <= hour && hour <= 15:
      return 3;
    case 16 <= hour && hour <= 17:
      return 4;
    case 18 <= hour && hour <= 19:
      return 5;
    case 20 <= hour && hour <= 21:
      return 6;
    default:
      return 6;
  }
};

export const drawSeriesWeeklyDayBar = (t, userId = null) =>
  createSelector(selectMonetizationItemsByUser(userId), (monetizationItems) => {
    //[Array(0), Array(0), Array(0), Array(0), Array(0), Array(0)]
    let monetizationItemByDay = Array.from({ length: 7 }, (v, k) => []);
    monetizationItems.forEach((mo) => {
      const index = new Date(mo.createdAt).getDay();
      monetizationItemByDay[index].push(mo);
    });

    let percentagePositiveByDay = [];
    monetizationItemByDay.forEach((monetizationItems) => {
      const countPositives = monetizationItems.filter(
        (mo) => mo.revenue > 0,
      ).length;
      const percentagePositives = countPositives / monetizationItems.length;
      percentagePositiveByDay.push(
        isFinite(percentagePositives) ? percentagePositives : 0,
      );
    });

    return {
      series: percentagePositiveByDay,
      labels: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ],
    };
  });
