import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';

const createTableColumns = (openViewModal, openEditModal) => {
  return [
    {
      title: 'ID',
      name: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      name: 'name',
      sort: true,
    },
    {
      title: 'Internal?',
      name: 'internal',
      sort: true,
    },
    {
      title: 'Company Name',
      name: 'companyName',
      sort: true,
    },
    {
      title: 'City',
      name: 'city',
      sort: true,
    },
    {
      title: 'Country',
      name: 'country',
      sort: true,
    },
    {
      title: 'Action',
      name: 'action',
      width: 120,
      align: 'center',
      formatterComponent: ({ value, row }) => {
        return (
          <>
            <ul className="list-inline font-size-20 contact-links mb-0">
              <ViewButton row={row} onPress={openViewModal} />
              <EditButton row={row} onPress={openEditModal} />
            </ul>
          </>
        );
      },
      isDummyField: true,
    },
  ];
};

export default createTableColumns;
