import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CALL_STATE,
});

export const createCall = (call) => ({
  type: types.CREATE_CALL.REQUEST,
  call,
});

export const createCallSuccess = (call) => ({
  type: types.CREATE_CALL.SUCCESS,
  call,
});

export const createCallFailure = (error) => ({
  type: types.CREATE_CALL.FAILURE,
  error,
});

export const createExtraCall = (newCall, prevCallId) => ({
  type: types.CREATE_EXTRA_CALL.REQUEST,
  newCall,
  prevCallId,
});

export const createExtraCallSuccess = () => ({
  type: types.CREATE_EXTRA_CALL.SUCCESS,
});

export const createExtraCallFailure = (error) => ({
  type: types.CREATE_EXTRA_CALL.FAILURE,
  error,
});

export const updateCall = (call) => ({
  type: types.UPDATE_CALL.REQUEST,
  call,
});

export const updateCallSuccess = () => ({
  type: types.UPDATE_CALL.SUCCESS,
});

export const updateCallFailure = (error) => ({
  type: types.UPDATE_CALL.FAILURE,
  error,
});

export const fetchCalls = (startDate, endDate, filters = null) => ({
  type: types.FETCH_CALLS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchCallsSuccess = (calls, startDate, endDate, filters) => ({
  type: types.FETCH_CALLS.SUCCESS,
  calls,
  startDate,
  endDate,
  filters,
});

export const fetchCallsFailure = (error) => ({
  type: types.FETCH_CALLS.FAILURE,
  error,
});

export const fetchCallsByLead = (lead) => ({
  type: types.FETCH_CALLS_BY_LEAD.REQUEST,
  lead,
});

export const fetchCallsByLeadSuccess = (calls) => ({
  type: types.FETCH_CALLS_BY_LEAD.SUCCESS,
  calls,
});

export const fetchCallsByLeadFailure = (error) => ({
  type: types.FETCH_CALLS_BY_LEAD.FAILURE,
  error,
});

export const fetchClosedCalls = (startDate, endDate, filters = null) => ({
  type: types.FETCH_CLOSED_CALLS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchClosedCallsSuccess = (calls) => ({
  type: types.FETCH_CLOSED_CALLS.SUCCESS,
  calls,
});

export const fetchClosedCallsFailure = (error) => ({
  type: types.FETCH_CLOSED_CALLS.FAILURE,
  error,
});

export const syncQueuedCalls = () => ({
  type: types.SYNC_QUEUED_CALLS.REQUEST,
});

export const syncQueuedCallsSuccess = (calls) => ({
  type: types.SYNC_QUEUED_CALLS.SUCCESS,
  calls,
});

export const syncQueuedCallsFailure = (error) => ({
  type: types.SYNC_QUEUED_CALLS.FAILURE,
  error,
});
