import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as callsActions from '../../store/calls/actions';
import * as leadsActions from '../../store/leads/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as usersActions from '../../store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { countCallsWithStatus } from '../../selectors/call';
import { selectLead } from '../../selectors/lead';
import { hasRole } from '../../selectors/auth';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import FormCall from '../../components/Calls/FormCall';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Calls/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import MiniCard from '../../components/Common/MiniCard';
import moment from 'moment';
import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const List = (props) => {
  const isTeamLeaderAndUp = useSelector(
    hasRole([roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER, roles.TEAM_LEADER]),
  );

  const [selectedLead, setSelectedLead] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [extraCallModal, setExtraCallModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const loading = useSelector((state) => state.Call.loading);
  const calls = useSelector((state) => state.Call.calls);
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const users = useSelector((state) => state.User.users);
  const showSelectedLead = useSelector(selectLead(selectedLead));

  //Metrics
  const makeCalls = useSelector(countCallsWithStatus('new'));
  const closedCalls = useSelector(countCallsWithStatus('closed'));
  const ongoingCalls = useSelector(countCallsWithStatus('ongoing'));

  const startDate = useSelector((state) => state.Call.startDate);
  const endDate = useSelector((state) => state.Call.endDate);
  const dataFilters = useSelector((state) => state.Call.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      callsActions.fetchCalls(selectedStartDate, selectedEndDate, {
        sector,
        campaignIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
    dispatch(usersActions.syncUsers());
  }, []);

  const openLeadModal = (call) => {
    setSelectedLead(call.leadId);
    dispatch(leadsActions.fetchSingleLead(call.leadId));
    setLeadModal(true);
  };

  const openEditModal = (call) => {
    setSelectedCall(call);
    setEditModal(true);
  };

  const openViewModal = (call) => {
    setSelectedCall(call);
    setViewModal(true);
  };

  const openExtraCallModal = (call) => {
    setSelectedCall(call);
    setExtraCallModal(true);
  };

  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      createTableColumns(
        openLeadModal,
        openViewModal,
        openEditModal,
        openExtraCallModal,
        isTeamLeaderAndUp,
        acquisitionOrders,
        users,
        t,
      ),
    [isTeamLeaderAndUp, acquisitionOrders, users],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const metrics = [
    {
      title: t('Calls'),
      text: calls.length,
      color: 'dark',
      icon: 'fas fa-phone-alt',
    },
    {
      title: t('To make'),
      text: makeCalls,
      color: 'secondary',
      icon: 'fas fa-phone-alt',
      percentage: `${
        isFinite((makeCalls / calls.length) * 100)
          ? ((makeCalls / calls.length) * 100).toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Ongoing'),
      text: ongoingCalls,
      color: 'warning',
      icon: 'fas fa-phone-volume',
      percentage: `${
        isFinite((ongoingCalls / calls.length) * 100)
          ? ((ongoingCalls / calls.length) * 100).toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Closed'),
      text: closedCalls,
      color: 'success',
      icon: 'fas fa-phone-alt',
      percentage: `${
        isFinite((closedCalls / calls.length) * 100)
          ? ((closedCalls / calls.length) * 100).toFixed(0)
          : 0
      }%`,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Calls"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={calls}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead"
            isOpen={leadModal}
            toggle={() => setLeadModal(!leadModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={showSelectedLead}
              icon="bx bx-user-pin"
            />
          </Modal>
          <Modal
            size="lg"
            title="Call"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedCall}
              icon="bx bx-message-square-detail"
            />
          </Modal>
          <Modal
            size="xl"
            title="Call"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCall
              closeModal={() => setEditModal(false)}
              create={!selectedCall}
              loading={loading}
              call={selectedCall}
              acquisitionOrders={acquisitionOrders}
              users={users}
            />
          </Modal>
          <Modal
            size="xl"
            title="Create Extra Call"
            isOpen={extraCallModal}
            toggle={() => setExtraCallModal(!extraCallModal)}
            scrollable={false}>
            <FormCall
              closeModal={() => setExtraCallModal(false)}
              create
              loading={loading}
              prevCall={selectedCall}
              acquisitionOrders={acquisitionOrders}
              users={users}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
