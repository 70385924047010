const statsTypes = {
  RESET_DAILY_GOOGLE_CAMPAIGN_INSIGHT_STATE: 'RESET_DAILY_GOOGLE_CAMPAIGN_INSIGHT_STATE',
  DAILY_GOOGLE_CAMPAIGN_INSIGHTS: {
    REQUEST: 'DAILY_GOOGLE_CAMPAIGN_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_GOOGLE_CAMPAIGN_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_GOOGLE_CAMPAIGN_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
