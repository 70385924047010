import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import * as campaignsActions from '../../store/campaigns/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import { selectCampaignsBySector } from '../../selectors/campaign';

import { components } from 'react-select';
import sectors from '../../config/sectors';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../helpers/sharedFunction';

const MAX_SELECTED_CAMPAIGNS = 10;

const { Option } = components;

const CampaignSelectOption = (props) => (
  <Option {...props}>
    {capitalize(props.data.source)} - {props.data.label}
  </Option>
);

const FilterCampaignsBox = ({ initialFilters, onUpdate }) => {
  const [sector, setSector] = useState(initialFilters?.sector || '');
  const [campaignIds, setCampaignIds] = useState(
    initialFilters?.campaignIds || null,
  );

  const campaigns = useSelector(selectCampaignsBySector(sector, false));
  const loading = useSelector((state) => state.Campaign.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
  }, []);

  const handleFilters = (value, field, setFieldValue) => {
    if (field === 'sector') {
      setSector(value);
      setCampaignIds(null);
      setFieldValue('campaignIds', null);
    } else setCampaignIds(value);

    onUpdate(
      field === 'sector' ? value : sector,
      field === 'sector' ? null : field === 'campaignIds' ? value : campaignIds,
    );
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ sector, campaignIds }}
        validationSchema={yup.object().shape({
          sector: yup.string(),
          campaignIds: yup.array().nullable(),
        })}
        className="d-flex">
        {({ setFieldValue }) => (
          <>
            <Col md="3" className="d-flex align-items-center px-0">
              <h5 className="text-muted m-0 mr-2">{t('Sector')}:</h5>
              <div className="flex-fill">
                <Select
                  name="sector"
                  id="sector"
                  isMulti={false}
                  value={sector}
                  onChange={(value) =>
                    handleFilters(value, 'sector', setFieldValue)
                  }
                  options={[{ label: t('All'), value: '' }, ...sectors]}
                />
              </div>
            </Col>
            <Col md="9" className="d-flex align-items-center px-0">
              <h5 className="text-muted my-0 ml-5 mr-2">{t('Campaigns')}:</h5>
              <div className="flex-fill">
                <Select
                  name="campaignIds"
                  id="campaignIds"
                  isMulti
                  maxLength={MAX_SELECTED_CAMPAIGNS}
                  value={campaignIds}
                  onChange={(values) =>
                    handleFilters(values, 'campaignIds', setFieldValue)
                  }
                  options={campaigns.map((campaign) => ({
                    label: campaign.name,
                    value: campaign.id,
                    source: campaign.source,
                  }))}
                  isDisabled={sector === '' || loading}
                  components={{ Option: CampaignSelectOption }}
                  clearButtonClassName="btn btn-outline-secondary btn-sm ml-1"
                />
              </div>
            </Col>
          </>
        )}
      </Form>
    </CardBody>
  );
};

export default FilterCampaignsBox;
