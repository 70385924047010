import { createSelector } from 'reselect';
import { selectLeadCampaigns } from '../campaign';
import { getDates, dateIsInRange } from '../../helpers/sharedFunction';
import moment from 'moment';

export const selectCampaignInsights = (onlyLeadCampaign = false) =>
  createSelector(
    selectLeadCampaigns,
    (state) => state.DailyFBCampaignInsights.insights,
    (campaigns, insights) => {
      if (onlyLeadCampaign) {
        const campaignIds = campaigns.map(({ id }) => id);
        return insights.filter(({ campaignId }) =>
          campaignIds.includes(campaignId),
        );
      }
      return insights;
    },
  );

export const totalCosts = (onlyLeadCampaign = false) =>
  createSelector(selectCampaignInsights(onlyLeadCampaign), (insights) =>
    insights.map(({ totalSpend }) => totalSpend).reduce((a, b) => a + b, 0),
  );

export const totalLeads = createSelector(
  (state) => state.DailyFBCampaignInsights.insights,
  (insights) =>
    insights.map((insight) => insight.totalLeads).reduce((a, b) => a + b, 0),
);

export const averageCostPerLead = createSelector(
  totalCosts(true),
  totalLeads,
  (costs, leads) => costs / leads,
);

export const drawSeriesDailyGraph = (dateRange) =>
  createSelector(selectCampaignInsights(true), (insights) => {
    const { startDate, endDate } = dateRange;

    const dates = getDates(startDate.toDate(), endDate.toDate());

    let dataGraph = {
      series: [
        { name: 'Spend', data: [], type: 'column' },
        { name: 'Leads', data: [], type: 'area' },
        { name: 'Cost per Lead', data: [], type: 'line' },
      ],
    };

    dates.forEach((day) => {
      const startDay = moment(day).utc().startOf('day').toDate();
      const endDay = moment(day).utc().endOf('day').toDate();

      const spend = insights
        .filter((insight) =>
          dateIsInRange(new Date(insight.date), startDay, endDay),
        )
        .map((insight) => insight.totalSpend)
        .reduce((a, b) => a + b, 0);
      dataGraph.series[0].data.push([day.getTime(), spend]);

      const leads = insights
        .filter((insight) =>
          dateIsInRange(new Date(insight.date), startDay, endDay),
        )
        .map((insight) => insight.totalLeads)
        .reduce((a, b) => a + b, 0);
      dataGraph.series[1].data.push([day.getTime(), leads]);

      const eCPL = spend / leads;
      dataGraph.series[2].data.push([day.getTime(), isNaN(eCPL) ? 0 : eCPL]);
    });
    return dataGraph;
  });
