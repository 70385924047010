const typeFields = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'TextArea',
    value: 'textarea',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Select',
    value: 'select',
  },
  {
    label: 'Async Select',
    value: 'async-select',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
];

const sizeFields = [
  {
    label: 'Small',
    value: '2',
  },
  {
    label: 'Medium',
    value: '3',
  },
  {
    label: 'Large',
    value: '4',
  },
  {
    label: 'Extra-large',
    value: '6',
  },
];

export { typeFields, sizeFields };
