import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import * as callsActions from '../../../store/calls/actions';
import * as leadsActions from '../../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../Common/Modal/Alert';
import negativeOutcomes from '../../../config/negativeOutcomes';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';
import { arrayToSelect, capitalize } from '../../../helpers/sharedFunction';

const NegativeAlert = ({ call, acquisitionOrder, isOpen, onCancel }) => {
  const negativeReasons = acquisitionOrder?.negativeReasons;
  const customNegativeReasons =
    Array.isArray(negativeReasons) && negativeReasons.length
      ? negativeReasons
      : negativeOutcomes;

  const [reason, setReason] = useState(call.negativeOutcome || '');
  const [step, setStep] = useState(1);

  const [disabledAlertBtn, setDisabledAlertBtn] = useState(false);

  const userId = useSelector((state) => state.Auth.admin.id);

  const labels = [
    { title: 'Why was the call closed negatively?', btnLabel: 'Save' },
    { title: 'Do you confirm negative closure because', btnLabel: 'Confirm' },
  ];

  const dispatch = useDispatch();

  const handleOutcomeNegative = () => {
    if (reason === '') return toastr.error(t('Choose a reason!'), t('Error'));

    step === 1 ? setStep(2) : updateCall();
  };

  const updateCall = () => {
    setDisabledAlertBtn(true);
    dispatch(
      callsActions.updateCall({
        id: call.id,
        outcome: 'negative',
        status: 'closed',
        nextCall: 'none',
        negativeOutcome: reason,
        monetizationItemIds: null,
        endDateCall: new Date(),
        userId,
      }),
    );
    dispatch(
      leadsActions.updateLead({
        id: call.leadId,
        qualified: 'completed',
      }),
    );
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setStep(1);
    onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={isOpen}
      title={t(labels[step - 1].title)}
      confirmBtnLabel={labels[step - 1].btnLabel}
      onCancel={handleOnCancel}
      onConfirm={handleOutcomeNegative}
      disabledBtn={disabledAlertBtn}>
      <>
        {step === 1 && (
          <Form
            initialValues={{ reason }}
            validationSchema={yup.object().shape({
              reason: yup.string().required(),
            })}>
            <FormGroup>
              <Label htmlFor="reason">{t('Choose a reason')}:</Label>
              <Select
                name="reason"
                id="reason"
                isMulti={false}
                onChange={(value) => setReason(value)}
                value={reason}
                options={arrayToSelect(customNegativeReasons, t)}
              />
            </FormGroup>
          </Form>
        )}
        {step === 2 && (
          <ul className="list-group">
            <li className="list-group-item">{t(capitalize(reason, true))}</li>
          </ul>
        )}
      </>
    </Alert>
  );
};

export default NegativeAlert;
