import React from 'react';
import { Container } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isPublisher as isPublisherSelector,
} from '../../selectors/auth';
import AdminLeads from './admin';
import PublisherLeads from './publisher';

const List = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isPublisher = useSelector(isPublisherSelector);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {(isAdmin || isManager) && <AdminLeads />}
          {isPublisher && <PublisherLeads />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
