import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  internal: yup.string().required(),
  companyName: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
  taxId: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
  address: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
  city: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
  zip: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
  country: yup.string().when('internal', {
    is: 'no',
    then: () => yup.string().required(),
  }),
});

export default formValidation;
