import React from 'react';
import { EditButton } from '../Common/DataTable/tableActionButtons';

const createTableColumns = (openEditModal, leadFields) => [
  {
    title: 'Name',
    name: 'name',
    width: 250,
  },
  {
    title: 'Fields',
    name: 'leadFields',
    formatterComponent: ({ value }) =>
      leadFields
        .filter(({ id }) => value.find(({ leadFieldId }) => id === leadFieldId))
        .map(({ name }) => name)
        .join(', '),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
