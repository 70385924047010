const statsTypes = {
  RESET_MESSAGE_TEMPLATE_STATE: 'RESET_MESSAGE_TEMPLATE_STATE',
  CREATE_MESSAGE_TEMPLATE: {
    REQUEST: 'CREATE_MESSAGE_TEMPLATE.REQUEST',
    SUCCESS: 'CREATE_MESSAGE_TEMPLATE.SUCCESS',
    FAILURE: 'CREATE_MESSAGE_TEMPLATE.FAILURE',
  },
  UPDATE_MESSAGE_TEMPLATE: {
    REQUEST: 'UPDATE_MESSAGE_TEMPLATE.REQUEST',
    SUCCESS: 'UPDATE_MESSAGE_TEMPLATE.SUCCESS',
    FAILURE: 'UPDATE_MESSAGE_TEMPLATE.FAILURE',
  },
  SYNC_MESSAGE_TEMPLATES: {
    REQUEST: 'SYNC_MESSAGE_TEMPLATES.REQUEST',
    SUCCESS: 'SYNC_MESSAGE_TEMPLATES.SUCCESS',
    FAILURE: 'SYNC_MESSAGE_TEMPLATES.FAILURE',
  },
};

export default statsTypes;
