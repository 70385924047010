import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as audiencesActions from '../../store/audiences/actions';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormAudience from '../../components/Audiences/FormAudience';
import AudiencesQueryTable from '../../components/People/AudiencesQueryTable';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Audiences/tableColumns';
import {
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.Audience.loading);
  const audiences = useSelector((state) => state.Audience.audiences);
  const messageTemplates = useSelector(
    (state) => state.MessageTemplate.messageTemplates,
  );

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [peopleModal, setPeopleModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(audiencesActions.syncAudiences());
  }, []);

  const openViewModal = (audience) => {
    setSelectedAudience(audience);
    setViewModal(true);
  };

  const openEditModal = (audience) => {
    setSelectedAudience(audience);
    setEditModal(true);
  };

  const openActiveAlert = (audience) => {
    setSelectedAudience(audience);
    setActiveAlert(true);
  };

  const openPeopleModal = (audience) => {
    setSelectedAudience(audience);
    setPeopleModal(true);
  };

  const handleActiveAudience = () => {
    let audience = {
      id: selectedAudience.id,
      active: !selectedAudience.active,
    };
    dispatch(audiencesActions.updateAudience(audience));
    setActiveAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openActiveAlert,
        openPeopleModal,
      ),
    [],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Audience" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Audience"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={audiences}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Audience"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedAudience} />
          </Modal>
          <Modal
            size="xl"
            title="Audience"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormAudience
              closeModal={() => setEditModal(false)}
              create={!selectedAudience}
              loading={loading}
              audience={selectedAudience}
              messageTemplates={messageTemplates}
            />
          </Modal>
          <Alert
            isOpen={activeAlert}
            title={`Do you want to ${
              selectedAudience && selectedAudience.active
                ? 'disable'
                : 'activate'
            } this Audience?`}
            confirmBtnLabel={
              selectedAudience && selectedAudience.active ? 'Disable' : 'Active'
            }
            onCancel={() => setActiveAlert(false)}
            onConfirm={handleActiveAudience}>
            Keep attention!
          </Alert>
          <Modal
            size="xl"
            title="First 30 People"
            isOpen={peopleModal}
            toggle={() => setPeopleModal(!peopleModal)}
            showClose
            scrollable={false}>
            <AudiencesQueryTable
              audienceId={selectedAudience ? selectedAudience.id : null}
              audiences={audiences}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
