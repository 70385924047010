import {
  fork,
  put,
  all,
  call,
  takeLeading,
  take,
  cancel,
  takeLatest,
  select,
  delay,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as nextCallActions from './actions';
import * as callsActions from '../calls/actions';
import { actualCountry } from '../../selectors/country';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getFirestore } from 'firebase/firestore';
import rsf from '../../helpers/firebase';

import { callTransformer } from '../calls/saga';

function* fetchNextCallSaga(payload) {
  const user = yield select((state) => state.Auth.admin);
  const country = yield select(actualCountry);

  let { newCall, inbound } = payload;
  try {
    if (!newCall) {
      const functions = getFunctions();
      const nextCallFunction = httpsCallable(functions, 'nextCall-nextCall');
      const { data } = yield call(nextCallFunction, {
        countryId: country.id,
        waitingTimeRecall: country.waitingTimeRecall || 2,
      });

      if (data.error) throw new Error(data.error.message);

      newCall = data.data.nextCall;
    } else {
      yield delay(2000);
    }
    if (newCall !== 'none') {
      //yield put(leadsActions.fetchSingleLead(newCall.leadId));
      //yield put(surveysActions.fetchSurveyByLead(newCall.leadId));
      yield put(nextCallActions.fetchNextCallSuccess({ ...newCall, inbound }));
      yield put(
        callsActions.updateCall({
          id: newCall.id,
          status: 'ongoing',
          userId: user.id,
        }),
      );
    } else yield put(nextCallActions.fetchNextCallSuccess(newCall));
  } catch (error) {
    yield put(nextCallActions.fetchNextCallFailure(error));
  }
}

const nextCallTransformer = (payload, inbound) => {
  const data = payload.data();
  return callTransformer(payload, data, inbound);
};

function* syncNextCallSaga({ callId, inbound }) {
  const db = getFirestore(rsf.app);
  const callRef = doc(db, 'calls', callId);

  const task = yield fork(rsf.firestore.syncDocument, callRef, {
    successActionCreator: nextCallActions.syncNextCallSuccess,
    failureActionCreator: nextCallActions.syncNextCallFailure,
    transform: (payload) => nextCallTransformer(payload, inbound),
  });

  yield take(types.RESET_NEXT_CALL);
  yield cancel(task);
}

function* callSaga() {
  yield all([
    takeLeading(types.FETCH_NEXT_CALL.REQUEST, fetchNextCallSaga),
    takeLatest(types.SYNC_NEXT_CALL.REQUEST, syncNextCallSaga),
  ]);
}

export default callSaga;
