import { createSelector } from 'reselect';

export const selectLeadForm = (id) =>
  createSelector(
    (state) => state.LeadForm.leadForms,
    (leadForms) => {
      if (!id) return null;
      return leadForms.find((leadForm) => leadForm.id === id);
    },
  );
