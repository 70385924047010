import { createSelector } from 'reselect';
import {
  positiveMonetizationItems,
  negativeMonetizationItems,
  countPositiveMonetizationItems,
  countNegativeMonetizationItems,
} from './monetizationItem';
import { selectActiveAcquisitionOrders } from './acquisitionOrder';
import { selectUser, selectUserByRole } from './user';
import { actualCountry } from './country';
import colors from '../components/CommonStyle/colors';
import {
  getDates,
  dateIsInRange,
  addHours,
  isNullish,
} from '../helpers/sharedFunction';
import moment from 'moment';

const DEFAULT_WAITING_TIME_RECALL = 2;

export const selectCallsWithStatus = (status) =>
  createSelector(
    (state) => state.Call.calls,
    (calls) => calls.filter((call) => call.status === status),
  );

export const countCallsWithStatus = (status) =>
  createSelector(
    (state) => state.Call.calls,
    (calls) => calls.filter((call) => call.status === status).length,
  );

export const selectCallsByLead = (lead) =>
  createSelector(
    (state) => state.Call.calls,
    (calls) => {
      if (!lead) return [];
      return calls.filter((call) => call.leadId === lead.id);
    },
  );

export const selectLeadCallsWithStatus = (lead, status) =>
  createSelector(selectCallsByLead(lead), (calls) => {
    status = Array.isArray(status) ? status : [status];
    return calls.filter((call) => status.includes(call.status));
  });

export const selectQueuedCalls = (filters = null) =>
  createSelector(
    (state) => state.Call.queuedCalls,
    (calls) => {
      if (!filters || isNullish(filters)) return calls;
      if (filters.sector && !filters.campaignIds)
        return calls.filter((call) => call.sector === filters.sector);
      return calls.filter((call) =>
        filters.campaignIds.includes(call.campaignId),
      );
    },
  );

export const countQueuedCalls = (filters = null) =>
  createSelector(selectQueuedCalls(filters), (calls) => calls.length);

export const selectNewQueuedCalls = (filters = null) =>
  createSelector(selectQueuedCalls(filters), (calls) =>
    calls.filter((call) => call.type === 'new'),
  );

export const countNewQueuedCalls = (filters = null) =>
  createSelector(selectNewQueuedCalls(filters), (calls) => calls.length);

export const selectRecallQueuedCalls = (filters = null) =>
  createSelector(selectQueuedCalls(filters), (calls) =>
    calls.filter((call) => call.type === 'recall'),
  );

export const countRecallQueuedCalls = (filters = null) =>
  createSelector(selectRecallQueuedCalls(filters), (calls) => calls.length);

export const countMakeRecallsNow = (filters = null) =>
  createSelector(
    actualCountry,
    selectRecallQueuedCalls(filters),
    (country, calls) =>
      calls.filter(
        (call) =>
          call.createdAt <=
          addHours(
            new Date(),
            -(country
              ? country.waitingTimeRecall || DEFAULT_WAITING_TIME_RECALL
              : DEFAULT_WAITING_TIME_RECALL),
          ),
      ).length,
  );

export const selectNextAppointments = (filters = null) =>
  createSelector(selectQueuedCalls(filters), (calls) =>
    calls.filter((call) => call.type === 'appointment'),
  );

export const selectNextPersonalAndImpersonalAppointments = (
  userId = null,
  filters = null,
) =>
  createSelector(selectNextAppointments(filters), (appointments) =>
    userId
      ? appointments.filter(
          (call) =>
            call.appointmentUserId === '' || call.appointmentUserId === userId,
        )
      : appointments,
  );

export const selectNextPersonalAppointments = (userId = null, filters = null) =>
  createSelector(
    selectNextAppointments(filters),
    selectActiveAcquisitionOrders,
    (appointments, acquisitionOrders) => {
      const acquisitionOrderIds = acquisitionOrders.map(({ id }) => id);
      appointments = appointments.filter(({ acquisitionOrderId }) =>
        acquisitionOrderIds.includes(acquisitionOrderId),
      );
      return userId
        ? appointments.filter((call) => call.appointmentUserId === userId)
        : appointments;
    },
  );

export const selectExpiredAppointments = (userId = null, filters = null) =>
  createSelector(selectNextAppointments(filters), (appointments) => {
    const expiredAppointments = appointments.filter(
      (call) => call.appointmentDate <= new Date(),
    );
    return userId
      ? expiredAppointments.filter(
          (call) =>
            call.appointmentUserId === '' || call.appointmentUserId === userId,
        )
      : expiredAppointments;
  });

export const countExpiredAppointments = (userId = null, filters = null) =>
  createSelector(
    selectExpiredAppointments(userId, filters),
    (appointments) => appointments.length,
  );

export const countMakeCallsNow = (userId = null, filters = null) =>
  createSelector(
    countNewQueuedCalls(filters),
    countMakeRecallsNow(filters),
    countExpiredAppointments(userId, filters),
    (newCalls, recallCalls, expiredAppointments) =>
      newCalls + recallCalls + expiredAppointments,
  );

export const selectNextCall = (userId = null, filters = null) =>
  createSelector(
    actualCountry,
    selectNextPersonalAndImpersonalAppointments(userId, filters),
    selectRecallQueuedCalls(filters),
    (country, appointments, recalls) => {
      const nextAppointments = appointments.sort(
        (a, b) =>
          new Date(a.appointmentDate).getTime() -
          new Date(b.appointmentDate).getTime(),
      );
      const nextAppointment = nextAppointments.length
        ? nextAppointments[0]
        : null;
      const nextRecalls = recalls.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      const nextCall = nextRecalls.length ? nextRecalls[0] : null;
      if (!nextAppointment) return nextCall;
      if (!nextCall) return nextAppointment;
      return new Date(nextAppointment.appointmentDate) <=
        addHours(
          new Date(nextCall.createdAt),
          country
            ? country.waitingTimeRecall || DEFAULT_WAITING_TIME_RECALL
            : DEFAULT_WAITING_TIME_RECALL,
        )
        ? nextAppointment
        : nextCall;
    },
  );

export const nextCallTime = (userId = null, filters = null) =>
  createSelector(selectNextCall(userId, filters), (call) => {
    if (!call) return null;
    if (call.type === 'appointment') return new Date(call.appointmentDate);
    const createdAt = new Date(call.createdAt);
    return addHours(createdAt, DEFAULT_WAITING_TIME_RECALL);
  });

export const closedCalls = (userId = null) =>
  createSelector(
    (state) => state.Call.closedCalls,
    (calls) =>
      userId ? calls.filter((call) => call.userId === userId) : calls,
  );

export const countClosedCalls = (userId = null) =>
  createSelector(closedCalls(userId), (calls) => calls.length);

export const closedCallsWithOutcome = (outcome, userId = null) =>
  createSelector(closedCalls(userId), (calls) =>
    calls.filter((call) => call.outcome === outcome),
  );

export const countClosedCallsWithOutcome = (outcome, userId = null) =>
  createSelector(
    closedCallsWithOutcome(outcome, userId),
    (calls) => calls.length,
  );

export const percentageCallAttempts = (userId = null) =>
  createSelector(
    countPositiveMonetizationItems(userId, true),
    countNegativeMonetizationItems(userId, true),
    countClosedCalls(userId),
    (positives, negatives, closed) => ((positives + negatives) / closed) * 100,
  );

export const uniqueClosedLeadCalls = (userId = null) =>
  createSelector(closedCalls(userId), (calls) => {
    let callLeadIds = [];
    let uniqueCalls = [];
    calls.forEach((call) => {
      !callLeadIds.includes(call.leadId) &&
        callLeadIds.push(call.leadId) &&
        uniqueCalls.push(call);
    });
    return uniqueCalls;
  });

export const countUniqueClosedLeadCalls = (userId = null) =>
  createSelector(uniqueClosedLeadCalls(userId), (calls) => {
    return calls.length;
  });

export const percentageSpokenCalls = (userId = null) =>
  createSelector(
    countPositiveMonetizationItems(userId, true),
    countNegativeMonetizationItems(userId, true),
    countUniqueClosedLeadCalls(userId),
    (positives, negatives, closed) => ((positives + negatives) / closed) * 100,
  );

export const drawSeriesDailyGraph = (dateRange, t, userId = null) =>
  createSelector(
    uniqueClosedLeadCalls(userId),
    positiveMonetizationItems(userId, true),
    negativeMonetizationItems(userId, true),
    (closedCalls, positives, negatives) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t('Unique Calls'),
            data: [],
            type: 'column',
            color: colors.dark,
          },
          {
            name: t('Spoken Calls'),
            data: [],
            type: 'line',
            color: colors.info,
          },
          {
            name: t('Positive'),
            data: [],
            type: 'line',
            color: colors.success,
          },
          {
            name: t('Negative'),
            data: [],
            type: 'line',
            color: colors.danger,
          },
        ],
      };

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const countUniqueClosedCalls = closedCalls.filter((call) =>
          dateIsInRange(new Date(call.endDateCall), startDay, endDay),
        ).length;

        const countPositives = positives.filter((monetizationItem) =>
          dateIsInRange(new Date(monetizationItem.createdAt), startDay, endDay),
        ).length;

        const countNegatives = negatives.filter((monetizationItem) =>
          dateIsInRange(new Date(monetizationItem.createdAt), startDay, endDay),
        ).length;

        const percentageSpokenCalls = (
          ((countPositives + countNegatives) / countUniqueClosedCalls) *
          100
        ).toFixed(2);
        const percentagePositives = (
          (countPositives / countUniqueClosedCalls) *
          100
        ).toFixed(2);
        const percentageNegatives = (
          (countNegatives / countUniqueClosedCalls) *
          100
        ).toFixed(2);

        dataGraph.series[0].data.push([day.getTime(), countUniqueClosedCalls]);

        dataGraph.series[1].data.push([
          day.getTime(),
          isNaN(percentageSpokenCalls) ? 0 : percentageSpokenCalls,
        ]);

        dataGraph.series[2].data.push([
          day.getTime(),
          isNaN(percentagePositives) ? 0 : percentagePositives,
        ]);

        dataGraph.series[3].data.push([
          day.getTime(),
          isNaN(percentageNegatives) ? 0 : percentageNegatives,
        ]);
      });

      return dataGraph;
    },
  );

export const calculateUserCallInsights = (roles, userId) =>
  createSelector(
    (state) => state.Call.closedCalls,
    (state) => state.MonetizationItem.monetizationItems,
    selectUserByRole(roles),
    selectUser(userId),
    (calls, monetizationItems, users, user) => {
      if (!users && !user) return [];
      users = user ? [user] : users;
      let data = [];

      for (let user of users) {
        let closedCalls = calls.filter((call) => call.userId === user.id);
        if (closedCalls.length === 0) continue;

        const positives = closedCalls.filter(
          (call) => call.outcome === 'positive',
        );
        const averageDurationPositives =
          positives
            .filter((call) => !!call.durationCall)
            .map((call) => call.durationCall)
            .reduce((a, b) => a + b, 0) /
          positives.filter((call) => !!call.durationCall).length;

        const negatives = closedCalls.filter(
          (call) =>
            call.outcome === 'negative' &&
            !['fake', 'expired'].includes(call.negativeOutcome),
        );
        const averageDurationNegatives =
          negatives
            .filter((call) => !!call.durationCall)
            .map((call) => call.durationCall)
            .reduce((a, b) => a + b, 0) /
          negatives.filter((call) => !!call.durationCall).length;

        const spokenCalls = positives.length + negatives.length;

        const fakes = closedCalls.filter(
          (call) => call.negativeOutcome === 'fake',
        );
        const averageDurationFakes =
          fakes
            .filter((call) => !!call.durationCall)
            .map((call) => call.durationCall)
            .reduce((a, b) => a + b, 0) /
          fakes.filter((call) => !!call.durationCall).length;

        const recalls = closedCalls.filter((call) => call.outcome === 'recall');
        const averageDurationRecalls =
          recalls
            .filter((call) => !!call.durationCall)
            .map((call) => call.durationCall)
            .reduce((a, b) => a + b, 0) /
          recalls.filter((call) => !!call.durationCall).length;

        const appointments = closedCalls.filter(
          (call) => call.outcome === 'appointment',
        );
        const averageDurationAppointments =
          appointments
            .filter((call) => !!call.durationCall)
            .map((call) => call.durationCall)
            .reduce((a, b) => a + b, 0) /
          appointments.filter((call) => !!call.durationCall).length;

        const revenues = monetizationItems
          .filter((monetizationItem) => monetizationItem.userId === user.id)
          .map((monetizationItem) => monetizationItem.revenue)
          .reduce((a, b) => a + b, 0);
        closedCalls = closedCalls.length;

        data.push({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          closedCalls,
          spokenCalls,
          positives: positives.length,
          percentagePositives: (positives.length / closedCalls) * 100,
          averageDurationPositives,
          negatives: negatives.length,
          percentageNegatives: (negatives.length / closedCalls) * 100,
          averageDurationNegatives,
          percentagePositiveSpokenCalls: (positives.length / spokenCalls) * 100,
          percentageNegativeSpokenCalls: (negatives.length / spokenCalls) * 100,
          fakes: fakes.length,
          percentageFakes: (fakes.length / closedCalls) * 100,
          averageDurationFakes,
          recalls: recalls.length,
          percentageRecalls: (recalls.length / closedCalls) * 100,
          averageDurationRecalls,
          appointments: appointments.length,
          percentageAppointments: (appointments.length / closedCalls) * 100,
          averageDurationAppointments,
          revenues: revenues,
        });
      }
      return data;
    },
  );
