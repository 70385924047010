import types from './actionTypes';

const initialState = {
  loading: false,
  nextCall: null,
  dirtyLeadForm: false,
  error: '',
};

const nextCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_NEXT_CALL:
      return initialState;
    case types.FETCH_NEXT_CALL.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.FETCH_NEXT_CALL.SUCCESS:
    case types.SYNC_NEXT_CALL.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        nextCall: action.call,
      };
    case types.FETCH_NEXT_CALL.FAILURE:
      return {
        ...state,
        loading: false,
        nextCall: null,
        error: action.error.message,
      };
    case types.SYNC_NEXT_CALL.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case types.SET_DIRTY_LEAD_FORM:
      return {
        ...state,
        dirtyLeadForm: action.dirtyLeadForm,
      };
    default:
      return state;
  }
};

export default nextCallReducer;
