import roles from './roles';

export default {
  SUPER_ADMIN: [
    roles.SUPER_ADMIN,
    roles.ADMIN,
    roles.MANAGER,
    roles.TEAM_LEADER,
    roles.OPERATOR,
    roles.SALE,
    roles.PUBLISHER,
    roles.CUSTOMER,
  ],
  ADMIN: [
    roles.ADMIN,
    roles.MANAGER,
    roles.TEAM_LEADER,
    roles.OPERATOR,
    roles.SALE,
  ],
  MANAGER: [roles.MANAGER, roles.TEAM_LEADER, roles.OPERATOR],
  TEAM_LEADER: [roles.TEAM_LEADER, roles.OPERATOR],
  OPERATOR: [roles.TEAM_LEADER, roles.OPERATOR],
};
