import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (
  monetizationOrders,
  sales,
  isAdmin,
  t,
  openViewModal,
) => {
  const actions = isAdmin
    ? {
        title: 'Action',
        name: 'action',
        width: 80,
        align: 'center',
        formatterComponent: ({ row }) => (
          <ul className="list-inline font-size-20 contact-links mb-0">
            <ViewButton row={row} onPress={openViewModal} />
          </ul>
        ),
        filter: 'disabled',
        sorting: 'disabled',
      }
    : {
        title: 'Action',
        name: 'action',
        hidden: true,
        filter: 'disabled',
        sorting: 'disabled',
      };

  const adminColumns = isAdmin
    ? [
        {
          title: 'Revenues',
          name: 'revenues',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Qualified Costs',
          name: 'positiveQualifiedCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Acquisition Costs',
          name: 'acquisitionCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Sale',
          name: 'customerId',
          width: 180,
          formatterComponent: ({ value }) => getLabel(value, sales),
          sorting: (a, b) => getSort(getLabel(a, sales), getLabel(b, sales)),
          filter: (value, filter) =>
            getFilter(getLabel(value, sales), filter.value),
        },
        {
          title: 'Total Costs',
          name: 'totalCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Margin',
          name: 'margin',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
      ]
    : [];

  return [
    {
      title: 'Monetization Order',
      name: 'monetizationOrderId',
      width: isAdmin ? 180 : 250,
      formatterComponent: ({ value }) => getLabel(value, monetizationOrders),
      sorting: (a, b) =>
        getSort(
          getLabel(a, monetizationOrders),
          getLabel(b, monetizationOrders),
        ),
      filter: (value, filter) =>
        getFilter(getLabel(value, monetizationOrders), filter.value),
    },
    {
      title: 'Day',
      name: 'date',
      width: isAdmin ? 120 : 200,
      formatterComponent: ({ value }) =>
        moment(value).format('dddd, DD/MM/YYYY'),
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: (value, filter) =>
        getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
    },
    {
      title: 'Positive',
      name: 'positives',
    },
    {
      title: 'Refused',
      name: 'refused',
    },
    {
      title: isAdmin ? 'Sale Costs' : 'Revenues',
      name: 'totalSupplierCosts',
      formatterComponent: ({ value }) =>
        `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    },
    ...adminColumns,
    actions,
  ];
};

export default createTableColumns;
