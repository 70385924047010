import React from 'react';
import * as nextCallActions from '../../store/nextCall/actions';
import { useDispatch } from 'react-redux';

import Alert from '../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';

const StartCallAlert = (props) => {
  const { call, user } = props;

  const dispatch = useDispatch();

  const handleStartCall = () => {
    dispatch(nextCallActions.fetchNextCall(call, true));
    props.onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Do you want to manage this call?')}
      mainIcon={
        <div className="avatar-sm mx-auto my-2">
          <span className="avatar-title rounded-circle bg-warning font-size-24">
            <i className={`bx bx-phone-call text-dark`}></i>
          </span>
        </div>
      }
      confirmBtnLabel="Confirm"
      onCancel={props.onCancel}
      onConfirm={handleStartCall}>
      <h5>{call?.leadName || ''}</h5>
      {call?.appointmentUserId && call?.appointmentUserId !== user.id && (
        <h5 className="mt-3 text-danger">
          {t('This call is an appointment assigned to another operator')}!
        </h5>
      )}
    </Alert>
  );
};

export default StartCallAlert;
