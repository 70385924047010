import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_AUDIENCE_STATE,
});

export const createAudience = (audience) => ({
  type: types.CREATE_AUDIENCE.REQUEST,
  audience,
});

export const createAudienceSuccess = (audience) => ({
  type: types.CREATE_AUDIENCE.SUCCESS,
  audience,
});

export const createAudienceFailure = (error) => ({
  type: types.CREATE_AUDIENCE.FAILURE,
  error,
});

export const updateAudience = (audience) => ({
  type: types.UPDATE_AUDIENCE.REQUEST,
  audience,
});

export const updateAudienceSuccess = () => ({
  type: types.UPDATE_AUDIENCE.SUCCESS,
});

export const updateAudienceFailure = (error) => ({
  type: types.UPDATE_AUDIENCE.FAILURE,
  error,
});

export const syncAudiences = () => ({
  type: types.SYNC_AUDIENCES.REQUEST,
});

export const syncAudiencesSuccess = (audiences) => ({
  type: types.SYNC_AUDIENCES.SUCCESS,
  audiences,
});

export const syncAudiencesFailure = (error) => ({
  type: types.SYNC_AUDIENCES.FAILURE,
  error,
});
