const statsTypes = {
  CHANGE_LAYOUT: 'CHANGE_LAYOUT',
  CHANGE_LAYOUT_WIDTH: 'CHANGE_LAYOUT_WIDTH',
  CHANGE_SIDEBAR_THEME: 'CHANGE_SIDEBAR_THEME',
  CHANGE_SIDEBAR_TYPE: 'CHANGE_SIDEBAR_TYPE',
  CHANGE_TOPBAR_THEME: 'CHANGE_TOPBAR_THEME',
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
  TOGGLE_LEFT_MENU: 'TOGGLE_LEFT_MENU',
  SHOW_RIGHT_SIDEBAR: 'SHOW_RIGHT_SIDEBAR',
  CHANGE_PRELOADER: 'CHANGE_PRELOADER',
  TOGGLE_SENSITIVE_DATA: 'TOGGLE_SENSITIVE_DATA',
};

export default statsTypes;
