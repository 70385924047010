import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  waitingTimeRecall: yup.string().required(),
  phoneExchange: yup.string().required(),
  facebookModule: yup.boolean().required(),
  facebookAdAccountIds: yup.string().when('facebookModule', {
    is: true,
    then: () => yup.string().matches(/^[0-9]+((,)[0-9]+)*[0-9]+$/),
  }),
  facebookAccessToken: yup.string().when('facebookModule', {
    is: true,
    then: () => yup.string().required(),
  }),
  googleModule: yup.boolean().required(),
  supplierModule: yup.boolean().required(),
  audienceModule: yup.boolean().required(),
});

export default formValidation;
