import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as dailyPublisherInsightsActions from '../../store/suppliers/dailyPublisherInsights/actions';
import * as suppliersActions from '../../store/suppliers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  userPublisherId,
} from '../../selectors/auth';
import { selectSupplierByType } from '../../selectors/supplier';
import PublisherMetrics from '../../components/Suppliers/PublisherMetrics';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Suppliers/DailyPublisherInsights/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import supplierTypes from '../../config/supplierTypes';
import { useTranslation } from 'react-i18next';

const DailyPublisherInsights = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const supplierId = useSelector(userPublisherId);

  const dailyInsights = useSelector(
    (state) => state.DailyPublisherInsights.insights,
  );
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const publishers = useSelector(selectSupplierByType(supplierTypes.PUBLISHER));
  const loading = useSelector((state) => state.DailyPublisherInsights.loading);

  const [selectedInsight, setSelectedInsight] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector(
    (state) => state.DailyPublisherInsights.startDate,
  );
  const endDate = useSelector((state) => state.DailyPublisherInsights.endDate);
  const dataFilters = useSelector(
    (state) => state.DailyPublisherInsights.filters,
  );
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    supplierId: dataFilters?.supplierId || supplierId || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, supplierId, campaignIds }) => {
      const selectedStartDate = startDate.utc().startOf('day').toDate();
      const selectedEndDate = endDate.utc().endOf('day').toDate();
      dispatch(
        dailyPublisherInsightsActions.fetchDailyInsights(
          selectedStartDate,
          selectedEndDate,
          {
            supplierId,
            campaignIds,
          },
        ),
      );
      setSelectedFilters({ startDate, endDate, supplierId, campaignIds });
    },
  );

  useEffect(() => {
    dailyInsights.length === 0 && refresh(selectedFilters);
    dispatch(suppliersActions.syncSuppliers());
  }, []);

  const openViewModal = (insight) => {
    setSelectedInsight(insight);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const columns = useMemo(
    () => createTableColumns(campaigns, publishers, isAdmin, t, openViewModal),
    [campaigns, publishers, isAdmin, t],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Daily Publisher Insights"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
          />
          <Row>
            <PublisherMetrics />
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={dailyInsights}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Call"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedInsight}
              icon="bx bx-message-square-detail"
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DailyPublisherInsights;
