const statsTypes = {
  RESET_DAILY_MONETIZATION_ORDER_INSIGHT_STATE:
    'RESET_DAILY_MONETIZATION_ORDER_INSIGHT_STATE',
  DAILY_MONETIZATION_ORDER_INSIGHTS: {
    REQUEST: 'DAILY_MONETIZATION_ORDER_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_MONETIZATION_ORDER_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_MONETIZATION_ORDER_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
