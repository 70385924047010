export default [
  {
    label: 'Automotive',
    value: 'automotive',
  },
  {
    label: 'Live Hunting',
    value: 'liveHunting',
  },
];
