import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { selectSupplierByType } from '../../selectors/supplier';
import { selectMonetizationOrdersBySale } from '../../selectors/monetizationOrder';
import { userSaleId } from '../../selectors/auth';
import * as suppliersActions from '../../store/suppliers/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';

import { useTranslation } from 'react-i18next';
import supplierTypes from '../../config/supplierTypes';

const MAX_SELECTED_MONETIZATION_ORDERS = 10;

const FilterSalesBox = ({ initialFilters, onUpdate }) => {
  const [supplierId, setSupplierId] = useState(
    initialFilters?.supplierId || '',
  );
  const [monetizationOrderIds, setMonetizationOrderIds] = useState(
    initialFilters?.monetizationOrderIds || null,
  );

  const adminSupplierId = useSelector(userSaleId);
  const suppliers = useSelector(selectSupplierByType(supplierTypes.SALE));
  const loadingSuppliers = useSelector((state) => state.Supplier.loading);
  const monetizationOrders = useSelector(
    selectMonetizationOrdersBySale(supplierId),
  );
  const loadingMonetizationOrders = useSelector(
    (state) => state.MonetizationOrder.loading,
  );

  const selectedSuppliers = adminSupplierId
    ? suppliers.filter((supplier) => supplier.id === adminSupplierId)
    : suppliers;

  const suppliersForSelect = selectedSuppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(suppliersActions.syncSuppliers());
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const handleFilters = (value, field, setFieldValue) => {
    if (field === 'supplierId') {
      setSupplierId(value);
      setMonetizationOrderIds(null);
      setFieldValue('monetizationOrderIds', null);
    } else setMonetizationOrderIds(value);

    onUpdate(
      field === 'supplierId' ? value : supplierId,
      field === 'supplierId'
        ? null
        : field === 'monetizationOrderIds'
        ? value
        : monetizationOrderIds,
    );
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ supplierId, monetizationOrderIds }}
        validationSchema={yup.object().shape({
          supplierId: yup.string(),
          monetizationOrderIds: yup.array().nullable(),
        })}
        className="d-flex">
        {({ setFieldValue }) => (
          <>
            <Col md="3" className="d-flex align-items-center px-0">
              <h5 className="text-muted m-0 mr-2">{t('Sale')}:</h5>
              <div className="flex-fill">
                <Select
                  name="supplierId"
                  id="supplierId"
                  isMulti={false}
                  value={supplierId}
                  onChange={(value) =>
                    handleFilters(value, 'supplierId', setFieldValue)
                  }
                  options={
                    suppliersForSelect.length === 1
                      ? suppliersForSelect
                      : [{ label: t('All'), value: '' }, ...suppliersForSelect]
                  }
                  isDisabled={loadingSuppliers}
                />
              </div>
            </Col>
            <Col md="9" className="d-flex align-items-center px-0">
              <h5 className="text-muted my-0 ml-5 mr-2">
                {t('Monetization Orders')}:
              </h5>
              <div className="flex-fill">
                <Select
                  name="monetizationOrderIds"
                  id="monetizationOrderIds"
                  isMulti
                  maxLength={MAX_SELECTED_MONETIZATION_ORDERS}
                  value={monetizationOrderIds}
                  onChange={(values) =>
                    handleFilters(values, 'monetizationOrderIds', setFieldValue)
                  }
                  options={monetizationOrders.map((monetizationOrder) => ({
                    label: monetizationOrder.name,
                    value: monetizationOrder.id,
                  }))}
                  isDisabled={supplierId === '' || loadingMonetizationOrders}
                />
              </div>
            </Col>
          </>
        )}
      </Form>
    </CardBody>
  );
};

export default FilterSalesBox;
