import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_LEAD_STATE,
});

export const fetchLeads = (startDate, endDate, filters = null) => ({
  type: types.FETCH_LEADS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchLeadsSuccess = (leads, startDate, endDate, filters) => ({
  type: types.FETCH_LEADS.SUCCESS,
  leads,
  startDate,
  endDate,
  filters,
});

export const fetchLeadsFailure = (error) => ({
  type: types.FETCH_LEADS.FAILURE,
  error,
});

export const fetchSingleLead = (leadId) => ({
  type: types.FETCH_SINGLE_LEAD.REQUEST,
  leadId,
});

export const fetchSingleLeadSuccess = (lead) => ({
  type: types.FETCH_SINGLE_LEAD.SUCCESS,
  lead,
});

export const fetchSingleLeadFailure = (error) => ({
  type: types.FETCH_SINGLE_LEAD.FAILURE,
  error,
});

export const createLead = (lead) => ({
  type: types.CREATE_LEAD.REQUEST,
  lead,
});

export const createLeadSuccess = () => ({
  type: types.CREATE_LEAD.SUCCESS,
});

export const createLeadFailure = (error) => ({
  type: types.CREATE_LEAD.FAILURE,
  error,
});

export const updateLead = (lead) => ({
  type: types.UPDATE_LEAD.REQUEST,
  lead,
});

export const updateLeadSuccess = () => ({
  type: types.UPDATE_LEAD.SUCCESS,
});

export const updateLeadFailure = (error) => ({
  type: types.UPDATE_LEAD.FAILURE,
  error,
});

export const importLeads = (
  importLeadType,
  campaignId,
  startDate,
  endDate,
  costPerLead,
  leads,
) => ({
  type: types.IMPORT_LEADS.REQUEST,
  importLeadType,
  campaignId,
  startDate,
  endDate,
  costPerLead,
  leads,
});

export const importLeadsSuccess = () => ({
  type: types.IMPORT_LEADS.SUCCESS,
});

export const importLeadsFailure = (error) => ({
  type: types.IMPORT_LEADS.FAILURE,
  error,
});

export const duplicateLeads = (campaignId, leads) => ({
  type: types.DUPLICATE_LEADS.REQUEST,
  campaignId,
  leads,
});

export const duplicateLeadsSuccess = (logs) => ({
  type: types.DUPLICATE_LEADS.SUCCESS,
  logs,
});

export const duplicateLeadsFailure = (error) => ({
  type: types.DUPLICATE_LEADS.FAILURE,
  error,
});

export const addPushTokenLead = (leadId, pushToken, os, browser) => ({
  type: types.ADD_PUSH_TOKEN_LEAD.REQUEST,
  leadId,
  pushToken,
  os,
  browser,
});

export const addPushTokenLeadSuccess = () => ({
  type: types.ADD_PUSH_TOKEN_LEAD.SUCCESS,
});

export const addPushTokenLeadFailure = (error) => ({
  type: types.ADD_PUSH_TOKEN_LEAD.FAILURE,
  error,
});
