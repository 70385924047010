import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { FormGroup, Label, Collapse, Input } from 'reactstrap';

import toastr from 'toastr';

const buttonRef = React.createRef();
const configCSV = {
  header: true,
  skipEmptyLines: true,
}

const ImportPartialSurvey = (props) => {
  const [selectedSurveyFormId, setSelectedSurveyFormId] = useState(null);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log('handleOnFileLoad: ---------------------------');
    console.log(data);
    console.log('---------------------------')
    props.onFileLoad(selectedSurveyFormId, data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    toastr.error(err, 'Error');
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleSurveyFormField = (event) => {
    setSelectedSurveyFormId(event.target.value);
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="surveyForm">Survey Form</Label>
        <Input
          type="select"
          name="surveyForm"
          id="surveyForm"
          value={selectedSurveyFormId}
          onChange={handleSurveyFormField}>
          <option value="" disabled selected>Select Survey Form</option>
          {props.surveyForms &&
            props.surveyForms.map((survey) => (
              <option key={survey.id} value={survey.id}>
                {survey.name}
              </option>
            ))}
        </Input>
      </FormGroup>
      <Collapse isOpen={!!selectedSurveyFormId}>
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          config={configCSV}
          onRemoveFile={handleOnRemoveFile}>
          {({ file }) => (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
              }}>
              <button
                type="button"
                onClick={handleOpenDialog}
                style={{
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  width: '40%',
                  paddingLeft: 0,
                  paddingRight: 0,
                }}>
                Browse file
              </button>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#ccc',
                  height: 45,
                  lineHeight: 2.5,
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: '60%',
                }}>
                {file && file.name}
              </div>
              <button
                style={{
                  borderRadius: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                onClick={handleRemoveFile}>
                Remove
              </button>
            </aside>
          )}
        </CSVReader>
      </Collapse>
    </>
  );
};

export default ImportPartialSurvey;
