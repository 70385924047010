import '@availity/yup';
import * as yup from 'yup';
import { types } from '../../config/surveyFormTypes';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  provider: yup.string().required(),
  messageTemplateId: yup.string().required(),
  urlSchema: yup
    .string()
    .required()
    .when('type', {
      is: (value) => value === types.MARKETING_CAMPAIGN,
      then: () => yup.string().matches(/^(?=.*\b(?:personId|leadId)\b).*$/g),
      //.matches(/^(?=.*\bpersonId\b)(?=.*\bmarketingCampaignId\b).*$/g),
    }),
  type: yup.string().required(),
  headerCSV: yup.string().when('type', {
    is: (value) => value === types.DATA_ENRICHMENT,
    then: () =>
      yup
        .string()
        .matches(/^\w+?(?:,\w+?)*$/g)
        .required(),
  }),
  personalAttributes: yup.string().when('type', {
    is: (value) => value === types.DATA_ENRICHMENT,
    then: () =>
      yup
        .string()
        .matches(/^\w+?(?:,\w+?)*$/g)
        .required(),
  }),
  proceedAnswer: yup.string().when('type', {
    is: (value) => value === types.MARKETING_CAMPAIGN,
    then: () => yup.string().required(),
  }),
});

export default formValidation;
