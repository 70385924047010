import React from 'react';
import { Media, UncontrolledTooltip } from 'reactstrap';

import { selectUser } from '../../selectors/user';
import { useSelector } from 'react-redux';

import callOutcomes from '../../config/callOutcomes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const CallElement = ({ call, key }) => {
  const user = useSelector(selectUser(call.userId));

  const outcome = callOutcomes[call.outcome];

  const { t } = useTranslation();
  return (
    <li className="list-group-item">
      <div>
        <Media>
          <div className="avatar-xs mr-3">
            <span className="avatar-title rounded-circle bg-light font-size-20">
              <i className={`${outcome.icon} text-${outcome.color}`}></i>
            </span>
          </div>
          <Media body>
            <h5 className="font-size-14">{t(outcome.title)}</h5>
            {call.note && <p className="text-muted">{call.note}</p>}
          </Media>
        </Media>
      </div>
      <div className="float-right">
        <p className="text-muted mb-0" id={'viewUserName_' + key}>
          <i className="mdi mdi-account font-size-16"></i>
          {user && (
            <UncontrolledTooltip placement="top" target={'viewUserName_' + key}>
              {user.displayName}
            </UncontrolledTooltip>
          )}
        </p>
      </div>
      <p className="text-muted mb-0">
        {moment(call.endDateCall).format('DD/MM/YY HH:mm')}
      </p>
    </li>
  );
};

export const AnyCallToShow = ({ status }) => {
  const { t } = useTranslation();

  return (
    <li className="list-group-item">
      <Media>
        <div className="avatar-xs mr-3">
          <span className="avatar-title rounded-circle bg-light font-size-20">
            <i className="bx bx bx-error-circle text-warning"></i>
          </span>
        </div>
        <Media body>
          <h5 className="font-size-14">
            {t('Any call in status')} <i>'{t(status)}'</i> {t('to show')}
          </h5>
        </Media>
      </Media>
    </li>
  );
};
