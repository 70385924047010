import React from 'react';
import * as nextCallActions from '../../../store/nextCall/actions';
import { useDispatch } from 'react-redux';

import Alert from '../../Common/Modal/Alert';
import relaxIcon from '../../../assets/images/relax-icon.png';

import { useTranslation } from 'react-i18next';

const EndShiftAlert = (props) => {
  const dispatch = useDispatch();

  const handleEndShift = () => {
    dispatch(nextCallActions.resetState());
    props.onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Is it time to end this working day?')}
      mainIcon={relaxIcon}
      confirmBtnLabel="Confirm"
      onCancel={props.onCancel}
      onConfirm={handleEndShift}
    />
  );
};

export default EndShiftAlert;
