import React from 'react';
import { useTranslation } from 'react-i18next';

export const SubmitButton = ({ disabled, create }) => {
    const icon = create ? 'bx bx-plus' : 'bx bx-pencil';
    const { t } = useTranslation();
    return (
        <button
            type="submit"
            disabled={disabled}
            className="btn btn-dark waves-effect waves-light">
            <i className={`${icon} font-size-16 align-middle mr-2`}></i>
            {create ? t('Create') : t('Save')}
        </button>
    );
};
