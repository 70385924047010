import React, { useEffect } from 'react';

import { Row, Col, Card, CardBody, Container } from 'reactstrap';

import * as nextCallActions from '../../store/nextCall/actions';
import * as leadsActions from '../../store/leads/actions';
import * as leadFormsActions from '../../store/leadForms/actions';
import * as surveysActions from '../../store/surveys/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLead } from '../../selectors/lead';
import { selectSurveyByLead } from '../../selectors/survey';
import { selectAcquisitionOrder } from '../../selectors/acquisitionOrder';

import CallHistory from '../../components/Calls/CallHistory';
import FindNewCalls from '../../components/Calls/FindNewCalls';
import ReadyToStart from '../../components/Calls/ReadyToStart';
import ManageLead from '../../components/Calls/ManageLead';
import MonetizationItemHistory from '../../components/MonetizationItems/MonetizationItemHistory';
import CreateNote from '../../components/Calls/CreateNote';
import ShowSuggestion from '../../components/AcquisitionOrders/ShowSuggestion';
import OutcomeBar from '../../components/Calls/outcome/OutcomeBar';
import TitleCall from '../../components/Calls/TitleCall';

import colors from '../../components/CommonStyle/colors';
import Alert from '../../components/Common/Modal/Alert';
import CenterBox from '../../components/Common/CenterBox';
import { Dots } from 'react-activity';
import BeforeUnloadComponent from '../../lib/react-beforeunload-component/src';
import { useTranslation } from 'react-i18next';

const ManageCall = (props) => {
  const loadingNextCall = useSelector((state) => state.NextCall.loading);
  const loadingLeads = useSelector((state) => state.Leads.loading);
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);
  const loadingMonetizationOrders = useSelector(
    (state) => state.MonetizationOrder.loading,
  );
  const loadingSurveys = useSelector((state) => state.Survey.loading);

  const call = useSelector((state) => state.NextCall.nextCall);
  const callId = call?.id;
  const inbound = call?.inbound ?? false;
  const lead = useSelector(selectLead(call?.leadId));
  const survey = useSelector(selectSurveyByLead(call?.leadId));
  const acquisitionOrder = useSelector(
    selectAcquisitionOrder(call?.acquisitionOrderId),
  );

  const dispatch = useDispatch();

  const handleOnStart = () => {
    dispatch(nextCallActions.fetchNextCall());
  };

  useEffect(() => {
    callId && dispatch(nextCallActions.syncNextCall(callId, inbound));
    callId && dispatch(leadsActions.fetchSingleLead(call.leadId));
    callId && dispatch(surveysActions.fetchSurveyByLead(call.leadId));
  }, [callId]);

  useEffect(() => {
    dispatch(leadFormsActions.syncLeadForms());
  }, []);

  /*
  const onBeforeUnload = () => {
    callId &&
      call.status !== 'closed' &&
      dispatch(
        callsActions.updateCall({
          id: callId,
          outcome: 'recall',
          status: 'closed',
          endDateCall: new Date(),
        }),
      );
  };*/

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              {loadingNextCall ? (
                <Card>
                  <CardBody style={{ textAlign: 'center' }}>
                    <CenterBox height={500}>
                      <Dots color={colors.info} size={30} />
                    </CenterBox>
                  </CardBody>
                </Card>
              ) : !call || call === 'none' ? (
                <>
                  <Card>
                    <CardBody>
                      <ReadyToStart start={!call} onStart={handleOnStart} />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <FindNewCalls />
                    </CardBody>
                  </Card>
                </>
              ) : (
                <>
                  <BeforeUnloadComponent
                    blockRoute={true}
                    //beforeUnload={onBeforeUnload}
                    modalComponentHandler={({
                      handleModalLeave,
                      handleModalCancel,
                    }) => {
                      return (
                        <Alert
                          isOpen={true}
                          title={t(
                            'You cannot leave before finishing the call',
                          )}
                          onCancel={handleModalCancel}
                          mainIcon={
                            <div className="avatar-sm mx-auto my-2">
                              <span className="avatar-title rounded-circle bg-danger font-size-24">
                                <i className="bx bx-error text-light"></i>
                              </span>
                            </div>
                          }
                        />
                      );
                    }}>
                    <Row>
                      <Col md="9">
                        <TitleCall call={call} lead={lead} />
                        {lead && (
                          <>
                            <ManageLead
                              lead={lead}
                              survey={survey}
                              loading={
                                loadingNextCall ||
                                loadingCampaigns ||
                                loadingMonetizationOrders ||
                                loadingLeads ||
                                loadingSurveys
                              }
                            />
                            <MonetizationItemHistory lead={lead} />
                          </>
                        )}
                      </Col>
                      <Col md="3">
                        <CallHistory lead={lead} status="closed" />
                        {acquisitionOrder && acquisitionOrder.suggestion && (
                          <ShowSuggestion acquisitionOrder={acquisitionOrder} />
                        )}
                        <CreateNote call={call} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <OutcomeBar
                          call={call}
                          lead={lead}
                          acquisitionOrder={acquisitionOrder}
                        />
                      </Col>
                    </Row>
                  </BeforeUnloadComponent>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageCall;
