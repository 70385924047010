import React from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as suppliersActions from '../../store/suppliers/actions';
import { useDispatch } from 'react-redux';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import supplierTypes from '../../config/supplierTypes';

const FormSupplier = (props) => {
  const editingSupplier = props.supplier || {};

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? suppliersActions.createSupplier(values)
        : suppliersActions.updateSupplier({
            id: editingSupplier.id,
            ...values,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingSupplier}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="4">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="2">
                  <Select
                    name="supplierType"
                    id="supplierType"
                    label="Type"
                    isMulti={false}
                    options={Object.values(supplierTypes).map((supplier) => ({
                      label: supplier,
                      value: supplier,
                    }))}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSupplier;
