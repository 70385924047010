import {
  takeEvery,
  select,
  put,
  all,
  call,
  takeLeading,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as surveysActions from './actions';
import { selectSurveyForm } from '../../selectors/surveyForm';

import {
  getFirestore,
  query,
  collection,
  doc,
  where,
  orderBy,
  limit,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import {
  toDateFirebase,
  DDMMYYYYHHmmssToDateTime,
} from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

const surveyTransformer = (survey, data) => {
  return {
    id: survey.id,
    ...data,
    interests: data.interests ? data.interests.split(',') : '',
    ...(data.createdAt && {
      createdAt: toDateFirebase(survey, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(survey, data, 'updatedAt').toDate(),
    }),
  };
};

function* createSurveySaga({ survey }) {
  try {
    const surveysRef = collection(db, 'surveys');
    const countryId = yield select((state) => state.Dashboard.countryId);

    yield call(rsf.firestore.addDocument, surveysRef, {
      ...survey,
      countryId,
      createdAt: serverTimestamp(),
    });
    yield put(surveysActions.createSurveySuccess(survey));
    toastr.success('Survey created!', '');
  } catch (error) {
    yield put(surveysActions.createSurveyFailure(error));
  }
}

function* updateSurveySaga({ survey }) {
  try {
    const surveysRef = doc(db, 'surveys', survey.id);
    delete survey.id;

    yield call(
      rsf.firestore.setDocument,
      surveysRef,
      {
        ...survey,
        ...(survey.interests && {
          interests: survey.interests.join(','),
        }),
        ...(survey.createdAt && {
          createdAt: Timestamp.fromDate(new Date(survey.createdAt)),
        }),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(surveysActions.updateSurveySuccess(survey));
    toastr.success('Survey updated!', '');
  } catch (error) {
    yield put(surveysActions.updateSurveyFailure(error));
  }
}

function* fetchSurveysSaga({ startDate, endDate }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const surveysRef = query(
      collection(db, 'surveys'),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      where('countryId', '==', countryId),
      orderBy('createdAt', 'desc'),
    );

    const surveysSnap = yield call(rsf.firestore.getCollection, surveysRef);

    let surveys = [];

    surveysSnap.forEach((survey) => {
      const data = survey.data();
      surveys.push(surveyTransformer(survey, data));
    });

    yield put(surveysActions.fetchSurveysSuccess(surveys, startDate, endDate));
  } catch (error) {
    yield put(surveysActions.fetchSurveysFailure(error));
  }
}

function* fetchSurveyByLeadSaga({ leadId }) {
  try {
    const surveyRef = query(
      collection(db, 'surveys'),
      where('leadId', '==', leadId),
      orderBy('createdAt', 'desc'),
      limit(1),
    );

    const surveySnap = yield call(rsf.firestore.getCollection, surveyRef);

    let survey = null;
    if (!surveySnap.empty) {
      const data = surveySnap.docs[0].data();
      survey = surveyTransformer(surveySnap.docs[0], data);
    }

    yield put(surveysActions.fetchSurveyByLeadSuccess(survey));
  } catch (error) {
    yield put(surveysActions.fetchSurveyByLeadFailure(error));
  }
}

function* importPartialSurveysSaga({ surveyFormId, partialSurveys }) {
  try {
    const lastPartialSurveyRef = query(
      collection(db, 'surveys'),
      where('completed', '==', 'no'),
      orderBy('createdAt', 'desc'),
      limit(1),
    );
    const lastPartialSurveySnap = yield call(
      rsf.firestore.getCollection,
      lastPartialSurveyRef,
    );

    const surveyForm = yield select(selectSurveyForm(surveyFormId));

    //Rewrite object from CSV with new key from surveyForm.headerCSVArray

    let cleanPartialSurveys = partialSurveys.map((survey) => {
      const { data } = survey;
      let cleanSurvey = {};
      let i = 0;
      for (let key in data) {
        cleanSurvey[surveyForm.headerCSVArray[i]] = data[key];
        i++;
      }
      return cleanSurvey;
    });

    //console.log('1.', cleanPartialSurveys);

    if (!lastPartialSurveySnap.empty) {
      const lastPartialSurveyDate = lastPartialSurveySnap.docs[0]
        .data()
        .createdAt.toDate();

      cleanPartialSurveys = cleanPartialSurveys.filter(
        (survey) =>
          DDMMYYYYHHmmssToDateTime(survey.createdAt) > lastPartialSurveyDate,
      );
    }

    //console.log('2. ', cleanPartialSurveys);

    const countryId = yield select((state) => state.Dashboard.countryId);
    const surveysRef = collection(db, 'surveys');
    yield all(
      cleanPartialSurveys.map((survey) =>
        call(rsf.firestore.addDocument, surveysRef, {
          ...survey,
          surveyFormId,
          countryId,
          completed: 'no',
          createdAt: Timestamp.fromDate(
            DDMMYYYYHHmmssToDateTime(survey.createdAt),
          ),
        }),
      ),
    );
    yield put(surveysActions.importPartialSurveysSuccess());
    toastr.success(`${cleanPartialSurveys.length} Surveys imported!`, '');
  } catch (error) {
    yield put(surveysActions.importPartialSurveysFailure(error));
    toastr.error(error, 'Error');
  }
}

function* surveySaga() {
  yield all([
    takeEvery(types.FETCH_SURVEYS.REQUEST, fetchSurveysSaga),
    takeLeading(types.CREATE_SURVEY.REQUEST, createSurveySaga),
    takeLeading(types.UPDATE_SURVEY.REQUEST, updateSurveySaga),
    takeLeading(types.FETCH_SURVEY_BY_LEAD.REQUEST, fetchSurveyByLeadSaga),
    takeLeading(types.IMPORT_PARTIAL_SURVEYS.REQUEST, importPartialSurveysSaga),
  ]);
}

export default surveySaga;
