import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import usFlag from '../../../assets/images/flags/us.jpg';
import itFlag from '../../../assets/images/flags/italy.jpg';

import moment from 'moment';
import 'moment/locale/it';

const LanguageDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [flag, setFlag] = useState(itFlag);
  const [lng, setLng] = useState('Italiano');

  const changeLanguageAction = (lng) => {
    i18n.changeLanguage(lng);

    if (lng === 'it') {
      setFlag(itFlag);
      setLng('Italiano');
      moment.locale('it');
    } else if (lng === 'eng') {
      setFlag(usFlag);
      setLng('English');
      moment.locale('en');
    }
  };

  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img src={flag} alt="flag" height="16" className="mr-1" />
          <span className="align-middle">{lng}</span>
        </DropdownToggle>
        <DropdownMenu className="language-switch" right>
          <DropdownItem
            tag="a"
            href="#"
            onClick={() => changeLanguageAction('eng')}
            className="notify-item">
            <img src={usFlag} alt="usFlag" className="mr-1" height="12" />
            <span className="align-middle">English</span>
          </DropdownItem>
          <DropdownItem
            tag="a"
            href="#"
            onClick={() => changeLanguageAction('it')}
            className="notify-item">
            <img src={itFlag} alt="itFlag" className="mr-1" height="12" />
            <span className="align-middle">Italiano</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
