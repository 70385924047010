const statsTypes = {
  RESET_ACQUISITION_ORDER_STATE: 'RESET_ACQUISITION_ORDER_STATE',
  CREATE_ACQUISITION_ORDER: {
    REQUEST: 'CREATE_ACQUISITION_ORDER.REQUEST',
    SUCCESS: 'CREATE_ACQUISITION_ORDER.SUCCESS',
    FAILURE: 'CREATE_ACQUISITION_ORDER.FAILURE',
  },
  UPDATE_ACQUISITION_ORDER: {
    REQUEST: 'UPDATE_ACQUISITION_ORDER.REQUEST',
    SUCCESS: 'UPDATE_ACQUISITION_ORDER.SUCCESS',
    FAILURE: 'UPDATE_ACQUISITION_ORDER.FAILURE',
  },
  SYNC_ACQUISITION_ORDERS: {
    REQUEST: 'SYNC_ACQUISITION_ORDERS.REQUEST',
    SUCCESS: 'SYNC_ACQUISITION_ORDERS.SUCCESS',
    FAILURE: 'SYNC_ACQUISITION_ORDERS.FAILURE',
  },
};

export default statsTypes;
