const statsTypes = {
  RESET_SUPPLIER_STATE: 'RESET_SUPPLIER_STATE',
  CREATE_SUPPLIER: {
    REQUEST: 'CREATE_SUPPLIER.REQUEST',
    SUCCESS: 'CREATE_SUPPLIER.SUCCESS',
    FAILURE: 'CREATE_SUPPLIER.FAILURE',
  },
  UPDATE_SUPPLIER: {
    REQUEST: 'UPDATE_SUPPLIER.REQUEST',
    SUCCESS: 'UPDATE_SUPPLIER.SUCCESS',
    FAILURE: 'UPDATE_SUPPLIER.FAILURE',
  },
  SYNC_SUPPLIERS: {
    REQUEST: 'SYNC_SUPPLIERS.REQUEST',
    SUCCESS: 'SYNC_SUPPLIERS.SUCCESS',
    FAILURE: 'SYNC_SUPPLIERS.FAILURE',
  },
};

export default statsTypes;
