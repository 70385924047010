import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as leadFieldsActions from '../../store/leadFields/actions';
import { showOnFormLeadField } from '../../selectors/leadField';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/LeadFields/tableColumns';
import {
  CreateButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import FormLeadField from '../../components/LeadFields/FormLeadField';
import { ReactSortable } from 'react-sortablejs';

const LeadFields = (props) => {
  const loading = useSelector((state) => state.LeadField.loading);
  const leadFields = useSelector((state) => state.LeadField.leadFields);
  const leadFieldsOnForm = useSelector(showOnFormLeadField());

  const [editModal, setEditModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [selectedLeadField, setSelectedLeadField] = useState(null);
  const [sortedLeadFields, setSortedLeadFields] = useState(leadFieldsOnForm);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFieldsActions.syncLeadFields());
  }, []);

  const openEditModal = (leadField = null) => {
    setSelectedLeadField(leadField);
    setEditModal(true);
  };

  const handleSortLeadFields = () => {
    const sortNumbers = sortedLeadFields.map((field, index) => ({
      id: field.id,
      sort: index + 1,
    }));
    dispatch(leadFieldsActions.sortLeadFields(sortNumbers));
    setSortModal(false);
  };

  const columns = createTableColumns(openEditModal);
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Lead Fields"
            onClickButtons={[() => setSortModal(true)]}
            titleButtons={['Sort Field']}
            iconButtons={['bx bx-sort-down']}
            loading={loading}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Lead Field"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={leadFields}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead Field"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormLeadField
              closeModal={() => setEditModal(false)}
              create={!selectedLeadField}
              loading={loading}
              leadField={selectedLeadField}
              leadFields={leadFields}
            />
          </Modal>
          <Modal
            size="sm"
            title="Sort Lead Field"
            isOpen={sortModal}
            toggle={() => setSortModal(!sortModal)}
            scrollable={false}>
            <ReactSortable
              list={sortedLeadFields}
              setList={setSortedLeadFields}>
              {sortedLeadFields.map((field, index) => (
                <Container key={field.id}>
                  <div
                    className="waves-effect btn btn-outline-secondary"
                    style={{ marginBottom: '10px' }}>
                    {index + 1} - {field.name}
                  </div>
                </Container>
              ))}
            </ReactSortable>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark waves-effect waves-light"
                onClick={handleSortLeadFields}>
                Save
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LeadFields;
