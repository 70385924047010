import { createSelector } from 'reselect';
import { selectAcquisitionOrdersBySector } from './acquisitionOrder';

export const selectCampaign = (id) =>
  createSelector(
    (state) => state.Campaign.campaigns,
    (campaigns) => {
      if (!id) return null;
      return campaigns.find((campaign) => campaign.id === id);
    },
  );

export const selectLeadCampaigns = createSelector(
  (state) => state.Campaign.campaigns,
  (campaigns) => campaigns.filter(({ isLeadCampaign }) => isLeadCampaign),
);

export const selectCampaignInsights = (onlyLeadCampaign = false) =>
  createSelector(
    selectLeadCampaigns,
    (state) => state.DailyCampaignInsights.insights,
    (campaigns, insights) => {
      if (onlyLeadCampaign) {
        const campaignIds = campaigns.map(({ id }) => id);
        return insights.filter(({ campaignId }) =>
          campaignIds.includes(campaignId),
        );
      }
      return insights;
    },
  );

export const selectCampaignsForAcquisitionOrderSelect = (acquisitionOrder) =>
  createSelector(
    (state) => state.Campaign.campaigns,
    (campaigns) => {
      let data = [];

      if (!acquisitionOrder) {
        campaigns.forEach((campaign) => {
          campaign.acquisitionOrderId === '' &&
            data.push({
              value: campaign.id,
              label: campaign.name,
            });
        });
      } else {
        campaigns.forEach((campaign) => {
          (campaign.acquisitionOrderId === '' ||
            acquisitionOrder.campaignIds.includes(campaign.id)) &&
            data.push({
              value: campaign.id,
              label: campaign.name,
            });
        });
      }

      return data;
    },
  );

export const selectCampaignsWithActiveAcquisitionOrder = createSelector(
  (state) => state.Campaign.campaigns,
  (state) => state.AcquisitionOrder.acquisitionOrders,
  (campaigns, acquisitionOrders) => {
    const activeAcquisitionOrders = acquisitionOrders.filter(
      (acquisitionOrder) => acquisitionOrder.active,
    );
    const activeAcquisitionOrdersId = activeAcquisitionOrders.map(
      (acquisitionOrder) => acquisitionOrder.id,
    );
    return campaigns.filter(
      (campaign) =>
        campaign.acquisitionOrderId !== '' &&
        activeAcquisitionOrdersId.includes(campaign.acquisitionOrderId),
    );
  },
);

export const selectCampaignsBySector = (sector, withArchived = true) =>
  createSelector(
    (state) => state.Campaign.campaigns,
    selectAcquisitionOrdersBySector(sector),
    (campaigns, acquisitionOrders) => {
      const selectedCampaigns = withArchived
        ? campaigns
        : campaigns.filter((campaign) => !campaign.archived);
      if (sector === '') return selectedCampaigns;
      const acquisitionOrderIds = acquisitionOrders.map((ao) => ao.id).flat();
      return selectedCampaigns.filter((campaign) =>
        acquisitionOrderIds.includes(campaign.acquisitionOrderId),
      );
    },
  );

export const selectCampaignsByPublisher = (supplierId, onlyActive = false) =>
  createSelector(
    (state) => state.Campaign.campaigns,
    (campaigns) => {
      const selectedCampaigns = campaigns.filter(
        (campaign) => campaign.supplierId === supplierId,
      );
      return onlyActive
        ? selectedCampaigns.filter((campaign) => !campaign.archived)
        : selectedCampaigns;
    },
  );

export const countCampaignsByPublisher = (supplierId, onlyActive = false) =>
  createSelector(
    selectCampaignsByPublisher(supplierId, onlyActive),
    (campaigns) => campaigns.length,
  );

export const selectCampaignsBySource = (source, onlyActive = false) =>
  createSelector(
    (state) => state.Campaign.campaigns,
    (campaigns) => {
      const selectedCampaigns = campaigns.filter(
        (campaign) => campaign.source === source,
      );
      return onlyActive
        ? selectedCampaigns.filter((campaign) => !campaign.archived)
        : selectedCampaigns;
    },
  );

export const totalRevenues = createSelector(
  (state) => state.DailyCampaignInsights.insights,
  (insights) =>
    insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
);

export const totalAdvCosts = (onlyLeadCampaign = false) =>
  createSelector(selectCampaignInsights(onlyLeadCampaign), (insights) =>
    insights.map(({ advCosts }) => advCosts).reduce((a, b) => a + b, 0),
  );

export const totalCosts = (onlyLeadCampaign = false) =>
  createSelector(selectCampaignInsights(onlyLeadCampaign), (insights) =>
    insights.map(({ totalCosts }) => totalCosts).reduce((a, b) => a + b, 0),
  );

export const totalMargin = (onlyLeadCampaign = false) =>
  createSelector(selectCampaignInsights(onlyLeadCampaign), (insights) =>
    insights.map(({ margin }) => margin).reduce((a, b) => a + b, 0),
  );

export const totalLeads = createSelector(
  (state) => state.DailyCampaignInsights.insights,
  (insights) =>
    insights.map((insight) => insight.leads).reduce((a, b) => a + b, 0),
);

export const totalPositiveLeads = createSelector(
  (state) => state.DailyCampaignInsights.insights,
  (insights) =>
    insights.map((insight) => insight.positiveLeads).reduce((a, b) => a + b, 0),
);

export const averageCostPerLead = createSelector(
  totalCosts(true),
  totalLeads,
  (costs, leads) => costs / leads,
);
