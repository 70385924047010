import { put, all, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as callInsightsActions from './actions';
import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import * as callsActions from '../../store/calls/actions';

function* setFiltersSaga({ startDate, endDate, filters }) {
  try {
    yield put(
      monetizationItemsActions.fetchMonetizationItems(
        startDate,
        endDate,
        filters,
      ),
    );
    yield put(callsActions.fetchClosedCalls(startDate, endDate, filters));

    yield yield put(
      callInsightsActions.setFiltersSuccess(startDate, endDate, filters),
    );
  } catch (error) {
    yield put(callInsightsActions.setFiltersFailure(error));
  }
}

function* callInsightSaga() {
  yield all([
    takeLatest(types.SET_CALL_INSIGHT_FILTERS.REQUEST, setFiltersSaga),
  ]);
}

export default callInsightSaga;
