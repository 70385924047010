import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (
  campaigns,
  publishers,
  isAdmin,
  t,
  openViewModal,
) => {
  const actions = isAdmin
    ? {
        title: 'Action',
        name: 'action',
        width: 80,
        align: 'center',
        formatterComponent: ({ row }) => (
          <ul className="list-inline font-size-20 contact-links mb-0">
            <ViewButton row={row} onPress={openViewModal} />
          </ul>
        ),
        filter: 'disabled',
        sorting: 'disabled',
      }
    : {
        title: 'Action',
        name: 'action',
        hidden: true,
        filter: 'disabled',
        sorting: 'disabled',
      };

  const adminColumns = isAdmin
    ? [
        {
          title: 'Revenues',
          name: 'revenues',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Adv Costs',
          name: 'advCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
          hidden: true,
        },
        {
          title: 'Qualified Costs',
          name: 'qualifiedCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
          hidden: true,
        },
        {
          title: 'Publisher',
          name: 'supplierId',
          width: 180,
          formatterComponent: ({ value }) => getLabel(value, publishers),
          sorting: (a, b) =>
            getSort(getLabel(a, publishers), getLabel(b, publishers)),
          filter: (value, filter) =>
            getFilter(getLabel(value, publishers), filter.value),
        },
        {
          title: 'Total Costs',
          name: 'totalCosts',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'Margin',
          name: 'margin',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
        },
        {
          title: 'eCPA',
          name: 'eCPA',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,

          hidden: true,
        },
        {
          title: 'eCPQ',
          name: 'eCPQ',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
          hidden: true,
        },
        {
          title: 'eRPL',
          name: 'eRPL',
          formatterComponent: ({ value }) =>
            `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
          hidden: true,
        },
      ]
    : [];

  return [
    {
      title: 'Campaign',
      name: 'campaignId',
      width: 180,
      formatterComponent: ({ value }) => getLabel(value, campaigns),
      sorting: (a, b) =>
        getSort(getLabel(a, campaigns), getLabel(b, campaigns)),
      filter: (value, filter) =>
        getFilter(getLabel(value, campaigns), filter.value),
    },
    {
      title: 'Day',
      name: 'date',
      width: isAdmin ? 120 : 200,
      formatterComponent: ({ value }) =>
        moment(value).format('dddd, DD/MM/YYYY'),
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: (value, filter) =>
        getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
    },
    {
      title: 'Incomings',
      name: 'leads',
    },
    {
      title: 'Worked',
      name: 'workedLeads',
    },
    {
      title: 'Positive',
      name: 'positiveLeads',
    },
    {
      title: 'Negative',
      name: 'negativeLeads',
    },
    {
      title: 'Fake',
      name: 'fakeLeads',
    },
    {
      title: 'No timing',
      name: 'leadsNoTiming',
    },
    {
      title: 'Refused',
      name: 'refusedLeads',
    },
    {
      title: isAdmin ? 'Publisher Costs' : 'Revenues',
      name: 'totalSupplierCosts',
      formatterComponent: ({ value }) =>
        `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    },
    ...adminColumns,
    actions,
  ];
};

export default createTableColumns;
