import { serverTimestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const createNote = (lead) => {
  return `- Azienda: ${lead['AZIENDA'] || ''}
- Indirizzo: ${lead['INDIRIZZO'] || ''}
- Telefono: ${lead['TELEFONO'] || ''}
- Sito web: ${lead['SITO_WEB'] || ''}
- Email: ${lead['EMAIL'] || ''}`;
};

const trovoBiciImportLead = (lead) => ({
  firstName: toTitleCase(lead['AZIENDA'].trim()),
  zipCode: toTitleCase(lead['CAP'].trim()),
  phone: normalizePhone(lead['TELEFONO'].trim()),
  note: createNote(lead),
  createdAt: serverTimestamp(),
});

export default trovoBiciImportLead;
