const statsTypes = {
  RESET_CALL_INSIGHT_STATE: 'RESET_CALL_INSIGHT_STATE',
  SET_CALL_INSIGHT_FILTERS: {
    REQUEST: 'SET_CALL_INSIGHT_FILTERS.REQUEST',
    SUCCESS: 'SET_CALL_INSIGHT_FILTERS.SUCCESS',
    FAILURE: 'SET_CALL_INSIGHT_FILTERS.FAILURE',
  },
};

export default statsTypes;
