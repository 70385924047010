import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MONETIZATION_ORDER_STATE,
});

export const createMonetizationOrder = (monetizationOrder) => ({
  type: types.CREATE_MONETIZATION_ORDER.REQUEST,
  monetizationOrder,
});

export const createMonetizationOrderSuccess = (monetizationOrder) => ({
  type: types.CREATE_MONETIZATION_ORDER.SUCCESS,
  monetizationOrder,
});

export const createMonetizationOrderFailure = (error) => ({
  type: types.CREATE_MONETIZATION_ORDER.FAILURE,
  error,
});

export const updateMonetizationOrder = (monetizationOrder) => ({
  type: types.UPDATE_MONETIZATION_ORDER.REQUEST,
  monetizationOrder,
});

export const updateMonetizationOrderSuccess = () => ({
  type: types.UPDATE_MONETIZATION_ORDER.SUCCESS,
});

export const updateMonetizationOrderFailure = (error) => ({
  type: types.UPDATE_MONETIZATION_ORDER.FAILURE,
  error,
});

export const syncMonetizationOrders = () => ({
  type: types.SYNC_MONETIZATION_ORDERS.REQUEST,
});

export const syncMonetizationOrdersSuccess = (monetizationOrders) => ({
  type: types.SYNC_MONETIZATION_ORDERS.SUCCESS,
  monetizationOrders,
});

export const syncMonetizationOrdersFailure = (error) => ({
  type: types.SYNC_MONETIZATION_ORDERS.FAILURE,
  error,
});
