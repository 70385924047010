import types from './actionTypes';

const initialState = {
  loading: false,
  audiences: [],
  error: '',
};

const audienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_AUDIENCE_STATE:
      return initialState;
    case types.CREATE_AUDIENCE.REQUEST:
    case types.UPDATE_AUDIENCE.REQUEST:
    case types.SYNC_AUDIENCES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_AUDIENCE.SUCCESS:
    case types.UPDATE_AUDIENCE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_AUDIENCES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        audiences: [...action.audiences],
      };
    case types.CREATE_AUDIENCE.FAILURE:
    case types.UPDATE_AUDIENCE.FAILURE:
    case types.SYNC_AUDIENCES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default audienceReducer;
