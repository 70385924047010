import { createSelector } from 'reselect';
import { selectLeadForm } from './leadForm';

export const selectLeadFieldsBy = (key) =>
  createSelector(
    (state) => state.LeadField.leadFields,
    (leadFields) => leadFields.filter((field) => field[key] === true),
  );

export const selectLeadFieldsByLeadForm = (leadFormId) =>
  createSelector(
    selectLeadForm(leadFormId),
    (state) => state.LeadField.leadFields,
    (leadForm, leadFields) => {
      if (!Array.isArray(leadForm?.leadFields)) return [];

      let fields = [];
      leadForm.leadFields.forEach(({ leadFieldId, required, readOnly }) => {
        const leadField = leadFields.find(({ id }) => leadFieldId === id);
        leadField && fields.push({ ...leadField, required, readOnly });
      });
      return fields;
    },
  );

export const createQueryBuilderConfig = (customLeadFields = null) =>
  createSelector(
    (state) => state.LeadField.leadFields,
    (leadFields) => {
      let config = {};
      const fields = customLeadFields || leadFields;

      fields.forEach((field) => {
        const obj =
          field.typeField === 'select'
            ? {
                label: field.name,
                type: field.typeField,
                valueSources: ['value'],
                fieldSettings: { listValues: field.listValues },
              }
            : {
                label: field.name,
                type: field.typeField,
              };
        config[field.name] = obj;
      });

      return config;
    },
  );

export const showOnFormLeadField = (leadFormId = null, sector = null) =>
  createSelector(
    selectLeadFieldsByLeadForm(leadFormId),
    (state) => state.LeadField.leadFields,
    (formLeadFields, leadFields) => {
      if (formLeadFields.length) return formLeadFields;

      const fields = leadFields
        .filter((field) => field.showInFormLead === true)
        .sort((a, b) => a.sort - b.sort);
      if (!sector) return fields;
      return fields.filter(
        (field) => field.sectors && field.sectors.includes(sector),
      );
    },
  );
