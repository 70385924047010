import React from 'react';
import { Row, Col, Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo-sm-light.png';
import heroImg from '../../assets/images/hero.png';
import { useTranslation } from 'react-i18next';

const WelcomeBoxWide = (props) => {
  const user = useSelector((state) => state.Auth.admin);

  const { t } = useTranslation();

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row>
              <Col lg="5">
                <Media>
                  <div className="mr-3">
                    <img
                      src={user.photoURL || logo}
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <Media className="align-self-center" body>
                    <div className="text-muted">
                      <h6 className="mb-2 text-primary">
                        {t('Welcome to Lead1 CRM')}
                      </h6>
                      <h5 className="mb-1">{user.displayName}</h5>
                      <p className="mb-0">{user.role}</p>
                    </div>
                  </Media>
                </Media>
              </Col>

              <Col
                lg="4"
                className="d-none d-sm-block align-self-center text-lg-center">
                <Link
                  to="/calls/manage"
                  className="btn btn-info waves-effect waves-light">
                  <i className="bx bx-phone-call font-size-20 align-middle mr-2"></i>
                  {t('Start calls')}
                </Link>
              </Col>

              <Col lg="3" className="d-none d-sm-block align-self-center">
                <img
                  src={heroImg}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: '122px' }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default WelcomeBoxWide;
