import { createSelector } from 'reselect';

export const countPushNotifications = createSelector(
  (state) => state.PushNotification.pushNotifications,
  (pushNotifications) => pushNotifications.length,
);

export const pushNotificationSentCount = createSelector(
  (state) => state.PushNotification.pushNotifications,
  (pushNotifications) =>
    pushNotifications.filter(
      (pushNotification) => pushNotification.status === 'SENT',
    ).length,
);

export const pushNotificationUnsentCount = createSelector(
  (state) => state.PushNotification.pushNotifications,
  (pushNotifications) =>
    pushNotifications.filter(
      (pushNotification) => pushNotification.status === 'new',
    ).length,
);

export const totalCosts = createSelector(
  (state) => state.PushNotification.pushNotifications,
  (pushNotifications) =>
    pushNotifications
      .map((pushNotification) => pushNotification.cost)
      .reduce((a, b) => a + b, 0),
);
