import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  InputGroupAddon,
  FormGroup,
  Label,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import { FieldArray } from 'formik';
import formValidation from './formValidation';
import '@availity/yup';

import * as campaignsActions from '../../store/campaigns/actions';
import * as suppliersActions from '../../store/suppliers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSupplierByType } from '../../selectors/supplier';

import { SubmitButton } from '../Common/Modal/modalActionButtons';
import campaignSources from '../../config/campaignSources';
import supplierTypes from '../../config/supplierTypes';

const FormCampaign = (props) => {
  const editingCampaign = props.campaign || {};

  const publishers = useSelector(selectSupplierByType(supplierTypes.PUBLISHER));

  const [isLeadCampaign, setIsLeadCampaign] = useState(
    editingCampaign.isLeadCampaign ?? true,
  );
  const [archived, setArchived] = useState(editingCampaign.archived || false);
  const [allowDuplicateLead, setAllowDuplicateLead] = useState(
    editingCampaign.allowDuplicateLead ?? true,
  );
  const [onlyQualifiedCompleted, setOnlyQualifiedCompleted] = useState(
    editingCampaign.onlyQualifiedCompleted ?? true,
  );
  const [allowCreatePerson, setAllowCreatePerson] = useState(
    editingCampaign.allowCreatePerson ?? true,
  );
  //FB and Google campaigns are created automatically
  const sources = campaignSources.map((source) =>
    source.value !== 'facebook' && source.value !== 'googleAds'
      ? source
      : { ...source, isDisabled: true },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(suppliersActions.syncSuppliers());
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? campaignsActions.createCampaign({ ...values, archived })
        : campaignsActions.updateCampaign({
            id: editingCampaign.id,
            ...values,
            archived,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingCampaign,
            isLeadCampaign,
            allowDuplicateLead,
            onlyQualifiedCompleted,
            allowCreatePerson,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="4">
                  <Input type="text" name="name" id="name" required />
                </Col>
                <Col md="3">
                  <Select
                    name="source"
                    id="source"
                    required
                    isMulti={false}
                    options={sources}
                    isDisabled={!props.create}
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="isLeadCampaign"
                    label="Lead Campaign?"
                    defaultChecked={archived}
                    onClick={() => setIsLeadCampaign(!isLeadCampaign)}
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="archived"
                    label="Archived?"
                    defaultChecked={archived}
                    onClick={() => setArchived(!archived)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <Switch
                    name="allowDuplicateLead"
                    label="Allow Duplicate Lead?"
                    defaultChecked={allowDuplicateLead}
                    onClick={() => setAllowDuplicateLead(!allowDuplicateLead)}
                  />
                </Col>
                <Col md="2">
                  <Collapse isOpen={allowDuplicateLead}>
                    <Switch
                      name="onlyQualifiedCompleted"
                      label="Only Qualified Completed?"
                      defaultChecked={onlyQualifiedCompleted}
                      onClick={() =>
                        setOnlyQualifiedCompleted(!onlyQualifiedCompleted)
                      }
                    />
                  </Collapse>
                </Col>
                <Col md="2">
                  <Switch
                    name="allowCreatePerson"
                    label="Allow Create Person?"
                    defaultChecked={allowCreatePerson}
                    onClick={() => setAllowCreatePerson(!allowCreatePerson)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Select
                    name="supplierId"
                    id="supplierId"
                    label="Publisher"
                    options={
                      Array.isArray(publishers)
                        ? publishers.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        : []
                    }
                  />
                </Col>
                <Col md="2">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="percentageRevenueShare"
                      name="percentageRevenueShare"
                      label="Revenue Share"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
                <Col md="2">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="percentageMarginShare"
                      name="percentageMarginShare"
                      label="Margin Share"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="costPerLeadGenerated"
                      name="costPerLeadGenerated"
                      label="Cost Per Lead Generated"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">€</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
                <Col md="3">
                  <Collapse isOpen={!!values.supplierId}>
                    <Input
                      id="costPerPositiveLead"
                      name="costPerPositiveLead"
                      label="Cost Per Positive Lead"
                      min={0}
                      type="number"
                      step="0.1">
                      <InputGroupAddon addonType="append">€</InputGroupAddon>
                    </Input>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col md="10">
                  <FieldArray
                    name="defaultLeadValues"
                    render={(arrayHelpers) => {
                      const fields = values.defaultLeadValues;
                      return (
                        <FormGroup>
                          <Label htmlFor="defaultLeadValues">
                            Default Lead Values
                          </Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() =>
                              arrayHelpers.push({
                                key: '',
                                value: '',
                              })
                            }>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(fields) &&
                            fields.map((field, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="3">
                                  <Input
                                    type="text"
                                    name={`defaultLeadValues.${index}.key`}
                                    id={`defaultLeadValues.${index}.key`}
                                    label="Name"
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    type="text"
                                    name={`defaultLeadValues.${index}.value`}
                                    id={`defaultLeadValues.${index}.value`}
                                    label="Value">
                                    <button
                                      className="btn btn-danger p-0"
                                      style={{ width: '31px' }}
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }>
                                      <i className="bx bx-trash d-block font-size-15" />
                                    </button>
                                  </Input>
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCampaign;
