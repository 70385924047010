import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DAILY_LEAD_INSIGHT_STATE,
});

export const fetchDailyInsights = () => ({
  type: types.DAILY_LEAD_INSIGHTS.REQUEST,
});

export const fetchDailyInsightsSuccess = (stats) => ({
  type: types.DAILY_LEAD_INSIGHTS.SUCCESS,
  stats,
});

export const fetchDailyInsightsFailure = (error) => ({
  type: types.DAILY_LEAD_INSIGHTS.FAILURE,
  error,
});
