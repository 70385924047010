const statsTypes = {
  RESET_CALL_STATE: 'RESET_CALL_STATE',
  CREATE_CALL: {
    REQUEST: 'CREATE_CALL.REQUEST',
    SUCCESS: 'CREATE_CALL.SUCCESS',
    FAILURE: 'CREATE_CALL.FAILURE',
  },
  CREATE_EXTRA_CALL: {
    REQUEST: 'CREATE_EXTRA_CALL.REQUEST',
    SUCCESS: 'CREATE_EXTRA_CALL.SUCCESS',
    FAILURE: 'CREATE_EXTRA_CALL.FAILURE',
  },
  UPDATE_CALL: {
    REQUEST: 'UPDATE_CALL.REQUEST',
    SUCCESS: 'UPDATE_CALL.SUCCESS',
    FAILURE: 'UPDATE_CALL.FAILURE',
  },
  FETCH_CALLS: {
    REQUEST: 'FETCH_CALLS.REQUEST',
    SUCCESS: 'FETCH_CALLS.SUCCESS',
    FAILURE: 'FETCH_CALLS.FAILURE',
  },
  FETCH_CALLS_BY_LEAD: {
    REQUEST: 'FETCH_CALLS_BY_LEAD.REQUEST',
    SUCCESS: 'FETCH_CALLS_BY_LEAD.SUCCESS',
    FAILURE: 'FETCH_CALLS_BY_LEAD.FAILURE',
  },
  FETCH_CLOSED_CALLS: {
    REQUEST: 'FETCH_CLOSED_CALLS.REQUEST',
    SUCCESS: 'FETCH_CLOSED_CALLS.SUCCESS',
    FAILURE: 'FETCH_CLOSED_CALLS.FAILURE',
  },
  SYNC_QUEUED_CALLS: {
    REQUEST: 'SYNC_QUEUED_CALLS.REQUEST',
    SUCCESS: 'SYNC_QUEUED_CALLS.SUCCESS',
    FAILURE: 'SYNC_QUEUED_CALLS.FAILURE',
  },
};

export default statsTypes;
