const statsTypes = {
  RESET_AUDIENCE_STATE: 'RESET_AUDIENCE_STATE',
  CREATE_AUDIENCE: {
    REQUEST: 'CREATE_AUDIENCE.REQUEST',
    SUCCESS: 'CREATE_AUDIENCE.SUCCESS',
    FAILURE: 'CREATE_AUDIENCE.FAILURE',
  },
  UPDATE_AUDIENCE: {
    REQUEST: 'UPDATE_AUDIENCE.REQUEST',
    SUCCESS: 'UPDATE_AUDIENCE.SUCCESS',
    FAILURE: 'UPDATE_AUDIENCE.FAILURE',
  },
  SYNC_AUDIENCES: {
    REQUEST: 'SYNC_AUDIENCES.REQUEST',
    SUCCESS: 'SYNC_AUDIENCES.SUCCESS',
    FAILURE: 'SYNC_AUDIENCES.FAILURE',
  },
};

export default statsTypes;
