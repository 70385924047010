const statsTypes = {
  RESET_LEAD_STATE: 'RESET_LEAD_STATE',
  FETCH_LEADS: {
    REQUEST: 'FETCH_LEADS.REQUEST',
    SUCCESS: 'FETCH_LEADS.SUCCESS',
    FAILURE: 'FETCH_LEADS.FAILURE',
  },
  FETCH_SINGLE_LEAD: {
    REQUEST: 'FETCH_SINGLE_LEAD.REQUEST',
    SUCCESS: 'FETCH_SINGLE_LEAD.SUCCESS',
    FAILURE: 'FETCH_SINGLE_LEAD.FAILURE',
  },
  CREATE_LEAD: {
    REQUEST: 'CREATE_LEAD.REQUEST',
    SUCCESS: 'CREATE_LEAD.SUCCESS',
    FAILURE: 'CREATE_LEAD.FAILURE',
  },
  UPDATE_LEAD: {
    REQUEST: 'UPDATE_LEAD.REQUEST',
    SUCCESS: 'UPDATE_LEAD.SUCCESS',
    FAILURE: 'UPDATE_LEAD.FAILURE',
  },
  IMPORT_LEADS: {
    REQUEST: 'IMPORT_LEADS.REQUEST',
    SUCCESS: 'IMPORT_LEADS.SUCCESS',
    FAILURE: 'IMPORT_LEADS.FAILURE',
  },
  DUPLICATE_LEADS: {
    REQUEST: 'DUPLICATE_LEADS.REQUEST',
    SUCCESS: 'DUPLICATE_LEADS.SUCCESS',
    FAILURE: 'DUPLICATE_LEADS.FAILURE',
  },
  ADD_PUSH_TOKEN_LEAD: {
    REQUEST: 'ADD_PUSH_TOKEN_LEAD.REQUEST',
    SUCCESS: 'ADD_PUSH_TOKEN_LEAD.SUCCESS',
    FAILURE: 'ADD_PUSH_TOKEN_LEAD.FAILURE',
  },
};

export default statsTypes;
