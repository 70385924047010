const statsTypes = {
  RESET_DAILY_COUNTRY_INSIGHT_STATE: 'RESET_DAILY_COUNTRY_INSIGHT_STATE',
  DAILY_COUNTRY_INSIGHTS: {
    REQUEST: 'DAILY_COUNTRY_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_COUNTRY_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_COUNTRY_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
