const authTypes = {
    LOGIN_WITH_GOOGLE: {
      REQUEST: 'LOGIN_WITH_GOOGLE.REQUEST',
      SUCCESS: 'LOGIN_WITH_GOOGLE.SUCCESS',
      FAILURE: 'LOGIN_WITH_GOOGLE.FAILURE',
    },
    LOGIN_WITH_EMAIL: {
      REQUEST: 'LOGIN_WITH_EMAIL.REQUEST',
      SUCCESS: 'LOGIN_WITH_EMAIL.SUCCESS',
      FAILURE: 'LOGIN_WITH_EMAIL.FAILURE',
    },
    LOGOUT: {
      REQUEST: 'LOGOUT.REQUEST',
      SUCCESS: 'LOGOUT.SUCCESS',
      FAILURE: 'LOGOUT.FAILURE',
    },
    REGISTER_WITH_EMAIL: {
      REQUEST: 'REGISTER_WITH_EMAIL.REQUEST',
      SUCCESS: 'REGISTER_WITH_EMAIL.SUCCESS',
      FAILURE: 'REGISTER_WITH_EMAIL.FAILURE',
    },
    CREATE_ADMIN: {
      SUCCESS: 'CREATE_ADMIN.SUCCESS',
      FAILURE: 'CREATE_ADMIN.FAILURE',
    },
    UPDATE_ADMIN: {
      REQUEST: 'UPDATE_ADMIN.REQUEST',
      SUCCESS: 'UPDATE_ADMIN.SUCCESS',
      FAILURE: 'UPDATE_ADMIN.FAILURE',
    },
    PASSWORD_CHANGE: {
      REQUEST: 'PASSWORD_CHANGE.REQUEST',
      SUCCESS: 'PASSWORD_CHANGE.SUCCESS',
      FAILURE: 'PASSWORD_CHANGE.FAILURE',
    },
    PASSWORD_FORGET: {
      REQUEST: 'PASSWORD_FORGET.REQUEST',
      SUCCESS: 'PASSWORD_FORGET.SUCCESS',
      FAILURE: 'PASSWORD_FORGET.FAILURE',
    },
    SYNC_ADMIN: 'SYNC_ADMIN',
    FETCH_ADMIN: {
      REQUEST: 'FETCH_ADMIN.REQUEST',
      SUCCESS: 'FETCH_ADMIN.SUCCESS',
      FAILURE: 'FETCH_ADMIN.FAILURE',
    },
    RESET_MESSAGES: 'RESET_MESSAGES',
  };
  
  export default authTypes;
  