import types from './actionTypes';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  surveys: [],
  error: '',
  startDate: null,
  endDate: null,
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SURVEY_STATE:
      return initialState;
    case types.CREATE_SURVEY.REQUEST:
    case types.FETCH_SURVEYS.REQUEST:
    case types.IMPORT_PARTIAL_SURVEYS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_SURVEY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        surveys: [...state.surveys, action.survey],
      };
    case types.FETCH_SURVEYS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        surveys: action.surveys,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case types.FETCH_SURVEY_BY_LEAD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        surveys: action.survey
          ? addOrReplaceObjectInArray(state.surveys, action.survey)
          : state.surveys,
      };
    case types.IMPORT_PARTIAL_SURVEYS.SUCCESS:
    case types.UPDATE_SURVEY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.CREATE_SURVEY.FAILURE:
    case types.UPDATE_SURVEY.FAILURE:
    case types.FETCH_SURVEYS.FAILURE:
    case types.IMPORT_PARTIAL_SURVEYS.FAILURE:
    case types.FETCH_SURVEY_BY_LEAD.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default surveyReducer;
