import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_ACQUISITION_ORDER_STATE,
});

export const createAcquisitionOrder = (acquisitionOrder) => ({
  type: types.CREATE_ACQUISITION_ORDER.REQUEST,
  acquisitionOrder,
});

export const createAcquisitionOrderSuccess = (acquisitionOrder) => ({
  type: types.CREATE_ACQUISITION_ORDER.SUCCESS,
  acquisitionOrder,
});

export const createAcquisitionOrderFailure = (error) => ({
  type: types.CREATE_ACQUISITION_ORDER.FAILURE,
  error,
});

export const updateAcquisitionOrder = (acquisitionOrder, prevCampaignIds) => ({
  type: types.UPDATE_ACQUISITION_ORDER.REQUEST,
  acquisitionOrder,
  prevCampaignIds,
});

export const updateAcquisitionOrderSuccess = () => ({
  type: types.UPDATE_ACQUISITION_ORDER.SUCCESS,
});

export const updateAcquisitionOrderFailure = (error) => ({
  type: types.UPDATE_ACQUISITION_ORDER.FAILURE,
  error,
});

export const syncAcquisitionOrders = () => ({
  type: types.SYNC_ACQUISITION_ORDERS.REQUEST,
});

export const syncAcquisitionOrdersSuccess = (acquisitionOrders) => ({
  type: types.SYNC_ACQUISITION_ORDERS.SUCCESS,
  acquisitionOrders,
});

export const syncAcquisitionOrdersFailure = (error) => ({
  type: types.SYNC_ACQUISITION_ORDERS.FAILURE,
  error,
});
