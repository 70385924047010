import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MONETIZATION_ITEM_STATE,
});

export const createMonetizationItem = (monetizationItem) => ({
  type: types.CREATE_MONETIZATION_ITEM.REQUEST,
  monetizationItem,
});

export const createMonetizationItemSuccess = (monetizationItem) => ({
  type: types.CREATE_MONETIZATION_ITEM.SUCCESS,
  monetizationItem,
});

export const createMonetizationItemFailure = (error) => ({
  type: types.CREATE_MONETIZATION_ITEM.FAILURE,
  error,
});

export const fetchMonetizationItems = (startDate, endDate, filters = null) => ({
  type: types.FETCH_MONETIZATION_ITEMS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchMonetizationItemsSuccess = (
  monetizationItems,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_MONETIZATION_ITEMS.SUCCESS,
  monetizationItems,
  startDate,
  endDate,
  filters,
});

export const fetchMonetizationItemsFailure = (error) => ({
  type: types.FETCH_MONETIZATION_ITEMS.FAILURE,
  error,
});

export const fetchMonetizationItemsByLead = (lead) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_LEAD.REQUEST,
  lead,
});

export const fetchMonetizationItemsByLeadSuccess = (monetizationItems) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_LEAD.SUCCESS,
  monetizationItems,
});

export const fetchMonetizationItemsByLeadFailure = (error) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_LEAD.FAILURE,
  error,
});

export const fetchMonetizationItemsByPhone = (phone) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_PHONE.REQUEST,
  phone,
});

export const fetchMonetizationItemsByPhoneSuccess = (monetizationItems) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_PHONE.SUCCESS,
  monetizationItems,
});

export const fetchMonetizationItemsByPhoneFailure = (error) => ({
  type: types.FETCH_MONETIZATION_ITEMS_BY_PHONE.FAILURE,
  error,
});

export const resendMonetizationItems = (monetizationItems) => ({
  type: types.RESEND_MONETIZATION_ITEMS.REQUEST,
  monetizationItems,
});

export const resendMonetizationItemsSuccess = (logs) => ({
  type: types.RESEND_MONETIZATION_ITEMS.SUCCESS,
  logs,
});

export const resendMonetizationItemsFailure = (error) => ({
  type: types.RESEND_MONETIZATION_ITEMS.FAILURE,
  error,
});
