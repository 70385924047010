import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_NEXT_CALL,
});

export const fetchNextCall = (newCall, inbound = false) => ({
  type: types.FETCH_NEXT_CALL.REQUEST,
  newCall,
  inbound,
});

export const fetchNextCallSuccess = (call) => ({
  type: types.FETCH_NEXT_CALL.SUCCESS,
  call,
});

export const fetchNextCallFailure = (error) => ({
  type: types.FETCH_NEXT_CALL.FAILURE,
  error,
});

export const syncNextCall = (callId, inbound = false) => ({
  type: types.SYNC_NEXT_CALL.REQUEST,
  callId,
  inbound,
});

export const syncNextCallSuccess = (call) => ({
  type: types.SYNC_NEXT_CALL.SUCCESS,
  call,
});

export const syncNextCallFailure = (error) => ({
  type: types.SYNC_NEXT_CALL.FAILURE,
  error,
});

export const setDirtyLeadForm = (dirtyLeadForm) => ({
  type: types.SET_DIRTY_LEAD_FORM,
  dirtyLeadForm,
});
