import types from './actionTypes';
import initialFilters from '../../config/filters';

const initialState = {
  loading: false,
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  countryId: null,
  exchangeQueuesModal: 'unnecessary',
  error: '',
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_DASHBOARD_STATE:
      return initialState;
    case types.SET_DASHBOARD_FILTERS.REQUEST:
    case types.SET_COUNTRY.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.SET_DASHBOARD_FILTERS.SUCCESS:
      return {
        ...state,
        loading: false,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
        error: '',
      };
    case types.SET_COUNTRY.SUCCESS:
      return {
        ...state,
        loading: false,
        countryId: action.countryId,
        exchangeQueuesModal: action.exchangeQueuesModal,
        error: '',
      };
    case types.SET_EXCHANGE_QUEUES_MODAL:
      return {
        ...state,
        exchangeQueuesModal: action.exchangeQueuesModal,
      };
    case types.SET_DASHBOARD_FILTERS.FAILURE:
    case types.SET_COUNTRY.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
