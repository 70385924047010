import { createSelector } from 'reselect';

export const selectPerson = (id) =>
  createSelector(
    (state) => state.People.people,
    (people) => {
      if (!id) return null;
      const person = people.filter((person) => person.id === id);
      return person.length > 0 ? person[0] : null;
    },
  );

export const countPeople = createSelector(
  (state) => state.People.people,
  (people) => people.length,
);

export const certifiedPeople = createSelector(
  (state) => state.People.people,
  (people) => people.filter((person) => person.verifiedPhoneNumber),
);

export const countCertifiedPeople = createSelector(
  certifiedPeople,
  (people) => people.length,
);

export const peopleWithMarketingConsent = createSelector(
  (state) => state.People.people,
  (people) => people.filter((person) => person.marketingConsent === 'yes'),
);

export const countPeopleWithMarketingConsent = createSelector(
  peopleWithMarketingConsent,
  (people) => people.length,
);

export const peopleWithThirdPartyConsent = createSelector(
  (state) => state.People.people,
  (people) => people.filter((person) => person.thirdPartyConsent === 'yes'),
);

export const countPeopleWithThirdPartyConsent = createSelector(
  peopleWithThirdPartyConsent,
  (people) => people.length,
);

export const peopleWithPushTokens = createSelector(
  (state) => state.People.people,
  (people) =>
    people.filter(
      (person) => Array.isArray(person.pushTokens) && person.pushTokens.length,
    ),
);

export const countPeopleWithPushTokens = createSelector(
  peopleWithPushTokens,
  (people) => people.length,
);
