import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  InputGroupAddon,
  Collapse,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import formValidation from './formValidation';

import * as usersActions from '../../store/users/actions';
import * as countriesActions from '../../store/countries/actions';
import { selectAcquisitionOrdersForSelect } from '../../selectors/acquisitionOrder';
import { userEmailInUse } from '../../selectors/user';
import { selectSupplierByType } from '../../selectors/supplier';
import { useDispatch, useSelector } from 'react-redux';

import { deleteField } from 'firebase/firestore';
import assignAllowedRoles from '../../config/assignAllowedRoles';
import roles from '../../config/roles';
import supplierTypes from '../../config/supplierTypes';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const MAX_SELECTED_ACQUISITION_ORDERS = 10;
const MAX_SELECTED_COUNTRIES = 10;

const FormUser = (props) => {
  const editingUser = props.user || {};

  const [active, setActive] = useState(editingUser.active || false);

  const user = useSelector((state) => state.Auth.admin);
  const allowedRoles = assignAllowedRoles[user.role];
  const emailsInUse = useSelector(userEmailInUse(editingUser.id));

  const countries = useSelector((state) => state.Country.countries);
  const countryId = useSelector((state) => state.Dashboard.countryId);
  const customers = useSelector((state) => state.Customer.customers);
  const publishers = useSelector(selectSupplierByType(supplierTypes.PUBLISHER));
  const sales = useSelector(selectSupplierByType(supplierTypes.SALE));

  const acquisitionOrderIds = editingUser.acquisitionOrderIds
    ? editingUser.acquisitionOrderIds[countryId]
    : null;

  const acquisitionOrdersForSelect = useSelector(
    selectAcquisitionOrdersForSelect,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesActions.syncCountries());
  }, []);

  const selectSuppliers = (type) => {
    if (supplierTypes.PUBLISHER === type)
      return publishers.map((publisher) => ({
        label: publisher.name,
        value: publisher.id,
      }));
    return sales.map((sale) => ({ label: sale.name, value: sale.id }));
  };

  const handleSubmit = (values) => {
    const editValues =
      values.role === roles.PUBLISHER || values.role === roles.SALE
        ? {
            id: editingUser.id,
            ...values,
            active,
          }
        : {
            id: editingUser.id,
            ...values,
            active,
            supplierId: deleteField(),
          };
    dispatch(
      props.create
        ? usersActions.createUser(values)
        : usersActions.updateUser(editValues),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingUser, acquisitionOrderIds }}
          validationSchema={formValidation(emailsInUse)}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    label="First Name"
                  />
                </Col>
                <Col md="3">
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    label="Last Name"
                  />
                </Col>
                <Col md="3">
                  <Input
                    type="text"
                    name="displayName"
                    id="displayName"
                    label="Display Name"
                  />
                </Col>
                <Col md="3">
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    disabled={user.role !== roles.SUPER_ADMIN}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Select
                    name="acquisitionOrderIds"
                    id="acquisitionOrderIds"
                    label="Acquisition Orders"
                    isMulti
                    maxLength={MAX_SELECTED_ACQUISITION_ORDERS}
                    isSearchable
                    options={acquisitionOrdersForSelect}
                  />
                </Col>
                <Col md="2">
                  <Input
                    id="percentageNewCalls"
                    name="percentageNewCalls"
                    label="Percentage New Calls"
                    addonType="append"
                    min={0}
                    type="number"
                    step="1">
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </Input>
                </Col>
                <Col md="2">
                  <Input
                    type="text"
                    name="phoneExchangeId"
                    id="phoneExchangeId"
                    label="Phone Exchange ID"
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="dailyGoalCalls"
                    id="dailyGoalCalls"
                    label="Daily Goal Calls"
                    min={1}
                    type="number"
                    step="1"
                  />
                </Col>
              </Row>
              <Row>
                {editingUser.role !== roles.SUPER_ADMIN && (
                  <Col md="4">
                    <Select
                      name="countryIds"
                      id="countryIds"
                      label="Country"
                      isMulti
                      maxLength={MAX_SELECTED_COUNTRIES}
                      isSearchable
                      options={countries.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                    />
                  </Col>
                )}
                {(editingUser.role !== user.role ||
                  user.role === roles.SUPER_ADMIN) && (
                  <>
                    <Col md="2">
                      <Select
                        name="role"
                        id="role"
                        options={allowedRoles.map((role) => ({
                          label: role,
                          value: role,
                        }))}
                      />
                    </Col>
                    <Col md="3">
                      <Collapse
                        isOpen={
                          values.role === roles.PUBLISHER ||
                          values.role === roles.SALE
                        }>
                        <Select
                          name="supplierId"
                          id="supplierId"
                          label="Supplier"
                          options={selectSuppliers(values.role)}
                        />
                      </Collapse>
                      <Collapse isOpen={values.role === roles.CUSTOMER}>
                        <Select
                          name="customerId"
                          id="customerId"
                          label="Customer"
                          options={customers.map(({ id, name }) => ({
                            label: name,
                            value: id,
                          }))}
                        />
                      </Collapse>
                    </Col>
                  </>
                )}
                <Col md="3">
                  <Switch
                    name="active"
                    label="Active?"
                    defaultChecked={active}
                    onClick={() => setActive(!active)}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormUser;
