import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { selectSupplierByType } from '../../selectors/supplier';
import { selectCampaignsByPublisher } from '../../selectors/campaign';
import { userPublisherId } from '../../selectors/auth';
import * as suppliersActions from '../../store/suppliers/actions';
import * as campaignsActions from '../../store/campaigns/actions';

import { useTranslation } from 'react-i18next';
import supplierTypes from '../../config/supplierTypes';

const MAX_SELECTED_CAMPAIGNS = 10;

const FilterPublishersBox = ({ initialFilters, onUpdate }) => {
  const [supplierId, setSupplierId] = useState(
    initialFilters?.supplierId || '',
  );
  const [campaignIds, setCampaignIds] = useState(
    initialFilters?.campaignIds || null,
  );

  const adminSupplierId = useSelector(userPublisherId);
  const suppliers = useSelector(selectSupplierByType(supplierTypes.PUBLISHER));
  const loadingSuppliers = useSelector((state) => state.Supplier.loading);
  const campaigns = useSelector(selectCampaignsByPublisher(supplierId));
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);

  const selectedSuppliers = adminSupplierId
    ? suppliers.filter((supplier) => supplier.id === adminSupplierId)
    : suppliers;

  const suppliersForSelect = selectedSuppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(suppliersActions.syncSuppliers());
    dispatch(campaignsActions.syncCampaigns());
  }, []);

  const handleFilters = (value, field, setFieldValue) => {
    if (field === 'supplierId') {
      setSupplierId(value);
      setCampaignIds(null);
      setFieldValue('campaignIds', null);
    } else setCampaignIds(value);

    onUpdate(
      field === 'supplierId' ? value : supplierId,
      field === 'supplierId'
        ? null
        : field === 'campaignIds'
        ? value
        : campaignIds,
    );
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ supplierId, campaignIds }}
        validationSchema={yup.object().shape({
          supplierId: yup.string(),
          campaignIds: yup.array().nullable(),
        })}
        className="d-flex">
        {({ setFieldValue }) => (
          <>
            <Col md="3" className="d-flex align-items-center px-0">
              <h5 className="text-muted m-0 mr-2">{t('Publisher')}:</h5>
              <div className="flex-fill">
                <Select
                  name="supplierId"
                  id="supplierId"
                  isMulti={false}
                  value={supplierId}
                  onChange={(value) =>
                    handleFilters(value, 'supplierId', setFieldValue)
                  }
                  options={
                    suppliersForSelect.length === 1
                      ? suppliersForSelect
                      : [{ label: t('All'), value: '' }, ...suppliersForSelect]
                  }
                  isDisabled={loadingSuppliers}
                />
              </div>
            </Col>
            <Col md="9" className="d-flex align-items-center px-0">
              <h5 className="text-muted my-0 ml-5 mr-2">{t('Campaigns')}:</h5>
              <div className="flex-fill">
                <Select
                  name="campaignIds"
                  id="campaignIds"
                  isMulti
                  maxLength={MAX_SELECTED_CAMPAIGNS}
                  value={campaignIds}
                  onChange={(values) =>
                    handleFilters(values, 'campaignIds', setFieldValue)
                  }
                  options={campaigns.map((campaign) => ({
                    label: campaign.name,
                    value: campaign.id,
                  }))}
                  isDisabled={supplierId === '' || loadingCampaigns}
                />
              </div>
            </Col>
          </>
        )}
      </Form>
    </CardBody>
  );
};

export default FilterPublishersBox;
