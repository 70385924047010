import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_LEAD_FIELD_STATE,
});

export const createLeadField = (leadField) => ({
  type: types.CREATE_LEAD_FIELD.REQUEST,
  leadField,
});

export const createLeadFieldSuccess = (leadField) => ({
  type: types.CREATE_LEAD_FIELD.SUCCESS,
  leadField,
});

export const createLeadFieldFailure = (error) => ({
  type: types.CREATE_LEAD_FIELD.FAILURE,
  error,
});

export const updateLeadField = (leadField) => ({
  type: types.UPDATE_LEAD_FIELD.REQUEST,
  leadField,
});

export const updateLeadFieldSuccess = (leadField) => ({
  type: types.UPDATE_LEAD_FIELD.SUCCESS,
  leadField,
});

export const updateLeadFieldFailure = (error) => ({
  type: types.UPDATE_LEAD_FIELD.FAILURE,
  error,
});

export const syncLeadFields = () => ({
  type: types.SYNC_LEAD_FIELDS.REQUEST,
});

export const syncLeadFieldsSuccess = (leadFields) => ({
  type: types.SYNC_LEAD_FIELDS.SUCCESS,
  leadFields,
});

export const syncLeadFieldsFailure = (error) => ({
  type: types.SYNC_LEAD_FIELDS.FAILURE,
  error,
});

export const sortLeadFields = (leadFields) => ({
  type: types.SORT_LEAD_FIELDS.REQUEST,
  leadFields,
});

export const sortLeadFieldsSuccess = () => ({
  type: types.SORT_LEAD_FIELDS.SUCCESS,
});

export const sortLeadFieldsFailure = (error) => ({
  type: types.SORT_LEAD_FIELDS.FAILURE,
  error,
});
