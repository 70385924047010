import React  from 'react';
import { title as titleStyle } from "../styles/SweetAlertStyles";

interface TitleProps {
  children?: React.ReactNode;
}

const Title: React.FunctionComponent<TitleProps> = ({ children }) => <h2 style={titleStyle}>{children}</h2>;

export default Title;
