export default [
  {
    label: 'Data Entry',
    value: 'dataEntry',
  },
  {
    label: 'Web Service',
    value: 'webService',
  },
  {
    label: 'Manual Export',
    value: 'manualExport',
  },
  {
    label: 'Tracking Pixel',
    value: 'trackingPixel',
  },
  {
    label: 'Google Spreadsheets',
    value: 'googleSpreadsheets',
  },
];
