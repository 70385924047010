import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import { userPublisherId } from '../../selectors/auth';
import {
  totalLeads,
  totalWorkedLeads,
  totalPositives,
  totalNegatives,
  totalFakes,
  totalSupplierCosts,
} from '../../selectors/publisher';

import MiniCard from '../Common/MiniCard';

import { useTranslation } from 'react-i18next';

const PublisherMetrics = (props) => {
  const supplierId = useSelector(userPublisherId);

  const leads = useSelector(totalLeads);
  const workedLeads = useSelector(totalWorkedLeads);
  const positiveLeads = useSelector(totalPositives);
  const negativeLeads = useSelector(totalNegatives);
  const fakeLeads = useSelector(totalFakes);
  const supplierCosts = useSelector(totalSupplierCosts);

  const loading = useSelector((state) => state.DailyPublisherInsights.loading);

  const { t } = useTranslation();

  let metrics = [
    {
      title: t('Incomings'),
      text: leads,
      color: 'info',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Worked'),
      text: workedLeads,
      color: 'warning',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Positive'),
      text: positiveLeads,
      color: 'success',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Negative'),
      text: negativeLeads,
      color: 'danger',
      icon: 'bx bx-user-pin',
    },
    {
      title: t(supplierId ? 'Revenues' : 'Publisher Costs'),
      text: `${isFinite(supplierCosts) ? supplierCosts.toFixed(0) : 0} €`,
      color: 'dark',
      icon: 'fas fa-laugh-wink',
      isSensitiveData: true,
    },
  ];

  return (
    <>
      {metrics.map((metric, index) => (
        <Col key={'mainMetric_' + index}>
          <MiniCard {...metric} loading={loading} />
        </Col>
      ))}
    </>
  );
};

export default PublisherMetrics;
