const statsTypes = {
  RESET_MARKETING_CAMPAIGN_STATE: 'RESET_MARKETING_CAMPAIGN_STATE',
  CREATE_MARKETING_CAMPAIGN: {
    REQUEST: 'CREATE_MARKETING_CAMPAIGN.REQUEST',
    SUCCESS: 'CREATE_MARKETING_CAMPAIGN.SUCCESS',
    FAILURE: 'CREATE_MARKETING_CAMPAIGN.FAILURE',
  },
  UPDATE_MARKETING_CAMPAIGN: {
    REQUEST: 'UPDATE_MARKETING_CAMPAIGN.REQUEST',
    SUCCESS: 'UPDATE_MARKETING_CAMPAIGN.SUCCESS',
    FAILURE: 'UPDATE_MARKETING_CAMPAIGN.FAILURE',
  },
  SYNC_MARKETING_CAMPAIGNS: {
    REQUEST: 'SYNC_MARKETING_CAMPAIGNS.REQUEST',
    SUCCESS: 'SYNC_MARKETING_CAMPAIGNS.SUCCESS',
    FAILURE: 'SYNC_MARKETING_CAMPAIGNS.FAILURE',
  },
};

export default statsTypes;
