import types from './actionTypes';
import initialFilters from '../../config/filters';

const initialState = {
  loading: false,
  monetizationItems: [],
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  error: '',
  logs: [],
};

const monetizationItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_MONETIZATION_ITEM_STATE:
      return initialState;
    case types.CREATE_MONETIZATION_ITEM.REQUEST:
    case types.FETCH_MONETIZATION_ITEMS.REQUEST:
    case types.FETCH_MONETIZATION_ITEMS_BY_LEAD.REQUEST:
    case types.FETCH_MONETIZATION_ITEMS_BY_PHONE.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.RESEND_MONETIZATION_ITEMS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        logs: [],
      };
    case types.CREATE_MONETIZATION_ITEM.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        monetizationItems: [
          ...state.monetizationItems,
          action.monetizationItem,
        ],
      };
    case types.FETCH_MONETIZATION_ITEMS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        monetizationItems: action.monetizationItems,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.FETCH_MONETIZATION_ITEMS_BY_LEAD.SUCCESS:
    case types.FETCH_MONETIZATION_ITEMS_BY_PHONE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        monetizationItems: action.monetizationItems,
      };
    case types.RESEND_MONETIZATION_ITEMS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        logs: action.logs,
      };
    case types.CREATE_MONETIZATION_ITEM.FAILURE:
    case types.FETCH_MONETIZATION_ITEMS.FAILURE:
    case types.FETCH_MONETIZATION_ITEMS_BY_LEAD.FAILURE:
    case types.FETCH_MONETIZATION_ITEMS_BY_PHONE.FAILURE:
    case types.RESEND_MONETIZATION_ITEMS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default monetizationItemReducer;
