import React, { useState } from 'react';
import {
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CreateButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light ml-3 mr-2 btn btn-success"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="mdi mdi-plus mr-1"></i>
      {t(label)}
    </button>
  );
};

export const ViewButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'view_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-spreadsheet"></i>
        <UncontrolledTooltip placement="top" target={'view_' + row.id}>
          {t('View')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const EditButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'edit_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-edit"></i>
        <UncontrolledTooltip placement="top" target={'edit_' + row.id}>
          {t('Edit')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const PersonButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'person_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-street-view"></i>
        <UncontrolledTooltip placement="top" target={'person_' + row.id}>
          {t('Person')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const TestQueryButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'testQuery_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-filter-alt"></i>
        <UncontrolledTooltip placement="top" target={'testQuery_' + row.id}>
          {t('Test Query')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ForceToChiedimiCCButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    onPress && (
      <li className="list-inline-item px-1">
        <Link
          to="#"
          id={'forceChiedimiCC_' + row.id}
          onClick={() => onPress(row)}>
          <i className="fas fa-headset"></i>
          <UncontrolledTooltip
            placement="top"
            target={'forceChiedimiCC_' + row.id}>
            {t('Force Send to Chiedimi Call Center')}
          </UncontrolledTooltip>
        </Link>
      </li>
    )
  );
};

export const ReprocessLeadButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'reprocessLead_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-analyse"></i>
        <UncontrolledTooltip placement="top" target={'reprocessLead_' + row.id}>
          {t('Reprocess Lead')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const StartCallButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Button
        color="primary"
        type="button"
        className="w-sm waves-effect waves-light"
        onClick={() => onPress(row)}>
        <i className="bx bx-phone-call font-size-16 align-middle mr-2"></i>
        {t('Manage')}
      </Button>
    </li>
  );
};

export const ViewLeadButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'viewLead' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-pin"></i>
        <UncontrolledTooltip placement="top" target={'viewLead' + row.id}>
          {t('View Lead')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ExtraCallButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'extraCall' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-phone-call"></i>
        <UncontrolledTooltip placement="top" target={'extraCall' + row.id}>
          {t('Create extra call')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ActiveButton = ({ row, onPress, key }) => {
  const { t } = useTranslation();
  const config = row[key || 'active']
    ? { icon: 'bx bx-play-circle', tooltip: 'Active' }
    : { icon: 'bx bx-pause-circle', tooltip: 'Disable' };
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'active_' + row.id} onClick={() => onPress(row)}>
        <i className={config.icon}></i>
        <UncontrolledTooltip placement="top" target={'active_' + row.id}>
          {t(config.tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const DisablePersonButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'disablePerson_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-x"></i>
        <UncontrolledTooltip placement="top" target={'disablePerson_' + row.id}>
          {t('Disable Person')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const GoogleSpreadsheetButton = ({ row }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'spreadsheet_' + row.id}
        onClick={() =>
          window.open(
            `https://docs.google.com/spreadsheets/d/${row.googleSpreadsheetId}`,
            '_blank',
          )
        }>
        <i className="mdi mdi-google-spreadsheet"></i>
        <UncontrolledTooltip placement="top" target={'spreadsheet_' + row.id}>
          {t('Open Google Spreadsheets')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const HistoryButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'history_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-history"></i>
        <UncontrolledTooltip placement="top" target={'history_' + row.id}>
          {t('History')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ExportButtonPlacement = (props) => (
  <div id="export-button-table"></div>
);

export const RefreshButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      disabled={disabled}
      className="btn btn-sm btn-outline-dark waves-effect"
      style={{ marginLeft: '10px' }}
      onClick={onPress}>
      {disabled ? (
        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
      ) : (
        <i className="bx bx-refresh font-size-16 align-middle mr-2"></i>
      )}
      {t(label || 'Refresh')}
    </button>
  );
};

export const ToggleColumnButton = ({
  columns,
  hiddenColumns,
  onToggleColumn,
}) => {
  const [show, setShow] = useState(false);
  columns = columns.filter(({ name, skip }) => name !== 'action' && !skip);

  return (
    <Dropdown
      isOpen={show}
      toggle={() => setShow(!show)}
      className="d-inline-block">
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <i className="bx bx-columns font-size-24"></i>
        <i
          className={`mdi mdi-chevron-${
            show ? 'up' : 'down'
          } d-none d-xl-inline-block`}></i>
      </DropdownToggle>
      <DropdownMenu right>
        <div className="mx-1">
          {columns.map(({ name, title }) => (
            <div
              key={name}
              className="form-check form-check-end"
              onClick={() => onToggleColumn(name)}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                name={name}
                defaultChecked={!hiddenColumns.includes(name)}
              />
              <label className="form-check-label" htmlFor={name}>
                {title}
              </label>
            </div>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export const RemoteButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light mr-2 btn btn-primary"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="bx bx-cloud-download mr-1"></i>
      {t(label)}
    </button>
  );
};
