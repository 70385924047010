import {
  takeEvery,
  select,
  put,
  all,
  call,
  takeLeading,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as peopleActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

const personTransformer = (person, data) => {
  return {
    id: person.id,
    ...data,
    name: `${data.firstName} ${data.lastName}`,
    ...(data.birthday && {
      birthday: toDateFirebase(person, data, 'birthday').toDate(),
    }),
    ...(data.createdAt && {
      createdAt: toDateFirebase(person, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(person, data, 'updatedAt').toDate(),
    }),
  };
};

function* createPersonSaga({ person }) {
  try {
    const db = getFirestore(rsf.app);
    const peopleRef = collection(db, 'people');
    const countryId = yield select((state) => state.Dashboard.countryId);

    yield call(rsf.firestore.addDocument, peopleRef, {
      ...person,
      countryId,
      createdAt: serverTimestamp(),
    });
    yield put(peopleActions.createPersonSuccess(person));
    toastr.success('Person created!', '');
  } catch (error) {
    yield put(peopleActions.createPersonFailure(error));
  }
}

function* updatePersonSaga({ person }) {
  try {
    const peopleRef = doc(db, 'people', person.id);
    delete person.id;

    yield call(
      rsf.firestore.setDocument,
      peopleRef,
      {
        ...person,
        ...(person.createdAt && {
          createdAt: Timestamp.fromDate(new Date(person.createdAt)),
        }),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(peopleActions.updatePersonSuccess(person));
    toastr.success('Person updated!', '');
  } catch (error) {
    yield put(peopleActions.updatePersonFailure(error));
  }
}

function* fetchPeopleSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    let peopleSnaps = null;

    const peopleRef = query(
      collection(db, 'people'),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      where('countryId', '==', countryId),
      orderBy('createdAt', 'desc'),
    );

    if (!filters || isNullish(filters)) {
      peopleSnaps = yield call(rsf.firestore.getCollection, peopleRef);
      peopleSnaps = [peopleSnaps];
    } else {
      const audiences = arrayChunks(filters.audienceIds, 10);

      peopleSnaps = yield all(
        audiences.map((audienceId) =>
          call(
            rsf.firestore.getCollection,
            query(
              peopleRef,
              where('audienceIds', 'array-contains-any', audienceId),
            ),
          ),
        ),
      );
    }

    let people = [];

    peopleSnaps.forEach((peopleSnap) => {
      peopleSnap.forEach((person) => {
        const data = person.data();
        people.push(personTransformer(person, data));
      });
    });

    yield put(
      peopleActions.fetchPeopleSuccess(people, startDate, endDate, filters),
    );
  } catch (error) {
    yield put(peopleActions.fetchPeopleFailure(error));
  }
}

function* fetchPersonByAudienceSaga({ audienceId }) {
  try {
    const personRef = query(
      collection(db, 'people'),
      where('audienceIds', 'array-contains-any', audienceId),
      limit(30),
    );

    const personSnap = yield call(rsf.firestore.getCollection, personRef);

    let people = [];
    personSnap.forEach((person) => {
      const data = person.data();
      people.push(personTransformer(person, data));
    });

    yield put(peopleActions.fetchPeopleByAudienceSuccess(people));
  } catch (error) {
    yield put(peopleActions.fetchPeopleByAudienceFailure(error));
  }
}

function* fetchPersonByIdSaga({ personId }) {
  try {
    const personRef = doc(db, 'people', personId);

    const personSnap = yield call(rsf.firestore.getDocument, personRef);

    let person = null;
    if (personSnap.exists) {
      const data = personSnap.data();
      person = personTransformer(personSnap, data);
    }

    yield put(peopleActions.fetchPersonByIdSuccess(person));
  } catch (error) {
    yield put(peopleActions.fetchPersonByIdFailure(error));
  }
}

function* personSaga() {
  yield all([
    takeEvery(types.FETCH_PEOPLE.REQUEST, fetchPeopleSaga),
    takeLeading(types.CREATE_PERSON.REQUEST, createPersonSaga),
    takeLeading(types.UPDATE_PERSON.REQUEST, updatePersonSaga),
    takeLeading(
      types.FETCH_PEOPLE_BY_AUDIENCE.REQUEST,
      fetchPersonByAudienceSaga,
    ),
    takeLeading(types.FETCH_PERSON_BY_ID.REQUEST, fetchPersonByIdSaga),
  ]);
}

export default personSaga;
