import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { useSelector } from 'react-redux';

import { drawSeriesDailyGraph } from '../../selectors/call';
import ReactApexChart from 'react-apexcharts';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const CallsGraph = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles['OPERATOR'] ? user.id : null;

  const { t } = useTranslation();

  const dataGraph = useSelector(
    drawSeriesDailyGraph(props.dateRange, t, userId),
  );

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 3, 3, 3],
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
        },
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
            formatter: (val, index) => val.toFixed(0),
          },
          title: {
            text: dataGraph.series[0].name,
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[1].name,
          max: 100,
          min: 0,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[1].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[1].color,
            },
            formatter: (val, index) => `${val.toFixed(2)} %`,
          },
          title: {
            title: 'Spoken Calls',
            style: {
              color: dataGraph.series[1].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[2].name,
          max: 100,
          min: 0,
          labels: {
            formatter: (val, index) => `${val.toFixed(2)} %`,
          },
          show: false,
        },
        {
          seriesName: dataGraph.series[3].name,
          max: 100,
          min: 0,
          labels: {
            formatter: (val, index) => `${val.toFixed(2)} %`,
          },
          show: false,
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">{t('Calls')}</CardTitle>
          <div className="clearfix"></div>
          <div id="line-chart" className="apex-charts" dir="ltr">
            <ReactApexChart
              series={dailyGraph.series}
              options={dailyGraph.options}
              type="line"
              height={320}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CallsGraph;
