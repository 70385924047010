import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Spinner,
} from 'reactstrap';

import * as surveysActions from '../../store/surveys/actions';
import * as smsMessagesActions from '../../store/smsMessages/actions';
import * as leadsActions from '../../store/leads/actions';
import * as surveyFormsActions from '../../store/surveyForms/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLead } from '../../selectors/lead';
import { selectSurveyFormByType } from '../../selectors/surveyForm';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import ImportPartialSurveys from '../../components/Surveys/ImportPartialSurveys';
import SurveyInsights from '../../components/Surveys/SurveyInsights';
import FormSurvey from '../../components/Surveys/FormSurvey';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Surveys/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';
import { types } from '../../config/surveyFormTypes';

const List = (props) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadModal, setLeadModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const loading = useSelector((state) => state.Survey.loading);
  const surveys = useSelector((state) => state.Survey.surveys);
  const surveyForms = useSelector(
    selectSurveyFormByType(types.DATA_ENRICHMENT),
  );
  const showSelectedLead = useSelector(selectLead(selectedLead));

  const startDate = useSelector((state) => state.Survey.startDate);
  const endDate = useSelector((state) => state.Survey.endDate);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [dateRange, setDateRange] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
  });

  const dispatch = useDispatch();

  const refresh = useCallback(({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(surveysActions.fetchSurveys(selectedStartDate, selectedEndDate));
    dispatch(
      smsMessagesActions.fetchSmsMessages(selectedStartDate, selectedEndDate),
    );
  });

  useEffect(() => {
    refresh(dateRange);
    dispatch(surveyFormsActions.syncSurveyForms());
  }, []);

  const openLeadModal = (survey) => {
    setSelectedLead(survey.leadId);
    dispatch(leadsActions.fetchSingleLead(survey.leadId));
    setLeadModal(true);
  };

  const openViewModal = (survey) => {
    setSelectedSurvey(survey);
    setViewModal(true);
  };

  const openEditModal = (survey) => {
    setSelectedSurvey(survey);
    setEditModal(true);
  };

  const handleOnFileLoad = (surveyFormId, data) => {
    dispatch(surveysActions.importPartialSurveys(surveyFormId, data));
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openLeadModal,
        surveyForms,
      ),
    [surveyForms],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Surveys"
            breadcrumbItem="List"
            onRefresh={() => refresh(dateRange)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            loading={loading}
          />
          <Row>
            <Col className="col-6">
              <SurveyInsights loading={loading} />
            </Col>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Import Partial Submissions Surveys
                  </CardTitle>
                  <ImportPartialSurveys
                    surveyForms={surveyForms}
                    onFileLoad={handleOnFileLoad}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={surveys}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Lead"
            isOpen={leadModal}
            toggle={() => setLeadModal(!leadModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={showSelectedLead}
              icon="bx bx-user-pin"
            />
          </Modal>
          <Modal
            size="lg"
            title="Survey"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedSurvey}
              icon="mdi mdi-comment-question-outline"
            />
          </Modal>
          <Modal
            size="xl"
            title="Survey"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormSurvey
              closeModal={() => setEditModal(false)}
              create={!selectedSurvey}
              survey={selectedSurvey}
              loading={loading}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
