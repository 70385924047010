import React, { useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import * as monetizationOrdersActions from '../../../store/monetizationOrders/actions';
import { selectMonetizationOrdersBySale } from '../../../selectors/monetizationOrder';
import {
  totalLeadsByMonetizationOrder,
  totalPositivesByMonetizationOrder,
  totalRefusedByMonetizationOrder,
  totalSupplierCostsByMonetizationOrder,
} from '../../../selectors/sale';

import { useTranslation } from 'react-i18next';

const hideSensitiveDataString = '***';

const MonetizationItemInsights = ({ monetizationOrder, showSensitiveData }) => {
  const leads = useSelector(
    totalLeadsByMonetizationOrder(monetizationOrder.id),
  );
  const positives = useSelector(
    totalPositivesByMonetizationOrder(monetizationOrder.id),
  );
  const refused = useSelector(
    totalRefusedByMonetizationOrder(monetizationOrder.id),
  );
  const supplierCosts = useSelector(
    totalSupplierCostsByMonetizationOrder(monetizationOrder.id),
  );

  const { t } = useTranslation();

  return (
    <tr>
      <td className="align-middle">
        <h5 className="font-size-14 mb-1 text-wrap">
          {showSensitiveData ? monetizationOrder.name : hideSensitiveDataString}
        </h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Leads')}</p>
        <h5 className="mb-0">{leads}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Positive')}</p>
        <h5 className="mb-0">{positives}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Refused')}</p>
        <h5 className="mb-0">{refused}</h5>
      </td>
      <td className="align-bottom">
        <p className="text-muted mb-1">{t('Revenues')}</p>
        <h5 className="mb-0 text-nowrap">
          {showSensitiveData
            ? isNaN(supplierCosts)
              ? 0
              : supplierCosts.toFixed(2)
            : hideSensitiveDataString}{' '}
          €
        </h5>
      </td>
    </tr>
  );
};

const MonetizationItemsInsights = (props) => {
  const monetizationOrders = useSelector(
    selectMonetizationOrdersBySale(props.supplierId),
  );
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="clearfix">
            <h4 className="card-title mb-4">
              {t('Monetization Order Details')}
            </h4>
          </div>
          <div className="table-responsive mt-4">
            <Table className="mb-0">
              <tbody>
                {monetizationOrders.map((monetizationOrder, index) => (
                  <MonetizationItemInsights
                    key={'monetizationOrder_' + index}
                    monetizationOrder={monetizationOrder}
                    showSensitiveData={showSensitiveData}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonetizationItemsInsights;
