import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import FormLead from '../../components/Leads/FormLead';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CallCenterMetrics from '../../components/Leads/CallCenterMetrics';
import LeadHistory from '../../components/Leads/LeadHistory';
import BatchLeadBar from '../../components/Leads/BatchLeadBar';

import * as leadsActions from '../../store/leads/actions';
import * as leadFormsActions from '../../store/leadForms/actions';
import * as peopleActions from '../../store/people/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin as isAdminSelector } from '../../selectors/auth';
import { leadsWithoutPerson as leadsWithoutPersonSelector } from '../../selectors/lead';
import { selectPerson } from '../../selectors/person';

import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Leads/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import chiedimiIcon from '../../assets/images/logo-sm-light.png';
import moment from 'moment';

const AdminLeads = (props) => {
  const isAdmin = useSelector(isAdminSelector);

  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [personModal, setPersonModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [forceToChiedimiCCAlert, setForceToChiedimiCCAlert] = useState(false);
  const [reprocessLeadAlert, setReprocessLeadAlert] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const leads = useSelector((state) => state.Leads.leads);
  const leadsWithoutPerson = useSelector(leadsWithoutPersonSelector);
  const showSelectedPerson = useSelector(selectPerson(selectedPerson));
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );
  const loading = useSelector((state) => state.Leads.loading);
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);
  const loadingMonetizationOrders = useSelector(
    (state) => state.MonetizationOrder.loading,
  );

  const startDate = useSelector((state) => state.Leads.startDate);
  const endDate = useSelector((state) => state.Leads.endDate);
  const dataFilters = useSelector((state) => state.Leads.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('day'),
    endDate: momentEndDate || moment.utc(),
    sector: dataFilters?.sector || '',
    campaignIds: dataFilters?.campaignIds || null,
  });

  useEffect(() => {
    loading && setSelectedLeadIds([]);
  }, [loading]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFormsActions.syncLeadForms());
  }, []);

  const refresh = useCallback(({ startDate, endDate, sector, campaignIds }) => {
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    dispatch(
      leadsActions.fetchLeads(selectedStartDate, selectedEndDate, {
        sector,
        campaignIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, sector, campaignIds });
  });

  const openViewModal = (lead) => {
    setSelectedLead(lead);
    setViewModal(true);
  };

  const openEditModal = (lead) => {
    setSelectedLead(lead);
    setEditModal(true);
  };

  const openPersonModal = (lead) => {
    setSelectedPerson(lead.personId);
    dispatch(peopleActions.fetchPersonById(lead.personId));
    setPersonModal(true);
  };

  const openHistoryModal = (lead) => {
    setSelectedLead(lead);
    setHistoryModal(true);
  };

  const openForceToChiedimiCCAlert = (lead) => {
    setSelectedLead(lead);
    setForceToChiedimiCCAlert(true);
  };

  const handleForceToChiedimiCC = () => {
    const lead = { id: selectedLead.id, sentToChiedimiCC: 'force' };
    dispatch(leadsActions.updateLead(lead));
    setForceToChiedimiCCAlert(false);
  };

  const openReprocessLeadAlert = (lead) => {
    setSelectedLead(lead);
    setReprocessLeadAlert(true);
  };

  const handleReprocessLead = () => {
    let lead = { id: selectedLead.id, error: '' };
    if (selectedLead.qualified === 'error')
      lead = { ...lead, qualified: 'new' };
    if (selectedLead.survey === 'error') lead = { ...lead, survey: 'new' };
    if (selectedLead.personId === 'error') lead = { ...lead, personId: 'new' };
    dispatch(leadsActions.updateLead(lead));
    setReprocessLeadAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openPersonModal,
        openForceToChiedimiCCAlert,
        openReprocessLeadAlert,
        openHistoryModal,
        campaigns,
        showSensitiveData,
      ),
    [campaigns, showSensitiveData],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <Breadcrumbs
        title="Leads"
        breadcrumbItem="List"
        onRefresh={() => refresh(selectedFilters)}
        loading={loading}
      />
      <DateRangeFilter
        onUpdate={refresh}
        startDate={selectedFilters.startDate}
        endDate={selectedFilters.endDate}
        loading={loading}
        showFilters
        initialFilters={selectedFilters}
      />
      <Row>
        <CallCenterMetrics loading={loading} />
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row className="align-items-center justify-content-end mb-2">
                <Spinner
                  color="secondary"
                  animation="border"
                  hidden={!loading}
                />
                <ToggleColumnButton
                  columns={columns}
                  hiddenColumns={hiddenColumns}
                  onToggleColumn={handleToggleColumn}
                />
                <ExportButtonPlacement />
              </Row>
              <DataTableNext
                loading={loading}
                rows={leads}
                columns={columns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                hiddenColumns={hiddenColumns}
                onHiddenColumnsChange={setHiddenColumns}
                selection={isAdmin ? selectedLeadIds : undefined}
                onSelectionChange={isAdmin ? setSelectedLeadIds : undefined}
                nonSelectableIds={leadsWithoutPerson.map((lead) => lead.id)}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        title="Lead"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={selectedLead} icon="bx bx-user-pin" />
      </Modal>
      <Modal
        size="xl"
        title="Lead"
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        scrollable={false}>
        <FormLead
          closeModal={() => setEditModal(false)}
          create={!selectedLead}
          loading={loadingCampaigns || loadingMonetizationOrders}
          lead={selectedLead}
          disabledSelectCampaign={!!(selectedLead && selectedLead.error === '')}
          showMonetizationOrders
        />
      </Modal>
      <Modal
        size="xl"
        title="Person"
        isOpen={personModal}
        toggle={() => setPersonModal(!personModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={showSelectedPerson} icon="bx bx-user-pin" />
      </Modal>
      <Modal
        size="xl"
        title="Lead"
        isOpen={historyModal}
        toggle={() => setHistoryModal(!historyModal)}
        scrollable={false}>
        <LeadHistory lead={selectedLead} />
      </Modal>
      <Alert
        isOpen={forceToChiedimiCCAlert}
        title="Do you want sending to Chiedimi Call Center?"
        mainIcon={chiedimiIcon}
        confirmBtnLabel="Send"
        onCancel={() => setForceToChiedimiCCAlert(false)}
        onConfirm={handleForceToChiedimiCC}>
        There's no coming back!
      </Alert>
      <Alert
        isOpen={reprocessLeadAlert}
        title="Do you want to reprocess this Lead?"
        confirmBtnLabel="Reprocess"
        onCancel={() => setReprocessLeadAlert(false)}
        onConfirm={handleReprocessLead}>
        There's no coming back!
      </Alert>
      <BatchLeadBar leads={leads} selectedLeadIds={selectedLeadIds} />
    </React.Fragment>
  );
};

export default AdminLeads;
