import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DASHBOARD_STATE,
});

export const setFilters = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const setFiltersSuccess = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.SUCCESS,
  startDate,
  endDate,
  filters,
});

export const setFiltersFailure = (error) => ({
  type: types.SET_DASHBOARD_FILTERS.FAILURE,
  error,
});

export const setCountry = (countryId) => ({
  type: types.SET_COUNTRY.REQUEST,
  countryId,
});

export const setCountrySuccess = (countryId, exchangeQueuesModal) => ({
  type: types.SET_COUNTRY.SUCCESS,
  countryId,
  exchangeQueuesModal,
});

export const setCountryFailure = (error) => ({
  type: types.SET_COUNTRY.FAILURE,
  error,
});

export const setExchangeQueuesModal = (exchangeQueuesModal) => ({
  type: types.SET_EXCHANGE_QUEUES_MODAL,
  exchangeQueuesModal,
});
