import React from 'react';
import {
  ViewButton,
  EditButton,
  ActiveButton,
  TestQueryButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../Common/DataTable/tableHelpers';

const createTableColumns = (
  openViewModal,
  openEditModal,
  openActiveAlert,
  openPeopleModal,
) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Description',
    name: 'description',
  },
  {
    title: 'Size',
    name: 'size',
    width: 100,
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Setup',
    name: 'setup',
    width: 100,
    sort: true,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : value === 'error' ? (
        <span className="badge badge-danger font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value.toUpperCase()}
        </span>
      ),
  },
  {
    title: 'Action',
    name: 'action',
    width: 180,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {row.setup === 'new' ||
          (row.setup === 'completed' && (
            <EditButton row={row} onPress={openEditModal} />
          ))}
        {row.setup !== 'building' && (
          <ActiveButton row={row} onPress={openActiveAlert} />
        )}
        {row.query !== '' && (
          <TestQueryButton row={row} onPress={openPeopleModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
