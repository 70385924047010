import React from 'react';
import Alert from '../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';

const SaveLeadFormAlert = (props) => {
  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Unsaved data')}
      mainIcon={
        <div className="avatar-sm mx-auto my-2">
          <span className="avatar-title rounded-circle bg-warning font-size-24">
            <i className={`bx bx-error text-dark`}></i>
          </span>
        </div>
      }
      confirmBtnLabel="Continue"
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}>
      <h5>
        {t(
          "This lead's data was not saved or not completed, do you want to continue anyway?",
        )}
      </h5>
    </Alert>
  );
};

export default SaveLeadFormAlert;
