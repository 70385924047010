import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import * as audiencesActions from '../../store/audiences/actions';

import { useTranslation } from 'react-i18next';

const MAX_SELECTED_AUDIENCES = 10;

const FilterAudiencesBox = ({ initialFilters, onUpdate }) => {
  const [audienceIds, setAudienceIds] = useState(
    initialFilters?.audienceIds || null,
  );

  const audiences = useSelector((state) => state.Audience.audiences);
  const loading = useSelector((state) => state.Audience.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(audiencesActions.syncAudiences());
  }, []);

  const handleFilters = (values) => {
    setAudienceIds(values);
    onUpdate(values);
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ audienceIds }}
        validationSchema={yup.object().shape({
          audienceIds: yup.array().nullable(),
        })}
        className="d-flex">
        <Col md="12" className="d-flex align-items-center px-0">
          <h5 className="text-muted my-0 ml-5 mr-2">{t('Audiences')}:</h5>
          <div className="flex-fill">
            <Select
              name="audienceIds"
              id="audienceIds"
              isMulti
              maxLength={MAX_SELECTED_AUDIENCES}
              value={audienceIds}
              onChange={handleFilters}
              options={audiences.map((audience) => ({
                label: audience.name,
                value: audience.id,
              }))}
              isDisabled={loading}
              clearButtonClassName="btn btn-outline-info btn-sm"
            />
          </div>
        </Col>
      </Form>
    </CardBody>
  );
};

export default FilterAudiencesBox;
