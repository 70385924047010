import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (monetizationOrders, sales, openViewModal) => [
  {
    title: 'MonetizationOrder',
    name: 'monetizationOrderId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, monetizationOrders),
    sorting: (a, b) =>
      getSort(getLabel(a, monetizationOrders), getLabel(b, monetizationOrders)),
    filter: (value, filter) =>
      getFilter(getLabel(value, monetizationOrders), filter.value),
  },
  {
    title: 'Day',
    name: 'date',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('dddd, DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Positive Leads',
    name: 'positives',
    summary: 'sum',
  },
  {
    title: 'Negative Leads',
    name: 'refused',
    summary: 'sum',
  },
  {
    title: 'Revenues',
    name: 'revenues',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Positive Qualified Costs',
    name: 'positiveQualifiedCosts',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Sale',
    name: 'supplierId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, sales),
    sorting: (a, b) => getSort(getLabel(a, sales), getLabel(b, sales)),
    filter: (value, filter) => getFilter(getLabel(value, sales), filter.value),
  },
  {
    title: 'Sale Costs',
    name: 'totalSupplierCosts',
    formatterComponent: ({ value, row }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Total Costs',
    name: 'totalCosts',
    formatterComponent: ({ value, row }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Margin',
    name: 'margin',
    formatterComponent: ({ value, row }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
