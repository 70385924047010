const statsTypes = {
  RESET_SMS_MESSAGE_STATE: 'RESET_SMS_MESSAGE_STATE',
  CREATE_SMS_MESSAGE: {
    REQUEST: 'CREATE_SMS_MESSAGE.REQUEST',
    SUCCESS: 'CREATE_SMS_MESSAGE.SUCCESS',
    FAILURE: 'CREATE_SMS_MESSAGE.FAILURE',
  },
  FETCH_SMS_MESSAGES: {
    REQUEST: 'FETCH_SMS_MESSAGES.REQUEST',
    SUCCESS: 'FETCH_SMS_MESSAGES.SUCCESS',
    FAILURE: 'FETCH_SMS_MESSAGES.FAILURE',
  },
};

export default statsTypes;
