import { createSelector } from 'reselect';

export const selectMessageTemplate = (id) => {
  return createSelector(
    (state) => state.MessageTemplate.messageTemplates,
    (messageTemplates) => {
      if (!id) return null;
      const messageTemplate = messageTemplates.filter(
        (messageTemplate) => messageTemplate.id === id,
      );
      return messageTemplate.length > 0 ? messageTemplate[0] : null;
    },
  );
};

export const selectMessageTemplateByType = (type) => {
  return createSelector(
    (state) => state.MessageTemplate.messageTemplates,
    (messageTemplates) => {
      if (!type) return null;
      const selectedMessageTemplates = messageTemplates.filter(
        (messageTemplate) => messageTemplate.templateType === type,
      );
      return selectedMessageTemplates.length > 0
        ? selectedMessageTemplates
        : null;
    },
  );
};

export const selectMessageTemplateByMacro = (macro) => {
  return createSelector(
    (state) => state.MessageTemplate.messageTemplates,
    (messageTemplates) => {
      if (!macro) return null;
      const selectedMessageTemplates = messageTemplates.filter(
        (messageTemplate) =>
          messageTemplate.messageText.includes('{{' + macro + '}}'),
      );
      return selectedMessageTemplates.length > 0
        ? selectedMessageTemplates
        : null;
    },
  );
};

export const selectMessageTemplateByLinkType = (linkType) => {
  return createSelector(
    (state) => state.MessageTemplate.messageTemplates,
    (messageTemplates) =>
      messageTemplates.filter(
        (messageTemplate) => messageTemplate.linkType === linkType,
      ),
  );
};

export const selectMessageTemplateByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    (state) => state.MessageTemplate.messageTemplates,
    (messageTemplates) =>
      monetizationOrderId
        ? messageTemplates.filter(
            (messageTemplate) =>
              messageTemplate.monetizationOrderId === monetizationOrderId,
          )
        : messageTemplates.filter(
            (messageTemplate) => messageTemplate.linkType === 'trackingLink',
          ),
  );

export const selectMessageTemplateWithoutSurveyForms = createSelector(
  selectMessageTemplateByLinkType('surveyForm'),
  (state) => state.SurveyForm.surveyForms,
  (messageTemplates, surveyForms) => {
    const messageTemplateIds = surveyForms.map(
      (survey) => survey.messageTemplateId,
    );
    return messageTemplates.filter(
      (messageTemplate) => !messageTemplateIds.includes(messageTemplate.id),
    );
  },
);
