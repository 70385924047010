import { createSelector } from 'reselect';
import colors from '../components/CommonStyle/colors';
import { getDates, dateIsInRange } from '../helpers/sharedFunction';
import moment from 'moment';

export const selectCustomer = (id) =>
  createSelector(
    (state) => state.Customer.customers,
    (customers) => {
      if (!id) return null;
      const customer = customers.filter((customer) => customer.id === id);
      return customer.length > 0 ? customer[0] : null;
    },
  );

export const totalLeads = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.leads).reduce((a, b) => a + b, 0),
);

export const totalPositives = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.positives).reduce((a, b) => a + b, 0),
);

export const totalRefused = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.refused).reduce((a, b) => a + b, 0),
);

export const totalRevenues = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
);

export const totalPositiveQualifiedCosts = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.positiveQualifiedCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalSupplierCosts = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.totalSupplierCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalRebateCosts = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.rebateCosts).reduce((a, b) => a + b, 0),
);

export const totalCosts = createSelector(
  (state) => state.DailyCustomerInsights.insights,
  (insights) =>
    insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
);

export const selectDailyInsightsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    (state) => state.DailyCustomerInsights.insights,
    (insights) =>
      insights.filter(
        (insight) => insight.monetizationOrderId === monetizationOrderId,
      ),
  );

export const totalLeadsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.leads).reduce((a, b) => a + b, 0),
  );

export const totalPositivesByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.positives).reduce((a, b) => a + b, 0),
  );

export const totalRefusedByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.refused).reduce((a, b) => a + b, 0),
  );

export const totalRevenuesByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
  );

export const totalPositiveQualifiedCostsByMonetizationOrder = (
  monetizationOrderId,
) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights
        .map((insight) => insight.positiveQualifiedCosts)
        .reduce((a, b) => a + b, 0),
  );

export const totalSupplierCostsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights
        .map((insight) => insight.totalSupplierCosts)
        .reduce((a, b) => a + b, 0),
  );

export const totalRebateCostsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.rebateCosts).reduce((a, b) => a + b, 0),
  );

export const totalCostsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
  );

export const drawSeriesDailyGraph = (
  dateRange,
  t,
  showRebate = false,
  isAgency = false,
) =>
  createSelector(
    (state) => state.DailyCustomerInsights.insights,
    (insights) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t(isAgency ? 'Billing' : 'Costs'),
            data: [],
            type: 'line',
            color: colors.danger,
          },
          {
            name: t('Leads'),
            data: [],
            type: 'line',
            color: colors.dark,
          },
        ],
        max: -9999999999,
        min: 99999999999,
      };

      showRebate &&
        dataGraph.series.push({
          name: t('Rebates'),
          data: [],
          type: 'line',
          color: colors.success,
        });

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const dailyInsights = insights.filter((insight) =>
          dateIsInRange(new Date(insight.date), startDay, endDay),
        );

        const revenues = dailyInsights
          .map((insight) => insight.revenues)
          .reduce((a, b) => a + b, 0);

        const leads = dailyInsights
          .map((insight) => insight.leads)
          .reduce((a, b) => a + b, 0);

        const rebates = dailyInsights
          .map((insight) => insight.rebateCosts)
          .reduce((a, b) => a + b, 0);

        dataGraph.series[0].data.push([day.getTime(), revenues]);
        dataGraph.series[1].data.push([day.getTime(), leads]);
        showRebate && dataGraph.series[2].data.push([day.getTime(), rebates]);

        dataGraph.max = Math.max(dataGraph.max, revenues, rebates);
        dataGraph.min = Math.min(dataGraph.min, revenues, rebates);
      });
      return dataGraph;
    },
  );
