import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Button, Table } from 'reactstrap';

import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';

const ResendMonetizationItems = ({ monetizationItems }) => {
  const [sent, setSent] = useState(false);

  const loading = useSelector((state) => state.MonetizationItem.loading);
  const logs = useSelector((state) => state.MonetizationItem.logs);

  const dispatch = useDispatch();

  const handleResendMonetizationItems = () => {
    dispatch(
      monetizationItemsActions.resendMonetizationItems(monetizationItems),
    );
    setSent(true);
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <Row className="my-2">
          <Col md="12">
            <h4 className="text-center">
              {t('duplicate_monetizationItems', {
                countMonetizationItems: monetizationItems.length,
              })}
            </h4>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md="12" className="text-center">
            <Button
              color="warning"
              outline
              className="waves-effect waves-light"
              onClick={handleResendMonetizationItems}
              disabled={sent}>
              <i className={`bx bx-send font-size-20 align-middle mr-2`}></i>
              {t('Resend Monetization Items')}
            </Button>
          </Col>
        </Row>
        {!!(sent && loading) && <Dots />}
        {!!(sent && logs.length) && (
          <Row className="my-2">
            <Col md="12">
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>MonetizationItem ID</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log, index) => (
                      <tr key={log.monetizationItemId}>
                        <th scope="row">{index}</th>
                        <td>{log.monetizationItemId}</td>
                        <td>{log.response}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ResendMonetizationItems;
