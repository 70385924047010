import { serverTimestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const limoniRestartImportLead = (lead) => {
  /*if (!lead['Email'] || lead['Email'] === '')
    throw new Error(`Email is required!`);*/

  if (!lead['phone_number'] || lead['phone_number'] === '')
    throw new Error(`Phone is required!`);

  return {
    firstName: toTitleCase(lead['first_name']),
    lastName: toTitleCase(lead['last_name']),
    ...(lead['email'] && lead['email'] !== '' && { email: lead['email'] }),
    phone: normalizePhone(lead['phone_number'].replace('p:', '')),
    ...(lead['note'] && lead['note'] !== '' && { note: lead['note'] }),
    createdAt: serverTimestamp(),
  };
};

export default limoniRestartImportLead;
