import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';

import { useTranslation } from 'react-i18next';

const MAX_SELECTED_MONETIZATION_ORDERS = 10;

const FilterMonetizationOrdersBox = ({ initialFilters, onUpdate }) => {
  const [monetizationOrderIds, setMonetizationOrderIds] = useState(
    initialFilters?.monetizationOrderIds || null,
  );

  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const loading = useSelector((state) => state.MonetizationOrder.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const handleFilters = (values) => {
    setMonetizationOrderIds(values);
    onUpdate(values);
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ monetizationOrderIds }}
        validationSchema={yup.object().shape({
          monetizationOrderIds: yup.array().nullable(),
        })}
        className="d-flex">
        <Col md="12" className="d-flex align-items-center px-0">
          <h5 className="text-muted my-0 ml-5 mr-2">
            {t('Monetization Orders')}:
          </h5>
          <div className="flex-fill">
            <Select
              name="monetizationOrderIds"
              id="monetizationOrderIds"
              isMulti
              maxLength={MAX_SELECTED_MONETIZATION_ORDERS}
              value={monetizationOrderIds}
              onChange={handleFilters}
              options={monetizationOrders.map((monetizationOrder) => ({
                label: monetizationOrder.name,
                value: monetizationOrder.id,
              }))}
              isDisabled={loading}
            />
          </div>
        </Col>
      </Form>
    </CardBody>
  );
};

export default FilterMonetizationOrdersBox;
