import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  countQueuedCalls,
  countNewQueuedCalls,
  countRecallQueuedCalls,
  countClosedCalls,
  percentageSpokenCalls,
} from '../../selectors/call';

import MiniCard from '../Common/MiniCard';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const CallMetrics = ({ filters }) => {
  const { sector, campaignIds } = filters;

  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles.OPERATOR ? user.id : null;

  const queuedCalls = useSelector(countQueuedCalls({ sector, campaignIds }));
  const newQueuedCalls = useSelector(
    countNewQueuedCalls({ sector, campaignIds }),
  );
  const percentageNewQueuedCalls = (newQueuedCalls / queuedCalls) * 100;
  const recallQueuedCalls = useSelector(
    countRecallQueuedCalls({ sector, campaignIds }),
  );
  const percentageRecallQueuedCalls = (recallQueuedCalls / queuedCalls) * 100;
  const closedCalls = useSelector(countClosedCalls(userId));
  const spokenCalls = useSelector(percentageSpokenCalls(userId));

  const { t } = useTranslation();

  const callMetrics = [
    {
      title: t('Queued Calls'),
      text: queuedCalls,
      color: 'warning',
      icon: 'fas fa-phone-alt',
    },
    {
      title: t('New Calls'),
      text: newQueuedCalls,
      color: 'secondary',
      icon: 'fas fa-phone-alt',
      percentage: `${
        isFinite(percentageNewQueuedCalls)
          ? percentageNewQueuedCalls.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Recall Calls'),
      text: recallQueuedCalls,
      color: 'secondary',
      icon: 'fas fa-phone-alt',
      percentage: `${
        isFinite(percentageRecallQueuedCalls)
          ? percentageRecallQueuedCalls.toFixed(0)
          : 0
      }%`,
    },
    {
      title: t('Closed Calls'),
      text: closedCalls,
      color: 'dark',
      icon: 'fas fa-phone-slash',
    },
    {
      title: t('Spoken Calls'),
      text: `${isFinite(spokenCalls) ? spokenCalls.toFixed(2) : 0} % `,
      color: 'info',
      icon: 'fas fa-phone-volume',
    },
  ];

  const loadingMonetizationItems = useSelector(
    (state) => state.MonetizationItem.loading,
  );

  return (
    <>
      {callMetrics.map((metric, index) => (
        <Col key={'mainMetric_' + index}>
          <MiniCard
            key={'callMetric_' + index}
            {...metric}
            loading={loadingMonetizationItems}
          />
        </Col>
      ))}
    </>
  );
};

export default CallMetrics;
