import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import * as leadsActions from '../../store/leads/actions';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { detect } from 'detect-browser';
import useDeviceDetect from '../../lib/useDeviceDetect';
import rsf from '../../helpers/firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';

import logoDark from '../../assets/images/logo-dark.png';

// Paperform survey success redirect: https://crm.lead1car.com/web-push?leadId={{ eh5ei }}&name={{ 7boi9 }} =>
// https://crm.lead1car.com/web-push?leadId=userId&name=name
// Paperform survey link: https://paperform.co/edit/chiedinfo/after/success-pages

const WebPush = (props) => {
  const [name] = useQueryParam('name', StringParam);
  const [leadId] = useQueryParam('leadId', StringParam);
  const [message] = useQueryParam('message', StringParam);

  const [pushToken, setPushToken] = useState('');
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const { isMobile } = useDeviceDetect();

  const successMessage =
    message ||
    'Ora che il tuo profilo è completo, la tua richiesta avrà maggiore priorità 🚀';

  const pushMessage = 'Ricevi tutte le notifiche relative alla tua richiesta.';

  const invitationMessage = `Clicca su 'Consenti' ${
    isMobile ? 'qui in basso' : 'in alto a sinistra'
  } e non perderti i nostri aggiornamenti!`;

  const acceptedMessage = `Grazie ${
    name || ''
  } riceverai tutti gli aggiornamenti!`;

  const getPushToken = () => {
    const messaging = getMessaging(rsf.app);
    const isSupported = messaging.isSupported();

    isSupported
      ? getToken(messaging, {
          vapidKey: process.env.REACT_APP_WEB_PUSH_KEY,
        })
          .then((token) => {
            if (token) {
              const browser = detect();
              setPushToken(token);
              leadId &&
                dispatch(
                  leadsActions.addPushTokenLead(
                    leadId,
                    token,
                    browser ? browser.os : null,
                    browser
                      ? { name: browser.name, version: browser.version }
                      : null,
                  ),
                );
              console.log('Push token:', token);
            } else {
              setError(true);
              console.log(
                'No registration token available. Request permission to generate one.',
              );
            }
          })
          .catch((err) => {
            setError(true);
            console.log('An error occurred while retrieving token. ', err);
          })
      : setError(true);
  };

  useEffect(() => {
    getPushToken();
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-4 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="text-center mb-4">
                <img src={logoDark} alt="" height={isMobile ? '50' : '90'} />
                <div className="row justify-content-center">
                  <div className="col-xl-12 mt-4">
                    <h3 className="text-primary">Grazie, {name}!</h3>
                    <p className="font-size-16 mb-4">{successMessage}</p>
                    {!error && (
                      <>
                        {!pushToken.length && (
                          <p className="font-size-16 mb-4">{pushMessage}</p>
                        )}
                        <div className="avatar-md mx-auto mb-4">
                          <div
                            className={`avatar-title bg-${
                              !!pushToken.length ? 'success' : 'light'
                            } rounded-circle text-${
                              !!pushToken.length ? 'light' : 'primary'
                            } h1`}>
                            <i className="bx bx-bell"></i>
                          </div>
                        </div>
                        <p className="font-size-16 mb-4">
                          {!!pushToken.length
                            ? acceptedMessage
                            : invitationMessage}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(WebPush);
