import React, { useState } from 'react';
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  Card,
  CardBody,
} from 'reactstrap';

import * as nextCallActions from '../../../store/nextCall/actions';
import { useDispatch, useSelector } from 'react-redux';

import AppointmentAlert from './AppointmentAlert';
import RecallAlert from './RecallAlert';
import NegativeAlert from './NegativeAlert';
import PositiveAlert from './PositiveAlert';
import EndShiftAlert from './EndShiftAlert';
import SaveLeadFormAlert from '../SaveLeadFormAlert';
import Modal from '../../Common/Modal/Modal';
import FindNewCalls from '../FindNewCalls';

import callOutcomes from '../../../config/callOutcomes';
import { useTranslation } from 'react-i18next';

const { appointment, recall, negative, positive } = callOutcomes;

const OutcomeBar = (props) => {
  const { call, lead, acquisitionOrder } = props;

  const dirtyLeadForm = useSelector((state) => state.NextCall.dirtyLeadForm);

  const [appointmentAlert, setAppointmentAlert] = useState(false);
  const [recallAlert, setRecallAlert] = useState(false);
  const [negativeAlert, setNegativeAlert] = useState(false);
  const [positiveAlert, setPositiveAlert] = useState(false);
  const [endShiftAlert, setEndShiftAlert] = useState(false);
  const [searchCallModal, setSearchCallModal] = useState(false);
  const [saveLeadFormAlert, setSaveLeadFormAlert] = useState(false);
  const [selectedClosureType, setSelectedClosureType] = useState('');

  const dispatch = useDispatch();

  const handleNextCall = () => {
    dispatch(nextCallActions.resetState());
    dispatch(nextCallActions.fetchNextCall());
  };

  const handleOnClick = (type) => {
    setSelectedClosureType(type);
    if (dirtyLeadForm) setSaveLeadFormAlert(true);
    else openClosureAlert(type);
  };

  const handleOnConfirmSaveLeadFormAlert = () => {
    setSaveLeadFormAlert(false);
    openClosureAlert(selectedClosureType);
  };

  const openClosureAlert = (type) => {
    switch (type) {
      case 'appointment':
        setAppointmentAlert(true);
        break;
      case 'recall':
        setRecallAlert(true);
        break;
      case 'positive':
        setPositiveAlert(true);
        break;
      case 'negative':
        setNegativeAlert(true);
        break;
      case 'nextCall':
        handleNextCall();
        break;
      case 'SearchCall':
        setSearchCallModal(true);
        break;
      case 'endShift':
        setEndShiftAlert(true);
        break;
      default:
        break;
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="fixed-bottom">
        <Navbar style={{ backgroundColor: '#2a3042' }} dark expand="md">
          <Container style={{ justifyContent: 'flex-start' }}>
            {call.status !== 'closed' ? (
              <>
                <NavbarBrand>{t('Outcome')}:</NavbarBrand>
                <Nav pills>
                  <NavItem style={{ marginRight: '15px' }}>
                    <Button
                      color={appointment.color}
                      outline
                      disabled={call.status === 'closed'}
                      className="waves-effect waves-light"
                      onClick={() => handleOnClick('appointment')}>
                      <i
                        className={`${appointment.icon} font-size-20 align-middle mr-2`}></i>
                      {t(appointment.title)}
                    </Button>
                  </NavItem>
                  <NavItem style={{ marginRight: '15px' }}>
                    <Button
                      color={recall.color}
                      outline
                      disabled={call.status === 'closed'}
                      className="waves-effect waves-light"
                      onClick={() => handleOnClick('recall')}>
                      <i
                        className={`${recall.icon} font-size-20 align-middle mr-2`}></i>
                      {t(recall.title)}
                    </Button>
                  </NavItem>
                  <NavItem style={{ marginRight: '15px' }}>
                    <Button
                      color={negative.color}
                      outline
                      disabled={call.status === 'closed'}
                      className="waves-effect waves-light"
                      onClick={() => handleOnClick('negative')}>
                      <i
                        className={`${negative.icon} font-size-20 align-middle mr-2`}></i>
                      {t(negative.title)}
                    </Button>
                  </NavItem>
                  <NavItem style={{ marginRight: '15px' }}>
                    <Button
                      color={positive.color}
                      outline
                      disabled={call.status === 'closed'}
                      className="waves-effect waves-light"
                      onClick={() => handleOnClick('positive')}>
                      <i
                        className={`${positive.icon} font-size-20 align-middle mr-2`}></i>
                      {t(positive.title)}
                    </Button>
                  </NavItem>
                </Nav>
              </>
            ) : (
              <>
                <NavbarBrand>{t('Go to next Call')}?</NavbarBrand>
                <Nav pills>
                  <NavItem>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-md"
                      onClick={() => handleOnClick('nextCall')}>
                      <i className="far fa-arrow-alt-circle-right font-size-22 align-middle mr-2"></i>
                      {t('Yes')}
                    </Button>
                  </NavItem>
                </Nav>
                <NavbarBrand style={{ marginLeft: '60px' }}>
                  {t('Search a call')}?
                </NavbarBrand>
                <Nav pills>
                  <NavItem>
                    <Button
                      color="info"
                      className="waves-effect waves-light w-md"
                      onClick={() => handleOnClick('SearchCall')}>
                      <i className="bx bx-search-alt align-middle mr-2"></i>
                      {t('Search')}
                    </Button>
                  </NavItem>
                </Nav>
                <NavbarBrand style={{ marginLeft: '60px' }}>
                  {t('Or exit')}?
                </NavbarBrand>
                <Nav pills>
                  <NavItem>
                    <Button
                      color="warning"
                      className="waves-effect waves-light"
                      onClick={() => handleOnClick('endShift')}>
                      <i className="mdi mdi-close-circle-outline align-middle mr-2"></i>
                      {t('Exit')}
                    </Button>
                  </NavItem>
                </Nav>
              </>
            )}
          </Container>
        </Navbar>
      </div>
      <AppointmentAlert
        isOpen={appointmentAlert}
        onCancel={() => setAppointmentAlert(false)}
        call={call}
        lead={lead}
      />
      <RecallAlert
        isOpen={recallAlert}
        onCancel={() => setRecallAlert(false)}
        call={call}
      />
      <NegativeAlert
        isOpen={negativeAlert}
        onCancel={() => setNegativeAlert(false)}
        call={call}
        acquisitionOrder={acquisitionOrder}
      />
      <PositiveAlert
        isOpen={positiveAlert}
        onCancel={() => setPositiveAlert(false)}
        lead={lead}
        call={call}
        acquisitionOrder={acquisitionOrder}
      />
      <EndShiftAlert
        isOpen={endShiftAlert}
        onCancel={() => setEndShiftAlert(false)}
      />
      <SaveLeadFormAlert
        isOpen={saveLeadFormAlert}
        onCancel={() => setSaveLeadFormAlert(false)}
        onConfirm={handleOnConfirmSaveLeadFormAlert}
      />
      <Modal
        size="xl"
        title={t('Search a call')}
        isOpen={searchCallModal}
        toggle={() => setSearchCallModal(!searchCallModal)}
        showClose
        scrollable={false}>
        <Card>
          <CardBody>
            <FindNewCalls
              closeModal={() => setSearchCallModal(!searchCallModal)}
            />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default OutcomeBar;
