import types from './actionTypes';
import { leadFilters } from '../../config/filters';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  leads: [],
  startDate: null,
  endDate: null,
  filters: { ...leadFilters },
  error: '',
  logs: [],
};

const allLeads = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_LEAD_STATE:
      return initialState;
    case types.FETCH_LEADS.REQUEST:
    case types.FETCH_SINGLE_LEAD.REQUEST:
    case types.CREATE_LEAD.REQUEST:
    case types.IMPORT_LEADS.REQUEST:
    case types.ADD_PUSH_TOKEN_LEAD.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.DUPLICATE_LEADS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        logs: [],
      };
    case types.FETCH_LEADS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        leads: action.leads,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.FETCH_SINGLE_LEAD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        leads: addOrReplaceObjectInArray(state.leads, action.lead),
      };
    case types.IMPORT_LEADS.SUCCESS:
    case types.CREATE_LEAD.SUCCESS:
    case types.ADD_PUSH_TOKEN_LEAD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.DUPLICATE_LEADS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        logs: action.logs,
      };
    case types.FETCH_LEADS.FAILURE:
    case types.FETCH_SINGLE_LEAD.FAILURE:
    case types.CREATE_LEAD.FAILURE:
    case types.IMPORT_LEADS.FAILURE:
    case types.UPDATE_LEAD.FAILURE:
    case types.DUPLICATE_LEADS.FAILURE:
    case types.ADD_PUSH_TOKEN_LEAD.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default allLeads;
