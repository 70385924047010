import types from './actionTypes';

const initialState = {
  loading: false,
  loggedIn: false,
  credential: null,
  admin: null,
  error: '',
  success: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_MESSAGES:
      return {
        ...state,
        error: '',
        success: '',
      };
    case types.LOGOUT.SUCCESS:
      return initialState;
    case types.LOGIN_WITH_GOOGLE.REQUEST:
    case types.LOGIN_WITH_EMAIL.REQUEST:
    case types.REGISTER_WITH_EMAIL.REQUEST:
    case types.UPDATE_ADMIN.REQUEST:
    case types.FETCH_ADMIN.REQUEST:
    case types.PASSWORD_FORGET.REQUEST:
    case types.LOGOUT.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.UPDATE_ADMIN.SUCCESS:
    case types.PASSWORD_CHANGE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.FETCH_ADMIN.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: action.admin ? !!action.admin.role : false,
        admin: { ...action.admin },
        success: action.message,
        error: '',
      };
    case types.CREATE_ADMIN.SUCCESS:
    case types.PASSWORD_FORGET.SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.message,
        error: '',
      };
    case types.LOGIN_WITH_GOOGLE.FAILURE:
    case types.LOGIN_WITH_EMAIL.FAILURE:
    case types.REGISTER_WITH_EMAIL.FAILURE:
    case types.CREATE_ADMIN.FAILURE:
    case types.UPDATE_ADMIN.FAILURE:
    case types.FETCH_ADMIN.FAILURE:
    case types.PASSWORD_FORGET.FAILURE:
    case types.LOGOUT.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case types.SYNC_ADMIN:
      return {
        ...state,
        loggedIn: action.credential != null,
        credential: { ...action.credential },
      };
    default:
      return state;
  }
};

export default authReducer;
