import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  source: yup.string().required(),
  isLeadCampaign: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  archived: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  allowDuplicateLead: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  onlyQualifiedCompleted: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  allowCreatePerson: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  supplierId: yup.string(),
  percentageRevenueShare: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
  percentageMarginShare: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
  costPerLeadGenerated: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
  costPerPositiveLead: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
  defaultLeadValues: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .min(0),
});

export default formValidation;
