import '@availity/yup';
import * as yup from 'yup';

const COMMA_SEPARATED_VALUES_REGEXP =
  /^[a-zA-Z\u00C0-\u017F0-9\s'\-./]+(?:(?:,)[a-zA-Z\u00C0-\u017F0-9\s'\-./]+)*[a-zA-Z\u00C0-\u017F0-9\s'\-./]+$/g;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  customerId: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(), //.min(yup.ref('startDate')),
  priority: yup.number().required().positive(),
  leads: yup.number().required().integer(),
  payout: yup.number().required().min(0),
  percentageRebate: yup.number().required().min(0),
  /*messageTemplateId: yup
    .string()
    .required()
    .when('sendLeadType', {
      is: 'trackingPixel',
      then: () => yup.string().matches(/^(?!.*none).*$/),
    }),*/
  messageTemplateId: yup.string().required(),
  messageSendDelay: yup.number().when('messageTemplateId', {
    is: (value) => value && value !== 'none',
    then: () => yup.number().required().min(0),
  }),
  sendLeadType: yup.string().required(),
  landingPageCustomer: yup.string().when('sendLeadType', {
    is: (value) => value === 'dataEntry' || value === 'trackingPixel',
    then: () => yup.string().required(),
  }),
  trackingMessageTemplateId: yup.string().when('sendLeadType', {
    is: 'trackingPixel',
    then: () => yup.string().required(),
  }),
  webService: yup.string().when('sendLeadType', {
    is: 'webService',
    then: () => yup.string().required(),
  }),
  spreadsheetLeadFieldIds: yup.array().when('sendLeadType', {
    is: 'googleSpreadsheets',
    then: () => yup.array().required().min(3),
  }),
  costPositiveClosure: yup.number().required().min(0),
  validLeads: yup.number().min(0),
  reasons: yup.string().matches(COMMA_SEPARATED_VALUES_REGEXP, {
    excludeEmptyString: true,
  }),
  supplierId: yup.string(),
  percentageRevenueShare: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
  percentageMarginShare: yup.number().when('supplierId', {
    is: (value) => !!value,
    then: () => yup.number().min(0).required(),
  }),
});

export default formValidation;
