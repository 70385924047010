import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  FilteringState,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  SummaryState,
  IntegratedSummary,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableFixedColumns,
  TableColumnVisibility,
  TableSummaryRow,
  TableSelection,
  SearchPanel,
  Toolbar,
  ExportPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const TableComponent = ({ ...restProps }) => (
  <Table.Table {...restProps} className="table-striped" />
);

const ToolbarRoot = (props) => (
  <Toolbar.Root {...props} className="force-hidden" />
);

const FilterCell = (props) => {
  const {
    filteringEnabled,
    column: { fixed },
  } = props;

  return filteringEnabled ? (
    <TableFilterRow.Cell {...props} />
  ) : fixed ? (
    <th
      className="dx-g-bs4-fixed-cell position-sticky"
      colSpan="1"
      style={{ backgroundColor: 'rgb(248, 248, 251)', [fixed]: 0 }}></th>
  ) : (
    <th colSpan="1"></th>
  );
};

const SelectionCell = ({ row, ...restProps }) =>
  row.selectable ? (
    <TableSelection.Cell row={row} {...restProps} />
  ) : (
    <Table.Cell />
  );

const TitleLabel = ({ children: columnTitle }) => {
  const { t } = useTranslation();
  return <TableHeaderRow.Title>{t(columnTitle)}</TableHeaderRow.Title>;
};

const SortingIcon = ({ direction }) => (
  <span
    className={`bx bx-sort-${direction === 'asc' ? 'up' : 'down'}`}
    style={{ fontSize: '15px', paddingLeft: '5px' }}
  />
);

const SortLabel = ({ onSort, children, direction, disabled }) => (
  <span
    onClick={disabled ? undefined : onSort}
    style={{ cursor: disabled ? 'default' : 'pointer' }}>
    {children}
    {direction && <SortingIcon direction={direction} />}
  </span>
);

const summaryCalculator = (type, rows, getValue) => {
  // Built-in operations: sum, max, min, avg, count.
  if (type === 'median') {
    if (!rows.length) {
      return null;
    }
    const sortedRows = rows.sort((a, b) => getValue(a) - getValue(b));
    if (rows.length % 2 === 1) {
      return getValue(sortedRows[(sortedRows.length + 1) / 2]);
    }
    const halfIndex = sortedRows.length / 2;
    return (
      (getValue(sortedRows[halfIndex]) + getValue(sortedRows[halfIndex + 1])) /
      2
    );
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const onExport = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'Lead1 CRM - Export.xlsx',
    );
  });
};

const ExportToggleButton = ({ onToggle, getMessage, buttonRef }) => {
  const [container, setContainer] = useState(null);

  useEffect(
    () => setContainer(document.getElementById('export-button-table')),
    [],
  );

  return container
    ? createPortal(
        <i
          ref={buttonRef}
          onClick={onToggle}
          className="bx bx-download font-size-24 mr-2"
          style={{ cursor: 'pointer' }}></i>,
        container,
      )
    : null;
};

const DataTableNext = ({
  loading,
  rows,
  columns,
  filters,
  onFiltersChange,
  showSearch,
  searchValue,
  onSearchValueChange,
  sorting,
  onSortingChange,
  currentPage,
  onCurrentPageChange,
  hiddenColumns = [],
  onHiddenColumnsChange,
  selection,
  onSelectionChange,
  nonSelectableIds,
  pageSize,
  onPageSizeChange,
}) => {
  columns = useMemo(() => columns.filter(({ skip }) => !skip), [columns]);

  const formatterColumns = useMemo(
    () => columns.filter(({ formatterComponent }) => !!formatterComponent),
    [columns],
  );

  const filterColumns = useMemo(() => {
    let filterState = [];
    let filterFunctions = [];
    columns
      .filter(({ filter }) => !!filter)
      .forEach(({ name, filter }) => {
        if (filter === 'disabled')
          filterState.push({
            columnName: name,
            filteringEnabled: false,
          });
        if (typeof filter === 'function')
          filterFunctions.push({
            columnName: name,
            predicate: filter,
          });
      });
    return { filterState, filterFunctions };
  }, [columns]);

  const sortColumns = useMemo(
    () =>
      columns
        .filter(({ sorting }) => !!sorting)
        .map(({ name, sorting }) => {
          if (sorting === 'disabled')
            return {
              columnName: name,
              sortingEnabled: false,
            };
          return { columnName: name, sortingEnabled: true };
        }),
    [columns],
  );

  const fixedColumns = useMemo(() => {
    let leftColumns = [];
    let rightColumns = [];
    columns
      .filter(({ fixed }) => !!fixed)
      .forEach(({ name, fixed }) => {
        if (fixed === 'left') leftColumns.push(name);
        if (fixed === 'right') rightColumns.push(name);
      });
    return { leftColumns, rightColumns };
  }, [columns]);

  const styleColumns = useMemo(
    () =>
      columns.map(({ name, width, align, wordWrapEnabled }) => ({
        columnName: name,
        wordWrapEnabled: true,
        ...(width && { width }),
        ...(align && { align }),
        ...(wordWrapEnabled !== undefined && { wordWrapEnabled }),
      })),
    [columns],
  );

  const summaryColumns = useMemo(() => {
    const summaryColumns = columns
      .filter(({ summary }) => !!summary)
      .map(({ name, summary }) => ({ columnName: name, type: summary }));

    const firstVisibleColumn = columns.find(({ hidden }) => !hidden);
    if (firstVisibleColumn && !firstVisibleColumn.summary)
      summaryColumns.unshift({
        columnName: firstVisibleColumn.name,
        type: 'count',
      });

    return summaryColumns;
  }, [columns]);

  const selectableRows = useMemo(() => {
    if (!Array.isArray(selection)) return rows;
    if (!Array.isArray(nonSelectableIds) || !nonSelectableIds.length)
      return rows.map((row) => ({ ...row, selectable: true }));
    return rows.map((row) =>
      nonSelectableIds.includes(row.id) ? row : { ...row, selectable: true },
    );
  }, [rows, selection, nonSelectableIds]);

  const showSelectionColumn = Array.isArray(selection) && onSelectionChange;

  const rowIds = useMemo(() => (row) => row.id, [rows]);

  const exportCustomizeCell = useCallback((cell, row, column) => {
    const { value } = cell;

    cell.value =
      typeof column.exportValue === 'function'
        ? column.exportValue({ value, row })
        : value;
  }, []);

  const exporterRef = useRef(null);
  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef],
  );

  return (
    <div className="position-relative">
      {loading && <Loader size={4} top={400} />}
      <Grid rows={selectableRows} columns={columns} getRowId={rowIds}>
        {formatterColumns.map(({ name, formatterComponent }, index) => (
          <DataTypeProvider
            key={index}
            for={[name]}
            formatterComponent={formatterComponent}
            availableFilterOperations={['contains']}
          />
        ))}
        <FilteringState
          filters={filters}
          onFiltersChange={onFiltersChange}
          columnExtensions={filterColumns.filterState}
        />
        <SortingState
          sorting={sorting}
          onSortingChange={onSortingChange}
          columnExtensions={sortColumns}
        />
        <SelectionState
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
        <SummaryState totalItems={summaryColumns} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
        {showSearch && (
          <SearchState
            value={searchValue}
            onValueChange={onSearchValueChange}
          />
        )}
        <IntegratedFiltering columnExtensions={filterColumns.filterFunctions} />
        <IntegratedSorting />
        <IntegratedSelection />
        <IntegratedSummary calculator={summaryCalculator} />
        <IntegratedPaging />
        <Table
          tableComponent={TableComponent}
          columnExtensions={styleColumns}
        />
        <TableSummaryRow />
        <TableHeaderRow
          showSortingControls
          sortLabelComponent={SortLabel}
          titleComponent={TitleLabel}
          showSelectAll
        />
        <TableFilterRow cellComponent={FilterCell} />
        <TableSelection
          showSelectionColumn={showSelectionColumn}
          showSelectAll={
            !!selectableRows.filter((row) => row.selectable).length
          }
          cellComponent={SelectionCell}
        />
        <TableFixedColumns
          leftColumns={fixedColumns.leftColumns}
          rightColumns={fixedColumns.rightColumns}
        />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumns}
          onHiddenColumnNamesChange={onHiddenColumnsChange}
        />
        <PagingPanel pageSizes={[25, 50, 100, 200]} />
        <Toolbar rootComponent={ToolbarRoot} />
        {showSearch && <SearchPanel />}
        <ExportPanel
          startExport={startExport}
          toggleButtonComponent={ExportToggleButton}
        />
      </Grid>
      <GridExporter
        ref={exporterRef}
        getRowId={rowIds}
        rows={rows}
        columns={[
          {
            title: 'ID',
            name: 'id',
          },
          ...columns.filter(
            ({ name }) => !['id', 'action', ...hiddenColumns].includes(name),
          ),
        ]}
        customizeCell={exportCustomizeCell}
        onSave={onExport}
      />
    </div>
  );
};

export default DataTableNext;
