import React, { useEffect, useState } from 'react';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectNextPersonalAppointments } from '../../selectors/call';
import * as callsActions from '../../store/calls/actions';

import Modal from '../Common/Modal/Modal';
import NextAppointments from './NextAppointments';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const AppointmentsBox = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const { role } = user;
  const nextAppointments = useSelector(
    selectNextPersonalAppointments(role === roles['OPERATOR'] ? user.id : null),
  );

  const [viewAppointmentsModal, setViewAppointmentsModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callsActions.syncQueuedCalls());
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Card>
        <CardBody className="p-2">
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-3">
              <div className="avatar-title bg-light rounded-circle text-primary h3">
                <i className="bx bx-calendar-event"></i>
              </div>
            </div>

            <Row className="justify-content-center">
              <Col xl={10}>
                <p className="text-muted font-size-13 mb-2">
                  {t('Today Appointments')}:{' '}
                </p>
                <h5 className="mb-2">{nextAppointments.length}</h5>
                <Button
                  onClick={() => setViewAppointmentsModal(true)}
                  color="primary"
                  disabled={nextAppointments.length === 0}
                  className="waves-effect waves-light btn-sm">
                  {t('View')} <i className="mdi mdi-arrow-right ml-1"></i>
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <Modal
        size="xl"
        title="Today Appointments"
        isOpen={viewAppointmentsModal}
        toggle={() => setViewAppointmentsModal(!viewAppointmentsModal)}
        showClose
        scrollable={false}>
        <NextAppointments nextAppointments={nextAppointments} />
      </Modal>
    </>
  );
};

export default AppointmentsBox;
