import { Input } from '@availity/form';
import { FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../helpers/sharedFunction';

const Switch = ({ name, label, defaultChecked, onClick, readOnly }) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <div className="custom-control custom-switch mb-2" style={{ zIndex: 0 }}>
        <Input
          type="checkbox"
          className="custom-control-input"
          id={name}
          name={name}
          defaultChecked={defaultChecked}
          disabled={readOnly}
        />
        <label
          className="custom-control-label"
          htmlFor={name}
          onClick={() => onClick && !readOnly && onClick()}
          style={readOnly ? { cursor: 'not-allowed' } : {}}>
          {label || capitalize(t(name))}
        </label>
      </div>
    </FormGroup>
  );
};

export default Switch;
