import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Alert } from 'reactstrap';
import * as surveyFormsActions from '../../store/surveyForms/actions';
import * as audiencesActions from '../../store/audiences/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLeadFieldsBy } from '../../selectors/leadField';
import { personalAttributesForQueryBuilder } from '../../selectors/surveyForm';

import QueryBuilder from '../Common/QueryBuilder';
import { Form } from '@availity/form';
import { Select, Input, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import toastr from 'toastr';

const FormAudience = (props) => {
  const editingAudience = props.audience || {};

  const [active, setActive] = useState(editingAudience.active || false);

  const leadFields = useSelector(selectLeadFieldsBy('personalAttribute'));
  const personalAttributes = useSelector(personalAttributesForQueryBuilder);
  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );

  const initialQuery = editingAudience.query || null;
  const initialSqlQuery = editingAudience.sqlQuery || null;
  const initialJsonLogicQuery = editingAudience.jsonLogicQuery || null;

  const [selectedQuery, setSelectedQuery] = useState(initialQuery);
  const [selectedSqlQuery, setSelectedSqlQuery] = useState(initialSqlQuery);
  const [selectedJsonLogicQuery, setSelectedJsonLogicQuery] = useState(
    initialJsonLogicQuery,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(surveyFormsActions.syncSurveyForms());
  }, []);

  const handleSubmit = (values) => {
    if (!selectedQuery) return toastr.error('Check the fields!', 'Error');

    const audience = {
      ...values,
      query: selectedQuery || '',
      sqlQuery: selectedSqlQuery || '',
      jsonLogicQuery: selectedJsonLogicQuery || '',
      active,
    };

    dispatch(
      props.create
        ? audiencesActions.createAudience({
            ...audience,
          })
        : audiencesActions.updateAudience({
            id: editingAudience.id,
            ...audience,
          }),
    );
    props.closeModal();
  };

  const handleQueryTree = (jsonTree, sqlQuery, jsonLogicQuery) => {
    setSelectedQuery(jsonTree);
    setSelectedSqlQuery(sqlQuery);
    setSelectedJsonLogicQuery(jsonLogicQuery);
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingAudience, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                {!props.create && (
                  <Alert color="warning">
                    <i className="bx bxs-error font-size-16 text-danger align-middle mr-2"></i>
                    Editing an audience will not impact previously created
                    personas.
                  </Alert>
                )}
              </Row>
              <Row>
                <Col md="4">
                  <Input id="name" name="name" />
                </Col>
                <Col md="2">
                  <Switch
                    name="active"
                    label="Active?"
                    defaultChecked={active}
                    onClick={() => setActive(!active)}
                  />
                </Col>
                <Col md="6">
                  <Input id="description" name="description" type="textarea" />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Select
                    name="includeMonetizationOrderIds"
                    id="includeMonetizationOrderIds"
                    label="Sold to"
                    options={monetizationOrders.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isMulti
                  />
                </Col>
                <Col md="6">
                  <Select
                    name="excludeMonetizationOrderIds"
                    id="excludeMonetizationOrderIds"
                    label="Not sold to"
                    options={monetizationOrders.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isMulti
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-3">
                  <FormGroup>
                    <QueryBuilder
                      label="Logical condition for selecting people"
                      fields={[...leadFields, ...personalAttributes]}
                      onChange={handleQueryTree}
                      initialQuery={initialQuery}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormAudience;
