import types from './actionTypes';

const initialState = {
  loading: false,
  messageTemplates: [],
  error: '',
};

const messageTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_MESSAGE_TEMPLATE_STATE:
      return initialState;
    case types.CREATE_MESSAGE_TEMPLATE.REQUEST:
    case types.UPDATE_MESSAGE_TEMPLATE.REQUEST:
    case types.SYNC_MESSAGE_TEMPLATES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_MESSAGE_TEMPLATE.SUCCESS:
    case types.UPDATE_MESSAGE_TEMPLATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_MESSAGE_TEMPLATES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        messageTemplates: [...action.messageTemplates],
      };
    case types.CREATE_MESSAGE_TEMPLATE.FAILURE:
    case types.UPDATE_MESSAGE_TEMPLATE.FAILURE:
    case types.SYNC_MESSAGE_TEMPLATES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default messageTemplateReducer;
