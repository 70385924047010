import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  countPushNotifications,
  pushNotificationUnsentCount,
  totalCosts,
} from '../../selectors/pushNotification';

import MiniCard from '../Common/MiniCard';

const PushNotificationMetrics = (props) => {
  const pushNotifications = useSelector(countPushNotifications);
  const costs = useSelector(totalCosts);
  const pushNotificationUnsent = useSelector(pushNotificationUnsentCount);
  const percentageErrorPushNotificationMessage =
    (pushNotificationUnsent / pushNotifications) * 100;

  const pushNotificationMetrics = [
    {
      title: 'Push Notifications',
      text: pushNotifications,
      color: 'info',
      icon: 'bx bx-notification',
    },
    {
      title: 'Push Notifications Unsent',
      text: pushNotificationUnsent,
      color: 'warning',
      icon: 'bx bx-notification',
    },
    {
      title: 'Costs',
      text: `${costs.toFixed(2)} €`,
      color: 'secondary',
      icon: 'fas fa-money-bill-wave',
    },
    {
      title: 'Push Notifications in error',
      text: `${
        isFinite(percentageErrorPushNotificationMessage)
          ? percentageErrorPushNotificationMessage.toFixed(2)
          : 0
      } %`,
      color: 'danger',
      icon: 'bx bx-error-circle',
    },
  ];

  return (
    <>
      {pushNotificationMetrics.map((metric, index) => (
        <Col md="3" key={'pushNotificationMetric_' + index}>
          <MiniCard {...metric} loading={props.loading} />
        </Col>
      ))}
    </>
  );
};

export default PushNotificationMetrics;
