import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../Common/DataTable/tableHelpers';

const messageTemplateText = (cell, messageTemplates) => {
  const messageTemplate = messageTemplates.find(
    (messageTemplate) => messageTemplate.id === cell,
  );
  return messageTemplate
    ? `${messageTemplate.templateType.toUpperCase()}: ${
        messageTemplate.messageText
      }`
    : '';
};

const createTableColumns = (openViewModal, openEditModal, messageTemplates) => [
  {
    title: 'Name',
    name: 'name',
    width: 150,
    sort: true,
  },
  {
    title: 'Provider',
    name: 'provider',
    width: 150,
    sort: true,
  },
  {
    title: 'URL Schema',
    name: 'urlSchema',
  },
  {
    title: 'Template',
    name: 'messageTemplateId',
    formatterComponent: ({ value }) =>
      messageTemplateText(value, messageTemplates),
    sorting: (a, b) =>
      getSort(
        messageTemplateText(a, messageTemplates),
        messageTemplateText(b, messageTemplates),
      ),
    filter: (value, filter) =>
      getFilter(messageTemplateText(value, messageTemplates), filter.value),
    sort: true,
  },
  {
    title: 'Action',
    name: 'action',
    width: 120,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
