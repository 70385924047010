import React, { useState, useEffect } from 'react';

import { Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import { negativeMonetizationItemForAcquisitionOrders } from '../../selectors/monetizationItem';
import DataTableNext from '../Common/DataTable/DataTableNext';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const NegativeMonetizationItemInsights = ({ userId, role }) => {
  const negativeMonetizationItems = useSelector(
    negativeMonetizationItemForAcquisitionOrders(userId),
  );
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
  }, []);

  const { t } = useTranslation();

  const columns = [
    {
      title: 'Acquisition Order',
      name: 'acquisitionOrderId',
      formatterComponent: ({ value }) => getLabel(value, acquisitionOrders),
      sorting: (a, b) =>
        getSort(getLabel(a, acquisitionOrders), getLabel(b, acquisitionOrders)),
      filter: (value, filter) =>
        getFilter(getLabel(value, acquisitionOrders), filter.value),
    },
    {
      title: 'Sector',
      name: 'sector',
    },
    {
      title: 'Closures',
      name: 'count',
      width: 110,
      summary: 'sum',
    },
  ];

  const costColumn = [roles.ADMIN, roles.SUPER_ADMIN].includes(role)
    ? [
        {
          title: 'Qualify costs',
          name: 'costs',
          width: 180,
          formatterComponent: ({ value }) => `€ ${value.toFixed(2)}`,
          summary: 'sum',
        },
      ]
    : [];

  return (
    <Card>
      <CardBody>
        <DataTableNext
          loading={false}
          rows={negativeMonetizationItems}
          columns={[...columns, ...costColumn]}
          filters={filters}
          onFiltersChange={setFilters}
          sorting={sorting}
          onSortingChange={setSorting}
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </CardBody>
    </Card>
  );
};

export default NegativeMonetizationItemInsights;
