import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PERSON_STATE,
});

export const createPerson = (person) => ({
  type: types.CREATE_PERSON.REQUEST,
  person,
});

export const createPersonSuccess = (person) => ({
  type: types.CREATE_PERSON.SUCCESS,
  person,
});

export const createPersonFailure = (error) => ({
  type: types.CREATE_PERSON.FAILURE,
  error,
});

export const updatePerson = (person) => ({
  type: types.UPDATE_PERSON.REQUEST,
  person,
});

export const updatePersonSuccess = (person) => ({
  type: types.UPDATE_PERSON.SUCCESS,
  person,
});

export const updatePersonFailure = (error) => ({
  type: types.UPDATE_PERSON.FAILURE,
  error,
});

export const fetchPeople = (startDate, endDate, filters) => ({
  type: types.FETCH_PEOPLE.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchPeopleSuccess = (
  people,
  startDate,
  endDate,
  filters = null,
) => ({
  type: types.FETCH_PEOPLE.SUCCESS,
  people,
  startDate,
  endDate,
  filters,
});

export const fetchPeopleFailure = (error) => ({
  type: types.FETCH_PEOPLE.FAILURE,
  error,
});

export const fetchPeopleByAudience = (audienceId) => ({
  type: types.FETCH_PEOPLE_BY_AUDIENCE.REQUEST,
  audienceId,
});

export const fetchPeopleByAudienceSuccess = (people) => ({
  type: types.FETCH_PEOPLE_BY_AUDIENCE.SUCCESS,
  people,
});

export const fetchPeopleByAudienceFailure = (error) => ({
  type: types.FETCH_PEOPLE_BY_AUDIENCE.FAILURE,
  error,
});

export const fetchPersonById = (personId) => ({
  type: types.FETCH_PERSON_BY_ID.REQUEST,
  personId,
});

export const fetchPersonByIdSuccess = (person) => ({
  type: types.FETCH_PERSON_BY_ID.SUCCESS,
  person,
});

export const fetchPersonByIdFailure = (error) => ({
  type: types.FETCH_PERSON_BY_ID.FAILURE,
  error,
});
