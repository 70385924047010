import Date from '@availity/date';
import { FormGroup, InputGroup } from 'reactstrap';
import Label from './Label';
import './Date.scss';

const DateForm = ({ readOnly, children, addonType, ...restProps }) => (
  <FormGroup>
    <Label {...restProps} />
    <InputGroup className="flex-nowrap">
      {addonType === 'prepend' && children}
      <Date {...restProps} disabled={readOnly} />
      {(!addonType || addonType === 'append') && children}
    </InputGroup>
  </FormGroup>
);

export default DateForm;
