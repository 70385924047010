export const stringFilter = (value, filter) => {
  const toLowerCase = (value) => String(value).toLowerCase();
  return toLowerCase(value).startsWith(toLowerCase(filter.value));
};

export const getLabels = (cell, collection, key = 'id', label = 'name') =>
  Array.isArray(cell) && cell.length
    ? collection
        .filter((doc) => cell.includes(doc[key]))
        .map((doc) => doc[label])
        .join(', ')
    : '';

export const getLabel = (cell, collection, key = 'id', label = 'name') => {
  const doc = collection.find((doc) => doc[key] === cell);
  return doc ? doc[label] : '';
};

export const getFilter = (text, string) =>
  text.toLowerCase().includes(string.toLowerCase());

export const getSort = (a, b) => {
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

export const hideSensitiveData = (cell, characters) =>
  cell ? cell.replace(cell.substring(characters), '***') : '';

export const userName = (cell, users) => {
  const user = users.filter((user) => user.id === cell);
  return user.length ? user[0].displayName : '';
};

export const qualifiedName = (cell, formatted = false) => {
  let label,
    color = '';

  switch (cell) {
    case 'readyToQualify':
      label = 'READY';
      color = 'info';
      break;
    case 'completed':
      label = 'COMPLETED';
      color = 'success';
      break;
    case 'qualificationRefused':
      label = 'REFUSED';
      color = 'secondary';
      break;
    case 'error':
      label = 'ERROR';
      color = 'danger';
      break;
    case 'notNecessary':
      label = 'NOT NECESSARY';
      color = 'warning';
      break;
    default:
      label = cell ? cell.toUpperCase() : 'UNKNOWN';
      color = 'warning';
  }

  return formatted ? (
    <span className={`badge badge-${color} font-size-12 text-wrap`}>
      {label}
    </span>
  ) : (
    label
  );
};
