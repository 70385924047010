import { createSelector } from 'reselect';
import colors from '../components/CommonStyle/colors';
import { getDates, dateIsInRange } from '../helpers/sharedFunction';
import moment from 'moment';

export const totalLeads = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.leads).reduce((a, b) => a + b, 0),
);

export const totalWorkedLeads = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.workedLeads).reduce((a, b) => a + b, 0),
);

export const totalPositives = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.positiveLeads).reduce((a, b) => a + b, 0),
);

export const totalNegatives = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.negativeLeads).reduce((a, b) => a + b, 0),
);

export const totalFakes = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.fakeLeads).reduce((a, b) => a + b, 0),
);

export const totalNoTimings = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.leadsNoTiming).reduce((a, b) => a + b, 0),
);

export const totalCertified = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.leadsCertified)
      .reduce((a, b) => a + b, 0),
);

export const totalToQualify = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.leadsToQualify)
      .reduce((a, b) => a + b, 0),
);

export const totalRevenues = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
);

export const totalPositiveQualifiedCosts = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.positiveQualifiedCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalSupplierCosts = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.totalSupplierCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalCosts = createSelector(
  (state) => state.DailyPublisherInsights.insights,
  (insights) =>
    insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
);

export const selectDailyInsightsByCampaign = (campaignId) =>
  createSelector(
    (state) => state.DailyPublisherInsights.insights,
    (insights) =>
      insights.filter((insight) => insight.campaignId === campaignId),
  );

export const totalLeadsByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.leads).reduce((a, b) => a + b, 0),
  );

export const totalWorkedByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.workedLeads).reduce((a, b) => a + b, 0),
  );

export const totalPositivesByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.positiveLeads).reduce((a, b) => a + b, 0),
  );

export const totalNegativesByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.negativeLeads).reduce((a, b) => a + b, 0),
  );

export const totalFakesByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.fakeLeads).reduce((a, b) => a + b, 0),
  );

export const totalNoTimingsByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.leadsNoTiming).reduce((a, b) => a + b, 0),
  );

export const totalCertifiedByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights
      .map((insight) => insight.leadsCertified)
      .reduce((a, b) => a + b, 0),
  );

export const totalToQualifyByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights
      .map((insight) => insight.leadsToQualify)
      .reduce((a, b) => a + b, 0),
  );

export const totalRevenuesByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
  );

export const totalQualifiedCostsByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights
      .map((insight) => insight.qualifiedCosts)
      .reduce((a, b) => a + b, 0),
  );

export const totalSupplierCostsByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights
      .map((insight) => insight.totalSupplierCosts)
      .reduce((a, b) => a + b, 0),
  );

export const totalCostsByCampaign = (campaignId) =>
  createSelector(selectDailyInsightsByCampaign(campaignId), (insights) =>
    insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
  );

export const drawSeriesDailyGraph = (dateRange, t) =>
  createSelector(
    (state) => state.DailyPublisherInsights.insights,
    (insights) => {
      const { startDate, endDate } = dateRange;

      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        series: [
          {
            name: t('Leads'),
            data: [],
            type: 'line',
            color: colors.dark,
          },
          {
            name: t('Worked'),
            data: [],
            type: 'line',
            color: colors.warning,
          },
          {
            name: t('Revenues'),
            data: [],
            type: 'line',
            color: colors.success,
          },
        ],
        max: -9999999999,
        min: 99999999999,
      };

      dates.forEach((day) => {
        const startDay = moment(day).utc().startOf('day').toDate();
        const endDay = moment(day).utc().endOf('day').toDate();

        const dailyInsights = insights.filter((insight) =>
          dateIsInRange(new Date(insight.date), startDay, endDay),
        );

        const supplierCosts = dailyInsights
          .map((insight) => insight.totalSupplierCosts)
          .reduce((a, b) => a + b, 0);

        const leads = dailyInsights
          .map((insight) => insight.leads)
          .reduce((a, b) => a + b, 0);

        const workedLeads = dailyInsights
          .map((insight) => insight.workedLeads)
          .reduce((a, b) => a + b, 0);

        dataGraph.series[0].data.push([day.getTime(), leads]);
        dataGraph.series[1].data.push([day.getTime(), workedLeads]);
        dataGraph.series[2].data.push([day.getTime(), supplierCosts]);

        dataGraph.max = Math.max(dataGraph.max, leads, workedLeads);
        dataGraph.min = Math.min(dataGraph.min, leads, workedLeads);
      });
      return dataGraph;
    },
  );
