import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  countSmsMessages,
  smsUnsentCount,
  smsUndeliveredCount,
  totalCosts,
} from '../../selectors/smsMessage';

import MiniCard from '../Common/MiniCard';

const SmsMetrics = (props) => {
  const smsMessages = useSelector(countSmsMessages);
  const costs = useSelector(totalCosts);
  const smsUnsent = useSelector(smsUnsentCount);
  const smsUndelivered = useSelector(smsUndeliveredCount);
  const percentageErrorSmsMessage =
    ((smsUndelivered - smsUnsent) / smsMessages) * 100;

  const smsMetrics = [
    {
      title: 'SMS',
      text: smsMessages,
      color: 'info',
      icon: 'fas fa-sms',
    },
    {
      title: 'SMS Unsent',
      text: smsUnsent,
      color: 'warning',
      icon: 'fas fa-sms',
    },
    {
      title: 'Costs',
      text: `${costs.toFixed(2)} €`,
      color: 'secondary',
      icon: 'fas fa-money-bill-wave',
    },
    {
      title: 'SMS in error',
      text: `${
        isFinite(percentageErrorSmsMessage)
          ? percentageErrorSmsMessage.toFixed(2)
          : 0
      } %`,
      color: 'danger',
      icon: 'bx bx-error-circle',
    },
  ];

  return (
    <>
      {smsMetrics.map((metric, index) => (
        <Col md="3" key={'smsMetric_' + index}>
          <MiniCard {...metric} loading={props.loading} />
        </Col>
      ))}
    </>
  );
};

export default SmsMetrics;
