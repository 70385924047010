import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  personCreatedFrom: yup.date().required(),
  personCreatedTo: yup.date().required(),
  audienceId: yup.string().required(),
  messageTemplateId: yup.string().required(),
  creationDelay: yup.number().required().min(0),
  firstSendingDate: yup.date().required(),
  sendingTimeMessage: yup
    .string()
    .matches(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/)
    .required(),
  active: yup.boolean().required(),
  //setup: yup.string().required(),
});

export default formValidation;
