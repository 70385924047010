import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  includeMonetizationOrderIds: yup.array().notRequired(),
  excludeMonetizationOrderIds: yup.array().notRequired(),
  active: yup.boolean().required(),
  //setup: yup.string().required(),
});

export default formValidation;
