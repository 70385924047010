const insertItem = (array, item, index = 'id') => {
    let newArray = array.slice();
    newArray.splice(0, 0, item);
    return newArray;
  };
  
  const removeItem = (array, item, index = 'id', index2 = null) => {
    return !index2
      ? array.filter(arr => arr[index] !== item[index])
      : array.filter(arr => arr[index] !== item[index2]);
  };
  
  const addOrReplaceObjectInArray = (array, item, index = 'id') => {
    let replace = false;
    const newArray = array.map(arr => {
      if (arr[index] !== item[index]) {
        return arr;
      } else {
        replace = true;
        return {
          ...item,
        };
      }
    });
    return replace ? newArray : insertItem(array, item, index);
  };

  export {
    insertItem,
    removeItem,
    addOrReplaceObjectInArray,
  };