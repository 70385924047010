import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as customersActions from '../../store/customers/actions';
import { useDispatch } from 'react-redux';

import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormCustomer = (props) => {
  const editingCustomer = props.customer || {};

  const [internal, setInternal] = useState(editingCustomer.internal || 'yes');
  const [showRebate, setShowRebate] = useState(
    editingCustomer.showRebate || false,
  );
  const [isAgency, setIsAgency] = useState(editingCustomer.isAgency || false);

  const dispatch = useDispatch();

  const handleInternalField = (value) => {
    setInternal(value);
  };

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? customersActions.createCustomer({ ...values, showRebate })
        : customersActions.updateCustomer({
            id: editingCustomer.id,
            ...values,
            showRebate,
            isAgency,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingCustomer, internal, showRebate, isAgency }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col md="4">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="internal"
                    id="internal"
                    isMulti={false}
                    onChange={handleInternalField}
                    value={internal}
                    options={[
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        label: 'No',
                        value: 'no',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Collapse isOpen={internal === 'no'}>
                <Row>
                  <Col md="4">
                    <Input
                      type="text"
                      name="companyName"
                      id="companyName"
                      label="Company Name"
                    />
                  </Col>
                  <Col md="3">
                    <Input type="text" name="taxId" id="taxId" label="Tax ID" />
                  </Col>
                  <Col md="5">
                    <Input type="text" name="address" id="address" />
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input type="text" name="city" id="city" />
                  </Col>
                  <Col md="2">
                    <Input type="text" name="zip" id="zip" />
                  </Col>
                  <Col md="2">
                    <Input type="text" name="country" id="country" />
                  </Col>
                  <Col md="2">
                    <Switch
                      name="isAgency"
                      label="Is Agency?"
                      defaultChecked={isAgency}
                      onClick={() => setIsAgency(!isAgency)}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      name="showRebate"
                      label="Show Rebate?"
                      defaultChecked={isAgency}
                      onClick={() => setShowRebate(!showRebate)}
                    />
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCustomer;
