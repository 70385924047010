import types from './actionTypes';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';
import { personFilters } from '../../config/filters';

const initialState = {
  loading: false,
  people: [],
  startDate: null,
  endDate: null,
  filters: { ...personFilters },
  error: '',
};

const personReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PERSON_STATE:
      return initialState;
    case types.CREATE_PERSON.REQUEST:
    case types.FETCH_PEOPLE.REQUEST:
    case types.FETCH_PEOPLE_BY_AUDIENCE.REQUEST:
    case types.FETCH_PERSON_BY_ID.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_PERSON.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        people: [...state.people, action.person],
      };
    case types.FETCH_PEOPLE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        people: action.people,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.FETCH_PEOPLE_BY_AUDIENCE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        people: action.people,
      };
    case types.FETCH_PERSON_BY_ID.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        people: action.person
          ? addOrReplaceObjectInArray(state.people, action.person)
          : state.people,
      };
    case types.UPDATE_PERSON.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.CREATE_PERSON.FAILURE:
    case types.UPDATE_PERSON.FAILURE:
    case types.FETCH_PEOPLE.FAILURE:
    case types.FETCH_PEOPLE_BY_AUDIENCE.FAILURE:
    case types.FETCH_PERSON_BY_ID.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default personReducer;
