import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MARKETING_CAMPAIGN_STATE,
});

export const createMarketingCampaign = (marketingCampaign) => ({
  type: types.CREATE_MARKETING_CAMPAIGN.REQUEST,
  marketingCampaign,
});

export const createMarketingCampaignSuccess = (marketingCampaign) => ({
  type: types.CREATE_MARKETING_CAMPAIGN.SUCCESS,
  marketingCampaign,
});

export const createMarketingCampaignFailure = (error) => ({
  type: types.CREATE_MARKETING_CAMPAIGN.FAILURE,
  error,
});

export const updateMarketingCampaign = (marketingCampaign) => ({
  type: types.UPDATE_MARKETING_CAMPAIGN.REQUEST,
  marketingCampaign,
});

export const updateMarketingCampaignSuccess = () => ({
  type: types.UPDATE_MARKETING_CAMPAIGN.SUCCESS,
});

export const updateMarketingCampaignFailure = (error) => ({
  type: types.UPDATE_MARKETING_CAMPAIGN.FAILURE,
  error,
});

export const syncMarketingCampaigns = () => ({
  type: types.SYNC_MARKETING_CAMPAIGNS.REQUEST,
});

export const syncMarketingCampaignsSuccess = (marketingCampaigns) => ({
  type: types.SYNC_MARKETING_CAMPAIGNS.SUCCESS,
  marketingCampaigns,
});

export const syncMarketingCampaignsFailure = (error) => ({
  type: types.SYNC_MARKETING_CAMPAIGNS.FAILURE,
  error,
});
