import { createSelector } from 'reselect';

export const selectMonetizationOrder = (id) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      if (!id) return null;
      return monetizationOrders.find(
        (monetizationOrder) => monetizationOrder.id === id,
      );
    },
  );

export const selectMonetizationOrders = (ids = null) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      if (!ids || !Array.isArray(ids)) return monetizationOrders;
      return monetizationOrders.filter((monetizationOrder) =>
        ids.includes(monetizationOrder.id),
      );
    },
  );

export const selectActiveMonetizationOrders = (ids = null) =>
  createSelector(selectMonetizationOrders(ids), (monetizationOrders) =>
    monetizationOrders.filter((monetizationOrder) => monetizationOrder.active),
  );

export const selectMonetizationOrderWithSendType = (sendLeadType, ids = null) =>
  createSelector(selectMonetizationOrders(ids), (monetizationOrders) =>
    monetizationOrders.filter(
      (monetizationOrder) => monetizationOrder.sendLeadType === sendLeadType,
    ),
  );

export const selectActiveMonetizationOrderWithSendType = (
  sendLeadType,
  ids = null,
) =>
  createSelector(selectActiveMonetizationOrders(ids), (monetizationOrders) =>
    monetizationOrders.filter(
      (monetizationOrder) => monetizationOrder.sendLeadType === sendLeadType,
    ),
  );

export const selectMonetizationOrdersWithout = (ids) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      if (!ids || !Array.isArray(ids)) return monetizationOrders;
      return monetizationOrders.filter(
        (monetizationOrder) => !ids.includes(monetizationOrder.id),
      );
    },
  );

export const selectActiveMonetizationOrdersWithout = (ids) =>
  createSelector(selectMonetizationOrdersWithout(ids), (monetizationOrders) =>
    monetizationOrders.filter((monetizationOrder) => monetizationOrder.active),
  );

export const selectMonetizationOrdersBySendLeadType = (sendLeadType) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      return monetizationOrders.filter(
        (monetizationOrder) => monetizationOrder.sendLeadType === sendLeadType,
      );
    },
  );

export const selectMonetizationOrdersBySale = (
  supplierId,
  onlyActive = false,
) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      const selectedMonetizationOrders = monetizationOrders.filter(
        (monetizationOrder) => monetizationOrder.supplierId === supplierId,
      );
      return onlyActive
        ? selectedMonetizationOrders.filter(
            (monetizationOrder) => monetizationOrder.active,
          )
        : selectedMonetizationOrders;
    },
  );

export const countMonetizationOrdersBySale = (supplierId, onlyActive = false) =>
  createSelector(
    selectMonetizationOrdersBySale(supplierId, onlyActive),
    (monetizationOrders) => monetizationOrders.length,
  );

export const selectMonetizationOrdersByCustomer = (
  customerId,
  onlyActive = false,
) =>
  createSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
    (monetizationOrders) => {
      const selectedMonetizationOrders = monetizationOrders.filter(
        (monetizationOrder) => monetizationOrder.customerId === customerId,
      );
      return onlyActive
        ? selectedMonetizationOrders.filter(
            (monetizationOrder) => monetizationOrder.active,
          )
        : selectedMonetizationOrders;
    },
  );

export const countMonetizationOrdersByCustomer = (
  customerId,
  onlyActive = false,
) =>
  createSelector(
    selectMonetizationOrdersByCustomer(customerId, onlyActive),
    (monetizationOrders) => monetizationOrders.length,
  );

export const totalRevenues = createSelector(
  (state) => state.DailyMonetizationOrderInsights.insights,
  (insights) =>
    insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
);

export const totalPositiveQualifiedCosts = createSelector(
  (state) => state.DailyMonetizationOrderInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.positiveQualifiedCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalSupplierCosts = createSelector(
  (state) => state.DailyMonetizationOrderInsights.insights,
  (insights) =>
    insights
      .map((insight) => insight.totalSupplierCosts)
      .reduce((a, b) => a + b, 0),
);

export const totalCosts = createSelector(
  (state) => state.DailyMonetizationOrderInsights.insights,
  (insights) =>
    insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
);

export const selectDailyInsightsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    (state) => state.DailyMonetizationOrderInsights.insights,
    (insights) =>
      insights.filter(
        (insight) => insight.monetizationOrderId === monetizationOrderId,
      ),
  );

export const totalRevenuesByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.revenues).reduce((a, b) => a + b, 0),
  );

export const totalPositiveQualifiedCostsByMonetizationOrder = (
  monetizationOrderId,
) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights
        .map((insight) => insight.positiveQualifiedCosts)
        .reduce((a, b) => a + b, 0),
  );

export const totalSupplierCostsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights
        .map((insight) => insight.totalSupplierCosts)
        .reduce((a, b) => a + b, 0),
  );

export const totalCostsByMonetizationOrder = (monetizationOrderId) =>
  createSelector(
    selectDailyInsightsByMonetizationOrder(monetizationOrderId),
    (insights) =>
      insights.map((insight) => insight.totalCosts).reduce((a, b) => a + b, 0),
  );
