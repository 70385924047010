import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as campaignsActions from './actions';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  deleteField,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

const createLinkCampaign = (id, data) => {
  switch (data.source) {
    case 'facebook':
      return `https://business.facebook.com/adsmanager/manage/adsets?act=${data.accountId}&selected_campaign_ids=${id}`;
    case 'googleAds':
      return `https://ads.google.com/aw/overview?campaignId=${id}&ocid=600315594`;
    default:
      return '';
  }
};

function* createCampaignSaga({ campaign }) {
  try {
    const campaignsRef = collection(db, 'campaigns');
    const countryId = yield select((state) => state.Dashboard.countryId);

    if (!campaign.allowDuplicateLead) delete campaign.onlyQualifiedCompleted;

    yield call(rsf.firestore.addDocument, campaignsRef, {
      ...campaign,
      acquisitionOrderId: '',
      countryId,
      createdAt: serverTimestamp(),
    });
    yield put(campaignsActions.createCampaignSuccess(campaign));
    toastr.success('Campaign created!', '');
  } catch (error) {
    yield put(campaignsActions.createCampaignFailure(error));
  }
}

function* updateCampaignSaga({ campaign }) {
  try {
    const campaignsRef = doc(db, 'campaigns', campaign.id);
    delete campaign.id;

    yield call(
      rsf.firestore.setDocument,
      campaignsRef,
      {
        ...campaign,
        ...(!campaign.allowDuplicateLead && {
          onlyQualifiedCompleted: deleteField(),
        }),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(campaignsActions.updateCampaignSuccess(campaign));
    toastr.success('Campaign updated!', '');
  } catch (error) {
    yield put(campaignsActions.updateCampaignFailure(error));
  }
}

function* updateAcquisitionOrderIdSaga({
  campaignIds,
  prevCampaignIds,
  acquisitionOrderId,
}) {
  try {
    const linkAcquisitionOrderCampaigns = campaignIds.filter(
      (x) => !prevCampaignIds.includes(x),
    );
    const unlinkAcquisitionOrderCampaigns = prevCampaignIds.filter(
      (x) => !campaignIds.includes(x),
    );
    /*
    console.log("linkAcq: ", linkAcquisitionOrderCampaigns);
    console.log("unlinkAcq: ", unlinkAcquisitionOrderCampaigns);
    console.log("acquisitionOrderId: ", acquisitionOrderId);
    */
    yield all(
      linkAcquisitionOrderCampaigns.map((campaignId) =>
        call(
          rsf.firestore.setDocument,
          doc(db, 'campaigns', campaignId),
          {
            acquisitionOrderId,
          },
          { merge: true },
        ),
      ),
    );

    yield all(
      unlinkAcquisitionOrderCampaigns.map((campaignId) =>
        call(
          rsf.firestore.setDocument,
          doc(db, 'campaigns', campaignId),
          {
            acquisitionOrderId: '',
          },
          { merge: true },
        ),
      ),
    );
    yield put(campaignsActions.updateCampaignSuccess());
    toastr.success('Campaign updated!', '');
  } catch (error) {
    yield put(campaignsActions.updateCampaignFailure(error));
  }
}

function* syncFBCampaignsSaga({ facebookAdAccountIds }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);

    const functions = getFunctions();
    const updateFBCampaignsFunction = httpsCallable(
      functions,
      'updateFBCampaigns-updateFBCampaigns',
    );
    const { data } = yield call(updateFBCampaignsFunction, {
      facebookAdAccountIds,
      countryId,
    });

    if (data.error) throw new Error(data.error.message);

    yield put(campaignsActions.syncFBCampaignsSuccess());
    toastr.success(
      `Import ${data.data.newCampaignsCount} campaigns from Facebook!`,
      '',
    );
  } catch (error) {
    yield put(campaignsActions.syncFBCampaignsFailure(error));
    toastr.error(error.message, 'Error');
  }
}

const campaignTransformer = (payload) => {
  let campaigns = [];

  payload.forEach((campaign) => {
    const data = campaign.data();

    campaigns.push({
      id: campaign.id,
      ...data,
      link: createLinkCampaign(campaign.id, data),
      allowDuplicateLead: data.allowDuplicateLead ?? true,
      isLeadCampaign: data.isLeadCampaign ?? true,
      archived: data.archived || false,
      createdAt: toDateFirebase(campaign, data).toDate(),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(campaign, data, 'updatedAt').toDate(),
      }),
    });
  });

  return campaigns;
};

function* syncCampaignsSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const campaignsRef = query(
    collection(db, 'campaigns'),
    where('countryId', '==', countryId),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, campaignsRef, {
    successActionCreator: campaignsActions.syncCampaignsSuccess,
    failureActionCreator: campaignsActions.syncCampaignsFailure,
    transform: (payload) => campaignTransformer(payload),
  });

  yield take(types.RESET_CAMPAIGN_STATE);
  yield cancel(task);
}

function* campaignSaga() {
  yield all([
    takeLatest(types.SYNC_CAMPAIGNS.REQUEST, syncCampaignsSaga),
    takeLeading(types.CREATE_CAMPAIGN.REQUEST, createCampaignSaga),
    takeLeading(types.UPDATE_CAMPAIGN.REQUEST, updateCampaignSaga),
    takeLeading(
      types.UPDATE_ACQUISITION_ORDER_ID.REQUEST,
      updateAcquisitionOrderIdSaga,
    ),
    takeLeading(types.SYNC_FB_CAMPAIGNS.REQUEST, syncFBCampaignsSaga),
  ]);
}

export default campaignSaga;
