import {
  getFilter,
  getLabel,
  getSort,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (campaigns) => [
  {
    title: 'Campaign',
    name: 'campaignId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, campaigns),
    sorting: (a, b) => getSort(getLabel(a, campaigns), getLabel(b, campaigns)),
    filter: (value, filter) =>
      getFilter(getLabel(value, campaigns), filter.value),
  },
  {
    title: 'Day',
    name: 'date',
    formatterComponent: ({ value }) => moment(value).format('dddd, DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Leads',
    name: 'totalLeads',
  },
  {
    title: 'Spend',
    name: 'totalSpend',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
  },
  {
    title: 'Cost per Lead',
    name: 'costPerLead',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
  },
  {
    title: 'Google Ads Running',
    name: 'totalAdActiveCount',
  },
  {
    title: 'CTR',
    name: 'ctr',
    formatterComponent: ({ value }) => `${value ? value.toFixed(2) : 0} %`,
  },
  {
    title: 'Conversion Rate',
    name: 'conversionRate',
    formatterComponent: ({ value }) => `${value ? value.toFixed(2) : 0} %`,
  },
  {
    title: 'eCPC',
    name: 'averageCpc',
    formatterComponent: ({ value }) => `€ ${value ? value.toFixed(2) : 0}`,
  },
];

export default createTableColumns;
