import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import Alert from './Modal/Alert';
import { Bounce } from 'react-activity';
import useClipboard from 'react-hook-clipboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const URL =
  'https://us-central1-chiedimi-crm-dev.cloudfunctions.net/ws-ws/getFiscalCode';

const FiscalCodeAlert = (props) => {
  const { lead } = props;

  const [fiscalCode, setFiscalCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const [clipboard, copyToClipboard] = useClipboard();

  const getFiscalCode = async () => {
    const request = {
      firstName: lead.firstName,
      lastName: lead.lastName,
      gender: lead.gender === 'female' ? 'F' : 'M',
      birthday: moment(lead.birthday).format('YYYY-MM-DD'),
      birthPlaceCC: lead.birthPlaceCode.value,
    };

    const response = await fetch(URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
    setFiscalCode(await response.json());
  };

  const handleGenerateFiscalCode = async () => {
    setLoading(true);
    if (
      lead.firstName &&
      lead.lastName &&
      lead.birthPlaceCode &&
      lead.gender &&
      lead.birthday
    ) {
      await getFiscalCode();
    } else {
      setFiscalCode(
        'Set firstName, lastName, birthday, birth place and gender before generate fiscal code',
      );
    }
    setLoading(false);
  };

  const resetAlert = () => {
    setFiscalCode(null);
    setLoading(false);
  };

  const onClose = () => {
    resetAlert();
    props.onConfirm();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={props.isOpen}
      title={t('Generate fiscal code')}
      confirmBtnLabel="Close"
      onConfirm={onClose}>
      <>
        <h4 className="mt-2 mb-3 text-info">
          {lead.firstName + ' ' + lead.lastName}
        </h4>
        {!fiscalCode && !loading ? (
          <Button
            color="dark"
            outline
            className="waves-effect waves-light"
            onClick={handleGenerateFiscalCode}>
            <i className="mdi mdi-card-account-details font-size-20 align-middle mr-2"></i>
            {t('Generate')}
          </Button>
        ) : loading ? (
          <Bounce color="#50a5f1" size="50" />
        ) : (
          <div className="d-flex justify-content-center">
            <h5 className="mt-2">{fiscalCode.fiscalCode || t(fiscalCode)}</h5>
            {fiscalCode.fiscalCode && (
              <Button
                color="secondary"
                outline
                className="btn-sm waves-effect waves-light ml-2"
                onClick={() => copyToClipboard(fiscalCode.fiscalCode)}>
                <i className="bx bx-copy-alt align-middle mr-2"></i>
                {t('Copy')}
              </Button>
            )}
          </div>
        )}
      </>
    </Alert>
  );
};

export default FiscalCodeAlert;
