import { createSelector } from 'reselect';

export const selectSupplier = (id) =>
  createSelector(
    (state) => state.Supplier.suppliers,
    (suppliers) => {
      if (!id) return null;
      const supplier = suppliers.filter((supplier) => supplier.id === id);
      return supplier.length > 0 ? supplier[0] : null;
    },
  );

export const selectSupplierByType = (type) =>
  createSelector(
    (state) => state.Supplier.suppliers,
    (suppliers) =>
      suppliers.filter((supplier) => supplier.supplierType === type),
  );
