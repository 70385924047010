import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SURVEY_FORM_STATE,
});

export const createSurveyForm = (surveyForm) => ({
  type: types.CREATE_SURVEY_FORM.REQUEST,
  surveyForm,
});

export const createSurveyFormSuccess = (surveyForm) => ({
  type: types.CREATE_SURVEY_FORM.SUCCESS,
  surveyForm,
});

export const createSurveyFormFailure = (error) => ({
  type: types.CREATE_SURVEY_FORM.FAILURE,
  error,
});

export const updateSurveyForm = (surveyForm) => ({
  type: types.UPDATE_SURVEY_FORM.REQUEST,
  surveyForm,
});

export const updateSurveyFormSuccess = (surveyForm) => ({
  type: types.UPDATE_SURVEY_FORM.SUCCESS,
  surveyForm,
});

export const updateSurveyFormFailure = (error) => ({
  type: types.UPDATE_SURVEY_FORM.FAILURE,
  error,
});

export const syncSurveyForms = () => ({
  type: types.SYNC_SURVEY_FORMS.REQUEST,
});

export const syncSurveyFormsSuccess = (surveyForms) => ({
  type: types.SYNC_SURVEY_FORMS.SUCCESS,
  surveyForms,
});

export const syncSurveyFormsFailure = (error) => ({
  type: types.SYNC_SURVEY_FORMS.FAILURE,
  error,
});
