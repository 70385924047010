import React, { useState } from 'react';
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';
import Modal from '../Common/Modal/Modal';
import ResendMonetizationItems from './ResendMonetizationItems';

import { useTranslation } from 'react-i18next';

const BatchMonetizationItemBar = ({
  monetizationItems,
  selectedMonetizationItemIds,
}) => {
  const [duplicateModal, setResendModal] = useState(false);
  monetizationItems = monetizationItems.filter(({ id }) =>
    selectedMonetizationItemIds.includes(id),
  );
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`fixed-bottom ${
          selectedMonetizationItemIds.length ? 'visible' : 'invisible'
        }`}>
        <Navbar style={{ backgroundColor: '#2a3042' }} dark expand="md">
          <Container style={{ justifyContent: 'flex-start' }}>
            <NavbarBrand>{t('Manage')}:</NavbarBrand>
            <Nav pills>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="warning"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setResendModal(true)}>
                  <i
                    className={`bx bx-send font-size-20 align-middle mr-2`}></i>
                  {t('Resend Monetization Items')}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
      <Modal
        size="xl"
        title="Resend MonetizationItems"
        isOpen={duplicateModal}
        toggle={() => setResendModal(!duplicateModal)}
        showClose
        scrollable={false}>
        <ResendMonetizationItems monetizationItems={monetizationItems} />
      </Modal>
    </>
  );
};

export default BatchMonetizationItemBar;
