import React, { useEffect } from 'react';
import { Col, Card, CardBody, FormGroup } from 'reactstrap';
import { Form, Input } from '@availity/form';
import '@availity/yup';
import * as yup from 'yup';

import * as callsActions from '../../store/calls/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CreateNote = (props) => {
  const editingCall = props.call || {};

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      callsActions.updateCall({
        id: props.call.id,
        note: values.note,
      }),
    );
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Form
            initialValues={editingCall}
            validationSchema={yup.object().shape({
              note: yup.string().required(),
            })}
            onSubmit={handleSubmit}>
            {({ errors, isSubmitting, isValid, status, values }) => (
              <>
                <div
                  className="d-flex align-items-center mb-3"
                  style={{ justifyContent: 'space-between' }}>
                  <h4 className="card-title" style={{ margin: 0 }}>
                    {t('Note')}
                  </h4>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="btn btn-sm btn-outline-dark waves-effect">
                    <i
                      className={`bx bx-pencil font-size-16 align-middle mr-2`}></i>
                    {t('Save')}
                  </button>
                </div>
                <FormGroup>
                  <Input type="textarea" name="note" id="note" />
                </FormGroup>
              </>
            )}
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CreateNote;
