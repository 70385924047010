import { serverTimestamp } from 'firebase/firestore';
import { DDMMYYYYToDate, toTitleCase, normalizePhone } from '../sharedFunction';

const createNote = (lead) => {
  return `- Atc: ${lead['atc'].trim() || ''}
- Prodotto: ${lead['PRODOTTO'].trim() || ''}
- Montante (€): ${lead['MONTANTE'].trim() || ''}
- TAN: ${lead['TAN'].trim() || ''}
- TAEG: ${lead['TAEG'].trim() || ''}
- Durata (mesi): ${lead['Durata'].trim() || ''}
- Età a scadenza: ${lead["ETA' A SCADENZA"].trim() || ''}`;
};

const import251 = (lead) => {
  /*if (!lead['Email'] || lead['Email'] === '')
    throw new Error(`Email is required!`);*/

  if (!lead['Telefono'] || lead['Telefono'].trim() === '')
    throw new Error(`Phone is required!`);

  const birthday =
    lead['DATA NASCITA'].trim() !== ''
      ? DDMMYYYYToDate(lead['DATA NASCITA'].trim(), '-')
      : null;

  return {
    firstName: toTitleCase(lead['NOME'].trim()),
    lastName: toTitleCase(lead['COGNOME'].trim()),
    ...(lead['CF'] &&
      lead['CF'].trim() !== '' && { fiscalCode: lead['CF'].trim() }),
    ...(birthday && { birthday }),
    phone: normalizePhone(lead['Telefono']),
    note: createNote(lead),
    createdAt: serverTimestamp(),
  };
};

export default import251;
