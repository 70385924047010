import { createSelector } from 'reselect';
import { smsDeliveredCount } from '../selectors/smsMessage';

export const uniqSurveyName = createSelector(
  (state) => state.Survey.surveys,
  (surveys) => {
    if (surveys.length > 0) {
      let surveyNames = surveys.map((survey) => survey.surveyName);
      return Array.from(new Set(surveyNames));
    }
    return null;
  },
);

export const surveysCount = createSelector(
  (state) => state.Survey.surveys,
  (surveys) => surveys.length,
);

export const completedSurveysCount = createSelector(
  (state) => state.Survey.surveys,
  (surveys) => surveys.filter((survey) => survey.completed === 'yes').length,
);

export const partialSurveysCount = createSelector(
  (state) => state.Survey.surveys,
  (surveys) => surveys.filter((survey) => survey.completed === 'no').length,
);

export const percentageEngagementRate = createSelector(
  surveysCount,
  smsDeliveredCount,
  (surveys, sms) => (surveys / sms) * 100,
);

export const percentageCompletionRate = createSelector(
  surveysCount,
  completedSurveysCount,
  (surveys, completed) => (completed / surveys) * 100,
);

export const selectSurveyByLead = (leadId) =>
  createSelector(
    (state) => state.Survey.surveys,
    (surveys) => {
      if (!leadId) return null;
      const survey = surveys.filter((survey) => survey.leadId === leadId);
      return survey.length > 0 ? survey[0] : null;
    },
  );
