import React, { useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { userPublisherId } from '../../selectors/auth';
import { countCampaignsByPublisher } from '../../selectors/campaign';
import * as dailyInsightsActions from '../../store/suppliers/dailyPublisherInsights/actions';

// Pages Components
import PublisherMetrics from '../../components/Suppliers/PublisherMetrics';
import RevenuesGraph from '../../components/Dashboard/Publisher/RevenuesGraph';
import CampaignsInsights from '../../components/Dashboard/Publisher/CampaignsInsights';
import UserCard from '../../components/Common/UserCard';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const PublisherDashboard = (props) => {
  const supplierId = useSelector(userPublisherId);
  const countCampaigns = useSelector(countCampaignsByPublisher(supplierId));
  const countActiveCampaigns = useSelector(
    countCampaignsByPublisher(supplierId, true),
  );

  const loading = useSelector((state) => state.DailyPublisherInsights.loading);

  const startDate = useSelector(
    (state) => state.DailyPublisherInsights.startDate,
  );
  const endDate = useSelector((state) => state.DailyPublisherInsights.endDate);
  const filters = {
    supplierId,
    campaignIds: null,
  };

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc().startOf('month'),
    endDate: momentEndDate || moment.utc(),
    supplierId: filters?.supplierId || supplierId || '',
    campaignIds: filters?.campaignIds || null,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, supplierId, campaignIds }) => {
      const selectedStartDate = startDate.utc().startOf('day').toDate();
      const selectedEndDate = endDate.utc().endOf('day').toDate();
      dispatch(
        dailyInsightsActions.fetchDailyInsights(
          selectedStartDate,
          selectedEndDate,
          {
            campaignIds,
            supplierId,
          },
        ),
      );
      setSelectedFilters({
        startDate,
        endDate,
        campaignIds,
        supplierId,
      });
    },
  );

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        title={t('Dashboard')}
        onRefresh={() => refresh(selectedFilters)}
        loading={loading}
      />
      <Row>
        <Col md="12">
          <UserCard
            firstBoxLabel="Total Campaigns"
            firstBoxValue={countCampaigns}
            secondBoxLabel="Active Campaigns"
            secondBoxValue={countActiveCampaigns}
            pathLink="/suppliers/dailyPublisherInsights"
            iconLink="bx bx-bar-chart-square"
            textLink="Daily Insights"
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <DateRangeFilter
            onUpdate={refresh}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
          />
        </Col>
      </Row>

      <Row>
        <PublisherMetrics />
      </Row>
      <Row>
        <Col md="12">
          <RevenuesGraph dateRange={selectedFilters} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <CampaignsInsights supplierId={supplierId} />
        </Col>
      </Row>
    </>
  );
};

export default PublisherDashboard;
