import React, { useState } from 'react';
import * as callsActions from '../../../store/calls/actions';
import * as leadsActions from '../../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../Common/Modal/Alert';
import { useTranslation } from 'react-i18next';

const RecallAlert = ({ call, isOpen, onCancel }) => {
  const [disabledAlertBtn, setDisabledAlertBtn] = useState(false);
  const userId = useSelector((state) => state.Auth.admin.id);

  const dispatch = useDispatch();

  const handleRecall = () => {
    setDisabledAlertBtn(true);
    dispatch(
      callsActions.updateCall({
        id: call.id,
        outcome: 'recall',
        status: 'closed',
        endDateCall: new Date(),
        userId,
      }),
    );
    dispatch(
      leadsActions.updateLead({
        id: call.leadId,
        qualified: 'readyToQualify',
      }),
    );
    onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={isOpen}
      title={t('Nobody answered the call?')}
      confirmBtnLabel="Confirm"
      onCancel={onCancel}
      onConfirm={handleRecall}
      disabledBtn={disabledAlertBtn}
    />
  );
};

export default RecallAlert;
