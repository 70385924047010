import { serverTimestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const createNote = (lead) => {
  return `- Filiale: ${lead['DESCRIZIONE_FILIALE'] || ''}
- Data richiesta: ${lead['data_creazione'] || ''}`;
};

const compassRecallImportLead = (lead) => ({
  firstName: toTitleCase(lead['nome'].trim()),
  lastName: toTitleCase(lead['cognome'].trim()),
  ...(lead['codice_fiscale'] &&
    lead['codice_fiscale'].trim() !== '' && {
      fiscalCode: lead['codice_fiscale'].trim(),
    }),
  phone: normalizePhone(lead['cell'].trim()),
  ...(lead['contact_id'] &&
    lead['contact_id'].trim() !== '' && {
      customerId: lead['contact_id'].trim(),
    }),
  ...(lead['email'] && lead['email'].trim() !== '' && { email: lead['email'] }),
  ...(lead['cap'] && lead['cap'].trim() !== '' && { zipCode: lead['cap'] }),
  note: createNote(lead),
  createdAt: serverTimestamp(),
});

export default compassRecallImportLead;
