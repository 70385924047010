const statsTypes = {
  RESET_SURVEY_STATE: 'RESET_SURVEY_STATE',
  CREATE_SURVEY: {
    REQUEST: 'CREATE_SURVEY.REQUEST',
    SUCCESS: 'CREATE_SURVEY.SUCCESS',
    FAILURE: 'CREATE_SURVEY.FAILURE',
  },
  UPDATE_SURVEY: {
    REQUEST: 'UPDATE_SURVEY.REQUEST',
    SUCCESS: 'UPDATE_SURVEY.SUCCESS',
    FAILURE: 'UPDATE_SURVEY.FAILURE',
  },
  FETCH_SURVEYS: {
    REQUEST: 'FETCH_SURVEYS.REQUEST',
    SUCCESS: 'FETCH_SURVEYS.SUCCESS',
    FAILURE: 'FETCH_SURVEYS.FAILURE',
  },
  FETCH_SURVEY_BY_LEAD: {
    REQUEST: 'FETCH_SURVEY_BY_LEAD.REQUEST',
    SUCCESS: 'FETCH_SURVEY_BY_LEAD.SUCCESS',
    FAILURE: 'FETCH_SURVEY_BY_LEAD.FAILURE',
  },
  IMPORT_PARTIAL_SURVEYS: {
    REQUEST: 'IMPORT_PARTIAL_SURVEYS.REQUEST',
    SUCCESS: 'IMPORT_PARTIAL_SURVEYS.SUCCESS',
    FAILURE: 'IMPORT_PARTIAL_SURVEYS.FAILURE',
  },
};

export default statsTypes;
