import React from 'react';
import {
  ViewButton,
  EditButton,
  ViewLeadButton,
} from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const getInterests = (cell) => (cell.length > 0 ? cell.join(', ') : '');

const createTableColumns = (
  openViewModal,
  openEditModal,
  openLeadModal,
  surveyForms,
) => {
  return [
    {
      title: 'Survey Name',
      name: 'surveyFormId',
      formatterComponent: ({ value }) => getLabel(value, surveyForms),
      sorting: (a, b) =>
        getSort(getLabel(a, surveyForms), getLabel(b, surveyForms)),
      filter: (value, filter) =>
        getFilter(getLabel(value, surveyForms), filter.value),
    },
    {
      title: 'Interests',
      name: 'interests',
      formatterComponent: ({ value }) => getInterests(value),
      sorting: (a, b) => getSort(getInterests(a), getInterests(b)),
      filter: (value, filter) => getFilter(getInterests(value), filter.value),
    },
    {
      title: 'Car Owner',
      name: 'carOwner',
    },
    {
      title: 'Homeowner',
      name: 'homeowner',
    },
    {
      title: 'Children',
      name: 'children',
    },
    {
      title: 'Marital Status',
      name: 'maritalStatus',
      hidden: true,
    },
    {
      title: 'Device Type',
      name: 'deviceType',
      hidden: true,
    },
    {
      title: 'Completed',
      name: 'completed',
      formatterComponent: ({ value }) =>
        value === 'yes' ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
    },
    {
      title: 'Created At',
      name: 'createdAt',
      width: 120,
      formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: (value, filter) =>
        getFilter(`${moment(value).fromNow()}`, filter.value),
    },
    {
      title: 'Action',
      name: 'action',
      width: 150,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
          <ViewLeadButton row={row} onPress={openLeadModal} />
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
