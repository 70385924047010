import { serverTimestamp } from 'firebase/firestore';
import { DDMMYYYYToDate, toTitleCase } from '../sharedFunction';

const createNote = (lead) => {
  return `- CategoriaLav1: ${lead['CategoriaLav1'] || ''}
- CategoriaLav2: ${lead['CategoriaLav2'] || ''}
- CategoriaLav3: ${lead['CategoriaLav3'] || ''}
${lead['Link'] ? `- Link: ${lead['Link']}` : ''}`;
};

const iblRestartImportLead = (lead) => {
  /*if (!lead['Email'] || lead['Email'] === '')
    throw new Error(`Email is required!`);*/

  if (!lead['Telefono'] || lead['Telefono'] === '')
    throw new Error(`Phone is required!`);

  const birthday =
    lead['Data Nascita'] !== '00/01/1900'
      ? DDMMYYYYToDate(lead['Data Nascita'], '/')
      : null;

  return {
    firstName: toTitleCase(lead['Nome']),
    lastName: toTitleCase(lead['Cognome']),
    ...(lead['Email'] && lead['Email'] !== '' && { email: lead['Email'] }),
    ...(lead['Cap'] &&
      lead['Cap'] !== '' &&
      lead['Cap'] !== '0' && { zipCode: lead['Cap'] }),
    ...(birthday && { birthday }),
    phone:
      lead['Telefono'].substr(0, 2) === '00'
        ? `+${lead['Telefono'].substr(2)}`
        : lead['Telefono'],
    note: createNote(lead),
    createdAt: serverTimestamp(),
  };
};

export default iblRestartImportLead;
