import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

const ShowNote = ({ acquisitionOrder }) => {
  const HTMLNote = EditorState.createWithContent(
    convertFromRaw(JSON.parse(acquisitionOrder.note)),
  );
  const RenderNote = () => (
    <>{parse(draftToHtml(convertToRaw(HTMLNote.getCurrentContent())))}</>
  );

  return (
    <>
      <Col md="12">
        <Card>
          <CardBody>
            <RenderNote />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ShowNote;
