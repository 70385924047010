import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexRadial = (props) => {
    const options = {
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '14px',
                        color: void 0,
                        offsetY: 60,
                    },
                    value: {
                        offsetY: 22,
                        fontSize: '16px',
                        color: void 0,
                        formatter: (value) => {
                            return value + '%';
                        },
                    },
                },
            },
        },
        colors: props.colors,
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: !1,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91],
            },
        },
        stroke: {
            dashArray: 4,
        },
        labels: props.labels,
        chart: {
            fontFamily: '"Poppins", sans-serif',
        }
    };
    const series = props.series;

    return (
        <>
            <ReactApexChart
                options={options}
                series={series}
                type="radialBar"
                height="160"
            />
        </>
    );
};

export default ApexRadial;
