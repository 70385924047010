import { createSelector } from 'reselect';

export const selectAudienceBySetup = (setup) =>
  createSelector(
    (state) => state.Audience.audiences,
    (audiences) => {
      if (!setup) return null;
      return audiences.filter((audience) => audience.setup === setup);
    },
  );

export const selectAudienceByState = (state) =>
  createSelector(
    (state) => state.Audience.audiences,
    (audiences) => {
      if (!state) return null;
      return audiences.filter((audience) => audience.state === state);
    },
  );
