import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SMS_MESSAGE_STATE,
});

export const createSmsMessage = (smsMessage) => ({
  type: types.CREATE_SMS_MESSAGE.REQUEST,
  smsMessage,
});

export const createSmsMessageSuccess = (smsMessage) => ({
  type: types.CREATE_SMS_MESSAGE.SUCCESS,
  smsMessage,
});

export const createSmsMessageFailure = (error) => ({
  type: types.CREATE_SMS_MESSAGE.FAILURE,
  error,
});

export const fetchSmsMessages = (startDate, endDate) => ({
  type: types.FETCH_SMS_MESSAGES.REQUEST,
  startDate,
  endDate,
});

export const fetchSmsMessagesSuccess = (smsMessages, startDate, endDate) => ({
  type: types.FETCH_SMS_MESSAGES.SUCCESS,
  smsMessages,
  startDate,
  endDate,
});

export const fetchSmsMessagesFailure = (error) => ({
  type: types.FETCH_SMS_MESSAGES.FAILURE,
  error,
});
