import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SUPPLIER_STATE,
});

export const createSupplier = (supplier) => ({
  type: types.CREATE_SUPPLIER.REQUEST,
  supplier,
});

export const createSupplierSuccess = (supplier) => ({
  type: types.CREATE_SUPPLIER.SUCCESS,
  supplier,
});

export const createSupplierFailure = (error) => ({
  type: types.CREATE_SUPPLIER.FAILURE,
  error,
});

export const updateSupplier = (supplier) => ({
  type: types.UPDATE_SUPPLIER.REQUEST,
  supplier,
});

export const updateSupplierSuccess = (supplier) => ({
  type: types.UPDATE_SUPPLIER.SUCCESS,
  supplier,
});

export const updateSupplierFailure = (error) => ({
  type: types.UPDATE_SUPPLIER.FAILURE,
  error,
});

export const syncSuppliers = () => ({
  type: types.SYNC_SUPPLIERS.REQUEST,
});

export const syncSuppliersSuccess = (suppliers) => ({
  type: types.SYNC_SUPPLIERS.SUCCESS,
  suppliers,
});

export const syncSuppliersFailure = (error) => ({
  type: types.SYNC_SUPPLIERS.FAILURE,
  error,
});
