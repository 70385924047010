import React, { useState } from 'react';
import SweetAlert from '../../../lib/react-bootstrap-sweetalert/src';
import { useTranslation } from 'react-i18next';

const Alert = (props) => {
  const { t } = useTranslation();

  return props.isOpen ? (
    <SweetAlert
      custom
      showCancel
      customIcon={props.mainIcon || ''}
      title={props.title}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      closeOnClickOutside={false}
      customButtons={
        <>
          {props.onCancel && (
            <button
              type="button"
              className="btn btn-danger w-md waves-effect waves-light"
              style={{ marginRight: '10px' }}
              disabled={!!props.disabledBtn}
              onClick={props.onCancel}>
              {t('Cancel')}
            </button>
          )}
          {props.onConfirm && (
            <button
              type="button"
              className="btn btn-primary w-xs waves-effect waves-light"
              onClick={props.onConfirm}
              disabled={!!props.disabledBtn}>
              {t(props.confirmBtnLabel)}
            </button>
          )}
        </>
      }>
      {props.children || ''}
    </SweetAlert>
  ) : (
    <></>
  );
};

export default Alert;
