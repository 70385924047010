import React, { useEffect, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { selectMonetizationOrdersByCustomer } from '../../selectors/monetizationOrder';
import { userCustomerId } from '../../selectors/auth';

import * as customersActions from '../../store/customers/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';

import { useTranslation } from 'react-i18next';

const MAX_SELECTED_MONETIZATION_ORDERS = 10;

const FilterCustomersBox = ({ initialFilters, onUpdate }) => {
  const [customerId, setCustomerId] = useState(
    initialFilters?.customerId || '',
  );
  const [monetizationOrderIds, setMonetizationOrderIds] = useState(
    initialFilters?.monetizationOrderIds || null,
  );

  const adminCustomerId = useSelector(userCustomerId);
  const customers = useSelector((state) => state.Customer.customers);
  const monetizationOrders = useSelector(
    selectMonetizationOrdersByCustomer(customerId),
  );

  const selectedCustomers = adminCustomerId
    ? customers.filter((customer) => customer.id === adminCustomerId)
    : customers;

  const customersForSelect = selectedCustomers.map((customer) => ({
    label: customer.name,
    value: customer.id,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customersActions.syncCustomers());
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const handleFilters = (value, field, setFieldValue) => {
    if (field === 'customerId') {
      setCustomerId(value);
      setMonetizationOrderIds(null);
      setFieldValue('monetizationOrderIds', null);
    } else setMonetizationOrderIds(value);

    onUpdate(
      field === 'customerId' ? value : customerId,
      field === 'customerId'
        ? null
        : field === 'monetizationOrderIds'
        ? value
        : monetizationOrderIds,
    );
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ customerId, monetizationOrderIds }}
        validationSchema={yup.object().shape({
          customerId: yup.string(),
          monetizationOrderIds: yup.array().nullable(),
        })}
        className="d-flex">
        {({ setFieldValue }) => (
          <>
            <Col md="3" className="d-flex align-items-center px-0">
              <h5 className="text-muted m-0 mr-2">{t('Customer')}:</h5>
              <div className="flex-fill">
                <Select
                  name="customerId"
                  id="customerId"
                  isMulti={false}
                  value={customerId}
                  onChange={(value) =>
                    handleFilters(value, 'customerId', setFieldValue)
                  }
                  options={
                    customersForSelect.length === 1
                      ? customersForSelect
                      : [{ label: t('All'), value: '' }, ...customersForSelect]
                  }
                />
              </div>
            </Col>
            <Col md="9" className="d-flex align-items-center px-0">
              <h5 className="text-muted my-0 ml-5 mr-2">
                {t('Monetization Orders')}:
              </h5>
              <div className="flex-fill">
                <Select
                  name="monetizationOrderIds"
                  id="monetizationOrderIds"
                  isMulti
                  maxLength={MAX_SELECTED_MONETIZATION_ORDERS}
                  value={monetizationOrderIds}
                  onChange={(values) =>
                    handleFilters(values, 'monetizationOrderIds', setFieldValue)
                  }
                  options={monetizationOrders.map((monetizationOrder) => ({
                    label: monetizationOrder.name,
                    value: monetizationOrder.id,
                  }))}
                  isDisabled={customerId === ''}
                />
              </div>
            </Col>
          </>
        )}
      </Form>
    </CardBody>
  );
};

export default FilterCustomersBox;
