import {
  getStorage,
  ref,
  uploadString as fireUploadString,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL as fireGetDownloadURL,
  getMetadata,
  updateMetadata,
} from 'firebase/storage';
import { call } from 'redux-saga/effects';

export const getRef = (rsf, pathOrRef) => {
  const storage = getStorage(rsf.app);
  return typeof pathOrRef === 'string' ? ref(storage, pathOrRef) : pathOrRef;
};

function uploadFile(pathOrRef, file, metadata) {
  const ref = getRef(this, pathOrRef);
  const task = uploadBytesResumable(ref, file, metadata);
  return task;
}

function* uploadString(pathOrRef, string, format, metadata) {
  const ref = getRef(this, pathOrRef);
  return yield call(fireUploadString, ref, string, format, metadata);
}

function* getDownloadURL(pathOrRef) {
  const ref = getRef(this, pathOrRef);
  const url = yield call(fireGetDownloadURL, ref);
  return url;
}

function* getFileMetadata(pathOrRef) {
  const ref = getRef(this, pathOrRef);
  const metadata = yield call(getMetadata, ref);
  return metadata;
}

function* updateFileMetadata(pathOrRef, newMetadata) {
  const ref = getRef(this, pathOrRef);
  const metadata = yield call(updateMetadata, ref, newMetadata);
  return metadata;
}

function* deleteFile(pathOrRef) {
  const ref = getRef(this, pathOrRef);
  yield call(deleteObject, ref);
}

export default {
  uploadFile,
  uploadString,
  getDownloadURL,
  getFileMetadata,
  updateFileMetadata,
  deleteFile,
};
