import React from 'react';
import { Button, Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ReadyToStart = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const countryId = useSelector((state) => state.Dashboard.countryId);
  const error = useSelector((state) => state.NextCall.error);

  const hasAcquisitionOrders = !!(
    user.acquisitionOrderIds &&
    Array.isArray(user.acquisitionOrderIds[countryId])
  );

  const { t } = useTranslation();
  return (
    <>
      {error !== '' && <Alert color="danger">{error}</Alert>}
      <div className="hori-timeline">
        <div
          className="owl-carousel owl-theme  navs-carousel events"
          id="timeline-carousel">
          <div className="item event-list">
            <div>
              <div className="event-date">
                <div className="text-primary font-size-16 mb-3">
                  {moment().format('dddd, MMMM D YYYY')}
                </div>
                {props.start ? (
                  <h4 className="mb-4">
                    {`${
                      moment().hours() <= 12
                        ? t('Good morning')
                        : t('Good afternoon')
                    }, ${t('ready to start?')}`}
                  </h4>
                ) : (
                  <h5 className="mb-4">
                    {t('Any call is available at this moment, please retry')}
                  </h5>
                )}
              </div>
              <div className="event-down-icon">
                <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
              </div>

              <div className="mt-3 px-3">
                <Button
                  color="info"
                  type="button"
                  disabled={!hasAcquisitionOrders}
                  onClick={props.onStart}
                  className="btn btn-lg btn-info waves-effect waves-light">
                  {props.start && error === '' ? t("Let's go!") : t('Retry')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadyToStart;
