import React from 'react';
import { ViewButton } from '../Common/DataTable/tableActionButtons';
import { DisablePersonButton } from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabels,
  getSort,
  hideSensitiveData,
} from '../Common/DataTable/tableHelpers';
import moment from 'moment';

const countPushTokens = (cell) => (Array.isArray(cell) ? cell.length : 0);

const createTableColumns = (
  audiences,
  openViewModal,
  openDisablePersonAlert,
  showSensitiveData,
) => [
  {
    title: 'Name',
    width: 170,
    name: 'name',
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 4),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
  },
  {
    title: 'Phone',
    width: 120,
    name: 'phone',
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 6),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
  },
  {
    title: 'Gender',
    name: 'gender',
    width: 100,
    hidden: true,
  },
  {
    title: 'City',
    name: 'cityName',
    width: 120,
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 4),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
    hidden: true,
  },
  {
    title: 'Occupation',
    name: 'occupation',
    width: 120,
    hidden: true,
  },
  {
    title: 'Phone verified',
    name: 'verifiedPhoneNumber',
    width: 90,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) =>
      getFilter(!value || value === 'yes' ? 'yes' : 'no', filter.value),
    sorting: (a, b) =>
      getSort(
        !a || a === 'yes' ? 'yes' : 'no',
        !b || b === 'yes' ? 'yes' : 'no',
      ),
  },
  {
    title: 'Marketing Consent',
    name: 'marketingConsent',
    width: 90,
  },
  {
    title: 'Third Party Consent',
    name: 'thirdPartyConsent',
    width: 90,
  },
  {
    title: 'Push Tokens',
    name: 'pushTokens',
    width: 70,
    formatterComponent: ({ value, row }) => countPushTokens(value),
    sorting: (a, b) => getSort(countPushTokens(a), countPushTokens(b)),
    filter: (value, filter) => getFilter(countPushTokens(value), filter.value),
    hidden: true,
  },
  {
    title: 'Leads',
    width: 70,
    name: 'leadCount',
    hidden: true,
  },
  {
    title: 'Audiences',
    name: 'audienceIds',
    width: 180,
    formatterComponent: ({ value }) => getLabels(value, audiences),
    sorting: (a, b) =>
      getSort(getLabels(a, audiences), getLabels(b, audiences)),
    filter: (value, filter) =>
      getFilter(getLabels(value, audiences), filter.value),
  },
  {
    title: 'Disabled',
    name: 'disabled',
    width: 70,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-warning font-size-12">NO</span>
      ),
    filter: (value, filter) =>
      getFilter(!value || value === 'yes' ? 'yes' : 'no', filter.value),
    sorting: (a, b) =>
      getSort(
        !a || a === 'yes' ? 'yes' : 'no',
        !b || b === 'yes' ? 'yes' : 'no',
      ),
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(`${moment(value).fromNow()}`, filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 100,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        {openDisablePersonAlert && !row.disabled && (
          <DisablePersonButton row={row} onPress={openDisablePersonAlert} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
