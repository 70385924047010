import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_LEAD_FORM_STATE,
});

export const createLeadForm = (leadForm) => ({
  type: types.CREATE_LEAD_FORM.REQUEST,
  leadForm,
});

export const createLeadFormSuccess = (leadForm) => ({
  type: types.CREATE_LEAD_FORM.SUCCESS,
  leadForm,
});

export const createLeadFormFailure = (error) => ({
  type: types.CREATE_LEAD_FORM.FAILURE,
  error,
});

export const updateLeadForm = (leadForm) => ({
  type: types.UPDATE_LEAD_FORM.REQUEST,
  leadForm,
});

export const updateLeadFormSuccess = (leadForm) => ({
  type: types.UPDATE_LEAD_FORM.SUCCESS,
  leadForm,
});

export const updateLeadFormFailure = (error) => ({
  type: types.UPDATE_LEAD_FORM.FAILURE,
  error,
});

export const syncLeadForms = () => ({
  type: types.SYNC_LEAD_FORMS.REQUEST,
});

export const syncLeadFormsSuccess = (leadForms) => ({
  type: types.SYNC_LEAD_FORMS.SUCCESS,
  leadForms,
});

export const syncLeadFormsFailure = (error) => ({
  type: types.SYNC_LEAD_FORMS.FAILURE,
  error,
});

export const sortLeadForms = (leadForms) => ({
  type: types.SORT_LEAD_FORMS.REQUEST,
  leadForms,
});

export const sortLeadFormsSuccess = () => ({
  type: types.SORT_LEAD_FORMS.SUCCESS,
});

export const sortLeadFormsFailure = (error) => ({
  type: types.SORT_LEAD_FORMS.FAILURE,
  error,
});
