import React, { useState, useEffect } from 'react';
import { Media, Spinner } from 'reactstrap';
import { deleteField } from 'firebase/firestore';

import * as callActions from '../../store/calls/actions';
import { selectLead } from '../../selectors/lead';
import { selectAcquisitionOrdersByLead } from '../../selectors/acquisitionOrder';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../CommonStyle/colors';

const ManagePhone = (props) => {
  const call = useSelector((state) => state.NextCall.nextCall);
  const inbound = call?.inbound;
  const lead = useSelector(selectLead(call?.leadId || null));
  const user = useSelector((state) => state.Auth.admin);
  const acquisitionOrder = useSelector(selectAcquisitionOrdersByLead(lead));

  const loading = useSelector((state) => state.Leads.loading);

  const gender = lead?.gender === 'male' ? 'fas fa-male' : 'fas fa-female';

  const initialStatusCall = {
    status: 'Not ready',
    circle: 'danger',
    phoneColor: 'bg-secondary',
    phoneIcon: 'fas fa-phone-slash',
    phoneURL: '',
    pointerEvents: 'none',
  };

  const [statusCall, setStatusCall] = useState(initialStatusCall);

  const phoneURL = `http://192.168.1.3/hash2/hotdial/anonymous/?dialnumber=${
    lead?.phone ? lead.phone.substring(3) : ''
  }&bridgexten=${user.phoneExchangeId}&route=${encodeURI(
    acquisitionOrder?.phoneExchangeRoute || '#',
  )}`;

  const handleStatusCall = (call) => {
    if (!call || call === 'none' || !lead)
      return setStatusCall(initialStatusCall);
    if (call && call.status === 'closed')
      return setStatusCall({
        status: 'Ended',
        circle: 'secondary',
        phoneColor: 'bg-secondary',
        phoneIcon: 'fas fa-phone-slash',
        phoneURL,
        pointerEvents: 'none',
      });
    if (call && call.endDateCall)
      return setStatusCall({
        status: 'Ended',
        circle: 'secondary',
        phoneColor: 'bg-secondary',
        phoneIcon: 'fas fa-phone-alt',
        phoneURL,
        pointerEvents: 'initial',
      });
    if (call && call.startDateCall)
      return setStatusCall({
        status: 'On Call',
        circle: 'success',
        phoneColor: 'bg-danger',
        phoneIcon: 'fas fa-phone-slash',
        phoneURL: '#',
        pointerEvents: 'initial',
      });
    if (call && !call.startDateCall) {
      return setStatusCall({
        status: 'Ready',
        circle: 'warning',
        phoneColor: 'bg-success',
        phoneIcon: 'fas fa-phone-alt',
        phoneURL,
        //http://87.26.120.204:8080/hash2/hotdial/anonymous/?dialnumber=3389104309&bridgexten=20&route=rotta%20default
        //http://192.168.1.3:8080/hash2/hotdial/anonymous/?dialnumber=3389104309&bridgexten=20&route=rotta%20default
        pointerEvents: 'initial',
      });
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    handleStatusCall(call);
  }, [call, lead]);

  useEffect(() => {
    inbound &&
      statusCall.status === 'Ready' &&
      dispatch(
        callActions.updateCall({ id: call.id, startDateCall: new Date() }),
      );
  }, [call]);

  const handlePhone = () => {
    if (statusCall.status === 'Ready') {
      /*fetch(statusCall.phoneURL) //Voice@work
        .then(response => console.log("Status: ", response.status));*/
      dispatch(
        callActions.updateCall({ id: call.id, startDateCall: new Date() }),
      );
    } else if (statusCall.status === 'On Call') {
      dispatch(
        callActions.updateCall({ id: call.id, endDateCall: new Date() }),
      );
    } else if (statusCall.status === 'Ended') {
      dispatch(
        callActions.updateCall({
          id: call.id,
          endDateCall: deleteField(),
        }),
      );
    }
  };

  const { t } = useTranslation();

  return (
    <div className="d-inline-block mr-4 mini-stats-wid">
      <Media className="header-item">
        <div className="align-self-center mr-3">
          <span className="avatar-xs rounded-circle">
            <i className={`${gender} font-size-20`}></i>
          </span>
        </div>
        <div className="align-self-center mr-3">
          <h5 className="font-size-15 mt-0 mb-1">{lead?.name || '-'}</h5>
          <p className="text-muted mb-0">
            <i
              className={`mdi mdi-circle text-${statusCall.circle} align-middle mr-1`}></i>{' '}
            {t(statusCall.status)}
          </p>
        </div>

        {loading ? (
          <Spinner
            color={colors.dark}
            size={16}
            speed={1}
            animating={true}
            className="mini-stat-icon avatar-sm align-self-center rounded-circle"
          />
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            //href={statusCall.phoneURL}
            style={{ pointerEvents: statusCall.pointerEvents }}
            className="mini-stat-icon avatar-sm align-self-center rounded-circle"
            onClick={handlePhone}
            href={statusCall.phoneURL !== '#' ? statusCall.phoneURL : false}>
            <span className={`avatar-title ${statusCall.phoneColor}`}>
              <i className={`${statusCall.phoneIcon} font-size-24`}></i>
            </span>
          </a>
        )}
      </Media>
    </div>
  );
};

export default ManagePhone;
