import types from './actionTypes';

const initialState = {
  loading: false,
  smsMessages: [],
  startDate: null,
  endDate: null,
  error: '',
};

const smsMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SMS_MESSAGE_STATE:
      return initialState;
    case types.CREATE_SMS_MESSAGE.REQUEST:
    case types.FETCH_SMS_MESSAGES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_SMS_MESSAGE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        smsMessages: [...state.smsMessages, action.smsMessage],
      };
    case types.FETCH_SMS_MESSAGES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        smsMessages: action.smsMessages,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case types.CREATE_SMS_MESSAGE.FAILURE:
    case types.FETCH_SMS_MESSAGES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default smsMessageReducer;
