const statsTypes = {
  RESET_MONETIZATION_ITEM_STATE: 'RESET_MONETIZATION_ITEM_STATE',
  CREATE_MONETIZATION_ITEM: {
    REQUEST: 'CREATE_MONETIZATION_ITEM.REQUEST',
    SUCCESS: 'CREATE_MONETIZATION_ITEM.SUCCESS',
    FAILURE: 'CREATE_MONETIZATION_ITEM.FAILURE',
  },
  FETCH_MONETIZATION_ITEMS: {
    REQUEST: 'FETCH_MONETIZATION_ITEMS.REQUEST',
    SUCCESS: 'FETCH_MONETIZATION_ITEMS.SUCCESS',
    FAILURE: 'FETCH_MONETIZATION_ITEMS.FAILURE',
  },
  FETCH_MONETIZATION_ITEMS_BY_LEAD: {
    REQUEST: 'FETCH_MONETIZATION_ITEMS_BY_LEAD.REQUEST',
    SUCCESS: 'FETCH_MONETIZATION_ITEMS_BY_LEAD.SUCCESS',
    FAILURE: 'FETCH_MONETIZATION_ITEMS_BY_LEAD.FAILURE',
  },
  FETCH_MONETIZATION_ITEMS_BY_PHONE: {
    REQUEST: 'FETCH_MONETIZATION_ITEMS_BY_PHONE.REQUEST',
    SUCCESS: 'FETCH_MONETIZATION_ITEMS_BY_PHONE.SUCCESS',
    FAILURE: 'FETCH_MONETIZATION_ITEMS_BY_PHONE.FAILURE',
  },
  RESEND_MONETIZATION_ITEMS: {
    REQUEST: 'RESEND_MONETIZATION_ITEMS.REQUEST',
    SUCCESS: 'RESEND_MONETIZATION_ITEMS.SUCCESS',
    FAILURE: 'RESEND_MONETIZATION_ITEMS.FAILURE',
  },
};

export default statsTypes;
