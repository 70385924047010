export default [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Google Ads',
    value: 'googleAds',
  },
  {
    label: 'Outbrain',
    value: 'outbrain',
  },
  {
    label: 'Banner',
    value: 'banner',
  },
  { label: 'Marketing automation', value: 'marketingAutomation' },
  {
    label: 'Chiedimi Live Hunting',
    value: 'liveHunting',
  },
  {
    label: 'Chiedimi Form Widget Web',
    value: 'chiedimi_form_web',
  },
  {
    label: 'Chiedimi Form Widget Mobile',
    value: 'chiedimi_form_mobile',
  },
  {
    label: 'ChatBot',
    value: 'chatbot',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'CRM',
    value: 'crm',
  },
  { label: 'Email', value: 'email' },
  { label: 'Phone Exchange', value: 'phone_exchange' },
];
