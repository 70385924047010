import { IMPORT_LEAD_TYPES } from '../../config/importLeadTypes';
import defaultImportLead from './default';
import iblRestartImportLead from './iblRestart';
import limoniRestartImportLead from './limoniRestart';
import import251 from './import251';
import haierRestartImportLead from './haierRestart';
import googleLeadFormImportLead from './googleLeadForm';
import compassRecallImportLead from './compassRecall';
import compassFBAdsImportLead from './compassFBAds';
import compassPOLImportLead from './compassPOL';
import trovoBiciImportLead from './trovoBici';
import facebookAdsImportLead from './facebookAds';

const importLeadHelper = (importLeadType, lead) => {
  try {
    checkRequiredFields(importLeadType, lead);
    switch (importLeadType) {
      case 'IBLRestart':
        return iblRestartImportLead(lead);
      case 'limoniRestart':
        return limoniRestartImportLead(lead);
      case 'import251':
        return import251(lead);
      case 'haierRestart':
        return haierRestartImportLead(lead);
      case 'googleLeadForm':
        return googleLeadFormImportLead(lead);
      case 'compassRecall':
        return compassRecallImportLead(lead);
      case 'compassFBAds':
        return compassFBAdsImportLead(lead);
      case 'compassPOL':
        return compassPOLImportLead(lead);
      case 'trovoBici':
        return trovoBiciImportLead(lead);
      case 'facebookAds':
        return facebookAdsImportLead(lead);
      default:
        return defaultImportLead(lead);
    }
  } catch (error) {
    return { error: error.message };
  }
};

const checkRequiredFields = (importLeadType, lead) => {
  const importLeadHelper = IMPORT_LEAD_TYPES.find(
    ({ value }) => value === importLeadType,
  );

  if (!importLeadHelper)
    throw new Error(`ImportLeadHelper for ${importLeadType} not exists`);

  const { requiredFields } = importLeadHelper;
  if (!requiredFields) return;

  requiredFields.forEach((field) => {
    if (!lead[field] || lead[field].trim() === '')
      throw new Error(`"${field}" is required value!`);
  });
};

export default importLeadHelper;
