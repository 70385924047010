import { createSelector } from 'reselect';
import { types } from '../config/surveyFormTypes';

export const selectSurveyForm = (id) => {
  return createSelector(
    (state) => state.SurveyForm.surveyForms,
    (surveyForms) => {
      if (!id) return null;
      const surveyForm = surveyForms.filter(
        (surveyForm) => surveyForm.id === id,
      );
      return surveyForm.length > 0 ? surveyForm[0] : null;
    },
  );
};

export const selectSurveyFormByType = (type) => {
  return createSelector(
    (state) => state.SurveyForm.surveyForms,
    (surveyForms) => {
      if (!type) return null;
      const selectedSurveyForms = surveyForms.filter(
        (surveyForm) => surveyForm.type === type,
      );
      return selectedSurveyForms.length > 0 ? selectedSurveyForms : null;
    },
  );
};

export const selectSurveyFormsForSelect = createSelector(
  (state) => state.SurveyForm.surveyForms,
  (surveyForms) => {
    let data = [];
    surveyForms.forEach((surveyForm) => {
      data.push({
        value: surveyForm.id,
        label: surveyForm.name,
      });
    });
    return [{ value: 'none', label: 'None' }, ...data];
  },
);

export const selectAllPersonalAttributes = createSelector(
  selectSurveyFormByType(types.DATA_ENRICHMENT),
  (surveyForms) => {
    if (!surveyForms) return [];
    const personalAttributes = surveyForms
      .map((surveyForm) => surveyForm.personalAttributes)
      .join(',');
    return [...new Set([...personalAttributes.split(',')])];
  },
);

export const personalAttributesForQueryBuilder = createSelector(
  selectAllPersonalAttributes,
  (personalAttributes) =>
    personalAttributes
      .filter((attribute) => attribute !== 'occupation')
      .map((attribute) => ({
        name: attribute,
        typeField: 'text',
      })),
);
