import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MESSAGE_TEMPLATE_STATE,
});

export const createMessageTemplate = (messageTemplate) => ({
  type: types.CREATE_MESSAGE_TEMPLATE.REQUEST,
  messageTemplate,
});

export const createMessageTemplateSuccess = (messageTemplate) => ({
  type: types.CREATE_MESSAGE_TEMPLATE.SUCCESS,
  messageTemplate,
});

export const createMessageTemplateFailure = (error) => ({
  type: types.CREATE_MESSAGE_TEMPLATE.FAILURE,
  error,
});

export const updateMessageTemplate = (messageTemplate) => ({
  type: types.UPDATE_MESSAGE_TEMPLATE.REQUEST,
  messageTemplate,
});

export const updateMessageTemplateSuccess = (messageTemplate) => ({
  type: types.UPDATE_MESSAGE_TEMPLATE.SUCCESS,
  messageTemplate,
});

export const updateMessageTemplateFailure = (error) => ({
  type: types.UPDATE_MESSAGE_TEMPLATE.FAILURE,
  error,
});

export const syncMessageTemplates = () => ({
  type: types.SYNC_MESSAGE_TEMPLATES.REQUEST,
});

export const syncMessageTemplatesSuccess = (messageTemplates) => ({
  type: types.SYNC_MESSAGE_TEMPLATES.SUCCESS,
  messageTemplates,
});

export const syncMessageTemplatesFailure = (error) => ({
  type: types.SYNC_MESSAGE_TEMPLATES.FAILURE,
  error,
});
