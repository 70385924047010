import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import { userCustomerId } from '../../selectors/auth';
import {
  totalLeads,
  totalPositives,
  totalRefused,
  totalRevenues,
  totalRebateCosts,
} from '../../selectors/customer';

import MiniCard from '../Common/MiniCard';

import { useTranslation } from 'react-i18next';

const CustomerMetrics = (props) => {
  const customerId = useSelector(userCustomerId);

  const leads = useSelector(totalLeads);
  const positiveLeads = useSelector(totalPositives);
  const refusedLeads = useSelector(totalRefused);
  const revenues = useSelector(totalRevenues);
  const rebates = useSelector(totalRebateCosts);

  const loading = useSelector((state) => state.DailyCustomerInsights.loading);

  const { t } = useTranslation();

  let metrics = [
    {
      title: t('Incomings'),
      text: leads,
      color: 'info',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Positive'),
      text: positiveLeads,
      color: 'success',
      icon: 'bx bx-user-pin',
    },
    {
      title: t('Refused'),
      text: refusedLeads,
      color: 'danger',
      icon: 'bx bx-user-pin',
    },
    {
      title: t(
        customerId ? (props.isAgency ? 'Billing' : 'Costs') : 'Revenues',
      ),
      text: `${isFinite(revenues) ? revenues.toFixed(2) : 0} €`,
      color: 'dark',
      icon: 'fas fa-laugh-wink',
      isSensitiveData: true,
    },
  ];

  props.showRebate &&
    metrics.push({
      title: t('Rebates'),
      text: `${isFinite(rebates) ? rebates.toFixed(2) : 0} €`,
      color: 'success',
      icon: 'fas fa-laugh-wink',
      isSensitiveData: true,
    });

  return (
    <>
      {metrics.map((metric, index) => (
        <Col key={'mainMetric_' + index}>
          <MiniCard {...metric} loading={loading} />
        </Col>
      ))}
    </>
  );
};

export default CustomerMetrics;
