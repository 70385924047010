import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as marketingCampaignsActions from '../../store/marketingCampaigns/actions';
import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import * as audiencesActions from '../../store/audiences/actions';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormMarketingCampaign from '../../components/MarketingCampaigns/FormMarketingCampaign';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/MarketingCampaigns/tableColumns';
import {
  CreateButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.MarketingCampaign.loading);
  const marketingCampaigns = useSelector(
    (state) => state.MarketingCampaign.marketingCampaigns,
  );
  const messageTemplates = useSelector(
    (state) => state.MessageTemplate.messageTemplates,
  );
  const audiences = useSelector((state) => state.Audience.audiences);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState(false);
  const [selectedMarketingCampaign, setSelectedMarketingCampaign] =
    useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(marketingCampaignsActions.syncMarketingCampaigns());
    dispatch(messageTemplatesActions.syncMessageTemplates());
    dispatch(audiencesActions.syncAudiences());
  }, []);

  const openViewModal = (marketingCampaign) => {
    setSelectedMarketingCampaign(marketingCampaign);
    setViewModal(true);
  };

  const openEditModal = (marketingCampaign) => {
    setSelectedMarketingCampaign(marketingCampaign);
    setEditModal(true);
  };

  const openActiveAlert = (marketingCampaign) => {
    setSelectedMarketingCampaign(marketingCampaign);
    setActiveAlert(true);
  };

  const handleActiveMarketingCampaign = () => {
    let marketingCampaign = {
      id: selectedMarketingCampaign.id,
      active: !selectedMarketingCampaign.active,
    };
    dispatch(
      marketingCampaignsActions.updateMarketingCampaign(marketingCampaign),
    );
    setActiveAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        messageTemplates,
        audiences,
        openViewModal,
        openEditModal,
        openActiveAlert,
      ),
    [messageTemplates, audiences],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Marketing Campaign" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Marketing Campaign"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={marketingCampaigns}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Marketing Campaign"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedMarketingCampaign} />
          </Modal>
          <Modal
            size="xl"
            title="Marketing Campaign"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormMarketingCampaign
              closeModal={() => setEditModal(false)}
              create={!selectedMarketingCampaign}
              loading={loading}
              marketingCampaign={selectedMarketingCampaign}
              messageTemplates={messageTemplates}
            />
          </Modal>
          <Alert
            isOpen={activeAlert}
            title={`Do you want to ${
              selectedMarketingCampaign && selectedMarketingCampaign.active
                ? 'disable'
                : 'activate'
            } this Marketing Campaign?`}
            confirmBtnLabel={
              selectedMarketingCampaign && selectedMarketingCampaign.active
                ? 'Disable'
                : 'Active'
            }
            onCancel={() => setActiveAlert(false)}
            onConfirm={handleActiveMarketingCampaign}>
            Keep attention!
          </Alert>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
