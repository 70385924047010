const statsTypes = {
  RESET_NEXT_CALL: 'RESET_NEXT_CALL',
  FETCH_NEXT_CALL: {
    REQUEST: 'FETCH_NEXT_CALL.REQUEST',
    SUCCESS: 'FETCH_NEXT_CALL.SUCCESS',
    FAILURE: 'FETCH_NEXT_CALL.FAILURE',
  },
  SYNC_NEXT_CALL: {
    REQUEST: 'SYNC_NEXT_CALL.REQUEST',
    SUCCESS: 'SYNC_NEXT_CALL.SUCCESS',
    FAILURE: 'SYNC_NEXT_CALL.FAILURE',
  },
  SET_DIRTY_LEAD_FORM: 'SET_DIRTY_LEAD_FORM',
};

export default statsTypes;
