import React, { useEffect, useState } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';

import * as callsActions from '../../store/calls/actions';
import * as usersActions from '../../store/users/actions';
import { selectLeadCallsWithStatus } from '../../selectors/call';
import { useDispatch, useSelector } from 'react-redux';

import { CallElement, AnyCallToShow } from './callListElement';
import { useTranslation } from 'react-i18next';

const CallHistory = ({ lead, status }) => {
  const [isOpen, setIsOpen] = useState(false);

  const calls = useSelector(selectLeadCallsWithStatus(lead, status));
  const dispatch = useDispatch();

  useEffect(() => {
    lead && dispatch(callsActions.fetchCallsByLead(lead));
    dispatch(usersActions.syncUsers());
  }, [lead]);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <span
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="d-flex align-items-center"
            style={{ cursor: 'pointer' }}>
            <h4 className="card-title mb-4">{t('Call History')}</h4>
            {calls.length > 0 && (
              <>
                <span
                  className="badge badge-pill badge-info"
                  style={{ alignSelf: 'baseline' }}>
                  {calls.length}
                </span>
                <i className="mdi mdi-chevron-down mb-4 font-size-17 ml-1"></i>
              </>
            )}
          </span>
          <Collapse isOpen={isOpen || calls.length === 0}>
            <ul className="list-group">
              {calls.length > 0 ? (
                calls.map((call, index) => (
                  <CallElement key={'call_' + index} call={call} />
                ))
              ) : (
                <AnyCallToShow status={status} />
              )}
            </ul>
          </Collapse>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CallHistory;
