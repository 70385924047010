import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const createTableColumns = (t, openViewModal) => [
  {
    title: 'Day',
    name: 'date',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('dddd, DD/MM/YYYY'),
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Leads',
    name: 'leads',
    summary: 'sum',
  },
  {
    title: 'Positive',
    name: 'positives',
    summary: 'sum',
  },
  {
    title: 'Negative',
    name: 'negatives',
    summary: 'sum',
  },
  {
    title: 'Revenues',
    name: 'revenues',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Acquisition Costs',
    name: 'acquisitionCosts',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Total Costs',
    name: 'totalCosts',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'First Margin',
    name: 'firstMargin',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Second Margin',
    name: 'secondMargin',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
