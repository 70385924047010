export const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length;
};

const isAllowedModule = (route, country) => {
  if (!country) return null;

  const { facebookModule, googleModule, supplierModule, audienceModule } =
    country;
  if (!facebookModule && route.path.includes('facebook')) return false;
  if (!googleModule && route.path.includes('google')) return false;
  if (!supplierModule && route.path.includes('supplier')) return false;

  if (!audienceModule && route.path.includes('audience')) return false;
  if (!audienceModule && route.path.includes('marketingCampaign')) return false;

  return true;
};

export const isAllowedRoute = (route, role, country) => {
  const { permission } = route;

  const allowedModule = isAllowedModule(route, country);
  if (allowedModule === false) return false;

  if (!permission) return true;
  else if (!isArrayWithLength(permission)) return true;
  else return permission.includes(role);
};

export const getAllowedRoutes = (routes, admin, country) => {
  //return only a default "/" path
  if (!admin) return [routes[routes.length - 1]];
  if (!admin.role) return [routes[routes.length - 1]];

  let flatRoutes = [];
  routes.forEach((route) => {
    const { component, children } = route;
    if (component) return flatRoutes.push(route);
    if (children) return (flatRoutes = flatRoutes.concat(children));
  });

  //console.log(flatRoutes);

  return flatRoutes.filter((route) =>
    isAllowedRoute(route, admin.role, country),
  );
};
