import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as leadFieldsActions from '../../store/leadFields/actions';
import { useDispatch } from 'react-redux';

import { typeFields, sizeFields } from '../../config/typeFormFields';
import sectors from '../../config/sectors';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormLeadField = (props) => {
  const editingLeadField = props.leadField || {};
  const leadFields = editingLeadField
    ? props.leadFields.filter((field) => field.name !== editingLeadField.name)
    : props.leadFields;

  const listValues = editingLeadField.listValues
    ? editingLeadField.listValues.map((value) => value.title).join(',')
    : '';

  const [personalAttribute, setPersonalAttribute] = useState(
    editingLeadField.personalAttribute || false,
  );
  const [required, setRequired] = useState(editingLeadField.required || false);
  const [showInFormLead, setShowInFormLead] = useState(
    editingLeadField.showInFormLead || false,
  );

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const listValuesArray =
      values.listValues === '' ? [] : values.listValues.split(',');
    const listValuesArrayObj = listValuesArray.map((value) => ({
      title: value,
      value,
    }));

    const newLeadField = {
      ...values,
      listValues: listValuesArrayObj,
      required,
      showInFormLead,
    };

    dispatch(
      props.create
        ? leadFieldsActions.createLeadField(newLeadField)
        : leadFieldsActions.updateLeadField({
            id: editingLeadField.id,
            ...newLeadField,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingLeadField,
            personalAttribute,
            required,
            showInFormLead,
            listValues,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="typeField"
                    id="typeField"
                    label="Type Field"
                    isMulti={false}
                    options={typeFields}
                  />
                </Col>
                <Col md="3">
                  <Select
                    name="size"
                    id="size"
                    isMulti={false}
                    options={sizeFields}
                  />
                </Col>
                <Col md="3">
                  <Switch
                    name="personalAttribute"
                    label="Personal attribute?"
                    defaultChecked={personalAttribute}
                    onClick={() => setPersonalAttribute(!personalAttribute)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="7">
                  <Select
                    name="sectors"
                    id="sectors"
                    label="Sectors"
                    options={sectors}
                    isMulti
                  />
                </Col>
                <Col md="2">
                  <Switch
                    name="required"
                    label="Required?"
                    defaultChecked={required}
                    onClick={() => setRequired(!required)}
                  />
                </Col>
                <Col md="3">
                  <Switch
                    name="showInFormLead"
                    label=" Show in Form Lead?"
                    defaultChecked={required}
                    onClick={() => setShowInFormLead(!showInFormLead)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Select
                    name="onChangeUpdateField"
                    id="onChangeUpdateField"
                    label="On Change Update"
                    isMulti={false}
                    options={[
                      { label: 'None', value: 'none' },
                      ...leadFields.map(({ name }) => ({
                        label: name,
                        value: name,
                      })),
                    ]}
                  />
                </Col>
                <Col md="3">
                  <Collapse
                    isOpen={
                      values.onChangeUpdateField &&
                      values.onChangeUpdateField !== 'none'
                    }>
                    <Input
                      type="text"
                      name="onChangeUpdateKey"
                      id="onChangeUpdateKey"
                      label="Key"
                    />
                    <p className="font-size-12 text-muted">
                      Insert object key you want to copy
                    </p>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Collapse isOpen={values.typeField === 'select'}>
                    <Input
                      type="textarea"
                      name="listValues"
                      id="listValues"
                      label="List Values"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex. value1,value2,value3,
                      ...)
                    </p>
                  </Collapse>
                  <Collapse isOpen={values.typeField === 'async-select'}>
                    <Input
                      type="text"
                      name="asyncUrlValues"
                      id="asyncUrlValues"
                      label="Async URL Values"
                    />
                    <p className="font-size-12 text-muted">
                      URL response in json format with array objects label and
                      value keys
                    </p>
                  </Collapse>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormLeadField;
