import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
} from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import { Dots } from 'react-activity';
import { Form } from '@availity/form';
import Select from '@availity/select';
import InputLeadField from './InputLead';
import '@availity/yup';
import formValidation from './formValidation';
import { deleteField } from 'firebase/firestore';

import * as leadsActions from '../../store/leads/actions';
import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import * as leadFieldsActions from '../../store/leadFields/actions';
import * as usersActions from '../../store/users/actions';
import * as nextCallActions from '../../store/nextCall/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLead } from '../../selectors/lead';
import { showOnFormLeadField } from '../../selectors/leadField';
import { selectActiveMonetizationOrdersWithout } from '../../selectors/monetizationOrder';
import { selectAcquisitionOrderByCampaign } from '../../selectors/acquisitionOrder';
import { activeUsers } from '../../selectors/user';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import createTableColumns from '../../components/MonetizationItems/tableColumns';
import negativeOutcomes from '../../config/negativeOutcomes';
import { useTranslation } from 'react-i18next';
import { arrayToSelect } from '../../helpers/sharedFunction';
import { qualifiedName } from '../../components/Common/DataTable/tableHelpers';
import timeSlots from '../../config/timeSlots';

const FormLead = ({
  lead,
  loading,
  create,
  showMonetizationOrders,
  closeModal,
  bindSubmitForm,
  manageDirtyForm,
  disabledSelectCampaign,
  hideCampaignField,
  hideClosureFields,
  hideSubmitButton,
}) => {
  const editingLead = lead || {};
  const defaultValues = {
    create: create,
    ...(create && { sendInitialSurvey: 'default', closure: 'no' }),
  };

  const [campaignId, setCampaignId] = useState(editingLead.campaignId || '');

  const lastSavedLead = useSelector(selectLead(editingLead.id));
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const acquisitionOrder = useSelector(
    selectAcquisitionOrderByCampaign(editingLead.campaignId),
  );

  const negativeReasons = acquisitionOrder?.negativeReasons;
  const customNegativeReasons =
    Array.isArray(negativeReasons) && negativeReasons.length
      ? negativeReasons
      : negativeOutcomes;

  const leadFields = useSelector(
    showOnFormLeadField(acquisitionOrder?.leadFormId, acquisitionOrder?.sector),
  );

  const loadingLeadFields = useSelector((state) => state.LeadField.loading);

  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );

  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const operators = useSelector(activeUsers);

  const loadingMonetizationItems = useSelector(
    (state) => state.MonetizationItem.loading,
  );
  const monetizationItems = useSelector(
    (state) => state.MonetizationItem.monetizationItems,
  );
  const monetizationOrderIds = monetizationItems.map(
    (monetizationItem) => monetizationItem.monetizationOrderId,
  );
  const dirtyLeadForm = useSelector((state) => state.NextCall.dirtyLeadForm);
  const users = useSelector((state) => state.User.users);

  const monetizationOrdersForSelect = useSelector(
    selectActiveMonetizationOrdersWithout(create ? null : monetizationOrderIds),
  );

  const qualifyNotInProgress =
    editingLead.qualified &&
    editingLead.qualified !== 'new' &&
    editingLead.qualified !== 'error' &&
    editingLead.qualified !== 'readyToQualify';

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lead && !create) {
      dispatch(monetizationItemsActions.fetchMonetizationItemsByLead(lead));
      dispatch(monetizationOrdersActions.syncMonetizationOrders());
      dispatch(campaignsActions.syncCampaigns());
    }
  }, [lead]);

  useEffect(() => {
    dispatch(leadFieldsActions.syncLeadFields());
    showMonetizationOrders && dispatch(usersActions.syncUsers());
  }, [showMonetizationOrders]);

  const handleDirtyForm = (dirty, touched, isValid) => {
    console.log({ dirty, touched, isValid });
    const isTouched = Object.keys(touched).length > 0;
    const isDirty = !isValid || (dirty && isTouched);
    dirtyLeadForm !== isDirty &&
      dispatch(nextCallActions.setDirtyLeadForm(isDirty));
  };

  const handleSubmit = (values) => {
    //Add place attribute
    if (values.placeCode && values.placeCode.place) {
      const { place, city, cityName, zipCode } = values.placeCode;
      //console.log(place, city, cityName, zipCode);
      values.place = place;
      if (
        lastSavedLead &&
        lastSavedLead.placeCode &&
        values.placeCode.place !== lastSavedLead.place
      )
        values.zipCode = zipCode;
      values.city = city;
      values.cityName = cityName;
    }

    //console.log(values.birthPlaceCode);
    //Add birthPlace attribute
    if (values.birthPlaceCode && values.birthPlaceCode.place) {
      const { place, city, cityName } = values.birthPlaceCode;
      //console.log(place, city, cityName);
      values.birthPlace = place;
      values.birthCity = city === '' && !create ? deleteField() : city;
      values.birthCityName =
        cityName === '' && !create ? deleteField() : cityName;
    }

    if (values.closure === 'negative') values.monetizationOrders = null;
    if (values.closure === 'positive') delete values.negativeOutcome;

    if (values.appointmentUserId === 'none' || values.closure !== 'no') {
      delete values.appointmentUserId;
      delete values.timeSlot;
    }

    dispatch(
      create
        ? leadsActions.createLead(values)
        : leadsActions.updateLead({
            id: editingLead.id,
            ...values,
          }),
    );
    closeModal && closeModal();
  };

  const { t } = useTranslation();

  const monetizationItemsColumns = useMemo(
    () =>
      createTableColumns(
        null,
        null,
        monetizationOrders,
        acquisitionOrders,
        users,
        true,
        t,
      ),
    [monetizationOrders, acquisitionOrders, users, t],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    monetizationItemsColumns
      .filter(({ hidden }) => !!hidden)
      .map(({ name }) => name),
  );

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...defaultValues,
            ...editingLead,
            monetizationOrders: create ? null : [],
          }}
          validationSchema={formValidation(leadFields)}
          validateOnMount={!!manageDirtyForm}
          onSubmit={handleSubmit}>
          {({
            isValid,
            values,
            submitForm,
            resetForm,
            dirty,
            touched,
            setFieldValue,
          }) => {
            bindSubmitForm &&
              bindSubmitForm({
                submitForm,
                resetForm,
                values,
                isValid,
              });
            manageDirtyForm && handleDirtyForm(dirty, touched, isValid);
            return (
              <>
                <Row>
                  <Col md="6" hidden={!!hideCampaignField}>
                    <FormGroup>
                      <Label htmlFor="campaignId">{t('Campaign')}</Label>
                      <Select
                        name="campaignId"
                        id="campaignId"
                        isMulti={false}
                        isSearchable
                        isDisabled={!!disabledSelectCampaign}
                        onChange={(value) => setCampaignId(value)}
                        value={campaignId}
                        options={
                          Array.isArray(campaigns)
                            ? campaigns.map(
                                ({ id, name, acquisitionOrderId }) => ({
                                  label: name,
                                  value: id,
                                  isDisabled: acquisitionOrderId === '',
                                }),
                              )
                            : []
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Collapse isOpen={campaignId !== ''}>
                  {!loadingLeadFields ? (
                    <Row md="12" className="align-items-end">
                      {leadFields.map((field, index) => (
                        <InputLeadField
                          key={field.id}
                          field={field}
                          setFieldValue={setFieldValue}
                          values={values}
                          autoFocus={!index}
                        />
                      ))}
                    </Row>
                  ) : (
                    <Dots />
                  )}
                  {create && (
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label htmlFor="sendInitialSurvey">
                            {t('Send Initial Survey?')}
                          </Label>
                          <Select
                            name="sendInitialSurvey"
                            id="sendInitialSurvey"
                            isMulti={false}
                            options={[
                              {
                                label: t('Default'),
                                value: 'default',
                              },
                              {
                                label: 'No',
                                value: 'no',
                              },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <Collapse isOpen={values.closure === 'no'}>
                          <FormGroup>
                            <Label htmlFor="appointmentUserId">
                              {t('Schedule appointment')}
                            </Label>
                            <Select
                              name="appointmentUserId"
                              id="appointmentUserId"
                              isMulti={false}
                              options={
                                Array.isArray(operators)
                                  ? [
                                      { label: t('None'), value: 'none' },
                                      ...operators.map(
                                        ({ id, displayName }) => ({
                                          label: displayName,
                                          value: id,
                                        }),
                                      ),
                                    ]
                                  : []
                              }
                            />
                          </FormGroup>
                        </Collapse>
                      </Col>
                      <Col md="2">
                        <Collapse
                          isOpen={
                            values.closure === 'no' &&
                            values.appointmentUserId &&
                            values.appointmentUserId !== 'none'
                          }>
                          <FormGroup>
                            <Label htmlFor="timeSlot">{t('Time Slot')}</Label>
                            <Select
                              name="timeSlot"
                              id="timeSlot"
                              isMulti={false}
                              options={timeSlots()}
                            />
                          </FormGroup>
                        </Collapse>
                      </Col>
                    </Row>
                  )}
                  {!hideClosureFields && (
                    <Row>
                      {create && (
                        <Col md="2">
                          <FormGroup>
                            <Label htmlFor="closure">{t('Closure?')}</Label>
                            <Select
                              name="closure"
                              id="closure"
                              isMulti={false}
                              options={[
                                {
                                  label: 'No',
                                  value: 'no',
                                },
                                {
                                  label: t('Positive'),
                                  value: 'positive',
                                },
                                {
                                  label: t('Negative'),
                                  value: 'negative',
                                },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="7">
                        {!qualifyNotInProgress && !create && (
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>{' '}
                            <b className="mr-3">{t('Qualified')}:</b>
                            {qualifiedName(editingLead.qualified, true)}
                          </p>
                        )}
                        <Collapse
                          isOpen={
                            values.closure === 'positive' ||
                            qualifyNotInProgress ||
                            !create
                          }>
                          <FormGroup>
                            <Label htmlFor="monetizationOrders">
                              {t('Monetization Orders')}
                            </Label>
                            <Select
                              name="monetizationOrders"
                              id="monetizationOrders"
                              isMulti={true}
                              isSearchable={true}
                              options={monetizationOrdersForSelect.map(
                                ({ id, name }) => ({ value: id, label: name }),
                              )}
                            />
                          </FormGroup>
                        </Collapse>
                        <Collapse isOpen={values.closure === 'negative'}>
                          <FormGroup>
                            <Label htmlFor="negativeOutcome">
                              {t('Negative outcome')}
                            </Label>
                            <Select
                              name="negativeOutcome"
                              id="negativeOutcome"
                              isSearchable={true}
                              options={arrayToSelect(customNegativeReasons, t)}
                            />
                          </FormGroup>
                        </Collapse>
                      </Col>
                    </Row>
                  )}
                </Collapse>
                {!hideSubmitButton && (
                  <div className="modal-footer">
                    <SubmitButton
                      disabled={!isValid || loading}
                      create={create}
                    />
                  </div>
                )}
              </>
            );
          }}
        </Form>
        {showMonetizationOrders && (
          <Row>
            <Col className="col-12">
              <h4 className="mb-3 font-size-16">
                {t('Monetization Orders already created')}:
              </h4>
              <DataTableNext
                loading={loadingMonetizationItems}
                rows={monetizationItems}
                columns={monetizationItemsColumns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                hiddenColumns={hiddenColumns}
                onHiddenColumnsChange={setHiddenColumns}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default FormLead;
