import { Timestamp } from 'firebase/firestore';

const compassFBAdsImportLead = (lead) => ({
  fullName: lead.full_name,
  email: lead.email,
  phone: lead.phone_number.replace('p:', ''),
  createdAt: Timestamp.fromDate(new Date(lead.created_time)),
});

export default compassFBAdsImportLead;
