import { Timestamp } from 'firebase/firestore';
import {
  toTitleCase,
  normalizePhone,
  YYYYMMDDHHmmssToDateTime,
} from '../sharedFunction';

//first row in CSV
//id;created_time;ad_id;ad_name;adset_id;adset_name;campaign_id;campaign_name;form_id;form_name;is_organic;platform;first_name;last_name;phone_number;email;marketing_consent;third_party_consent

const facebookAdsImportLead = (lead) => ({
  firstName: toTitleCase(lead['first_name'].trim()),
  lastName: toTitleCase(lead['last_name'].trim()),
  ...(lead['email'] &&
    lead['email'].trim() !== '' && {
      email: lead['email'].trim(),
    }),
  phone: normalizePhone(lead['phone_number'].replace('p:', '')),
  adId: lead['ad_id'].trim().replace('ag:', ''),
  adsetId: lead['adset_id'].trim().replace('as:', ''),
  campaignId: lead['campaign_id'].trim().replace('c:', ''),
  formLeadId: lead['form_id'].trim().replace('f:', ''),
  formLeadName: lead['form_name'].trim(),
  marketingConsent:
    lead['marketing_consent'].trim().toLowerCase() === 'true' ? 'yes' : 'no',
  thirdPartyConsent:
    lead['third_party_consent'].trim().toLowerCase() === 'true' ? 'yes' : 'no',
  source: 'facebook',
  createdAt: Timestamp.fromDate(
    YYYYMMDDHHmmssToDateTime(lead['created_time'].trim()),
  ),
});

export default facebookAdsImportLead;
