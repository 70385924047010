import { takeLeading, select, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { toDateFirebase } from '../../../helpers/sharedFunction';

export const dailyInsightTransformer = (insight, data) => {
  const {
    revenues,
    fbCosts,
    googleCosts,
    smsCosts,
    publisherCosts,
    rebateCosts,
    saleCosts,
  } = data;
  const acquisitionCosts = fbCosts + googleCosts + smsCosts + publisherCosts;
  const totalCosts = acquisitionCosts + rebateCosts + saleCosts;
  const date = toDateFirebase(insight, data, 'date').toDate();

  return {
    id: insight.id,
    ...data,
    acquisitionCosts,
    totalCosts,
    firstMargin: revenues - acquisitionCosts,
    secondMargin: revenues - totalCosts,
    date,
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const db = getFirestore(rsf.app);
    const insightQuery = query(
      collection(db, 'dailyCountryInsights'),
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('countryId', '==', countryId),
      orderBy('date', 'desc'),
    );

    const dailyInsightsSnap = yield call(
      rsf.firestore.getCollection,
      insightQuery,
    );

    let dailyInsights = [];
    dailyInsightsSnap.forEach((insight) => {
      const data = insight.data();
      dailyInsights.push(dailyInsightTransformer(insight, data));
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeLeading(types.DAILY_COUNTRY_INSIGHTS.REQUEST, fetchDailyInsightsSaga),
  ]);
}

export default dailyInsightsSaga;
