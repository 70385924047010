import { Checkbox, CheckboxGroup } from '@availity/form';
import { FormGroup } from 'reactstrap';
import Label from './Label';

const InputForm = ({
  name,
  readOnly,
  style,
  children,
  addonType,
  ...restProps
}) => {
  const disabledStyle = readOnly ? { cursor: 'not-allowed' } : {};
  return (
    <FormGroup>
      <Label name={name} {...restProps} />
      <CheckboxGroup name={name}>
        <Checkbox
          label={name}
          value="yes"
          disabled={readOnly}
          style={{ ...style, ...disabledStyle }}
        />
      </CheckboxGroup>
    </FormGroup>
  );
};

export default InputForm;
