import types from './actionTypes';
import initialFilters from '../../../config/filters';

const initialState = {
  loading: false,
  insights: [],
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  error: '',
};

const dailyFBCampaignInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_DAILY_FB_CAMPAIGN_INSIGHT_STATE:
      return initialState;
    case types.DAILY_FB_CAMPAIGN_INSIGHTS.REQUEST:
      return {
        ...state,
        loading: true,
        insights: [],
        error: '',
      };
    case types.DAILY_FB_CAMPAIGN_INSIGHTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        insights: action.insights,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.DAILY_FB_CAMPAIGN_INSIGHTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default dailyFBCampaignInsightsReducer;
