import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import { useDispatch, useSelector } from 'react-redux';
import createTableColumns from './operatorTableColumns';
import { useTranslation } from 'react-i18next';

const MonetizationItemHistory = ({ lead, selectBy = 'phone' }) => {
  const loading = useSelector((state) => state.MonetizationItem.loading);
  const monetizationItems = useSelector(
    (state) => state.MonetizationItem.monetizationItems,
  );
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const users = useSelector((state) => state.User.users);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    lead &&
      dispatch(
        selectBy === 'phone'
          ? monetizationItemsActions.fetchMonetizationItemsByPhone(lead.phone)
          : monetizationItemsActions.fetchMonetizationItemsByLead(lead),
      );
  }, [lead]);

  const { t } = useTranslation();

  const columns = useMemo(
    () => createTableColumns(acquisitionOrders, monetizationOrders, users, t),
    [acquisitionOrders, monetizationOrders, users, t],
  );

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md="12">
            <div className="avatar-sm float-sm-left mr-3">
              <span className="avatar-title rounded-circle bg-light font-size-20">
                <i className="bx bx-wallet text-dark"></i>
              </span>
            </div>
            <CardTitle className="mt-2 mr-2 float-sm-left font-size-16">
              {selectBy === 'phone'
                ? `${t('Existing closures for this phone number')}: ${
                    loading ? 0 : monetizationItems.length
                  }`
                : `${t('Closures')}`}
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mt-1">
            <DataTableNext
              loading={loading}
              rows={monetizationItems}
              columns={columns}
              filters={filters}
              onFiltersChange={setFilters}
              sorting={sorting}
              onSortingChange={setSorting}
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default MonetizationItemHistory;
