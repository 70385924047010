import { createSelector } from 'reselect';

export const selectAcquisitionOrderByCampaign = (campaignId) =>
  createSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (acquisitionOrders) => {
      if (!campaignId) return null;

      const acquisitionOrder = acquisitionOrders.filter((acquisitionOrder) =>
        acquisitionOrder.campaignIds
          ? acquisitionOrder.campaignIds.includes(campaignId)
          : false,
      );
      return acquisitionOrder.length > 0 ? acquisitionOrder[0] : null;
    },
  );

export const selectAcquisitionOrder = (id) =>
  createSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (acquisitionOrders) => {
      if (!id) return null;
      const acquisitionOrder = acquisitionOrders.filter(
        (acquisitionOrder) => acquisitionOrder.id === id,
      );
      return acquisitionOrder.length > 0 ? acquisitionOrder[0] : null;
    },
  );

export const selectAcquisitionOrders = (ids) =>
  createSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (acquisitionOrders) => {
      if (!Array.isArray(ids)) return null;
      const selectedAcquisitionOrders = acquisitionOrders.filter(
        (acquisitionOrder) => ids.includes(acquisitionOrder.id),
      );
      return selectedAcquisitionOrders.length > 0
        ? selectedAcquisitionOrders
        : null;
    },
  );

export const selectPhoneExchangeQueues = (acquisitionOrderIds) =>
  createSelector(
    selectAcquisitionOrders(acquisitionOrderIds),
    (acquisitionOrders) => {
      if (!acquisitionOrders) return [];
      const phoneExchangeQueues = acquisitionOrders.map(
        (ao) => ao.phoneExchangeQueue,
      );
      return [...new Set(phoneExchangeQueues.filter((value) => !!value))]; //unique values
    },
  );

export const selectAcquisitionOrdersForSelect = createSelector(
  (state) => state.AcquisitionOrder.acquisitionOrders,
  (acquisitionOrders) =>
    acquisitionOrders.map((acquisitionOrder) => ({
      value: acquisitionOrder.id,
      label: acquisitionOrder.name,
    })),
);

export const selectAcquisitionOrdersBySector = (sector) =>
  createSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (acquisitionOrders) =>
      acquisitionOrders.filter((ao) =>
        ao.sector ? ao.sector === sector : false,
      ),
  );

export const selectAcquisitionOrdersByLead = (lead) =>
  createSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
    (acquisitionOrders) => {
      if (!lead) return null;
      return acquisitionOrders.find((ao) => lead.acquisitionOrderId === ao.id);
    },
  );

export const selectActiveAcquisitionOrders = createSelector(
  (state) => state.AcquisitionOrder.acquisitionOrders,
  (acquisitionOrders) => acquisitionOrders.filter(({ active }) => !!active),
);
