import { Timestamp } from 'firebase/firestore';
import { YYYYMMDDHHmmssToDateTime } from '../sharedFunction';

const defaultImportLead = (lead) => {
  return {
    ...lead,
    phone: lead.phone[0] === '+' ? lead.phone : `+${lead.phone}`,
    createdAt: Timestamp.fromDate(YYYYMMDDHHmmssToDateTime(lead.createdAt)),
  };
};

export default defaultImportLead;
