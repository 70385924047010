import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { selectUser, userAcquisitionOrderIds } from '../../selectors/user';
import { selectPhoneExchangeQueues } from '../../selectors/acquisitionOrder';

import { Trans, useTranslation } from 'react-i18next';
import restartExchangeImg from '../../assets/images/restart-exchange-queues.jpg';

const PhoneExchangeQueuesBox = ({ countryId }) => {
  const user = useSelector((state) => state.Auth.admin);
  const selectedUser = useSelector(selectUser(user.id));
  const acquisitionOrderIds = useSelector(
    userAcquisitionOrderIds(user.id, countryId),
  );
  const phoneExchangeQueues = useSelector(
    selectPhoneExchangeQueues(acquisitionOrderIds),
  );
  const login =
    selectedUser && selectedUser.phoneExchangeId
      ? selectedUser.phoneExchangeId
      : '';

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <h5>{t('Follow these steps before starting')}:</h5>
        <ol className="font-size-15">
          <li>
            <Trans i18nKey="login_phone_exchange">
              Click on "Sign in" and enter this user:{' '}
              <strong>{{ login }}</strong> and password:{' '}
              <strong>{{ login }}</strong>
            </Trans>
          </li>
          <li>{t('Click on "Code" in the menu on the left')}</li>
          <li>
            {t('Verify that only the following queues are enabled')}:{' '}
            <strong>{phoneExchangeQueues.join(', ')}</strong>{' '}
            <Trans i18nKey="queues_phone_exchange_green">
              (they must be <strong>green</strong>)
            </Trans>
          </li>
        </ol>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://192.168.1.3/it/hashuser/queues/"
          className="btn btn-outline-info btn-lg my-4 ml-4">
          <i className="bx bx-link-external align-middle mr-2"></i>
          {t('Sign in')}
        </a>
        <img src={restartExchangeImg} width="100%" alt="Restart Exchange" />
        {false && (
          <div
            style={{ width: '100%', height: '400px' }}
            dangerouslySetInnerHTML={{
              __html:
                "<iframe src='http://87.26.120.204:8080/it/hashuser/queues/' frameBorder='0' width='100%' height='400px' />",
            }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default PhoneExchangeQueuesBox;
