import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  revenuePerLead,
  costPerQualify,
} from '../../selectors/monetizationItem';
import {
  countFacebookLeads,
  countGoogleAdsLeads,
  countLeadWithCosts,
  countNewLeads,
  totalCosts as leadTotalCosts,
  countLeadWithPositiveMonetizationItems,
} from '../../selectors/lead';
import { totalCosts as fbTotalCosts } from '../../selectors/facebookAd/dailyCampaignInsight';
import { totalCosts as googleTotalCosts } from '../../selectors/googleAd/dailyCampaignInsight';

import MiniCard from '../Common/MiniCard';

import { useTranslation } from 'react-i18next';
import roles from '../../config/roles';

const LeadMetrics = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles['OPERATOR'] ? user.id : null;

  const fbCosts = parseFloat(useSelector(fbTotalCosts(true)));
  const googleCosts = parseFloat(useSelector(googleTotalCosts(true)));
  const leadCosts = parseFloat(useSelector(leadTotalCosts));

  const leads = useSelector(countNewLeads);
  const facebookLeads = useSelector(countFacebookLeads);
  const googleLeads = useSelector(countGoogleAdsLeads);
  const leadWithCosts = useSelector(countLeadWithCosts);
  const leadWithPositiveMonetizationItems = useSelector(
    countLeadWithPositiveMonetizationItems,
  );

  const eCPA =
    (fbCosts + googleCosts + leadCosts) /
    (facebookLeads + googleLeads + leadWithCosts);
  const costPerLead =
    (fbCosts + googleCosts + leadCosts) / leadWithPositiveMonetizationItems;

  const eCPQ = useSelector(costPerQualify(userId));
  const eRPL = useSelector(revenuePerLead(userId));

  const { t } = useTranslation();

  const leadMetrics = [
    {
      title: t('New Leads'),
      text: leads,
      color: 'info',
      icon: 'bx bx-user-pin',
    },
    {
      title: 'eCPA',
      text: `${isFinite(eCPA) ? eCPA.toFixed(2) : 0} €`,
      color: 'secondary',
      icon: 'mdi mdi-account-cash',
      isSensitiveData: true,
    },
    {
      title: 'eCPQ',
      text: `${isFinite(eCPQ) ? eCPQ.toFixed(2) : 0} €`,
      color: 'secondary',
      icon: 'mdi mdi-account-cash',
      isSensitiveData: true,
    },
    {
      title: 'eCPL',
      text: `${isFinite(costPerLead) ? costPerLead.toFixed(2) : 0} €`,
      color: 'warning',
      icon: 'mdi mdi-account-cash',
      isSensitiveData: true,
    },
    {
      title: 'eRPL',
      text: `${isFinite(eRPL) ? eRPL.toFixed(2) : 0} €`,
      color: 'dark',
      icon: 'fas fa-money-bill-wave',
      isSensitiveData: true,
    },
  ];

  const loadingMonetizationItems = useSelector(
    (state) => state.MonetizationItem.loading,
  );
  const loadingFBInsights = useSelector(
    (state) => state.DailyFBCampaignInsights.loading,
  );
  const loadingGoogleInsights = useSelector(
    (state) => state.DailyGoogleCampaignInsights.loading,
  );
  const loadingLeads = useSelector((state) => state.Leads.loading);

  return (
    <>
      {leadMetrics.map((metric, index) => (
        <Col key={'mainMetric_' + index}>
          <MiniCard
            {...metric}
            loading={
              loadingMonetizationItems ||
              loadingFBInsights ||
              loadingGoogleInsights ||
              loadingLeads
            }
          />
        </Col>
      ))}
    </>
  );
};

export default LeadMetrics;
