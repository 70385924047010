const blue = '#007eff';
const indigo = '#564ab1';
const purple = '#6f42c1';
const pink = '#e83e8c';
const red = '#D64045';
const orange = '#FE5F55';
const yellow = '#FFCB77';
const green = '#368F8B';
const teal = '#050505';
const cyan = '#29335C';
const gray = '#595959';
const dark = '#211A1E';

const white = '#fff';
const gray_100 = '#212529';
const gray_200 = '#2a3042';
const gray_300 = '#32394e';
const gray_400 = '#a6b0cf';
const gray_500 = '#bfc8e2';
const gray_600 = '#c3cbe4';
const gray_700 = '#f6f6f6';
const gray_800 = '#eff2f7';
const gray_900 = '#f8f9fa';
const black = '#000';

const colors = {
  primary: '#222E50',
  secondary: gray,
  success: green,
  info: blue,
  warning: yellow,
  danger: red,
  light: gray_200,
  dark: dark,
};

export default colors;
