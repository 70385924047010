import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import useDeviceDetect from '../../lib/useDeviceDetect';
import reactStringReplace from 'react-string-replace';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';

import logoDark from '../../assets/images/logo-dark.png';

const OptInOut = (props) => {
  const [optOutStatus, setOptOutStatus] = useQueryParam('OptOut', StringParam);
  const [optInStatus, setOptInStatus] = useQueryParam('OptIn', StringParam);

  const { isMobile } = useDeviceDetect();

  const messageOptOut =
    "Gentile Utente, questa pagina ti consente di eseguire l'opt-out (cancellazione) dai servizi pubblicitari di Lead1 che utilizzano i cookies per mostrarti pubblicità in target.\nTi ricordiamo che eseguendo questa procedura continuerai a visualizzare le campagne pubblicitarie, ma risulteranno del tutto generiche e per te meno interessanti.\n\nAl fine di eseguire l'opt-out, sarà utilizzato un cookie registrato nel tuo browser. I nostri sistemi riscontrando la presenza di quel cookie, ti riconosceranno come utente che ha eseguito l'opt-out. Qualora il cookie dovesse venir cancellato, dovrai ripetere la procedura di opt-out.\nInfine ti ricordiamo che i cookie sono legati al browser ed al computer utilizzato. Pertanto se utilizzi più browser, ad esempio Firefox e Internet Explorer, dovrai eseguire la procedura per ciascun browser.";

  const messageOptIn =
    "Se invece hai eseguito l'opt-out e vuoi riattivare i servizi, clicca sul pulsante seguente.";

  const handleOptOut = () => {
    window.location.replace(
      `${
        process.env.REACT_APP_TRACKING_FUNCTION_URL
      }/OptOut?redirectURL=${encodeURIComponent(
        window.location.origin + window.location.pathname + '?OptOut=completed',
      )}`,
    );
  };

  const handleOptIn = () => {
    window.location.replace(
      `${
        process.env.REACT_APP_TRACKING_FUNCTION_URL
      }/OptIn?redirectURL=${encodeURIComponent(
        window.location.origin + window.location.pathname + '?OptIn=completed',
      )}`,
    );
  };

  return (
    <React.Fragment>
      <div className="account-pages my-4 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={8}>
              <div className="text-center mb-4">
                <img src={logoDark} alt="" height={isMobile ? '50' : '90'} />
                <div className="row justify-content-center">
                  <div className="col-xl-12 mt-4">
                    <p className="font-size-14 mb-3">
                      {reactStringReplace(messageOptOut, '\n', (match, i) => (
                        <br key={i} />
                      ))}
                    </p>
                    {!optOutStatus ? (
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={handleOptOut}>
                        <i className="bx bxs-hand-down font-size-16 align-middle me-2"></i>{' '}
                        Esegui Opt-out
                      </button>
                    ) : (
                      <div class="badge badge-soft-success font-size-12 mb-3 p-2 text-wrap">
                        Opt-out completato!
                      </div>
                    )}
                    <p className="font-size-14 mt-4 mb-3">{messageOptIn}</p>
                    {!optInStatus ? (
                      <button
                        type="button"
                        className="btn btn-success mb-3"
                        onClick={handleOptIn}>
                        <i className="bx bxs-hand-up font-size-16 align-middle me-2"></i>{' '}
                        Esegui Opt-in
                      </button>
                    ) : (
                      <div class="badge badge-soft-success font-size-12 mb-3 p-2 text-wrap">
                        Opt-in completato!
                      </div>
                    )}
                  </div>
                </div>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(OptInOut);
