import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getSort } from '../Common/DataTable/tableHelpers';

const createTableColumns = (openViewModal, openEditModal) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
  },
  {
    title: 'Action',
    name: 'action',
    width: 120,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
