import React from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select, Switch } from '../../lib/form-field';
import { FieldArray } from 'formik';
import '@availity/yup';
import formValidation from './formValidation';

import * as leadFormsActions from '../../store/leadForms/actions';
import { useSelector, useDispatch } from 'react-redux';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormLeadForm = (props) => {
  const editingLeadForm = props.leadForm || {};

  const leadFields = useSelector((state) => state.LeadField.leadFields);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? leadFormsActions.createLeadForm(values)
        : leadFormsActions.updateLeadForm({
            id: editingLeadForm.id,
            ...values,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingLeadForm}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="6">
                  <Input type="text" name="name" />
                </Col>
              </Row>
              <Row>
                <Col md="10">
                  <FieldArray
                    name="leadFields"
                    render={(arrayHelpers) => {
                      const fields = values.leadFields;
                      return (
                        <FormGroup>
                          <Label htmlFor="leadFields">Lead Fields</Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() =>
                              arrayHelpers.push({
                                leadFieldId: '',
                                required: false,
                                readOnly: false,
                              })
                            }>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(fields) &&
                            fields.map((field, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="6">
                                  <Select
                                    name={`leadFields.${index}.leadFieldId`}
                                    label="Field"
                                    isMulti={false}
                                    isSearchable
                                    addonType="append"
                                    options={leadFields.map(({ id, name }) => ({
                                      label: name,
                                      value: id,
                                    }))}>
                                    <button
                                      className="btn btn-danger p-0"
                                      style={{ width: '31px' }}
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }>
                                      <i className="bx bx-trash d-block font-size-15" />
                                    </button>
                                  </Select>
                                </Col>
                                <Col md="3">
                                  <Switch
                                    name={`leadFields.${index}.required`}
                                    label="Required?"
                                    defaultChecked={
                                      values.leadFields[index]?.required
                                    }
                                  />
                                </Col>
                                <Col md="3">
                                  <Switch
                                    name={`leadFields.${index}.readOnly`}
                                    label="Read only?"
                                    defaultChecked={
                                      values.leadFields[index]?.readOnly
                                    }
                                  />
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormLeadForm;
