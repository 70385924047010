import React, { memo } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Dots } from 'react-activity';
import useClipboard from 'react-hook-clipboard';
import ReactJson from 'react-json-view';
import { orderMapByKey } from '../../helpers/sharedFunction';
import moment from 'moment';

const isFloat = (n) => Number(n) === n && n % 1 !== 0;

const valueToString = (value) => {
  if (value === null) return { type: 'null', value: 'null' };
  if (typeof value.getMonth === 'function')
    return { type: 'date', value: moment(value).format('DD/MM/YYYY HH:mm') };
  if (Array.isArray(value))
    return { type: 'array', value: `[ ${value.join(', ')} ]` };
  if (typeof value === 'boolean')
    return { type: 'boolean', value: value ? 'true' : 'false' };
  if (isFloat(value)) return { type: 'number', value: value.toFixed(2) };
  if (typeof value === 'object') return { type: 'object', value };
  return { type: 'other', value };
};

const ShowSingleElement = ({ element, icon }) => {
  const [clipboard, copyToClipboard] = useClipboard();

  let elementSorted = orderMapByKey(element || {});
  elementSorted = { id: element?.id, ...elementSorted };
  const values = element
    ? Object.entries(elementSorted).map(([key, value]) => ({
        key,
        ...valueToString(value),
      }))
    : [];

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            {element ? (
              <CardBody>
                <div className="font-size-18 d-flex align-items-center">
                  {icon && (
                    <i
                      className={icon}
                      style={{ marginRight: '10px', cursor: 'pointer' }}
                      onClick={() => copyToClipboard(element.id)}></i>
                  )}
                  <h4 className="font-weight-bold">
                    {element.name || element.title || element.id}
                  </h4>
                </div>
                <div className="table-responsive">
                  <Table className="table-nowrap table-sm m-0" striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.map(({ key, type, value }) => (
                        <tr key={key}>
                          <td>
                            <strong>{key}</strong>
                          </td>
                          <td>
                            {type === 'object' ? (
                              <ReactJson
                                src={value}
                                collapsed={2}
                                collapseStringsAfterLength={70}
                              />
                            ) : (
                              value
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            ) : (
              <Dots />
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default memo(ShowSingleElement);
