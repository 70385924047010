const statsTypes = {
  RESET_CAMPAIGN_STATE: 'RESET_CAMPAIGN_STATE',
  CREATE_CAMPAIGN: {
    REQUEST: 'CREATE_CAMPAIGN.REQUEST',
    SUCCESS: 'CREATE_CAMPAIGN.SUCCESS',
    FAILURE: 'CREATE_CAMPAIGN.FAILURE',
  },
  UPDATE_CAMPAIGN: {
    REQUEST: 'UPDATE_CAMPAIGN.REQUEST',
    SUCCESS: 'UPDATE_CAMPAIGN.SUCCESS',
    FAILURE: 'UPDATE_CAMPAIGN.FAILURE',
  },
  UPDATE_ACQUISITION_ORDER_ID: {
    REQUEST: 'UPDATE_ACQUISITION_ORDER_ID.REQUEST',
    SUCCESS: 'UPDATE_ACQUISITION_ORDER_ID.SUCCESS',
    FAILURE: 'UPDATE_ACQUISITION_ORDER_ID.FAILURE',
  },
  SYNC_FB_CAMPAIGNS: {
    REQUEST: 'SYNC_FB_CAMPAIGNS.REQUEST',
    SUCCESS: 'SYNC_FB_CAMPAIGNS.SUCCESS',
    FAILURE: 'SYNC_FB_CAMPAIGNS.FAILURE',
  },
  SYNC_CAMPAIGNS: {
    REQUEST: 'SYNC_CAMPAIGNS.REQUEST',
    SUCCESS: 'SYNC_CAMPAIGNS.SUCCESS',
    FAILURE: 'SYNC_CAMPAIGNS.FAILURE',
  },
};

export default statsTypes;
