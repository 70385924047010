import React from 'react';
import {contentContainer as contentContainerStyle} from "../styles/SweetAlertStyles";

interface ContentProps {
  children?: React.ReactNode;
}

const Content: React.FunctionComponent<ContentProps> = ({ children }) => (
  <div style={contentContainerStyle}>
      {children}
  </div>
);

export default Content;
