import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as leadFieldsActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

const leadFieldTransformer = (payload) => {
  let leadFields = [];

  payload.forEach((leadField) => {
    const data = leadField.data();

    leadFields.push({
      id: leadField.id,
      ...data,
      sort: data.sort || 999,
    });
  });

  return leadFields;
};

function* syncLeadFieldsSaga() {
  const leadFieldsRef = query(
    collection(db, 'leadFields'),
    orderBy('name', 'asc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, leadFieldsRef, {
    successActionCreator: leadFieldsActions.syncLeadFieldsSuccess,
    failureActionCreator: leadFieldsActions.syncLeadFieldsFailure,
    transform: (payload) => leadFieldTransformer(payload),
  });

  yield take(types.RESET_LEAD_FIELD_STATE);
  yield cancel(task);
}

function* createLeadFieldSaga({ leadField }) {
  try {
    const leadFieldsRef = collection(db, 'leadFields');

    yield call(rsf.firestore.addDocument, leadFieldsRef, {
      ...leadField,
    });
    yield put(leadFieldsActions.createLeadFieldSuccess(leadField));
    toastr.success('LeadField created!', '');
  } catch (error) {
    yield put(leadFieldsActions.createLeadFieldFailure(error));
  }
}

function* updateLeadFieldSaga({ leadField }) {
  try {
    const leadFieldsRef = doc(db, 'leadFields', leadField.id);
    delete leadField.id;

    yield call(
      rsf.firestore.setDocument,
      leadFieldsRef,
      {
        ...leadField,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(leadFieldsActions.updateLeadFieldSuccess(leadField));
    toastr.success('LeadField updated!', '');
  } catch (error) {
    yield put(leadFieldsActions.updateLeadFieldFailure(error));
  }
}

function* sortLeadFieldSaga({ leadFields }) {
  try {
    yield all(
      leadFields.map((leadField) => call(updateLeadFieldSaga, { leadField })),
    );
    yield put(leadFieldsActions.sortLeadFieldsSuccess(leadFields));
    toastr.success('LeadField sorted!', '');
  } catch (error) {
    yield put(leadFieldsActions.sortLeadFieldsFailure(error));
  }
}

function* leadFieldSaga() {
  yield takeLatest(types.SYNC_LEAD_FIELDS.REQUEST, syncLeadFieldsSaga);
  yield all([
    takeLeading(types.CREATE_LEAD_FIELD.REQUEST, createLeadFieldSaga),
    takeLeading(types.UPDATE_LEAD_FIELD.REQUEST, updateLeadFieldSaga),
    takeLeading(types.SORT_LEAD_FIELDS.REQUEST, sortLeadFieldSaga),
  ]);
}

export default leadFieldSaga;
