const timeSlots = () => {
  let slots = [];
  for (let i = 9; i < 20; i++) {
    slots.push({ label: `${i}.00`, value: `${i}.00` });
    slots.push({ label: `${i}.30`, value: `${i}.30` });
  }
  return [{ label: 'Now', value: 'now' }, ...slots];
};

export default timeSlots;
