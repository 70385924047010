import types from './actionTypes';

const initialState = {
  loading: false,
  leadFields: [],
  error: '',
};

const leadFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_LEAD_FIELD_STATE:
      return initialState;
    case types.CREATE_LEAD_FIELD.REQUEST:
    case types.UPDATE_LEAD_FIELD.REQUEST:
    case types.SYNC_LEAD_FIELDS.REQUEST:
    case types.SORT_LEAD_FIELDS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_LEAD_FIELD.SUCCESS:
    case types.UPDATE_LEAD_FIELD.SUCCESS:
    case types.SORT_LEAD_FIELDS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_LEAD_FIELDS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        leadFields: [...action.leadFields],
      };
    case types.CREATE_LEAD_FIELD.FAILURE:
    case types.UPDATE_LEAD_FIELD.FAILURE:
    case types.SYNC_LEAD_FIELDS.FAILURE:
    case types.SORT_LEAD_FIELDS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default leadFieldReducer;
