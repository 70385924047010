export const types = {
  MARKETING_CAMPAIGN: 'marketingCampaign',
  DATA_ENRICHMENT: 'leadDataEnrichment',
};

export default [
  {
    label: 'Marketing Campaign',
    value: types.MARKETING_CAMPAIGN,
  },
  {
    label: 'Lead Data Enrichment',
    value: types.DATA_ENRICHMENT,
  },
];
