import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import Modal from '../Common/Modal/Modal';
import NegativeMonetizationItemInsights from './NegativeMonetizationItemInsights';

import { useSelector, useDispatch } from 'react-redux';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import * as usersActions from '../../store/users/actions';
import {
  countPositiveMonetizationItems,
  countNegativeMonetizationItems,
  countFakeMonetizationItems,
  percentagePositiveItems,
  percentageNegativeItems,
  percentageFakeItems,
  countPositiveMonetizationItemsByOrder,
  countMonetizationItemsByOrder,
  revenuesMonetizationItemsByOrder,
  costsMonetizationItemsByOrder,
} from '../../selectors/monetizationItem';
import { selectUserByRole } from '../../selectors/user';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';
import { orderByKey } from '../../helpers/sharedFunction';

const hideSensitiveDataString = '***';

const MonetizationItemInsights = ({
  monetizationOrder,
  userId,
  role,
  showSensitiveData,
}) => {
  const totalItems = useSelector(
    countMonetizationItemsByOrder(monetizationOrder.id, userId),
  );
  const positiveItems = useSelector(
    countPositiveMonetizationItemsByOrder(monetizationOrder.id, userId),
  );
  const revenues = useSelector(
    revenuesMonetizationItemsByOrder(monetizationOrder.id, userId),
  );
  const costs = useSelector(
    costsMonetizationItemsByOrder(monetizationOrder.id, userId),
  );

  const { t } = useTranslation();

  if (totalItems)
    return (
      <tr>
        <td className="align-middle">
          <h5 className="font-size-14 mb-1 text-wrap">
            {showSensitiveData
              ? monetizationOrder.name
              : hideSensitiveDataString}
          </h5>
        </td>
        <td className="align-bottom">
          <p className="text-muted mb-1">{t('Positive')}</p>
          <h5 className="mb-0">{positiveItems}</h5>
        </td>
        {(role === roles['OPERATOR'] || role === roles['TEAM_LEADER']) && (
          <td className="align-bottom">
            <p className="text-muted mb-1">{t('Total')}</p>
            <h5 className="mb-0">{totalItems}</h5>
          </td>
        )}
        {role !== roles['OPERATOR'] && role !== roles['TEAM_LEADER'] && (
          <>
            <td className="align-bottom">
              <p className="text-muted mb-1">{t('Revenues')}</p>
              <h5 className="mb-0 text-nowrap">
                {showSensitiveData
                  ? isNaN(revenues)
                    ? 0
                    : revenues.toFixed(2)
                  : hideSensitiveDataString}{' '}
                €
              </h5>
            </td>
            <td className="align-bottom">
              <p className="text-muted mb-1">{t('Qualify costs')}</p>
              <h5 className="mb-0 text-nowrap">
                {showSensitiveData
                  ? isNaN(costs)
                    ? 0
                    : costs.toFixed(2)
                  : hideSensitiveDataString}{' '}
                €
              </h5>
            </td>
          </>
        )}
      </tr>
    );

  return null;
};

const MonetizationItemsInsights = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const initialUserId = user.role === roles.OPERATOR ? user.id : null;
  const [userId, setUserId] = useState(initialUserId);
  const [
    viewNegativeMonetizationItemsModal,
    setViewNegativeMonetizationItemsModal,
  ] = useState(false);

  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const users = useSelector(
    selectUserByRole([roles.OPERATOR, roles.TEAM_LEADER], true),
  );

  const positiveCount = useSelector(countPositiveMonetizationItems(userId));
  const negativeCount = useSelector(countNegativeMonetizationItems(userId));
  const fakeCount = useSelector(countFakeMonetizationItems(userId));

  const percentagePositives = useSelector(percentagePositiveItems(userId));
  const percentageNegatives = useSelector(percentageNegativeItems(userId));
  const percentageFakes = useSelector(percentageFakeItems(userId));

  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
    dispatch(usersActions.syncUsers());
  }, []);

  const handleOperator = (userId) => {
    setUserId(userId === 'all' ? null : userId);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="clearfix">
            {!initialUserId && (
              <div className="float-right">
                <div className="input-group input-group-sm">
                  <select
                    className="custom-select custom-select-sm"
                    defaultValue="all"
                    onChange={(e) => handleOperator(e.target.value)}>
                    <option key="all" value="all">
                      {t('All')}
                    </option>
                    {Array.isArray(users) &&
                      orderByKey(users, 'lastName').map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.displayName}
                        </option>
                      ))}
                  </select>
                  <div className="input-group-append">
                    <label className="input-group-text">{t('Operator')}</label>
                  </div>
                </div>
              </div>
            )}
            <h4 className="card-title mb-4">{t('Closures')}</h4>
          </div>

          <Row className="d-flex p-2 justify-content-around">
            <Col md="4" className="text-muted text-center">
              <p className="mb-2">{t('Positives')}</p>
              <h4>{positiveCount}</h4>
              <p className="mt-4 mb-0">
                <span className="badge badge-soft-success font-size-11 mr-2">
                  {`${
                    isFinite(percentagePositives)
                      ? percentagePositives.toFixed(2)
                      : 0
                  }%`}
                </span>
              </p>
            </Col>
            <Col
              md="4"
              className="text-muted text-center"
              style={{ cursor: 'pointer' }}
              onClick={() => setViewNegativeMonetizationItemsModal(true)}>
              <p className="mb-2">{t('Negatives')}</p>
              <h4>{negativeCount}</h4>
              <p className="mt-4 mb-0">
                <span className="badge badge-soft-danger font-size-11 mr-2">
                  {`${
                    isFinite(percentageNegatives)
                      ? percentageNegatives.toFixed(2)
                      : 0
                  }%`}
                </span>
              </p>
            </Col>
            <Col md="4" className="text-muted text-center">
              <p className="mb-2">Fakes</p>
              <h4>{fakeCount}</h4>
              <p className="mt-4 mb-0">
                <span className="badge badge-soft-warning font-size-11 mr-2">
                  {`${
                    isFinite(percentageFakes) ? percentageFakes.toFixed(2) : 0
                  }%`}
                </span>
              </p>
            </Col>
          </Row>

          <div className="table-responsive mt-4">
            <Table className="mb-0">
              <tbody>
                {monetizationOrders.map((monetizationOrder, index) => (
                  <MonetizationItemInsights
                    key={'monetizationOrder_' + index}
                    monetizationOrder={monetizationOrder}
                    userId={userId}
                    role={user.role}
                    showSensitiveData={showSensitiveData}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Modal
        size="lg"
        title={t('Negative closures')}
        isOpen={viewNegativeMonetizationItemsModal}
        toggle={() =>
          setViewNegativeMonetizationItemsModal(
            !viewNegativeMonetizationItemsModal,
          )
        }
        showClose
        scrollable={false}>
        <NegativeMonetizationItemInsights userId={userId} role={user.role} />
      </Modal>
    </React.Fragment>
  );
};

export default MonetizationItemsInsights;
