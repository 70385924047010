import React from 'react';
import { Col, Card, CardBody, Media, Text } from 'reactstrap';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const ShowSuggestion = ({ acquisitionOrder }) => {
  const HTMLSuggestion = EditorState.createWithContent(
    convertFromRaw(JSON.parse(acquisitionOrder.suggestion)),
  );
  const RenderSuggestion = () => (
    <>{parse(draftToHtml(convertToRaw(HTMLSuggestion.getCurrentContent())))}</>
  );

  const { t } = useTranslation();

  return (
    <>
      <Card>
        <CardBody>
          <div className="mb-3" style={{ justifyContent: 'space-between' }}>
            <h4 className="card-title" style={{ margin: 0 }}>
              {t('Suggestion')}
            </h4>
          </div>
          <RenderSuggestion />
        </CardBody>
      </Card>
    </>
  );
};

export default ShowSuggestion;
