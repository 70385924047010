import types from './actionTypes';

const initialState = {
  loading: false,
  pushNotifications: [],
  startDate: null,
  endDate: null,
  error: '',
};

const pushNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PUSH_NOTIFICATION_STATE:
      return initialState;
    case types.CREATE_PUSH_NOTIFICATION.REQUEST:
    case types.FETCH_PUSH_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_PUSH_NOTIFICATION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        pushNotifications: [
          ...state.pushNotifications,
          action.pushNotification,
        ],
      };
    case types.FETCH_PUSH_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        pushNotifications: action.pushNotifications,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case types.CREATE_PUSH_NOTIFICATION.FAILURE:
    case types.FETCH_PUSH_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default pushNotificationReducer;
