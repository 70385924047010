import { getSort } from '../Common/DataTable/tableHelpers';
import { secondsToHHMMSS } from '../../helpers/sharedFunction';

const createTableColumns = (t, isAdmin = false) => [
  {
    title: 'Operator',
    name: 'name',
    width: 180,
  },
  {
    title: 'Closed Calls',
    name: 'closedCalls',
    summary: 'sum',
  },
  {
    title: 'Spoken Calls',
    name: 'spokenCalls',
    summary: 'sum',
  },
  {
    title: `${t('Positives')} / ${t('Spoken Calls')} (%)`,
    name: 'percentagePositiveSpokenCalls',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: `${t('Negatives')} / ${t('Spoken Calls')} (%)`,
    name: 'percentageNegativeSpokenCalls',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: 'Positives',
    name: 'positives',
    filter: 'disabled',
    summary: 'sum',
  },
  {
    title: `${t('Positives')} (%)`,
    name: 'percentagePositives',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: 'Duration positives',
    name: 'averageDurationPositives',
    formatterComponent: ({ value }) =>
      isFinite(value) ? secondsToHHMMSS(value, true) : '00:00',
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: 'Negative',
    name: 'negatives',
    sort: true,
    footer: (columnsData) =>
      columnsData.reduce((acc, column) => acc + column, 0),
  },
  {
    title: `${t('Negative')} (%)`,
    name: 'percentageNegatives',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: 'Duration negatives',
    name: 'averageDurationNegatives',
    formatterComponent: ({ value }) =>
      isFinite(value) ? secondsToHHMMSS(value, true) : '00:00',
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
  },
  {
    title: `${t('Fakes')} (%)`,
    name: 'percentageFakes',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  {
    title: 'Duration fakes',
    name: 'averageDurationFakes',
    formatterComponent: ({ value }) =>
      isFinite(value) ? secondsToHHMMSS(value, true) : '00:00',
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  {
    title: `${t('Recalls')} (%)`,
    name: 'percentageRecalls',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  {
    title: 'Duration recalls',
    name: 'averageDurationRecalls',
    formatterComponent: ({ value }) =>
      isFinite(value) ? secondsToHHMMSS(value, true) : '00:00',
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  {
    title: `${t('Schedule appointment')} (%)`,
    name: 'percentageAppointments',
    formatterComponent: ({ value }) =>
      `${isFinite(value) ? value.toFixed(2) : 0} %`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  {
    title: `${t('Duration appointments')} (%)`,
    name: 'averageDurationAppointments',
    formatterComponent: ({ value }) =>
      isFinite(value) ? secondsToHHMMSS(value, true) : '00:00',
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'avg',
    hidden: true,
  },
  isAdmin && {
    title: 'Revenues',
    name: 'revenues',
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    sorting: (a, b) => getSort(isFinite(a) ? a : 0, isFinite(b) ? b : 0),
    filter: 'disabled',
    summary: 'sum',
  },
];

export default createTableColumns;
