const statsTypes = {
  RESET_LEAD_FIELD_STATE: 'RESET_LEAD_FIELD_STATE',
  CREATE_LEAD_FIELD: {
    REQUEST: 'CREATE_LEAD_FIELD.REQUEST',
    SUCCESS: 'CREATE_LEAD_FIELD.SUCCESS',
    FAILURE: 'CREATE_LEAD_FIELD.FAILURE',
  },
  UPDATE_LEAD_FIELD: {
    REQUEST: 'UPDATE_LEAD_FIELD.REQUEST',
    SUCCESS: 'UPDATE_LEAD_FIELD.SUCCESS',
    FAILURE: 'UPDATE_LEAD_FIELD.FAILURE',
  },
  SYNC_LEAD_FIELDS: {
    REQUEST: 'SYNC_LEAD_FIELDS.REQUEST',
    SUCCESS: 'SYNC_LEAD_FIELDS.SUCCESS',
    FAILURE: 'SYNC_LEAD_FIELDS.FAILURE',
  },
  SORT_LEAD_FIELDS: {
    REQUEST: 'SORT_LEAD_FIELDS.REQUEST',
    SUCCESS: 'SORT_LEAD_FIELDS.SUCCESS',
    FAILURE: 'SORT_LEAD_FIELDS.FAILURE',
  },
};

export default statsTypes;
