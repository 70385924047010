import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as countriesActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const db = getFirestore(rsf.app);

function* createCountrySaga({ country }) {
  try {
    const countriesRef = collection(db, 'countries');

    yield call(rsf.firestore.addDocument, countriesRef, {
      ...country,
      createdAt: serverTimestamp(),
    });
    yield put(countriesActions.createCountrySuccess(country));
    toastr.success('Country created!', '');
  } catch (error) {
    yield put(countriesActions.createCountryFailure(error));
  }
}

function* updateCountrySaga({ country }) {
  try {
    const countriesRef = doc(db, 'countries', country.id);
    delete country.id;

    yield call(
      rsf.firestore.setDocument,
      countriesRef,
      {
        ...country,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(countriesActions.updateCountrySuccess(country));
    toastr.success('Country updated!', '');
  } catch (error) {
    yield put(countriesActions.updateCountryFailure(error));
  }
}

const countryTransformer = (payload) => {
  let countries = [];

  payload.forEach((country) => {
    const data = country.data();
    countries.push({
      id: country.id,
      ...data,
      createdAt: toDateFirebase(country, data).toDate(),
    });
  });

  return countries;
};

function* syncCountriesSaga() {
  const countriesRef = query(
    collection(db, 'countries'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, countriesRef, {
    successActionCreator: countriesActions.syncCountriesSuccess,
    failureActionCreator: countriesActions.syncCountriesFailure,
    transform: (payload) => countryTransformer(payload),
  });

  yield take(types.RESET_COUNTRY_STATE);
  yield cancel(task);
}

function* countrySaga() {
  yield all([
    takeLatest(types.SYNC_COUNTRIES.REQUEST, syncCountriesSaga),
    takeLeading(types.CREATE_COUNTRY.REQUEST, createCountrySaga),
    takeLeading(types.UPDATE_COUNTRY.REQUEST, updateCountrySaga),
  ]);
}

export default countrySaga;
