import types from './actionTypes';

const initialState = {
  loading: false,
  campaigns: [],
  error: '',
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CAMPAIGN_STATE:
      return initialState;
    case types.CREATE_CAMPAIGN.REQUEST:
    case types.UPDATE_CAMPAIGN.REQUEST:
    case types.UPDATE_ACQUISITION_ORDER_ID.REQUEST:
    case types.SYNC_CAMPAIGNS.REQUEST:
    case types.SYNC_FB_CAMPAIGNS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_CAMPAIGN.SUCCESS:
    case types.UPDATE_CAMPAIGN.SUCCESS:
    case types.UPDATE_ACQUISITION_ORDER_ID.SUCCESS:
    case types.SYNC_FB_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        campaigns: [...action.campaigns],
      };
    case types.CREATE_CAMPAIGN.FAILURE:
    case types.UPDATE_CAMPAIGN.FAILURE:
    case types.UPDATE_ACQUISITION_ORDER_ID.FAILURE:
    case types.SYNC_CAMPAIGNS.FAILURE:
    case types.SYNC_FB_CAMPAIGNS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default campaignReducer;
