import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as leadFormsActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

const leadFormTransformer = (payload) => {
  let leadForms = [];

  payload.forEach((leadForm) => {
    const data = leadForm.data();

    leadForms.push({
      id: leadForm.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(leadForm, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(leadForm, data, 'updatedAt').toDate(),
      }),
    });
  });

  return leadForms;
};

function* syncLeadFormsSaga() {
  const leadFormsRef = query(
    collection(db, 'leadForms'),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, leadFormsRef, {
    successActionCreator: leadFormsActions.syncLeadFormsSuccess,
    failureActionCreator: leadFormsActions.syncLeadFormsFailure,
    transform: (payload) => leadFormTransformer(payload),
  });

  yield take(types.RESET_LEAD_FORM_STATE);
  yield cancel(task);
}

function* createLeadFormSaga({ leadForm }) {
  try {
    const leadFormsRef = collection(db, 'leadForms');

    yield call(rsf.firestore.addDocument, leadFormsRef, {
      ...leadForm,
      createdAt: serverTimestamp(),
    });
    yield put(leadFormsActions.createLeadFormSuccess(leadForm));
    toastr.success('LeadForm created!', '');
  } catch (error) {
    yield put(leadFormsActions.createLeadFormFailure(error));
  }
}

function* updateLeadFormSaga({ leadForm }) {
  try {
    const leadFormsRef = doc(db, 'leadForms', leadForm.id);
    delete leadForm.id;

    yield call(
      rsf.firestore.setDocument,
      leadFormsRef,
      {
        ...leadForm,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(leadFormsActions.updateLeadFormSuccess(leadForm));
    toastr.success('LeadForm updated!', '');
  } catch (error) {
    yield put(leadFormsActions.updateLeadFormFailure(error));
  }
}

function* leadFormSaga() {
  yield takeLatest(types.SYNC_LEAD_FORMS.REQUEST, syncLeadFormsSaga);
  yield all([
    takeLeading(types.CREATE_LEAD_FORM.REQUEST, createLeadFormSaga),
    takeLeading(types.UPDATE_LEAD_FORM.REQUEST, updateLeadFormSaga),
  ]);
}

export default leadFormSaga;
