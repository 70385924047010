import { HistoryButton } from '../../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getSort,
  hideSensitiveData,
  qualifiedName,
} from '../../Common/DataTable/tableHelpers';
import moment from 'moment';

const statusName = (row) => {
  if (row.qualified === 'new' || row.survey === 'new') return 'new';
  else if (row.error === '' || !row.error) return 'ok';
  return 'error';
};

const createTableColumns = (openHistoryModal, campaigns, showSensitiveData) => [
  {
    title: 'Campaign',
    name: 'campaignId',
    formatterComponent: ({ value }) => getLabel(value, campaigns),
    sorting: (a, b) => getSort(getLabel(a, campaigns), getLabel(b, campaigns)),
    filter: (value, filter) =>
      getFilter(getLabel(value, campaigns), filter.value),
    hidden: true,
  },
  {
    title: 'Name',
    name: 'name',
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 4),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
  },
  {
    title: 'Email',
    name: 'email',
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 4),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
  },
  {
    title: 'Phone',
    name: 'phone',
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 6),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
  },
  {
    title: 'Gender',
    name: 'gender',
    width: 100,
    hidden: true,
  },
  {
    title: 'City',
    name: 'city',
    width: 80,
    formatterComponent: ({ value }) =>
      showSensitiveData ? value : hideSensitiveData(value, 4),
    filter: showSensitiveData ? undefined : 'disabled',
    sorting: showSensitiveData ? undefined : 'disabled',
    hidden: true,
  },
  {
    title: 'Status',
    name: 'status',
    width: 100,
    sort: true,
    formatterComponent: ({ row }) =>
      row.qualified === 'new' && row.survey === 'new' ? (
        <span className="badge badge-warning font-size-12">NEW</span>
      ) : row.error === '' || !row.error ? (
        <span className="badge badge-success font-size-12">OK</span>
      ) : (
        <span className="badge badge-danger font-size-12">ERROR</span>
      ),
    filter: (value, filter, row) => getFilter(statusName(row), filter.value),
    sorting: 'disabled',
  },
  {
    title: 'Qualified',
    name: 'qualified',
    width: 110,
    formatterComponent: ({ value }) => qualifiedName(value, true),
    filter: (value, filter) => getFilter(qualifiedName(value), filter.value),
    sorting: 'disabled',
  },
  {
    title: 'Source',
    name: 'source',
    width: 100,
    hidden: true,
  },
  {
    title: 'Created At',
    name: 'createdAt',
    width: 120,
    formatterComponent: ({ value }) => `${moment(value).fromNow()}`,
    sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    filter: (value, filter) =>
      getFilter(`${moment(value).fromNow()}`, filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 150,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {row.qualified !== 'new' && (
          <HistoryButton row={row} onPress={openHistoryModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
