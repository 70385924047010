import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword as fireSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as fireCreateUserWithEmailAndPassword,
  sendPasswordResetEmail as fireSendPasswordResetEmail,
  sendEmailVerification as fireSendEmailVerification,
  signInAnonymously as fireSignInAnonymously,
  signInWithRedirect as fireSignInWithRedirect,
  signInWithCredential as fireSignInWithCredential,
  signInWithCustomToken as fireSignInWithCustomToken,
  signInWithPhoneNumber as fireSignInWithPhoneNumber,
  signInWithPopup as fireSignInWithPopup,
  confirmPasswordReset as fireConfirmPasswordReset,
  linkWithPopup as fireLinkWithPopup,
  linkWithRedirect as fireLinkWithRedirect,
  applyActionCode as fireApplyActionCode,
  unlink as fireUnlink,
  updateEmail as fireUpdateEmail,
  updatePassword as fireUpdatePassword,
  updateProfile as fireUpdateProfile,
  signOut as fireSignOut,
  deleteUser as fireDeleteUser,
} from 'firebase/auth';
import { eventChannel } from 'redux-saga';
import { call } from 'redux-saga/effects';

function* applyActionCode(code) {
  const auth = getAuth(this.app);
  return yield call(fireApplyActionCode, auth, code);
}

function channel() {
  if (this._authChannel) return this._authChannel;

  const auth = getAuth(this.app);
  const channel = eventChannel((emit) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => emit({ user }),
      (error) => emit({ error }),
    );

    return unsubscribe;
  });

  this._authChannel = channel;
  return channel;
}

function* confirmPasswordReset(code, newPassword) {
  const auth = getAuth(this.app);
  return yield call(fireConfirmPasswordReset, auth, code, newPassword);
}

function* createUserWithEmailAndPassword(email, password) {
  const auth = getAuth(this.app);
  return yield call(fireCreateUserWithEmailAndPassword, auth, email, password);
}

function* deleteUser() {
  const auth = getAuth(this.app);
  return yield call(fireDeleteUser, auth.currentUser);
}

function* linkWithPopup(authProvider) {
  const auth = getAuth(this.app);
  return yield call(fireLinkWithPopup, auth.currentUser, authProvider);
}

function* linkWithRedirect(authProvider) {
  const auth = getAuth(this.app);
  return yield call(fireLinkWithRedirect, auth.currentUser, authProvider);
}

function* sendEmailVerification(actionCodeSettings) {
  const auth = getAuth(this.app);
  return yield call(
    fireSendEmailVerification,
    auth.currentUser,
    actionCodeSettings,
  );
}

function* sendPasswordResetEmail(email, actionCodeSettings) {
  const auth = getAuth(this.app);
  return yield call(
    fireSendPasswordResetEmail,
    auth,
    email,
    actionCodeSettings,
  );
}

function* signInAnonymously() {
  const auth = getAuth(this.app);
  return yield call(fireSignInAnonymously, auth);
}

function* signInWithCredential(credential) {
  const auth = getAuth(this.app);
  return yield call(fireSignInWithCredential, auth, credential);
}

function* signInWithCustomToken(token) {
  const auth = getAuth(this.app);
  return yield call(fireSignInWithCustomToken, auth, token);
}

function* signInWithEmailAndPassword(email, password) {
  const auth = getAuth(this.app);
  return yield call(fireSignInWithEmailAndPassword, auth, email, password);
}

function* signInWithPhoneNumber(phoneNumber, applicationVerifier) {
  const auth = getAuth(this.app);
  return yield call(
    fireSignInWithPhoneNumber,
    auth,
    phoneNumber,
    applicationVerifier,
  );
}

function* signInWithPopup(authProvider) {
  const auth = getAuth(this.app);
  return yield call(fireSignInWithPopup, auth, authProvider);
}

function* signInWithRedirect(authProvider) {
  const auth = getAuth(this.app);
  yield call(fireSignInWithRedirect, auth, authProvider);
}

function* signOut() {
  const auth = getAuth(this.app);
  yield call(fireSignOut, auth);
}

function* unlink(provider) {
  const auth = getAuth(this.app);
  return yield call(fireUnlink, auth.currentUser, provider);
}

function* updateEmail(email) {
  const auth = getAuth(this.app);
  return yield call(fireUpdateEmail, auth.currentUser, email);
}

function* updatePassword(password) {
  const auth = getAuth(this.app);
  return yield call(fireUpdatePassword, auth.currentUser, password);
}

function* updateProfile(profile) {
  const auth = getAuth(this.app);
  return yield call(fireUpdateProfile, auth.currentUser, profile);
}

export default {
  applyActionCode,
  channel,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  linkWithPopup,
  linkWithRedirect,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  unlink,
  updateEmail,
  updatePassword,
  updateProfile,
};
