export default [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Restart',
    value: 'restart',
  },
];
