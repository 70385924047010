import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as leadFieldsActions from '../../store/leadFields/actions';
import { createQueryBuilderConfig } from '../../selectors/leadField';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import './query-builder-style.css';

const QueryBuilder = (props) => {
  const leadQueryConfig = useSelector(createQueryBuilderConfig(props.fields));

  const config = {
    ...MaterialConfig,
    fields: leadQueryConfig,
  };

  const initQuery = { id: QbUtils.uuid(), type: 'group' };

  const [query, setQuery] = useState({
    tree: QbUtils.checkTree(
      QbUtils.loadTree(props.initialQuery || initQuery),
      config,
    ),
    config,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadFieldsActions.syncLeadFields());
  }, []);

  const onChange = useCallback((immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setQuery((prevState) => ({ ...prevState, tree: immutableTree, config }));

    const jsonTree = QbUtils.getTree(immutableTree, true, false);
    const sqlQuery = QbUtils.sqlFormat(immutableTree, config);
    const jsonLogicQuery = QbUtils.jsonLogicFormat(immutableTree, config);
    //console.log(jsonTree);
    props.onChange(jsonTree, sqlQuery, jsonLogicQuery);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container">
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    [],
  );

  const renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div>
        Query string:{' '}
        <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre>
      </div>
      <div>
        MongoDb query:{' '}
        <pre>
          {JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}
        </pre>
      </div>
      <div>
        SQL where:{' '}
        <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
      </div>
      <div>
        JsonLogic:{' '}
        <pre>
          {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}
        </pre>
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <Col md="12">
          <Label>{props.label}</Label>
          <Query
            {...config}
            value={query.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
        </Col>
      </Row>
      {props.debug && query.tree && (
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>{renderResult(query)}</CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default QueryBuilder;
