export default [
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Push Notification',
    value: 'pushNotification',
  },
];
