import React from 'react';
import styled from 'styled-components';
import Digit from './Digit';
import colors from '../../components/CommonStyle/colors';

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SeparatorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

const Separator = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: ${colors.secondary};
  border-radius: 5px;
  margin: 5px 0px;
`;

const Timer = ({ seconds, minutes, hours, days, color }) => (
  <TimerContainer>
    {days && (
      <>
        <Digit value={days} color={color} addSeparator />
        <SeparatorContainer>
          <Separator style={{ backgroundColor: color }} />
          <Separator style={{ backgroundColor: color }} />
        </SeparatorContainer>
      </>
    )}
    {hours && (
      <>
        <Digit value={hours} color={color} addSeparator />
        <SeparatorContainer>
          <Separator style={{ backgroundColor: color }} />
          <Separator style={{ backgroundColor: color }} />
        </SeparatorContainer>
      </>
    )}
    <Digit value={minutes} color={color} addSeparator />
    <SeparatorContainer>
      <Separator style={{ backgroundColor: color }} />
      <Separator style={{ backgroundColor: color }} />
    </SeparatorContainer>
    <Digit value={seconds} color={color} />
  </TimerContainer>
);

export default Timer;
