import React from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import {
  revenuesMonetizationItems,
  costsMonetizationItems,
  percentagePositiveItems,
  percentageNegativeItems,
  revenuePerLead,
  costPerQualify,
} from '../../selectors/monetizationItem';
import { totalCosts as leadTotalCosts } from '../../selectors/lead';
import { percentageSpokenCalls, countQueuedCalls } from '../../selectors/call';
import { totalCosts as fbTotalCosts } from '../../selectors/facebookAd/dailyCampaignInsight';
import { totalCosts as googleTotalCosts } from '../../selectors/googleAd/dailyCampaignInsight';
import { totalCosts as smsTotalCosts } from '../../selectors/smsMessage';

import MiniCard from '../../components/Common/MiniCard';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const MainMetrics = (props) => {
  const { sector, campaignIds } = props.filters;

  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles['OPERATOR'] ? user.id : null;

  const revenues = parseFloat(useSelector(revenuesMonetizationItems(userId)));
  const qualifyCosts = parseFloat(useSelector(costsMonetizationItems(userId)));
  const fbCosts = parseFloat(useSelector(fbTotalCosts(true)));
  const googleCosts = parseFloat(useSelector(googleTotalCosts(true)));
  const leadCosts = parseFloat(useSelector(leadTotalCosts));
  const smsCosts = parseFloat(useSelector(smsTotalCosts));

  const costs = qualifyCosts + fbCosts + googleCosts + leadCosts + smsCosts;
  const percentageCosts = (costs / revenues) * 100;

  const margin = revenues - costs;
  const percentageMargin = (margin / revenues) * 100;

  const queuedCalls = useSelector(countQueuedCalls({ sector, campaignIds }));
  const spokenCalls = useSelector(percentageSpokenCalls(userId)) / 100;
  const positiveItems =
    useSelector(percentagePositiveItems(userId, true)) / 100;
  const negativeItems =
    useSelector(percentageNegativeItems(userId, true)) / 100;
  const eRPL = useSelector(revenuePerLead(userId));
  const eCPQ = useSelector(costPerQualify(userId));

  const potentialRevenues = queuedCalls * spokenCalls * positiveItems * eRPL;
  const potentialCosts = queuedCalls * spokenCalls * negativeItems * eCPQ;
  const potentialMargin = potentialRevenues - potentialCosts;

  const { t } = useTranslation();

  const mainMetrics = [
    {
      title: t('Revenues'),
      text: `${isFinite(revenues) ? revenues.toFixed(0) : 0} €`,
      color: 'dark',
      icon: 'fas fa-laugh-wink',
      subtext: {
        value: `${
          isFinite(potentialRevenues) ? potentialRevenues.toFixed(0) : 0
        } €`,
        title: t('potential'),
        color: 'dark',
      },
      isSensitiveData: true,
    },
    {
      title: t('Costs'),
      text: `${isFinite(costs) ? costs.toFixed(0) : 0} €`,
      color: 'danger',
      icon: 'fas fa-sad-tear',
      percentage: `${
        isFinite(percentageCosts) ? percentageCosts.toFixed(0) : 0
      }%`,
      subtext: {
        value: `${isFinite(potentialCosts) ? potentialCosts.toFixed(0) : 0} €`,
        title: t('potential'),
        color: 'danger',
      },
      isSensitiveData: true,
    },
    {
      title: t('Margin'),
      text: `${isFinite(margin) ? margin.toFixed(0) : 0} €`,
      color: 'success',
      icon: 'fas fa-grin-hearts',
      percentage: `${
        isFinite(percentageMargin) ? percentageMargin.toFixed(0) : 0
      }%`,
      subtext: {
        value: `${
          isFinite(potentialMargin) ? potentialMargin.toFixed(0) : 0
        } €`,
        title: t('potential'),
        color: 'success',
      },
      isSensitiveData: true,
    },
  ];

  const loadingMonetizationItems = useSelector(
    (state) => state.MonetizationItem.loading,
  );
  const loadingFBInsights = useSelector(
    (state) => state.DailyFBCampaignInsights.loading,
  );
  const loadingGoogleInsights = useSelector(
    (state) => state.DailyGoogleCampaignInsights.loading,
  );

  return (
    <>
      {mainMetrics.map((metric, index) => (
        <MiniCard
          key={'mainMetric_' + index}
          {...metric}
          loading={
            loadingMonetizationItems ||
            loadingFBInsights ||
            loadingGoogleInsights
          }
        />
      ))}
    </>
  );
};

export default MainMetrics;
