import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import * as peopleActions from '../../store/people/actions';
import { useDispatch, useSelector } from 'react-redux';
import createTableColumns from '../../components/People/tableColumns';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';

const AudiencesQueryTable = ({ audiences, audienceId }) => {
  const people = useSelector((state) => state.People.people);
  const loading = useSelector((state) => state.People.loading);
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const [selectedPerson, setSelectedPerson] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  const openViewModal = (person) => {
    setSelectedPerson(person);
    setViewModal(true);
  };

  const columns = createTableColumns(
    audiences,
    openViewModal,
    showSensitiveData,
  );

  useEffect(() => {
    audienceId && dispatch(peopleActions.fetchPeopleByAudience([audienceId]));
  }, [audienceId]);

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <DataTableNext
                loading={loading}
                rows={people}
                columns={columns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        title="Person"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={selectedPerson} icon="bx bx-street-view" />
      </Modal>
    </>
  );
};

export default AudiencesQueryTable;
