import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  personalAttribute: yup.boolean(),
  required: yup.boolean(),
  showInFormLead: yup.boolean(),
  size: yup.string().when('showInFormLead', {
    is: true,
    then: () => yup.string().required(),
  }),
  onChangeUpdateField: yup.string().required(),
  onChangeUpdateKey: yup.string().when('onChangeUpdateField', {
    is: (value) => value !== 'none',
    then: () => yup.string().required(),
  }),
  typeField: yup.string().required(),
  sectors: yup.array().when('showInFormLead', {
    is: true,
    then: () => yup.array().of(yup.string()).min(1).required(),
  }),
  asyncUrlValues: yup.string().when('typeField', {
    is: 'async-select',
    then: () => yup.string().url().required(),
  }),
  listValues: yup.string().when('typeField', {
    is: 'select',
    then: () =>
      yup
        .string()
        .matches(
          /^[a-zA-Z\u00C0-\u017F0-9]+((,|'|-|\s|\/)[a-zA-Z\u00C0-\u017F0-9]+)*[a-zA-Z\u00C0-\u017F0-9]+$/,
        )
        .required(),
  }),
});

export default formValidation;
