import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SURVEY_STATE,
});

export const createSurvey = (survey) => ({
  type: types.CREATE_SURVEY.REQUEST,
  survey,
});

export const createSurveySuccess = (survey) => ({
  type: types.CREATE_SURVEY.SUCCESS,
  survey,
});

export const createSurveyFailure = (error) => ({
  type: types.CREATE_SURVEY.FAILURE,
  error,
});

export const updateSurvey = (survey) => ({
  type: types.UPDATE_SURVEY.REQUEST,
  survey,
});

export const updateSurveySuccess = (survey) => ({
  type: types.UPDATE_SURVEY.SUCCESS,
  survey,
});

export const updateSurveyFailure = (error) => ({
  type: types.UPDATE_SURVEY.FAILURE,
  error,
});

export const fetchSurveys = (startDate, endDate) => ({
  type: types.FETCH_SURVEYS.REQUEST,
  startDate,
  endDate,
});

export const fetchSurveysSuccess = (surveys, startDate, endDate) => ({
  type: types.FETCH_SURVEYS.SUCCESS,
  surveys,
  startDate,
  endDate,
});

export const fetchSurveysFailure = (error) => ({
  type: types.FETCH_SURVEYS.FAILURE,
  error,
});

export const fetchSurveyByLead = (leadId) => ({
  type: types.FETCH_SURVEY_BY_LEAD.REQUEST,
  leadId,
});

export const fetchSurveyByLeadSuccess = (survey) => ({
  type: types.FETCH_SURVEY_BY_LEAD.SUCCESS,
  survey,
});

export const fetchSurveyByLeadFailure = (error) => ({
  type: types.FETCH_SURVEY_BY_LEAD.FAILURE,
  error,
});

export const importPartialSurveys = (surveyFormId, partialSurveys) => ({
  type: types.IMPORT_PARTIAL_SURVEYS.REQUEST,
  surveyFormId,
  partialSurveys,
});

export const importPartialSurveysSuccess = () => ({
  type: types.IMPORT_PARTIAL_SURVEYS.SUCCESS,
});

export const importPartialSurveysFailure = (error) => ({
  type: types.IMPORT_PARTIAL_SURVEYS.FAILURE,
  error,
});
