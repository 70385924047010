import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { useSelector } from 'react-redux';

import { drawSeriesDailyGraph } from '../../../selectors/customer';
import ReactApexChart from 'react-apexcharts';

import CenterBox from '../../Common/CenterBox';
import colors from '../../CommonStyle/colors';
import { useTranslation } from 'react-i18next';

const RevenuesGraph = (props) => {
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const { t } = useTranslation();

  const dataGraph = useSelector(
    drawSeriesDailyGraph(props.dateRange, t, props.showRebate, props.isAgency),
  );

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: [3, 2, 3],
        dashArray: [0, 6, 0],
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: colors.dark,
          },
        ],
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
            formatter: (val, index) => (val ? `${val.toFixed(0)} €` : '0 €'),
          },
          title: {
            text: dataGraph.series[0].name,
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[1].name,
          forceNiceScale: true,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[1].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[1].color,
            },
            formatter: (val, index) => (val ? val.toFixed(0) : '0'),
          },
          title: {
            text: dataGraph.series[1].name,
            style: {
              color: dataGraph.series[1].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[2].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          labels: {
            formatter: (val, index) => (val ? `${val.toFixed(0)} €` : '0 €'),
          },
          show: false,
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">{t('Earnings')}</CardTitle>
          <div className="clearfix"></div>
          {showSensitiveData ? (
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                series={dailyGraph.series}
                options={dailyGraph.options}
                type="line"
                height={290}
              />
            </div>
          ) : (
            <CenterBox height={290}>
              <i className="bx bx-hide font-size-32 text-secondary"></i>
            </CenterBox>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenuesGraph;
