import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DAILY_GOOGLE_CAMPAIGN_INSIGHT_STATE,
});

export const fetchDailyInsights = (startDate, endDate, filters) => ({
  type: types.DAILY_GOOGLE_CAMPAIGN_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchDailyInsightsSuccess = (insights, startDate, endDate, filters = null) => ({
  type: types.DAILY_GOOGLE_CAMPAIGN_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
  filters,
});

export const fetchDailyInsightsFailure = (error) => ({
  type: types.DAILY_GOOGLE_CAMPAIGN_INSIGHTS.FAILURE,
  error,
});
