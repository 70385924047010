const statsTypes = {
  RESET_PERSON_STATE: 'RESET_PERSON_STATE',
  CREATE_PERSON: {
    REQUEST: 'CREATE_PERSON.REQUEST',
    SUCCESS: 'CREATE_PERSON.SUCCESS',
    FAILURE: 'CREATE_PERSON.FAILURE',
  },
  UPDATE_PERSON: {
    REQUEST: 'UPDATE_PERSON.REQUEST',
    SUCCESS: 'UPDATE_PERSON.SUCCESS',
    FAILURE: 'UPDATE_PERSON.FAILURE',
  },
  FETCH_PEOPLE: {
    REQUEST: 'FETCH_PEOPLE.REQUEST',
    SUCCESS: 'FETCH_PEOPLE.SUCCESS',
    FAILURE: 'FETCH_PEOPLE.FAILURE',
  },
  FETCH_PEOPLE_BY_AUDIENCE: {
    REQUEST: 'FETCH_PEOPLE_BY_AUDIENCE.REQUEST',
    SUCCESS: 'FETCH_PEOPLE_BY_AUDIENCE.SUCCESS',
    FAILURE: 'FETCH_PEOPLE_BY_AUDIENCE.FAILURE',
  },
  FETCH_PERSON_BY_ID: {
    REQUEST: 'FETCH_PERSON_BY_ID.REQUEST',
    SUCCESS: 'FETCH_PERSON_BY_ID.SUCCESS',
    FAILURE: 'FETCH_PERSON_BY_ID.FAILURE',
  },
};

export default statsTypes;
