import './helpers/ReactotronConfig.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';

import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';

import Loader from './components/Common/Loader';
import 'react-activity/dist/library.css';

const app = (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
const root = createRoot(document.getElementById('root'));
root.render(app);
serviceWorker.unregister();
