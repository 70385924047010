import { createSelector } from 'reselect';

export const countSmsMessages = createSelector(
  (state) => state.SmsMessage.smsMessages,
  (smsMessages) => smsMessages.length,
);

export const smsUnsentCount = createSelector(
  (state) => state.SmsMessage.smsMessages,
  (smsMessages) => smsMessages.filter((sms) => sms.status === 'new').length,
);

export const smsDeliveredCount = createSelector(
  (state) => state.SmsMessage.smsMessages,
  (smsMessages) =>
    smsMessages.filter((sms) => sms.status === 'DELIVERED').length,
);

export const smsUndeliveredCount = createSelector(
  (state) => state.SmsMessage.smsMessages,
  (smsMessages) =>
    smsMessages.filter((sms) => sms.status !== 'DELIVERED').length,
);

export const totalCosts = createSelector(
  (state) => state.SmsMessage.smsMessages,
  (smsMessages) =>
    smsMessages.map((sms) => sms.cost).reduce((a, b) => a + b, 0),
);
