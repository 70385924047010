import { serverTimestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const compassPOLImportLead = (lead) => ({
  firstName: toTitleCase(lead['Nome'].trim()),
  lastName: toTitleCase(lead['Cognome'].trim()),
  ...(lead['CF'] &&
    lead['CF'].trim() !== '' && {
      fiscalCode: lead['CF'].trim(),
    }),
  ...(lead['Email'] &&
    lead['Email'].trim() !== '' && {
      email: lead['Email'].trim(),
    }),
  phone: normalizePhone(lead['Telefono']),
  createdAt: serverTimestamp(),
});

export default compassPOLImportLead;
