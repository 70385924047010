export default {
  sector: '',
  campaignIds: null,
};

export const leadFilters = {
  sector: '',
  supplierId: '',
  campaignIds: null,
};

export const customerFilters = {
  customerId: '',
  monetizationOrderIds: null,
};

export const saleFilters = {
  supplierId: '',
  monetizationOrderIds: null,
};

export const publisherFilters = {
  supplierId: '',
  campaignIds: null,
};

export const monetizationOrderFilters = {
  monetizationOrderIds: null,
};

export const personFilters = {
  audienceIds: null,
};
