import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import * as leadsActions from '../../store/leads/actions';
import { selectLeadByQuery } from '../../selectors/lead';
import { useDispatch, useSelector } from 'react-redux';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Leads/tableColumns';
import { ToggleColumnButton } from '../../components/Common/DataTable/tableActionButtons';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import { addOrRemove } from '../../helpers/sharedFunction';
import moment from 'moment';

const LeadsQueryTable = ({ query }) => {
  const leads = useSelector(selectLeadByQuery(query.logic));
  const loading = useSelector((state) => state.Leads.loading);
  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const [selectedLead, setSelectedLead] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dateRange = {
    startDate: moment.utc().subtract(30, 'days').startOf('day').toDate(),
    endDate: moment.utc().endOf('day').toDate(),
  };

  const dispatch = useDispatch();

  const openViewModal = (lead) => {
    setSelectedLead(lead);
    setViewModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        null,
        null,
        null,
        null,
        null,
        campaigns,
        showSensitiveData,
      ),
    [campaigns, showSensitiveData],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  useEffect(() => {
    leads.length === 0 &&
      dispatch(leadsActions.fetchLeads(dateRange.startDate, dateRange.endDate));
  }, [query]);

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <Row className="align-items-center justify-content-end mb-2">
                <Spinner
                  color="secondary"
                  animation="border"
                  hidden={!loading}
                />
                <ToggleColumnButton
                  columns={columns}
                  hiddenColumns={hiddenColumns}
                  onToggleColumn={handleToggleColumn}
                />
              </Row>
              <DataTableNext
                loading={loading}
                rows={leads}
                columns={columns}
                filters={filters}
                onFiltersChange={setFilters}
                sorting={sorting}
                onSortingChange={setSorting}
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                hiddenColumns={hiddenColumns}
                onHiddenColumnsChange={setHiddenColumns}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        title="Lead"
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        showClose
        scrollable={false}>
        <ShowSingleElement element={selectedLead} icon="bx bx-user-pin" />
      </Modal>
    </>
  );
};

export default LeadsQueryTable;
