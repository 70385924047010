import React from 'react';
import styled from 'styled-components';
import colors from '../../components/CommonStyle/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;
  }
`;

const Title = styled.span`
  font-size: 12px;
  margin-bottom: 5px;
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 25px;
  border-radius: 5px;
  padding: 0px 3px;
  width: 20px;
  color: ${colors.secondary};
  &:first-child {
    margin-right: 2px;
  }
`;

const Digit = ({ value, title, color }) => {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <DigitContainer>
        <SingleDigit style={{ color }}>{leftDigit}</SingleDigit>
        <SingleDigit style={{ color }}>{rightDigit}</SingleDigit>
      </DigitContainer>
    </Container>
  );
};

export default Digit;
