import React, { useState, useEffect, memo } from 'react';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';

import { selectActiveMonetizationOrderWithSendType } from '../../selectors/monetizationOrder';
import { useDispatch, useSelector } from 'react-redux';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import { replaceMacro, removeIntPrefix } from '../../helpers/sharedFunction';

const DataEntryLinkTable = ({ lead, acquisitionOrder }) => {
  const monetizationOrderIds =
    acquisitionOrder?.monetizationOrdersAllowed ?? null;

  const monetizationOrders = useSelector(
    selectActiveMonetizationOrderWithSendType(
      'dataEntry',
      monetizationOrderIds,
    ),
  );
  const loading = useSelector((state) => state.Leads.loading);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  const macros = { ...lead, phoneNoIntPref: removeIntPrefix(lead.phone) };

  useEffect(() => {
    dispatch(monetizationOrdersActions.syncMonetizationOrders());
  }, []);

  const columns = [
    {
      title: 'Name',
      name: 'name',
      sort: true,
    },
    {
      title: 'Link',
      name: 'landingPageCustomer',
      width: 120,
      formatterComponent: ({ value }) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={replaceMacro(value, macros)}
          className="btn btn-info w-xs">
          <i className="bx bx-link-external align-middle mr-2"></i>
          Link
        </a>
      ),
    },
  ];

  return !loading ? ( //force update component when lead is updated
    <DataTableNext
      loading={loading}
      rows={monetizationOrders}
      columns={columns}
      filters={filters}
      onFiltersChange={setFilters}
      sorting={sorting}
      onSortingChange={setSorting}
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  ) : null;
};

export default memo(DataEntryLinkTable);
