import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as campaignsActions from '../../store/campaigns/actions';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  isModuleFacebookActive,
  isModuleGoogleActive,
  actualFacebookAdAccountIds,
} from '../../selectors/country';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import FormCampaign from '../../components/Campaigns/FormCampaign';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Campaigns/tableColumns';
import {
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';
import googleScriptImg from '../../assets/images/run-script-google-ads.jpg';
import toastr from 'toastr';

const SYNC_GOOGLE_CAMPAIGNS_SCRIPT_URL =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://ads.google.com/aw/bulk/scripts/edit?ocid=600315594&scriptId=4240674'
    : 'https://ads.google.com/aw/bulk/scripts/edit?ocid=600315594&scriptId=4229653';

const List = (props) => {
  const loadingCampaigns = useSelector((state) => state.Campaign.loading);
  const loadingAcquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.loading,
  );
  const loading = loadingCampaigns || loadingAcquisitionOrders;

  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );

  const facebookModule = useSelector(isModuleFacebookActive);
  const googleModule = useSelector(isModuleGoogleActive);
  const countryFacebookAdAccountIds = useSelector(actualFacebookAdAccountIds);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [syncCampaignsModal, setSyncCampaignsModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.syncCampaigns());
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
  }, []);

  const openEditModal = (campaign = null) => {
    setSelectedCampaign(campaign);
    setEditModal(true);
  };

  const openViewModal = (campaign) => {
    setSelectedCampaign(campaign);
    setViewModal(true);
  };

  const handleSyncFBCampaigns = () => {
    countryFacebookAdAccountIds
      ? dispatch(campaignsActions.syncFBCampaigns(countryFacebookAdAccountIds))
      : toastr.error('There are no Facebook Ad Accounts set up!', '');
  };

  const openSyncGoogleCampaigns = () => {
    setSyncCampaignsModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal, acquisitionOrders),
    [acquisitionOrders],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const syncAcquisitionSourceButtons = {
    facebook: {
      title: 'Sync FB Campaigns',
      icon: 'bx bxl-facebook-square',
      onClick: handleSyncFBCampaigns,
    },
    google: {
      title: 'Sync Google Campaigns',
      icon: 'bx bxl-google',
      onClick: openSyncGoogleCampaigns,
    },
  };

  const activeAcquisitionSourceButtons = (key) => {
    let keyArray = [];
    facebookModule &&
      keyArray.push(syncAcquisitionSourceButtons['facebook'][key]);
    googleModule && keyArray.push(syncAcquisitionSourceButtons['google'][key]);
    return keyArray;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Campaigns"
            onClickButtons={activeAcquisitionSourceButtons('onClick')}
            titleButtons={activeAcquisitionSourceButtons('title')}
            iconButtons={activeAcquisitionSourceButtons('icon')}
            loading={loading}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Campaign"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={campaigns}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Campaign"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedCampaign}
              icon="bx bx-gas-pump"
            />
          </Modal>
          <Modal
            size="xl"
            title="Campaign"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCampaign
              closeModal={() => setEditModal(false)}
              create={!selectedCampaign}
              loading={loading || loadingAcquisitionOrders}
              acquisitionOrders={acquisitionOrders}
              campaign={selectedCampaign}
            />
          </Modal>
          <Modal
            size="md"
            title="Sync Google Campaigns"
            isOpen={syncCampaignsModal}
            toggle={() => setSyncCampaignsModal(!syncCampaignsModal)}>
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <h6>Open this link:</h6>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={SYNC_GOOGLE_CAMPAIGNS_SCRIPT_URL}
                      className="btn btn-outline-info btn-sm w-xs">
                      <i className="bx bx-link-external align-middle mr-2"></i>
                      Google Ads
                    </a>
                    <h6 className="mt-2">Click on "Preview" button:</h6>
                    <img
                      src={googleScriptImg}
                      width="100%"
                      alt="Google Script"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
