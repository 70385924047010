import React, { useState } from 'react';
import { FormGroup, Label, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import Select from '@availity/select';
import '@availity/yup';
import * as yup from 'yup';

import * as callsActions from '../../../store/calls/actions';
import * as leadsActions from '../../../store/leads/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveMonetizationOrders,
  selectMonetizationOrders,
} from '../../../selectors/monetizationOrder';

import Alert from '../../Common/Modal/Alert';
import DataEntryLinks from '../../MonetizationOrders/DataEntryLinks';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

const PositiveAlert = ({ lead, call, acquisitionOrder, isOpen, onCancel }) => {
  const monetizationOrdersAllowed = useSelector(
    selectActiveMonetizationOrders(
      acquisitionOrder ? acquisitionOrder.monetizationOrdersAllowed : null,
    ),
  );

  const [selectedMonetizationOrders, setSelectedMonetizationOrders] = useState(
    call.monetizationOrderIds || null,
  );
  const [reason, setReason] = useState(null);
  const [disabledAlertBtn, setDisabledAlertBtn] = useState(false);

  const monetizationOrders = useSelector(
    selectMonetizationOrders(selectedMonetizationOrders),
  );

  const dataEntryMonetizationOrders = monetizationOrders
    ? monetizationOrders.filter((mo) => mo.sendLeadType === 'dataEntry')
    : null;

  //Show reasons only if there is only one selected monetization order
  const reasons =
    monetizationOrders.length === 1 &&
    Array.isArray(monetizationOrders[0].reasons)
      ? monetizationOrders[0].reasons
      : [];

  const [step, setStep] = useState(1);

  const userId = useSelector((state) => state.Auth.admin.id);

  const labels = [
    { title: 'Great Job!', btnLabel: 'Save' },
    {
      title: 'Do you confirm that you have done the Data Entry?',
      btnLabel: 'Confirm',
    },
    { title: 'Do you confirm positive closure for', btnLabel: 'Confirm' },
  ];

  const dispatch = useDispatch();

  const handleOutcomePositive = () => {
    if (!selectedMonetizationOrders || !selectedMonetizationOrders.length)
      return toastr.error(
        t('Choose at least one monetization order!'),
        t('Error'),
      );
    if (reasons.length && !reason)
      return toastr.error(t('Choose a reason!'), t('Error'));

    if (step === 1) {
      setStep(dataEntryMonetizationOrders.length ? 2 : 3);
    } else if (step === 2) {
      setStep(3);
    } else updateCall();
  };

  const updateCall = () => {
    setDisabledAlertBtn(true);
    dispatch(
      callsActions.updateCall({
        id: call.id,
        outcome: 'positive',
        status: 'closed',
        nextCall: 'none',
        monetizationOrderIds: selectedMonetizationOrders,
        ...(reason && { positiveOutcome: reason }),
        monetizationItemIds: null,
        endDateCall: new Date(),
        userId,
      }),
    );
    dispatch(
      leadsActions.updateLead({
        id: call.leadId,
        qualified: 'completed',
      }),
    );
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setStep(1);
    onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={isOpen}
      title={t(labels[step - 1].title)}
      confirmBtnLabel={labels[step - 1].btnLabel}
      onCancel={handleOnCancel}
      onConfirm={handleOutcomePositive}
      disabledBtn={disabledAlertBtn}>
      <>
        {step === 1 && (
          <Form
            initialValues={{
              monetizationOrders: selectedMonetizationOrders,
              reason,
            }}
            validationSchema={yup.object().shape({
              monetizationOrders: yup.mixed().required(),
            })}>
            <FormGroup>
              <Label htmlFor="monetizationOrders">
                {t('Monetization Orders')}
              </Label>
              <Select
                name="monetizationOrders"
                id="monetizationOrders"
                isSearchable={true}
                isMulti={true}
                options={
                  monetizationOrdersAllowed
                    ? monetizationOrdersAllowed.map((mo) => ({
                        value: mo.id,
                        label: mo.name,
                      }))
                    : []
                }
                onChange={(values) => setSelectedMonetizationOrders(values)}
                value={selectedMonetizationOrders}
              />
            </FormGroup>
            <Collapse isOpen={!!reasons.length}>
              <FormGroup>
                <Label htmlFor="reason">{t('Choose a reason')}:</Label>
                <Select
                  name="reason"
                  id="reason"
                  isMulti={false}
                  onChange={(value) => setReason(value)}
                  value={reason}
                  options={reasons.map((value) => ({ value, label: value }))}
                />
              </FormGroup>
            </Collapse>
          </Form>
        )}
        {step === 2 && (
          <DataEntryLinks
            lead={lead}
            selectedMonetizationOrders={dataEntryMonetizationOrders}
          />
        )}
        {step === 3 && (
          <ul className="list-group">
            {monetizationOrdersAllowed
              .filter((monetizationOrder) =>
                selectedMonetizationOrders.includes(monetizationOrder.id),
              )
              .map((monetizationOrder) => (
                <li
                  key={'monetizationOrder_' + monetizationOrder.id}
                  className="list-group-item">
                  {monetizationOrder.name}
                </li>
              ))}
          </ul>
        )}
      </>
    </Alert>
  );
};

export default PositiveAlert;
