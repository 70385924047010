import '@availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  leadFields: yup
    .array()
    .of(
      yup.object().shape({
        leadFieldId: yup.string().required(),
        required: yup
          .boolean()
          .transform((value, originalValue) => (originalValue ? true : false)),
        readOnly: yup
          .boolean()
          .transform((value, originalValue) => (originalValue ? true : false)),
      }),
    )
    .min(3),
});

export default formValidation;
