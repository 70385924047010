import types from './actionTypes';
import initialFilters from '../../config/filters';

const initialState = {
  loading: false,
  calls: [],
  queuedCalls: [],
  closedCalls: [],
  startDate: null,
  endDate: null,
  filters: { ...initialFilters },
  error: '',
};

const callReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CALL_STATE:
      return initialState;
    case types.CREATE_CALL.REQUEST:
    case types.FETCH_CALLS.REQUEST:
    case types.FETCH_CLOSED_CALLS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_CALL.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        calls: [...state.calls, action.call],
      };
    case types.FETCH_CALLS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        calls: action.calls,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: { ...action.filters },
      };
    case types.FETCH_CALLS_BY_LEAD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        calls: action.calls,
      };
    case types.SYNC_QUEUED_CALLS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        queuedCalls: action.calls,
      };
    case types.FETCH_CLOSED_CALLS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        closedCalls: action.calls,
      };
    case types.CREATE_CALL.FAILURE:
    case types.CREATE_EXTRA_CALL.FAILURE:
    case types.UPDATE_CALL.FAILURE:
    case types.FETCH_CALLS.FAILURE:
    case types.FETCH_CALLS_BY_LEAD.FAILURE:
    case types.SYNC_QUEUED_CALLS.FAILURE:
    case types.FETCH_CLOSED_CALLS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default callReducer;
