import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-activity';
import colors from '../../components/CommonStyle/colors';

const hideSensitiveDataString = '***';

const MiniCards = (props) => {
  const sensitiveDataLayout = useSelector(
    (state) => state.Layout.showSensitiveData,
  );
  const showSensitiveData = props.isSensitiveData ? sensitiveDataLayout : true;

  return (
    <React.Fragment>
      <Card className="mini-stats-wid">
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted font-weight-medium text-nowrap">
                {props.title}
              </p>
              {props.loading ? (
                <Spinner
                  color={colors.dark}
                  size={16}
                  speed={1}
                  animating={true}
                />
              ) : (
                <>
                  <div className="d-flex align-items-end">
                    <h4 className="mb-0 text-nowrap">
                      {showSensitiveData ? props.text : hideSensitiveDataString}
                    </h4>
                    {props.percentage && (
                      <p className="text-muted font-size-12 mb-0 ml-1 align-self-end">
                        ({props.percentage})
                      </p>
                    )}
                  </div>
                  {props.subtext && (
                    <p className="text-muted font-size-12 mb-0">
                      <span className={`text-${props.subtext.color} mr-1`}>
                        {showSensitiveData && <i className="bx bx-plus"></i>}
                        {showSensitiveData
                          ? props.subtext.value
                          : hideSensitiveDataString}
                      </span>
                      {props.subtext.title}
                    </p>
                  )}
                </>
              )}
            </Media>

            <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">
              <span className={'avatar-title bg-' + props.color}>
                <i className={props.icon + ' font-size-24'}></i>
              </span>
            </div>
          </Media>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MiniCards;
