import { serverTimestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const createNote = (lead) => {
  if (lead['note']) return lead['note'];

  return lead['data'] && lead['data'].trim() !== ''
    ? `- Data creazione lead: ${lead['data']}`
    : null;
};

const haierRestartImportLead = (lead) => {
  if (!lead['email'] || lead['email'] === '')
    throw new Error(`Email is required!`);

  if (!lead['phone_number'] || lead['phone_number'].trim() === '')
    throw new Error(`Phone is required!`);

  const note = createNote(lead);

  return {
    firstName: toTitleCase(lead['first_name'].trim()),
    lastName: toTitleCase(lead['last_name'].trim()),
    email: lead['email'].trim(),
    ...(lead['zip_code'] &&
      lead['zip_code'].trim() !== '' && { zipCode: lead['zip_code'].trim() }),
    ...(note && { note }),
    phone: normalizePhone(lead['phone_number'].trim()),
    createdAt: serverTimestamp(),
  };
};

export default haierRestartImportLead;
