import { put, all, takeLatest, select } from 'redux-saga/effects';

import types from './actionTypes';
import * as dashboardActions from './actions';
import * as monetizationItemsActions from '../../store/monetizationItems/actions';
import * as dailyFBInsightsActions from '../../store/facebookAd/dailyCampaignInsights/actions';
import * as dailyGoogleInsightsActions from '../../store/googleAd/dailyCampaignInsights/actions';
import * as smsMessagesActions from '../../store/smsMessages/actions';
import * as callsActions from '../../store/calls/actions';
import * as leadsActions from '../leads/actions';

import { selectCountry } from '../../selectors/country';

import { isNullish } from '../../helpers/sharedFunction';

function* setFiltersSaga({ startDate, endDate, filters }) {
  try {
    yield put(
      monetizationItemsActions.fetchMonetizationItems(
        startDate,
        endDate,
        filters,
      ),
    );
    yield put(
      dailyFBInsightsActions.fetchDailyInsights(startDate, endDate, filters),
    );
    yield put(
      dailyGoogleInsightsActions.fetchDailyInsights(
        startDate,
        endDate,
        filters,
      ),
    );
    //SmsMessage hasn't sector or campaignId associations, retrieves sms data only when filters are no set
    isNullish(filters)
      ? yield put(smsMessagesActions.fetchSmsMessages(startDate, endDate))
      : yield put(smsMessagesActions.resetState());
    yield put(callsActions.fetchClosedCalls(startDate, endDate, filters));
    yield put(leadsActions.fetchLeads(startDate, endDate, filters));
    //yield put(callsActions.syncQueuedCalls());

    yield yield put(
      dashboardActions.setFiltersSuccess(startDate, endDate, filters),
    );
  } catch (error) {
    yield put(dashboardActions.setFiltersFailure(error));
  }
}

function* setCountrySaga({ countryId }) {
  try {
    const country = yield select(selectCountry(countryId));
    const exchangeQueuesModal =
      country && country.phoneExchange === 'restart' ? 'show' : 'unnecessary';
    yield put(
      dashboardActions.setCountrySuccess(countryId, exchangeQueuesModal),
    );
  } catch (error) {
    yield put(dashboardActions.setCountryFailure(error));
  }
}

function* dashboardSaga() {
  yield all([
    takeLatest(types.SET_DASHBOARD_FILTERS.REQUEST, setFiltersSaga),
    takeLatest(types.SET_COUNTRY.REQUEST, setCountrySaga),
  ]);
}

export default dashboardSaga;
