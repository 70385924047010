const statsTypes = {
  RESET_MONETIZATION_ORDER_STATE: 'RESET_MONETIZATION_ORDER_STATE',
  CREATE_MONETIZATION_ORDER: {
    REQUEST: 'CREATE_MONETIZATION_ORDER.REQUEST',
    SUCCESS: 'CREATE_MONETIZATION_ORDER.SUCCESS',
    FAILURE: 'CREATE_MONETIZATION_ORDER.FAILURE',
  },
  UPDATE_MONETIZATION_ORDER: {
    REQUEST: 'UPDATE_MONETIZATION_ORDER.REQUEST',
    SUCCESS: 'UPDATE_MONETIZATION_ORDER.SUCCESS',
    FAILURE: 'UPDATE_MONETIZATION_ORDER.FAILURE',
  },
  SYNC_MONETIZATION_ORDERS: {
    REQUEST: 'SYNC_MONETIZATION_ORDERS.REQUEST',
    SUCCESS: 'SYNC_MONETIZATION_ORDERS.SUCCESS',
    FAILURE: 'SYNC_MONETIZATION_ORDERS.FAILURE',
  },
};

export default statsTypes;
