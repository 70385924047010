import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as marketingCampaignsActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';
import moment from 'moment';

const db = getFirestore(rsf.app);

function* createMarketingCampaignSaga({ marketingCampaign }) {
  try {
    const marketingCampaignsRef = collection(db, 'marketingCampaigns');
    const countryId = yield select((state) => state.Dashboard.countryId);

    const personCreatedFrom = moment(
      marketingCampaign.personCreatedFrom,
    ).format('YYYY-MM-DD');
    const personCreatedTo = moment(marketingCampaign.personCreatedTo).format(
      'YYYY-MM-DD',
    );

    yield call(rsf.firestore.addDocument, marketingCampaignsRef, {
      ...marketingCampaign,
      setup: 'new',
      countryId,
      size: 0,
      personCreatedFrom: Timestamp.fromDate(
        new Date(`${personCreatedFrom} 00:00:00`),
      ),
      personCreatedTo: Timestamp.fromDate(
        new Date(`${personCreatedTo} 23:59:59`),
      ),
      createdAt: serverTimestamp(),
    });
    yield put(
      marketingCampaignsActions.createMarketingCampaignSuccess(
        marketingCampaign,
      ),
    );
    toastr.success('MarketingCampaign created!', '');
  } catch (error) {
    yield put(marketingCampaignsActions.createMarketingCampaignFailure(error));
  }
}

function* updateMarketingCampaignSaga({ marketingCampaign }) {
  try {
    const marketingCampaignsRef = doc(
      db,
      'marketingCampaigns',
      marketingCampaign.id,
    );
    delete marketingCampaign.id;

    const { personCreatedFrom, personCreatedTo } = marketingCampaign;

    const personCreatedFromDate = personCreatedFrom
      ? moment(personCreatedFrom).format('YYYY-MM-DD')
      : null;
    const personCreatedToDate = personCreatedTo
      ? moment(personCreatedTo).format('YYYY-MM-DD')
      : null;

    yield call(
      rsf.firestore.setDocument,
      marketingCampaignsRef,
      {
        ...marketingCampaign,
        ...(personCreatedFromDate && {
          personCreatedFrom: Timestamp.fromDate(
            new Date(`${personCreatedFromDate} 00:00:00`),
          ),
        }),
        ...(personCreatedToDate && {
          personCreatedTo: Timestamp.fromDate(
            new Date(`${personCreatedToDate} 23:59:59`),
          ),
        }),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(marketingCampaignsActions.updateMarketingCampaignSuccess());
    toastr.success('MarketingCampaign updated!', '');
  } catch (error) {
    yield put(marketingCampaignsActions.updateMarketingCampaignFailure(error));
  }
}

const marketingCampaignTransformer = (payload) => {
  let marketingCampaigns = [];

  payload.forEach((marketingCampaign) => {
    const data = marketingCampaign.data();

    const personCreatedFrom = toDateFirebase(
      payload,
      data,
      'personCreatedFrom',
    ).toDate();
    const personCreatedTo = toDateFirebase(
      payload,
      data,
      'personCreatedTo',
    ).toDate();

    marketingCampaigns.push({
      id: marketingCampaign.id,
      ...data,
      personCreatedFrom,
      personCreatedTo,
      ...(data.createdAt && {
        createdAt: toDateFirebase(marketingCampaign, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(
          marketingCampaign,
          data,
          'updatedAt',
        ).toDate(),
      }),
    });
  });

  return marketingCampaigns;
};

function* syncMarketingCampaignsSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const marketingCampaignsRef = query(
    collection(db, 'marketingCampaigns'),
    where('countryId', '==', countryId),
    orderBy('createdAt', 'desc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, marketingCampaignsRef, {
    successActionCreator:
      marketingCampaignsActions.syncMarketingCampaignsSuccess,
    failureActionCreator:
      marketingCampaignsActions.syncMarketingCampaignsFailure,
    transform: (payload) => marketingCampaignTransformer(payload),
  });

  yield take(types.RESET_MARKETING_CAMPAIGN_STATE);
  yield cancel(task);
}

function* marketingCampaignSaga() {
  yield all([
    takeLatest(
      types.SYNC_MARKETING_CAMPAIGNS.REQUEST,
      syncMarketingCampaignsSaga,
    ),
    takeLeading(
      types.CREATE_MARKETING_CAMPAIGN.REQUEST,
      createMarketingCampaignSaga,
    ),
    takeLatest(
      types.UPDATE_MARKETING_CAMPAIGN.REQUEST,
      updateMarketingCampaignSaga,
    ),
  ]);
}

export default marketingCampaignSaga;
