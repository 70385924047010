const statsTypes = {
  RESET_LEAD_FORM_STATE: 'RESET_LEAD_FORM_STATE',
  CREATE_LEAD_FORM: {
    REQUEST: 'CREATE_LEAD_FORM.REQUEST',
    SUCCESS: 'CREATE_LEAD_FORM.SUCCESS',
    FAILURE: 'CREATE_LEAD_FORM.FAILURE',
  },
  UPDATE_LEAD_FORM: {
    REQUEST: 'UPDATE_LEAD_FORM.REQUEST',
    SUCCESS: 'UPDATE_LEAD_FORM.SUCCESS',
    FAILURE: 'UPDATE_LEAD_FORM.FAILURE',
  },
  SYNC_LEAD_FORMS: {
    REQUEST: 'SYNC_LEAD_FORMS.REQUEST',
    SUCCESS: 'SYNC_LEAD_FORMS.SUCCESS',
    FAILURE: 'SYNC_LEAD_FORMS.FAILURE',
  },
  SORT_LEAD_FORMS: {
    REQUEST: 'SORT_LEAD_FORMS.REQUEST',
    SUCCESS: 'SORT_LEAD_FORMS.SUCCESS',
    FAILURE: 'SORT_LEAD_FORMS.FAILURE',
  },
};

export default statsTypes;
