import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as messageTemplatesActions from './actions';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const db = getFirestore(rsf.app);

function* createMessageTemplateSaga({ messageTemplate }) {
  try {
    const messageTemplatesRef = collection(db, 'messageTemplates');
    const countryId = yield select((state) => state.Dashboard.countryId);

    yield call(rsf.firestore.addDocument, messageTemplatesRef, {
      ...messageTemplate,
      countryId,
      active: true,
      createdAt: serverTimestamp(),
    });
    yield put(
      messageTemplatesActions.createMessageTemplateSuccess(messageTemplate),
    );
    toastr.success('MessageTemplate created!', '');
  } catch (error) {
    yield put(messageTemplatesActions.createMessageTemplateFailure(error));
  }
}

function* updateMessageTemplateSaga({ messageTemplate }) {
  try {
    const messageTemplateRef = doc(db, 'messageTemplates', messageTemplate.id);
    delete messageTemplate.id;

    yield call(
      rsf.firestore.setDocument,
      messageTemplateRef,
      {
        ...messageTemplate,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    yield put(
      messageTemplatesActions.updateMessageTemplateSuccess(messageTemplate),
    );
    toastr.success('MessageTemplate updated!', '');
  } catch (error) {
    yield put(messageTemplatesActions.updateMessageTemplateFailure(error));
  }
}

const messageTemplateTransformer = (payload) => {
  let messageTemplates = [];

  payload.forEach((messageTemplate) => {
    const data = messageTemplate.data();

    messageTemplates.push({
      id: messageTemplate.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(messageTemplate, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(messageTemplate, data, 'updatedAt').toDate(),
      }),
    });
  });

  return messageTemplates;
};

function* syncMessageTemplatesSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const messageTemplatesRef = query(
    collection(db, 'messageTemplates'),
    where('countryId', '==', countryId),
    orderBy('name', 'asc'),
  );

  const task = yield fork(rsf.firestore.syncCollection, messageTemplatesRef, {
    successActionCreator: messageTemplatesActions.syncMessageTemplatesSuccess,
    failureActionCreator: messageTemplatesActions.syncMessageTemplatesFailure,
    transform: (payload) => messageTemplateTransformer(payload),
  });

  yield take(types.RESET_MESSAGE_TEMPLATE_STATE);
  yield cancel(task);
}

function* messageTemplateSaga() {
  yield all([
    takeLatest(types.SYNC_MESSAGE_TEMPLATES.REQUEST, syncMessageTemplatesSaga),
    takeLeading(
      types.CREATE_MESSAGE_TEMPLATE.REQUEST,
      createMessageTemplateSaga,
    ),
    takeLeading(
      types.UPDATE_MESSAGE_TEMPLATE.REQUEST,
      updateMessageTemplateSaga,
    ),
  ]);
}

export default messageTemplateSaga;
