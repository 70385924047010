import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { useSelector } from 'react-redux';

import { drawSeriesHourlyBar } from '../../selectors/monetizationItem';
import ReactApexChart from 'react-apexcharts';

import roles from '../../config/roles';
import { useTranslation } from 'react-i18next';

const PositivesHourlyBar = (props) => {
  const user = useSelector((state) => state.Auth.admin);
  const userId = user.role === roles['OPERATOR'] ? user.id : null;

  const { t } = useTranslation();

  const dataBar = useSelector(drawSeriesHourlyBar(t, userId));

  const hourlyBar = {
    series: [
      {
        data: dataBar.series,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 270,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => `${(val * 100).toFixed(2)}%`,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: dataBar.labels,
        labels: {
          formatter: (val) => `${(val * 100).toFixed(0)}%`,
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => `${val}h`,
        }
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false
      }
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-1 float-sm-left">
          {t('Positive closures')} - {t('Time slot')}
        </CardTitle>
        <div className="clearfix"></div>
        <div id="line-chart" className="apex-charts" dir="ltr">
          <ReactApexChart
            series={hourlyBar.series}
            options={hourlyBar.options}
            type="bar"
            height={270}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PositivesHourlyBar;
