import { takeLeading, select, put, all, call } from 'redux-saga/effects';

import types from './actionTypes';
import * as dailyInsightsActions from './actions';

import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
} from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { selectCampaignsBySector } from '../../../selectors/campaign';

import {
  toDateFirebase,
  arrayChunks,
  isNullish,
} from '../../../helpers/sharedFunction';
import moment from 'moment';

export const dailyInsightTransformer = (stat, data) => {
  const date = toDateFirebase(stat, data, 'date').toDate();

  const leads = data.totalActionsCount.filter(
    (action) => action.action_type === 'lead',
  );
  const totalLeads = leads.length !== 0 ? leads[0].value : 0;
  const comments = data.totalActionsCount.filter(
    (action) => action.action_type === 'comment',
  );
  const totalComments = comments.length !== 0 ? comments[0].value : 0;
  const reactions = data.totalActionsCount.filter(
    (action) => action.action_type === 'post_reaction',
  );
  const totalReactions = reactions.length !== 0 ? reactions[0].value : 0;
  const engagements = data.totalActionsCount.filter(
    (action) => action.action_type === 'post_engagement',
  );
  const totalEngagements = engagements.length !== 0 ? engagements[0].value : 0;
  const totalSpend = parseFloat(data.totalSpend);
  const costPerLead = isFinite(totalSpend / totalLeads)
    ? totalSpend / totalLeads
    : 0;

  return {
    id: stat.id,
    ...data,
    totalSpend,
    costPerLead,
    totalLeads,
    totalComments,
    totalReactions,
    totalEngagements,
    date,
    dateToString: moment(date).format('dddd, DD/MM/YYYY'),
  };
};

function* fetchDailyInsightsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    let dailyInsightsSnaps = null;

    const db = getFirestore(rsf.app);
    const baseConditions = [
      where('date', '>=', startDate),
      where('date', '<=', endDate),
      where('countryId', '==', countryId),
      orderBy('date', 'desc'),
    ];
    const insightQuery = query(
      collection(db, 'dailyFBCampaignInsights'),
      ...baseConditions,
    );

    if (!filters || isNullish(filters)) {
      dailyInsightsSnaps = yield call(
        rsf.firestore.getCollection,
        insightQuery,
      );
      dailyInsightsSnaps = [dailyInsightsSnaps];
    } else {
      let campaigns = null;
      if (filters.sector && !filters.campaignIds) {
        campaigns = yield select(selectCampaignsBySector(filters.sector));
        campaigns = campaigns.map((campaign) => campaign.id);
        campaigns = arrayChunks(campaigns, 10);
      } else campaigns = arrayChunks(filters.campaignIds, 10);

      dailyInsightsSnaps = yield all(
        campaigns.map((campaignId) =>
          call(
            rsf.firestore.getCollection,
            query(
              collection(db, 'dailyFBCampaignInsights'),
              ...[...baseConditions, where('campaignId', 'in', campaignId)],
            ),
          ),
        ),
      );
    }

    let dailyInsights = [];

    dailyInsightsSnaps.forEach((dailyInsightsSnap) => {
      dailyInsightsSnap.forEach((stat) => {
        const data = stat.data();
        dailyInsights.push(dailyInsightTransformer(stat, data));
      });
    });

    yield put(
      dailyInsightsActions.fetchDailyInsightsSuccess(
        dailyInsights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeLeading(
      types.DAILY_FB_CAMPAIGN_INSIGHTS.REQUEST,
      fetchDailyInsightsSaga,
    ),
  ]);
}

export default dailyInsightsSaga;
