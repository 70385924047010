import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';
import types from './actionTypes';
import * as pushNotificationsActions from './actions';
import { getFirestore, collection, serverTimestamp } from 'firebase/firestore';
import rsf from '../../helpers/firebase';
import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

const pushNotificationTransformer = (pushNotification, data) => ({
  id: pushNotification.id,
  ...data,
  cost: parseFloat(data.cost || '0'),
  deviceNumbers: data.tokens.length,
  ...(data.sendAt && {
    sendAt: toDateFirebase(pushNotification, data, 'sendAt').toDate(),
  }),
  ...(data.createdAt && {
    createdAt: toDateFirebase(pushNotification, data).toDate(),
  }),
  ...(data.updatedAt && {
    updatedAt: toDateFirebase(pushNotification, data, 'updatedAt').toDate(),
  }),
});

function* createPushNotificationSaga({ pushNotification }) {
  try {
    const db = getFirestore(rsf.app);
    const countryId = yield select((state) => state.Dashboard.countryId);
    const pushNotificationsRef = collection(db, 'pushNotifications');

    yield call(rsf.firestore.addDocument, pushNotificationsRef, {
      ...pushNotification,
      countryId,
      createdAt: serverTimestamp(),
    });
    yield put(
      pushNotificationsActions.createPushNotificationSuccess(pushNotification),
    );
    toastr.success('Push Notification created!', '');
  } catch (error) {
    yield put(pushNotificationsActions.createPushNotificationFailure(error));
  }
}

function* fetchPushNotificationsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const pushNotificationsRef = rsf.firestore.createCollectionRefWithFilters(
      'pushNotifications',
      countryId,
      startDate,
      endDate,
      filters,
    );

    const pushNotificationsSnap = yield call(
      rsf.firestore.getCollection,
      pushNotificationsRef,
    );

    let pushNotifications = [];

    pushNotificationsSnap.forEach((pushNotification) => {
      const data = pushNotification.data();

      pushNotifications.push(
        pushNotificationTransformer(pushNotification, data),
      );
    });

    yield put(
      pushNotificationsActions.fetchPushNotificationsSuccess(
        pushNotifications,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(pushNotificationsActions.fetchPushNotificationsFailure(error));
  }
}

function* pushNotificationSaga() {
  yield all([
    takeEvery(
      types.FETCH_PUSH_NOTIFICATIONS.REQUEST,
      fetchPushNotificationsSaga,
    ),
    takeLeading(
      types.CREATE_PUSH_NOTIFICATION.REQUEST,
      createPushNotificationSaga,
    ),
  ]);
}

export default pushNotificationSaga;
