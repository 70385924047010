import React, { useState, useEffect, useCallback } from 'react';
import { CardTitle, Spinner, Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import * as usersActions from '../../store/users/actions';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import { getLabel, getSort, userName } from '../Common/DataTable/tableHelpers';
import { StartCallButton } from '../Common/DataTable/tableActionButtons';
import StartCallAlert from './StartCallAlert';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const functions = getFunctions();
const getOpenCalls = httpsCallable(functions, 'getOpenCalls-getOpenCalls');

const FindNewCalls = (props) => {
  const countryId = useSelector((state) => state.Dashboard.countryId);

  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const campaigns = useSelector((state) => state.Campaign.campaigns);

  const user = useSelector((state) => state.Auth.admin);
  const users = useSelector((state) => state.User.users);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState([]);

  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
    dispatch(usersActions.syncUsers());
  }, []);

  const [selectedCall, setSelectedCall] = useState(null);
  const [startCallAlert, setStartCallAlert] = useState(false);

  const handleSearchCall = useCallback(() => {
    setError(null);
    setRows([]);
    setLoading(true);
    getOpenCalls({ query: searchValue, countryId })
      .then((result) => {
        const data = result.data;
        const { status, calls } = data;
        status === 200 && Array.isArray(calls) && setRows(calls);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue, countryId]);

  const openStartCallAlert = useCallback((call) => {
    setSelectedCall(call);
    setStartCallAlert(true);
  }, []);

  const onCancelStartCallAlert = useCallback(() => {
    setStartCallAlert(false);
    props.closeModal && props.closeModal();
  }, []);

  const { t } = useTranslation();

  const columns = [
    {
      title: 'Acquisition Order',
      name: 'acquisitionOrderId',
      width: 250,
      getCellValue: (row) =>
        getLabel(row.acquisitionOrderId, acquisitionOrders),
      filter: 'disabled',
    },
    {
      title: 'Campaign',
      name: 'campaignId',
      width: 250,
      getCellValue: (row) => getLabel(row.campaignId, campaigns),
      filter: 'disabled',
    },
    {
      title: 'Phone',
      name: 'phone',
      width: 140,
      filter: 'disabled',
    },
    {
      title: 'Name',
      name: 'leadName',
      filter: 'disabled',
    },
    {
      title: 'Type',
      name: 'type',
      width: 130,
      formatterComponent: ({ value }) =>
        value === 'new' ? (
          <span className="badge badge-secondary font-size-12">{t(value)}</span>
        ) : (
          <span className="badge badge-warning font-size-12">{t(value)}</span>
        ),
      sorting: (a, b) => getSort(t(a), t(b)),
      filter: 'disabled',
    },
    {
      title: 'Appointment date',
      name: 'appointmentDate',
      width: 140,
      formatterComponent: ({ value }) =>
        value ? moment(value).format('DD/MM/YYYY HH:mm') : '',
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      filter: 'disabled',
    },
    {
      title: 'Appointment with',
      name: 'appointmentUserId',
      getCellValue: (row) => userName(row.appointmentUserId, users),
      filter: 'disabled',
    },
    {
      title: 'Action',
      name: 'action',
      width: 160,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline contact-links mb-0">
          <StartCallButton row={row} onPress={openStartCallAlert} />
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];

  return (
    <>
      <div className="avatar-sm float-sm-left mr-3">
        <span className="avatar-title rounded-circle bg-light font-size-24">
          <i className={`bx bx-phone-call text-dark`}></i>
        </span>
      </div>
      <CardTitle className="mt-2 mr-3 float-sm-left font-size-18">
        {t('Find inbound call')}:
      </CardTitle>
      <div className="mr-2 mb-2 d-inline-block">
        <div className="app-search mt-1 d-none d-lg-block p-0">
          <div className="position-relative">
            <input
              type="number"
              className="form-control"
              value={searchValue}
              placeholder={t('phone') + '...'}
              onChange={(e) => setSearchValue(e.target.value || '')}
              disabled={loading}
            />
            <span className="bx bx-search-alt search-icon" />
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn-rounded waves-effect waves-light btn btn-success mr-3"
        disabled={searchValue.length < 5 || loading}
        onClick={handleSearchCall}>
        {t('Cerca')}
      </button>
      <Spinner size="sm" color="primary" hidden={!loading} />
      <div className="clearfix"></div>
      {error && <Alert color="danger">{error}</Alert>}
      <div className="mt-1">
        <DataTableNext
          loading={false}
          rows={rows}
          columns={columns}
          sorting={sorting}
          onSortingChange={setSorting}
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <StartCallAlert
          isOpen={startCallAlert}
          onCancel={onCancelStartCallAlert}
          call={selectedCall}
          user={user}
        />
      </div>
    </>
  );
};

export default FindNewCalls;
