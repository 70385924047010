import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import * as callsActions from '../../store/calls/actions';
import * as usersActions from '../../store/users/actions';
import * as monetizationOrdersActions from '../../store/monetizationOrders/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isPublisher as isPublisherSelector } from '../../selectors/auth';
import { selectCampaign } from '../../selectors/campaign';
import MonetizationItemHistory from '../MonetizationItems/MonetizationItemHistory';
import createTableColumns from '../../components/Calls/tableColumns';
import { qualifiedName } from '../../components/Common/DataTable/tableHelpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const LeadHistory = ({ lead }) => {
  const isPublisher = useSelector(isPublisherSelector);

  const loadingCall = useSelector((state) => state.Call.loading);

  const campaign = useSelector(selectCampaign(lead.campaignId));
  const calls = useSelector((state) => state.Call.calls);
  const acquisitionOrders = useSelector(
    (state) => state.AcquisitionOrder.acquisitionOrders,
  );
  const monetizationOrders = useSelector(
    (state) => state.MonetizationOrder.monetizationOrders,
  );
  const users = useSelector((state) => state.User.users);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    !monetizationOrders.length &&
      dispatch(monetizationOrdersActions.syncMonetizationOrders());
    !users.length && dispatch(usersActions.syncUsers());
  }, []);

  useEffect(() => {
    lead && dispatch(callsActions.fetchCallsByLead(lead));
  }, [lead]);

  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      createTableColumns(
        null,
        null,
        null,
        null,
        null,
        acquisitionOrders,
        users,
        t,
        isPublisher,
        true,
      ),
    [acquisitionOrders, users, isPublisher, t],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col md="12">
            <h3>{lead.name}</h3>
            <div className="text-muted mt-4">
              <p>
                <i className="mdi mdi-chevron-right text-primary me-1"></i>{' '}
                <b>{t('Campaign')}:</b> {campaign?.name}
              </p>
              <p>
                <i className="mdi mdi-chevron-right text-primary me-1"></i>{' '}
                <b className="mr-3">{t('Qualified')}:</b>
                {qualifiedName(lead.qualified, true)}
              </p>
              {!isPublisher && (
                <p>
                  <i className="mdi mdi-chevron-right text-primary me-1"></i>{' '}
                  <b className="mr-3">{t('Survey')}:</b>
                  {qualifiedName(lead.survey, true)}
                </p>
              )}

              <p>
                <i className="mdi mdi-chevron-right text-primary me-1"></i>{' '}
                <b>{t('createdAt')}:</b>{' '}
                {`${moment(lead.createdAt).format('DD/MM/YYYY HH:mm')} (${t(
                  'date_fromNow',
                  {
                    date: new Date(lead.createdAt),
                  },
                )})`}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="12">
            <div className="d-flex align-items-center mb-2">
              <div className="avatar-sm mr-3">
                <span className="avatar-title rounded-circle bg-light font-size-20">
                  <i className="bx bx-phone text-dark"></i>
                </span>
              </div>
              <h5 className="my-3">{t('Calls')}</h5>
            </div>
            <DataTableNext
              loading={loadingCall}
              rows={calls}
              columns={columns}
              filters={filters}
              onFiltersChange={setFilters}
              sorting={sorting}
              onSortingChange={setSorting}
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              hiddenColumns={hiddenColumns}
              onHiddenColumnsChange={setHiddenColumns}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          </Col>
        </Row>
        {!isPublisher && (
          <Row>
            <MonetizationItemHistory lead={lead} selectBy="lead" />
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default LeadHistory;
