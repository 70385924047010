import React from 'react';

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { connect, useSelector } from 'react-redux';
import { actualCountry } from './selectors/country';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';
import { getAllowedRoutes } from './helpers/authHelper';

// Import all middleware
import AuthMiddleware from './routes/middleware/AuthMiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';
import 'toastr/build/toastr.min.css';

const App = (props) => {
  const admin = useSelector((state) => state.Auth.admin);
  const country = useSelector(actualCountry);

  const privateRoutes = getAllowedRoutes(userRoutes, admin, country);

  const NonAuthMiddleware = ({
    component: Component,
    layout: VerticalLayout,
  }) => (
    <Route
      render={(props) => {
        return (
          <VerticalLayout>
            <Component {...props} />
          </VerticalLayout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthMiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

            {privateRoutes.map((route, idx) => (
              <AuthMiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
              />
            ))}
          </Switch>
        </QueryParamProvider>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
