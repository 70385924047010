import Select from '@availity/select';
import { FormGroup, InputGroup } from 'reactstrap';
import Label from './Label';

const SelectForm = ({ readOnly, children, addonType, style, ...restProps }) => (
  <FormGroup>
    <Label {...restProps} />
    <InputGroup className="flex-nowrap">
      {addonType === 'prepend' && children}
      <div className="flex-grow-1">
        <Select
          {...restProps}
          isDisabled={readOnly}
          styles={{
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              cursor: state.isDisabled ? 'not-allowed' : 'auto',
            }),
          }}
        />
      </div>
      {(!addonType || addonType === 'append') && children}
    </InputGroup>
  </FormGroup>
);

export default SelectForm;
