import React, { useEffect } from 'react';
import { Media, Col } from 'reactstrap';

import * as acquisitionOrdersActions from '../../store/acquisitionOrders/actions';
import { selectAcquisitionOrder } from '../../selectors/acquisitionOrder';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TitleCall = ({ call, lead }) => {
  const acquisitionOrder = useSelector(
    selectAcquisitionOrder(call.acquisitionOrderId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(acquisitionOrdersActions.syncAcquisitionOrders());
  }, []);

  const icon =
    call.type === 'appointment' ? 'bx bx-calendar-event' : 'bx bx-phone-call';

  const { t } = useTranslation();

  return (
    <div className="d-inline-block mr-4 mini-stats-wid mb-2">
      <Media className="header-item">
        <div className="avatar-sm align-self-center mr-3">
          <span className="avatar-title rounded-circle bg-light font-size-24">
            <i className={`${icon} text-dark`}></i>
          </span>
        </div>
        <Col md={call.appointmentDate ? '7' : '12'} className="align-self-center mr-3">
          <h4 className="mt-0 mb-1">
            {acquisitionOrder ? acquisitionOrder.name : ''}
          </h4>
          <p className="text-muted mb-0">
            {`${t('Type')}: ${t(call.type)} - ${t('Calls')}: ${call.count}`}&ordf;
          </p>
          <p className="text-muted mb-0">
            {`${t('Lead createdAt')}: ${lead ? moment(lead.createdAt).format('DD/MM/YYYY HH:mm') : ''}`}
          </p>
        </Col>
        {call.appointmentDate && (
          <Col md="5" className="align-self-center mr-3">
            <h5 className="mt-0 mb-1">
              {`${t('Appointment Date')}: ${moment(call.appointmentDate).format(
                'DD/MM/YYYY HH:mm',
              )}`}
            </h5>
            <p className="text-muted mb-0">
              {`${t('Personal')}: `}
              <span className="badge badge-secondary font-size-10">
                {call.appointmentUserId === '' ? t('NO') : t('YES')}
              </span>
            </p>
          </Col>
        )}
      </Media>
    </div>
  );
};

export default TitleCall;
