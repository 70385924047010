const statsTypes = {
  RESET_DAILY_PUBLISHER_INSIGHT_STATE: 'RESET_DAILY_PUBLISHER_INSIGHT_STATE',
  DAILY_PUBLISHER_INSIGHTS: {
    REQUEST: 'DAILY_PUBLISHER_INSIGHTS.REQUEST',
    SUCCESS: 'DAILY_PUBLISHER_INSIGHTS.SUCCESS',
    FAILURE: 'DAILY_PUBLISHER_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
