import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { toTitleCase, normalizePhone } from '../sharedFunction';

const googleLeadFormImportLead = (lead) => {
  const createdAt = Date.parse(`${lead['Ora di invio UTC']} UTC`)
    ? Timestamp.fromDate(new Date(`${lead['Ora di invio UTC']} UTC`))
    : serverTimestamp();

  return {
    firstName: toTitleCase(lead['FIRST_NAME'].trim()),
    lastName: toTitleCase(lead['LAST_NAME'].trim()),
    email: lead['EMAIL'].trim(),
    campaignId: lead['ID campagna'].trim(),
    source: 'googleAds',
    adsetId: lead['ID gruppo di annunci'].trim(),
    phone: normalizePhone(lead['PHONE_NUMBER'].trim()),
    createdAt,
  };
};

export default googleLeadFormImportLead;
