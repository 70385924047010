import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';
import { Form } from '@availity/form';
import { Input, Select } from '../../lib/form-field';
import '@availity/yup';
import formValidation from './formValidation';

import * as surveyFormsActions from '../../store/surveyForms/actions';
import * as messageTemplatesActions from '../../store/messageTemplates/actions';
import {
  selectMessageTemplate,
  selectMessageTemplateWithoutSurveyForms,
} from '../../selectors/messageTemplate';
import { useSelector, useDispatch } from 'react-redux';

import { components } from 'react-select';
import { capitalize } from '../../helpers/sharedFunction';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import surveyFormTypes, { types } from '../../config/surveyFormTypes';

const { Option } = components;

const MessageTemplateSelectOption = (props) => (
  <Option {...props}>
    {props.data.type
      ? `${capitalize(props.data.type)}${
          props.data.linkType ? ' - ' + capitalize(props.data.linkType) : ''
        } - ${props.data.label}`
      : props.data.label}
  </Option>
);

const FormSurveyForm = (props) => {
  const editingSurveyForm = props.surveyForm || {};

  const messageTemplate = useSelector(
    selectMessageTemplate(editingSurveyForm.messageTemplateId),
  );
  const messageTemplates = useSelector(selectMessageTemplateWithoutSurveyForms);

  const messageTemplateForSelect = [
    ...(messageTemplate ? [messageTemplate] : []),
    ...messageTemplates,
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(messageTemplatesActions.syncMessageTemplates());
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      props.create
        ? surveyFormsActions.createSurveyForm(values)
        : surveyFormsActions.updateSurveyForm({
            id: editingSurveyForm.id,
            ...values,
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={editingSurveyForm}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="6">
                  <Input type="text" name="name" id="name" />
                </Col>
                <Col md="3">
                  <Select
                    name="provider"
                    id="provider"
                    isMulti={false}
                    options={[
                      {
                        label: 'Papeform.co',
                        value: 'papeform.co',
                      },
                    ]}
                  />
                </Col>
                <Col md="3">
                  <Select name="type" id="type" options={surveyFormTypes} />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Select
                    name="messageTemplateId"
                    id="messageTemplateId"
                    label="Message Template"
                    options={messageTemplateForSelect.map(
                      ({ id, name, templateType, linkType }) => ({
                        value: id,
                        label: name,
                        type: templateType,
                        linkType: linkType,
                      }),
                    )}
                    components={{ Option: MessageTemplateSelectOption }}
                  />
                  <p className="font-size-12 text-muted">
                    {'This template must have {{link}} macro'}
                  </p>
                </Col>
                <Col md="6">
                  <Input
                    name="urlSchema"
                    id="urlSchema"
                    type="textarea"
                    label="Survey URL schema"
                  />
                  <p className="font-size-12 text-muted">
                    {values.type === types.MARKETING_CAMPAIGN
                      ? `Insert valid URL with required macro parameters in GET: 'personId' or 'leadId'`
                      : `Insert valid URL with macro parameters in GET like {{firstName}} 
                      ...)`}
                  </p>
                </Col>
              </Row>
              <Collapse isOpen={values.type === types.DATA_ENRICHMENT}>
                <Row>
                  <Col md="6">
                    <Input
                      name="headerCSV"
                      id="headerCSV"
                      label="Header CSV Partial Surveys"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex. value1,value2,value3,
                      ...)
                    </p>
                  </Col>
                  <Col md="6">
                    <Input
                      name="personalAttributes"
                      id="personalAttributes"
                      label="Personal attributes"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex.
                      carOwner,maritalStatus,qualification, ...)
                    </p>
                  </Col>
                </Row>
              </Collapse>
              <Collapse isOpen={values.type === types.MARKETING_CAMPAIGN}>
                <Row>
                  <Col md="6">
                    <Input
                      name="proceedAnswer"
                      id="proceedAnswer"
                      label="Proceed Answer String"
                    />
                  </Col>
                </Row>
              </Collapse>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSurveyForm;
