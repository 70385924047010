import { takeEvery, put, all, call } from 'redux-saga/effects';
import types from './actionTypes';
import * as dailyInsightsActions from './actions';
import { getFirestore, query, collection, orderBy } from 'firebase/firestore';
import rsf from '../../../helpers/firebase';
import { toDateFirebase } from '../../../helpers/sharedFunction';
import moment from 'moment';

function* fetchDailyInsightsSaga() {
  try {
    const db = getFirestore(rsf.app);
    const dailyInsightsRef = query(
      collection(db, 'dailyLeadInsights'),
      orderBy('date', 'desc'),
    );

    const dailyInsightsSnap = yield call(
      rsf.firestore.getCollection,
      dailyInsightsRef,
    );

    let dailyInsights = [];

    dailyInsightsSnap.forEach((stat) => {
      const data = stat.data();

      const date = toDateFirebase(stat, data, 'date').toDate();

      dailyInsights.push({
        id: stat.id,
        ...data,
        date,
        dateToString: moment(date).format('dddd, DD/MM/YYYY'),
      });
    });

    yield put(dailyInsightsActions.fetchDailyInsightsSuccess(dailyInsights));
  } catch (error) {
    yield put(dailyInsightsActions.fetchDailyInsightsFailure(error));
  }
}

function* dailyInsightsSaga() {
  yield all([
    takeEvery(types.DAILY_LEAD_INSIGHTS.REQUEST, fetchDailyInsightsSaga),
  ]);
}

export default dailyInsightsSaga;
