import '@availity/yup';
import * as yup from 'yup';

import roles from '../../config/roles';

const formValidation = (emailsInUse) =>
  yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    displayName: yup.string().required(),
    email: yup.string().email().notOneOf(emailsInUse),
    acquisitionOrderIds: yup.mixed().nullable(),
    role: yup.string().required(),
    active: yup.boolean().required(),
    phoneExchangeId: yup.string().when('role', {
      is: roles.OPERATOR,
      then: () => yup.string().required(),
    }),
    percentageNewCalls: yup.number().min(0),
    dailyGoalCalls: yup.number().min(1),
    countryIds: yup.array().when('role', {
      is: (value) => value !== roles.SUPER_ADMIN,
      then: () => yup.array().required(),
    }),
    supplierId: yup.string().when('role', {
      is: (value) => value === roles.PUBLISHER || value === roles.SALE,
      then: () => yup.string().required(),
    }),
    customerId: yup.string().when('role', {
      is: (value) => value === roles.CUSTOMER,
      then: () => yup.string().required(),
    }),
  });

export default formValidation;
